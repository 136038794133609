export default {
  title: 'Информация по рассылке',
  description: 'Итоговое количество получателей и примерная стоимость рассылки.<br><br>' +
    'Нажмите <b><i class="icon-reload-alt"></i> Обновить подсчет</b> если список групп для рассылки и исключения изменился.',
  recipients: 'контактов',
  will_be_reserved: 'будет зарезервировано',
  placeholder: 'Как только большая часть сообщений в рассылке доставится баланс будет обновлен.',
  duplicates_has_been: 'дублей',
  cleared: 'очищено',
  contacts_has_been: 'контактов',
  excluded: 'исключено',
};

import api from '~/api';
import {getProjectOnePromises} from "~/store/project";
import {getChannelOnePromises} from "~/store/channel-and-gate/channel-and-gate/channel";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10} = {}) {
    return api.documents.layout.list(page, pagesize).then(async response => {
      //console.info('api.documents.layout.list | response = ', response.data);
      let items = response.data.Body || [];
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.layout.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {id}) {
    return api.documents.layout.get(id).then(async response => {
      //console.info('api.documents.layout.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.layout.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.layout.post(data).then(response => {
      //console.info('api.documents.layout.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.layout.post | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.layout.delete(id).then(response => {
      //console.info('api.documents.layout.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.layout.put | error = ', error);
      throw error;
    });
  },
};

export function getLayoutOnePromises(observer, item, layout_id_name = 'layout_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[layout_id_name]) {
      observer.subscribeObserver(
        'channel-and-gate/layout/one',
        {id: item[layout_id_name]},
        (layout) => {
          item.layout = layout;
          resolve(item);
        },
      );
    } else {
      item.layout = null;
      resolve(item);
    }
  });
}

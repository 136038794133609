import type { ActionTree } from 'vuex';
import api from '~/api';
import { RootState } from '.';

export type State = {};

export type ReqProfileEdit = {
  id: string;
  phone: string;
  firstName: string;
  secondName: string;
  lastName: string;
};

export type ReqProfilePasswordEdit = {
  old_password: string;
  new_password: string;
};

const stateInit: State = {};

export const state = () => stateInit;

export const actions: ActionTree<State, RootState> = {
  async edit(
    { commit },
    { id, phone, firstName, secondName, lastName }: ReqProfileEdit
  ) {
    const response = await api.profile.put(id, {
      phone,
      firstName,
      secondName,
      lastName,
    });
    commit('auth/setUser', response.data, { root: true });

    return response.data;
  },
  async passwordEdit(
    { dispatch, rootState },
    { old_password, new_password }: ReqProfilePasswordEdit
  ) {
    const response = await api.profile.passwordPut({
      old_password,
      new_password,
    });

    if (!rootState.auth.credentials) return;

    const { email } = JSON.parse(atob(rootState.auth.credentials));
    await dispatch(
      'auth/login',
      { email, password: new_password },
      { root: true }
    );

    return response.data;
  },
};

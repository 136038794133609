import { render, staticRenderFns } from "./MenuMainBoxDashboardComponent.vue?vue&type=template&id=40e0e504&scoped=true&"
import script from "./MenuMainBoxDashboardComponent.vue?vue&type=script&lang=ts&"
export * from "./MenuMainBoxDashboardComponent.vue?vue&type=script&lang=ts&"
import style0 from "./MenuMainBoxDashboardComponent.vue?vue&type=style&index=0&id=40e0e504&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@3.4.32_babel-core@7.0.0-bridge.0_@babel+core@7.24.9__css_uv5biolnn6ywtbh3eesigpjl3u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e0e504",
  null
  
)

export default component.exports
import message_type_option from '~/locales/en/ClientSender/TrailerProfile/Viber/MessageTypeOption';
import company_type_option from '~/locales/en/ClientSender/TrailerProfile/Viber/CompanyTypeOption';
import message_volume_option from '~/locales/en/ClientSender/TrailerProfile/Viber/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/en/ClientSender/TrailerProfile/Viber/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/en/ClientSender/TrailerProfile/Viber/ServiceLaunchDateOption';
import commercial_account_type_option from '~/locales/en/ClientSender/TrailerProfile/Viber/CommercialAccountTypeOption';

export default {
  commercial_account_type_option,
  service_name_original_language: 'Service name (original language)',
  service_name_english_language: 'Service name (English)',
  commercial_account_type: '',
  commercial_account_type_helper: 'Commercial Account needs to be created if this is your first sender or if you don\'t want to link this sender to existing CA.',
  website: 'Website',
  website_helper: 'Optional. Website for your bussines, must be a valid link complete with protocol. Up to 255 characters.',
  ca_new_description: 'Description',
  ca_new_description_helper: '(This is the description that will be written as your Commercial Account description IN the Viber app and will be presented as part of your CA info page, that is accessible to all your message recipients) 10-1000 characters',
  ca_new_logo_img: 'CA logo',
  ca_new_logo_img_helper: 'This image will be displayed in search results. Recommended dimensions: 256x256px.',
  ca_new_logo_chatscreen: 'CA Chatscreen',
  ca_new_logo_chatscreen_helper: 'This image will be displayed as a chatscreen on a page with detailed information on your CA. Recommended dimensions: 360x280px.',
  ca_exist_commercial_account_id: 'Commercial Account ID',
  ca_exist_commercial_account_id_helper: 'ID of already existing CA. Sender will be linked to this CA and be shown as one of available chats for your business.<br>If you have a CA and don\'t know it\'s ID, please contact our technical support.',
  description: 'Description',
  description_helper: '(This is the description that will be written as your business description IN the Viber app and will be presented as part of your business info page, that is accessible to all your message recipients) 10-400 characters.',
  logo_img: 'Sender logo',
  logo_img_helper: 'This image will be displayed in search results and in chat list. Recommended dimensions: 130x130px.',
  logo_chatscreen: 'Chatscreen',
  logo_chatscreen_helper: 'This image will be displayed as a chatscreen on a page with detailed information on your Sender. Recommended dimensions: 360x280px.\n',
  business_address: 'Business Address',
  business_address_helper: 'Addresses for your business. Up to 255 characters.',
  business_phone_number: 'Business Phone Number',
  business_phone_number_helper: 'Optional. Phone number for your business. Сan contain numbers, spaces, dashes, pluses, stars, and round bracket characters only. Up to 20 characters.',
  message_type: 'Message type',
  message_type_option,
  service_registered_country: 'Service Registered Country',
  company_type: 'Company Type',
  company_type_option,
  message_volume: 'Messaging volume per month',
  message_volume_option,
  service_integration_type: 'Service Integration Type',
  service_integration_type_option,
  destination_countries: 'Destination countries',
  service_launch_date: 'Service Launch Date',
  service_launch_date_option,
  sample_message_promotional: 'Sample Message',
  sample_message_transactional: 'Sample Message (Transactional)',
  company_official_letter: 'Company official letter (PDF)',
};

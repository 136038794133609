export default {
  title: 'Создать ссылку',

  project_id: 'Проект',
  name_placeholder: 'Ссылка {time}',
  select_project: 'Проект',
  select_project_title: 'Выберите проект',
  project_id_placeholder: 'Выберите проект',
  link_shortening: 'Короткая ссылка',
  select_domain: 'Домен',
  domain_id: 'Домен',
  uri_long: 'Длинный URL',
  uri_short: 'Короткий URL',
  uri_long_placeholder: 'Ваш длинный URL',
  uri_short_placeholder: 'Короткий адрес',

  save_success: 'Готово',

  error_uri_short_duplicate: 'Url уже существует',
  error_attempt_max: 'Превышено количество новых статических ссылок в день',
  error_not_active_sender: 'Нет активированного отправителя',
}

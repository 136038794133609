export default {
  loading: 'Завантаження...',

  name: 'Назва',
  tenant_id: 'Компания',
  channel: 'Канал',
  status: 'Статус',
  created_at: 'Дата',

  delete_confirm: 'Ви дійсно хочете видалити шаблон "{name}"?',
  delete_success: 'Шаблон "{name}" видалено.',
}

export default {
  start_title: 'The report in progress',
  end_title: 'The report is ready!',
  end_description: 'Download the report or find it in the Documents section',

  btn_title: 'Export chats history',
  modal_title: 'Export chats history',
  modal_description: 'Please, specify the period. All received and sent messages from every chat will be collected to the report.<br>Don\'t close the window while report is proceeded.',
  from: 'From',
  to: 'To',
  create_report: 'Create report',
}

export default {
  title: 'Switch to Corporate tariff plan',
  description: 'Take advantage of the additional features of Messaggio for multichannel messaging!',
  month_1: '1 month',
  free: 'Activate for free!',
  month_3: '3 months',
  month_6: '6 months',
  month_12: '12 months',
  more_info: 'More info',
  free_month_1: '1 month for free!',
  free_month_2: '2 months for free!',

  error: {
    empty_contract: 'Please, select a contract to continue',
  },
};

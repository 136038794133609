export default {
  progress: {
    title: 'The contact group file is processed',
    description: 'The {file_name} file is converted into .csv format.<br/>Once the process is complete, you can finish importing your contacts.',
  },
  success: {
    title: 'The file is ready to import contacts',
    description: 'The {file_name} file has been successfully converted.<br/>Click the "Continue" button to finish the import of the contacts.',
    btn_continue: 'Continue to',
  },
};

import merge from 'lodash.merge';
import api from '~/api';
import { getProjectOnePromises } from '~/store/project';
import { getChannelOnePromises } from '~/store/channel-and-gate/channel-and-gate/channel';
import ObserverApi from '~/utils/ObserverApi';
import { arrayToObject } from '~/utils/functions';
import * as uRelate from '~/utils/relate';

let clientSenderStatusActive;
let clientSenderStatusDeact;

export const state = () => ({
  // Виджет под левым меню
  showList: {
    loading: {},
    data: [],
  },
  // Только clientsender, без зависимостей
  list: {
    loading: {},
    error: {},
    item: {},
  },
  // Id созданых записей
  createdIds: [],
});

export const getters = {};

export const mutations = {
  showListLoading (state, {
    project_id,
    loading,
  }) {
    state.showList.loading = {
      ...state.showList.loading,
      [project_id]: loading,
    };
  },
  showListDataAdd (state, items) {
    const data1 = arrayToObject(state.showList.data);
    const data2 = arrayToObject(items);
    state.showList.data = Object.values({ ...data1, ...data2 });
  },
  addCreatedIds (store, id) {
    store.createdIds.push(id);
  },
  deleteCreatedIds (store, id) {
    store.createdIds.splice(store.createdIds.indexOf(id), 1);
  },

  listLoading (state, {
    id,
    loading,
    error,
  }) {
    state.list.loading = {
      ...state.list.loading,
      [id]: loading,
    };
    if (loading) {
      delete state.list.error[id];
    } else if (error) {
      state.list.error = {
        ...state.list.error,
        [id]: error,
      };
    }
  },
  listItemAdd (state, data) {
    state.list.item = {
      ...state.list.item,
      [data.id]: data,
    };
  },
};

export const actions = {
  async showListLoad ({
    dispatch,
    commit,
  }, {
    project_id = null,
    page = 1,
    pagesize = 5,
  }) {
    commit('showListLoading', {
      project_id,
      loading: true,
    });
    const result = await dispatch('list', {
      project_id,
      page,
      pagesize,
      relate: [
        uRelate.PROJECT,
        uRelate.CHANNEL,
      ],
    });
    commit('showListDataAdd', result.items);
    commit('showListLoading', {
      project_id,
      loading: false,
    });
  },
  list ({
    dispatch,
    commit,
    state,
    rootState,
  }, {
    project_id = null,
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch, relate);
    }

    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'id';
    let apiEnpoint;
    if (project_id === null) {
      apiEnpoint = api.documents.clientsender.list(page, pagesize, sort, options);
    } else {
      apiEnpoint = api.documents.project.clientsenders(project_id, page, pagesize, sort, options);
    }
    return apiEnpoint.then(async response => {
      // console.info('api.documents.clientsender.list | response = ', response.data);
      const items = response.data.Body || [];
      const promises = [];
      if (uRelate.include(uRelate.PROJECT, observer.relate)) {
        promises.push(...items.map(item => getProjectOnePromises(observer, item, item.project_id)));
      }
      if (uRelate.include(uRelate.CHANNEL, observer.relate)) {
        promises.push(...items.map(item => getChannelOnePromises(observer, item, item.channel_id)));
      }
      await Promise.all(promises);
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.documents.clientsender.list | error = ', error);
      throw error;
    });
  },
  one ({
    dispatch,
    commit,
    state,
    rootState,
  }, {
    id,
    observer = null,
    relate = [],
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch, relate);
    }

    commit('listLoading', {
      id,
      loading: true,
    });

    return api.documents.clientsender.get(id).then(async response => {
      // console.info('api.documents.clientsender.one | response = ', response.data);
      let item = response.data || {};

      const promises = [];
      if (uRelate.include(uRelate.PROJECT, observer.relate)) {
        promises.push(getProjectOnePromises(observer, item, item.project_id));
      }
      promises.push(getChannelOnePromises(observer, item, item.channel_id));
      promises.push(dispatch(
        'trailer/setting/list',
        {
          entity_id: item.id,
          key: 'clientsender_ttl_show',
          pagesize: 1,
        },
        { root: true },
      ).then(({ items }) => {
        item = merge(item, {
          trailer: {
            clientsender_ttl_show: items[0],
          },
        });
      }));
      await Promise.all(promises);

      commit('showListDataAdd', [item]);

      commit('listItemAdd', item);
      commit('listLoading', {
        id,
        loading: false,
      });

      return Object.assign({}, item);
    }).catch(error => {
      console.error('api.documents.clientsender.one | error = ', error);

      commit('listLoading', {
        id,
        loading: false,
        error,
      });

      throw error;
    });
  },
  trailerProfile ({
    commit,
    state,
    dispatch,
    rootState,
  }, {
    id,
    observer = null,
    relate = [],
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch, relate);
    }

    return dispatch(
      'trailer/setting/list',
      {
        entity_id: id,
        key: 'profile.*',
        pagesize: 99999,
      },
      { root: true },
    ).then(result => Promise.all(
      result.items.map(item => new Promise(async resolve => {
        switch (item.key) {
          case 'profile.viber_company_official_letter':
          case 'profile.sms_company_official_letter':
            if (item.value.length) {
              const [category, shortHash, name] = item.value.split('/');
              await api.drive.file.get(category, shortHash, name)
                .then(apiCallResult => item.drive_file_info = apiCallResult.data)
                .catch(e => ({}));
            }
            resolve(item);
            break;
          case 'profile.viber_destination_countries':
          case 'profile.viber_service_registered_country':
          case 'profile.whatsapp_destination_countries':
          case 'profile.whatsapp_service_registered_country':
          case 'profile.sms_destination_countries':
          case 'profile.sms_service_registered_country':
            item.value = (item.value || '').split(',');
            item.list = await Promise.all(item.value
              .filter(code => !!code.length)
              .map(code => new Promise(resolve => observer.subscribeObserver(
                'directory/country/one', { code },
                ({
                  code: id,
                  name,
                }) => resolve({
                  id,
                  text: name[rootState.locale],
                }),
              ))));
            if ([
              'profile.viber_service_registered_country',
              'profile.whatsapp_service_registered_country',
              'profile.sms_service_registered_country',
            ].includes(item.key)) {
              item.value = item.value.join(',');
            }
            resolve(item);
            break;
          default:
            resolve(item);
        }
      })),
    )).then(rs => rs.reduce((rt, r) => (rt[r.key.substr(8)] = r, rt), {}));
  },
  create ({
    commit,
    state,
  }, { data }) {
    data = { ...data };
    delete data.id;
    return api.documents.clientsender.post(data).then(response => {
      // console.info('api.documents.clientsender.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.post | error = ', error);
      throw error;
    });
  },
  edit ({
    commit,
    state,
  }, {
    id,
    data,
  }) {
    data = { ...data };
    return api.documents.clientsender.put(id, data).then(response => {
      // console.info('api.documents.clientsender.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.put | error = ', error);
      throw error;
    });
  },
  delete ({
    commit,
    state,
  }, { id }) {
    return api.documents.clientsender.delete(id).then(response => {
      // console.info('api.documents.clientsender.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.delete | error = ', error);
      throw error;
    });
  },
  statuses ({
    commit,
    state,
  }) {
    return api.documents.clientsender.statuses.list().then(response => {
      // console.info('api.documents.clientsender.statuses | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.statuses | error = ', error);
      throw error;
    });
  },
  comparisonSender ({
    commit,
    state,
  }, {
    sender_ids,
    clientsender_ids,
  }) {
    const promises = [];
    sender_ids.map(sender_id => {
      clientsender_ids.map(clientsender_id => {
        promises.push(
          api.documents.clientsender.comparison_sender(clientsender_id, sender_id).then(response => {
            // console.info('api.documents.clientsender.comparison_sender | response = ', response.data);
            return response.data;
          }).catch(error => {
            console.error('api.documents.clientsender.comparison_sender | error = ', error);
            throw error;
          }),
        );
      },
      );
    });
    return Promise.all(promises);
  },
  comparisonSenderDelete ({
    commit,
    state,
  }, {
    sender_ids,
    clientsender_ids,
  }) {
    const promises = [];
    sender_ids.map(sender_id => {
      clientsender_ids.map(clientsender_id => {
        promises.push(
          api.documents.clientsender.comparison_sender_delete(clientsender_id, sender_id).then(response => {
            // console.info('api.documents.clientsender.comparison_sender_delete | response = ', response.data);
            return response.data;
          }).catch(error => {
            console.error('api.documents.clientsender.comparison_sender_delete | error = ', error);
            throw error;
          }),
        );
      },
      );
    });
    return Promise.all(promises);
  },
  moderation ({ dispatch }, { id }) {
    return api.saga.clientsender.moderation(id).then(response => {
      // console.info('api.documents.clientsender.moderation | response = ', response.data);

      dispatch('clientsender/countByStatus/fetch', {}, { root: true });

      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.moderation | error = ', error);
      throw error;
    });
  },
};

export function getClientSenderOnePromises (observer, item, id_name = 'client_sender_id') {
  return new Promise(async resolve => {
    if (!item) {
      resolve(null);
    } else if (item.client_sender) {
      resolve(item);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'clientsender/one', { id: item[id_name] },
        client_sender => {
          item.client_sender = client_sender;
          resolve(item);
        },
      );
    } else {
      item.client_sender = null;
      resolve(item);
    }
  });
}

import message_type_option from '~/locales/uk/ClientSender/TrailerProfile/Viber/MessageTypeOption';
import company_type_option from '~/locales/uk/ClientSender/TrailerProfile/Viber/CompanyTypeOption';
import commercial_account_type_option from '~/locales/uk/ClientSender/TrailerProfile/Viber/CommercialAccountTypeOption';
import message_volume_option from '~/locales/uk/ClientSender/TrailerProfile/Viber/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/uk/ClientSender/TrailerProfile/Viber/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/uk/ClientSender/TrailerProfile/Viber/ServiceLaunchDateOption';

export default {
  commercial_account_type_option,
  service_name_original_language: 'Назва (оригінальною мовою)',
  service_name_english_language: 'Назва (англійською)',
  commercial_account_type: '',
  commercial_account_type_helper: 'Комерційний Акаунт необхідно створити, якщо це ваш перший відправник або якщо ви не хочете пов\'язувати цього відправника з існуючим КА.',
  website: 'Адреса сайту',
  website_helper: 'Опціонально. Веб-сайт для вашого бізнесу. Повинен бути дiючим посиланням із зазначеним протоколом. До 255 символів.',
  ca_new_description: 'Опис',
  ca_new_description_helper: '(Опис для Комерційного Облікового запису, який відображатиметься у Viber для користувачів.) 10-1000 символів.',
  ca_new_logo_img: 'Лого КА',
  ca_new_logo_img_helper: 'Це зображення відображатиметься в результаті пошуку по чатам. Рекомендована роздільна здатність: 256x256px.',
  ca_new_logo_chatscreen: 'Обкладинка КА',
  ca_new_logo_chatscreen_helper: 'Це зображення відображатиметься як чатскрин на сторінці з детальною інформацією про Ваш Комерційний Акаунт. Рекомендована роздільна здатність: 360x280px.',
  ca_exist_commercial_account_id: 'ID Комерційного Аккаунту',
  ca_exist_commercial_account_id_helper: 'ID існуючого Комерційного Аккаунту. Ім\'я Відправника буде закріплене за цим КА та відображатиметься у списку доступних чатів Вашої компанії.<br>Якщо у Вас є Комерційний Акаунт і Ви не знаєте його ID, зверніться до нашої технічної підтримки.\n',
  description: 'Опис',
  description_helper: '',
  logo_img: 'Логотип відправника',
  logo_img_helper: 'Це зображення відображатиметься в результаті пошуку чатів та списку чатів. Рекомендована роздільна здатність: 130x130px.',
  logo_chatscreen: 'Обкладинка',
  logo_chatscreen_helper: 'Це зображення буде відображатися як чатскрин на сторінці з детальною інформацією про Ваше Ім\'я Відправника. Рекомендована роздільна здатність: 360x280px.',
  business_address: 'Адреса компанії',
  business_address_helper: 'Адреса вашої компанії. До 255 знаків.',
  business_phone_number: 'Номер телефону компанії',
  business_phone_number_helper: 'Опціонально. Номер телефону Вашої компанії Може складатися з цифр, пробiлiв, дефісів, знаків плюсу, астерісків та круглих дужок. До 20 символів.',
  business_email: 'Email компанії',
  message_type: 'Тип повідомлень',
  message_type_option,
  service_registered_country: 'Країна знаходження компанії',
  company_type: 'Тип компанії',
  company_type_option,
  message_volume: 'Об`єм відправлених повідомлень в місяць',
  message_volume_option,
  service_integration_type: 'Тип інтеграції',
  service_integration_type_option,
  destination_countries: 'Країни розсилки',
  service_launch_date: 'Дата запуску',
  service_launch_date_option,
  tin_company: 'ІПН (Індивідуальный податковий номер) компании',
  sample_message_promotional: 'Приклад повідомлення',
  sample_message_transactional: 'Приклад транзакційного повідомлення',
  company_official_letter: 'Лист на бланку організації (PDF)',
};

import progress_box from '~/locales/en/GroupContact/Contact/Convert/ProgressBox';

export default {
  progress_box,

  badge: {
    title: 'Обработка',
    toottip: 'Файл загруженный для импорта контактов конвертируется в формат .csv. По завершению процесса Вы сможете завершить импорт контактов.',
  },

  modal: {
    title: 'Подготовка файла',
  },

  final_modal: {
    title: 'Конвертация файла завершена',
    text: 'Файл {file_name} был успешно конвертирован и готов для использования в импорте контактов.',
    cancel_confirm: 'Вы уверены, что хотите отменить конвертацию?',
    btn: {
      cancel: 'Отмена',
      confirm: 'К группе контактов',
    },
  },
};

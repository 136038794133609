export default {
  title: 'Выберите тариф',
  description: 'Сообщения и абонентская плата за имя отправителя оплачиваются отдельно.',
  header: {
    choose_plan: 'Возможности',
    free: 'Стандартный',
    corporate: {
      title: 'Корпоративный',
      price: '490',
      price_old: '660',
    },
    month1: 'месяц',
    month2: 'месяца',
    month5: 'месяцев',
    month_sm: 'мес.',
    top_value: 'ВЫГОДНО',
    currency: '₽',
  },
  features: {
    channels: 'Каналы рассылки',
    account_users: 'Пользователи',
    projects: 'Проекты',
    test_phones: {
      title: 'Тестовые номера',
      tooltip: 'Тестирование рассылки на вашем устройстве и устройствах ваших коллег.',
    },
    contacts_upload: 'Контакты',
    message_templates: 'Шаблоны сообщений',
    online_chats: {
      title: 'Онлайн-чаты',
      tooltip: 'Интерфейс для обмена сообщениями с клиентами. Оптимизируйте время реакции на обращения в компанию и сделайте процесс поддержки клиентов в мессенджерах более организованным.',
    },
    template_variables: {
      title: 'Персонализация сообщений',
      tooltip: 'Переменные для персонализации рассылки: имя, фамилия, дата, промокод и др. Персонализация помогает вовлекать пользователей и повышать кликабельность сообщений.',
    },
    api_plugin_integration: {
      title: 'API & Plugin интеграции',
      tooltip: 'Интеграция рассылок в вашу систему. Готовые решения по интеграции с популярными CRM и CMS.',
    },
    scheduling_dispatches: {
      title: 'Запланированная отправка',
      tooltip: 'Рассылку можно запланировать на конкретную дату и час. Создайте несколько рассылок заранее и не беспокоитесь — сообщения автоматически отправятся в указанное время.',
    },
    smooth_sending: {
      title: 'Плавная отправка',
      tooltip: 'Рассылку можно отправить в течение нескольких часов. Плавная отправка сообщений помогает защитить call-центр от большого потока одновременных заявок.',
    },
    best_time_delivery: {
      title: 'Доставка в лучшее время',
      tooltip: 'Отправка сообщений в момент, когда пользователь с наибольшей вероятностью откроет рассылку. Messaggio определяет лучшее время для отправки сообщения по накопленной истории рассылок абоненту.',
    },
    ab_testing: {
      title: 'A/B-тестирование рассылок',
      tooltip: 'Инструмент для проверки гипотез и поиска эффективных шаблонов сообщений. Масштабируйте решения, которые получают наиболее высокий отклик получателей.',
    },
    url_shortener: {
      title: 'Короткие ссылки и отслеживание кликов',
      tooltip: 'Сокращатель ссылок позволяет отобразить в сообщении привлекательный URL без UTM-меток. Клики по таким ссылкам фиксируются и отображаются в статистике — это поможет оценить конверсию рассылки.',
    },
    cascading: {
      title: 'Каскадные рассылки',
      tooltip: 'Доотправка сообщения в SMS, если мессенджера на устройстве пользователя не оказалось.',
    },
    action_history: {
      title: 'История действий',
      tooltip: 'История действий в аккаунте Messaggio с привязкой к пользователю.',
    },
    oauth2: {
      title: 'Двухфакторная авторизация',
      tooltip: 'Дополнительная защита аккаунта Messaggio через Google Auth.',
    },
    support: {
      title: 'Приоритетная поддержка',
      tooltip: 'Стандартное время ответа — 1 час. Обращения от клиентов с тарифом «Корпоративный» обрабатываются в течение 15 минут.',
    },
  },
  unlimited: 'Не ограничено',
  products: {
    trial: {
      title: 'Попробуйте один месяц тарифа Corporate бесплатно!',
      description: 'Для активации пробного периода не требуется банковская карта.<br>На протяжении и по окончанию пробного периода дополнительная плата за подписку взыматься не будет.',
      btn: 'Попробовать сейчас',
    },
    months1: {
      title: '490 / 1',
      price: '1470',
    },
    months3: {
      title: '1470 / 3',
      price: '1470',
    },
    months6: {
      title: '2450 / 6',
      price: '2450',
      free: '1 месяц бесплатно!',
      free_sm: '1 меc. бесплатно!',
    },
    months12: {
      title: '4900 / 12',
      price: '4900',
      free: '2 месяца бесплатно!',
      free_sm: '2 месc. бесплатно!',
    },
    btn: 'Активировать',
  },
  errors: {
    B187: 'Ошибка B187. Напишите на <a href="mailto:support@messaggio.com">support@messaggio.com</a>',
  },
};

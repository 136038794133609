<template>
  <div>
    <template v-if="!listClientSendersLength">
      <widget-stub-empty
        icon-static="image/scheme/sender.png"
        trans-prefix="template.form.sender_empty"
        route-create-name="sender-create"
        route-select-all-project-name="template-create"
      />
    </template>
    <template v-else>
      <h6 class="card-title">
        {{ $t(`${tForm}.client_sender_id_title`) }}
      </h6>
      <p
        class="mb-3"
        v-html="$t(`${tForm}.client_sender_id_description`)"
      />
      <form-channel
        v-for="channel in listChannels"
        v-if="['mobileid', 'flashcall'].includes(channel.name) === false"
        :key="`channel-${channel.id}`"
        :t-form="tForm"
        :channel="channel"
        :client-senders="listClientSenders[channel.id]"
        :client-sender-id="clientSender[channel.id] || ''"
        @update-channel="onUpdateChannel"
      />
    </template>
  </div>
</template>

<script>
import FormChannel from '@/components/Template/Form/Channel/ChannelBaseFormTemplateComponent.vue';
import WidgetStubEmpty from '@/components/Widget/WidgetStubEmpty';

export default {
  components: {
    FormChannel,
    WidgetStubEmpty,
  },
  props: {
    tForm: {
      type: String,
      default: '',
    },
    formName: {
      type: String,
      default: '',
    },
    listChannels: {
      type: Array,
      default: () => ([]),
    },
    listClientSenders: {
      type: Object,
      default: () => ({}),
    },
    contents: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    listClientSendersLength () {
      return Object.keys(this.listClientSenders).length;
    },
    clientSender () {
      return this.contents.reduce((r, { channel_id, client_sender_id }) => {
        r[channel_id] = client_sender_id;
        return r;
      }, {});
    },
  },
  methods: {
    onUpdateChannel (data) {
      this.$emit('update-channel', data);
    },
  },
};
</script>

import upload from '~/locales/en/Company/Document/Upload';
import table from '~/locales/en/Company/Document/table';
import modal_info from '~/locales/en/Company/Document/ModalInfo';
import public_access from '~/locales/en/Company/Document/PublicAccess';
import widget_space from '~/locales/en/Company/Document/WidgetSpace';

export default {
  title: 'Files',

  description: '<p>You can attach files and documents to your messages, if this allows you to do a messaging channel. Financial correspondence from Messaggio will be available for download here.</p>',

  tab_doc_my: 'My files',
  tab_doc_company: 'Company files',
  tab_doc_mes: 'Files in Messages',

  upload,
  table,
  modal_info,
  public_access,
  widget_space,
}

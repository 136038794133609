export default {
  title_browser: 'Реєстрація',
  title: 'Реєстрація майже завершена',
  subtitle: 'Придумайте пароль не меньше 8 символів.',
  hash_empty: 'Hash не вказано!',
  email: 'Email адреса',
  password: 'Пароль',
  password_confirmed: 'ще раз пароль',
  register_btn: 'Готово',

  verified_was: 'Користувач вже завершив реєстрацію',
}

export default {
  brand: 'Бренд',
  media_agency: 'Рекламное агентство',
  local_business: 'Местный бизнес',
  financial_institution: 'Финансовый сервис',
  community_organization: 'Общественная организация',
  governmental_non_governmental_organization: 'Государственная / Негосударственная организация',
  other: 'Другое',
  publisher: 'Издательство',
  commerce: 'Торговля',
  ecommerce: 'Интернет торговля',
  real_estate_and_construction_companies: 'Недвижимость и строительство',
  it_and_internet_companies: 'IT и интернет сервисы',
  education: 'Образование',
  healthcare: 'Здравохранение',
  transportation_services: 'Транспортные сервисы',
}

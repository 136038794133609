export default {
  title: 'Надіслати шаблон на модерацію',
  description: 'Ви не матимете можливості відредагувати шаблон після відправки на модерацію.<br>Будь ласка, підтвердьте коректність інформації, що вказана нижче.',

  name: 'Назва',
  channel: 'Канал',
  preview: 'попередній перегляд',

  cancel: 'Відміна',
  ok: 'Надіслати на модерацію',

  status_change_success: 'Готово',
}

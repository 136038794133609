import api from '~/api';
import { arrayToObject } from '~/utils/functions';
import ObserverApi from '~/utils/ObserverApi';
import * as uRelate from '~/utils/relate';
import { getProjectOnePromises } from '~/store/project';
import { getGroupContactOnePromises } from '~/store/group-contact';
import { getChannelOnePromises } from '~/store/channel-and-gate/channel-and-gate/channel';
import { getClientSenderOnePromises } from '~/store/clientsender';
import { getDispatchDocumentScheduledTasksOnePromises } from '~/store/dispatch-document/scheduled';
import { getDispatchDocumentDestinationListPromises } from '~/store/dispatch-document/destination';
import { ActionTree, GetterTree, MutationTree } from '~/node_modules/vuex';
import { RootState } from '~/store';

export const state = () => ({
  // Виджет под левым меню
  showList: {
    loading: {},
    data: [],
  },
  // Только dispatch, без зависимостей
  list: {
    loading: {},
    error: {},
    item: {},
  },
  // Id созданых записей
  createdIds: [],
});

export type DispatchDocumentState = ReturnType<typeof state>

export const getters: GetterTree<DispatchDocumentState, RootState> = {};

export const mutations: MutationTree<DispatchDocumentState> = {
  showListLoading (state, { project_id, loading }) {
    state.showList.loading = { ...state.showList.loading, [project_id]: loading };
  },
  showListDataAdd (state, items) {
    const data1 = arrayToObject(state.showList.data);
    const data2 = arrayToObject(items);
    state.showList.data = Object.values({ ...data1, ...data2 });
  },

  listLoading (state, { id, loading, error }) {
    state.list.loading = { ...state.list.loading, [id]: loading };
    if (loading) {
      delete state.list.error[id];
    } else if (error) {
      state.list.error = { ...state.list.error, [id]: error };
    }
  },
  listItemAdd (state, data) {
    state.list.item = { ...state.list.item, [data.id]: data };
  },
  listItemUpdate (state, { id, ...data }) {
    state.list.item = { ...state.list.item, [id]: { ...state.list.item[id], ...data } };
  },
  listItemDelete (state, { id }) {
    delete state.list.item[id];
  },

  addCreatedIds (store, id) {
    store.createdIds.push(id);
  },
  deleteCreatedIds (store, id) {
    store.createdIds.splice(store.createdIds.indexOf(id), 1);
  },
};

export const actions: ActionTree<DispatchDocumentState, RootState> = {
  async showListLoad ({ dispatch, commit }, { project_id, page = 1, pagesize = 5 }) {
    commit('showListLoading', { project_id, loading: true });
    const result = await dispatch('list', {
      project_id,
      page,
      pagesize,
      relate: [
        uRelate.PROJECT,
      ],
    });
    commit('showListDataAdd', result.items);
    commit('showListLoading', { project_id, loading: false });
  },
  list ({ dispatch, commit }, {
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    relate = [],
    project_id = null,
    ...options
  } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    if (sort.length > 0) { sort += ','; }
    sort += 'id';

    let apiEnpoint;
    if (project_id === null) {
      apiEnpoint = api.documents.dispatch_document.list(page, pagesize, sort, options);
    } else {
      apiEnpoint = api.documents.project.dispatch_document(project_id, page, pagesize, sort, options);
    }
    return apiEnpoint.then(async response => {
      // console.info('api.documents.dispatch_document.list | response = ', response.data);
      const items = response.data.Body || [];
      const promises = [];
      if (uRelate.include(uRelate.PROJECT, observer.relate)) {
        promises.push(...items.map(item => getProjectOnePromises(observer, item, item.project_id)));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_INFO, observer.relate)) {
        promises.push(...items.filter(item => item.status !== 'new').map(item => getDispatchDocumentInfoOnePromises(observer, item, item.id)));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_CONTACT, observer.relate)) {
        promises.push(...items.filter(item => item.status === 'new').map(item => getDispatchDocumentContactOnePromises(observer, item, item.id)));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_CONTENT, observer.relate)) {
        promises.push(...items.map(item => getDispatchDocumentContentOnePromises(observer, item, item.id)));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_DESTINATION, observer.relate)) {
        promises.push(...items.map(item => getDispatchDocumentDestinationListPromises(observer, item, item.id)));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_SCHEDULER_TASKS, observer.relate)) {
        promises.push(...items.map(item => getDispatchDocumentScheduledTasksOnePromises(observer, item, item.id)));
      }
      await Promise.all(promises);

      items.forEach(item => {
        commit('listItemAdd', item);
        commit('listLoading', { id: item.id, loading: false });
      });

      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.documents.dispatch_document.list | error = ', error);
      throw error;
    });
  },
  one ({ dispatch, commit }, { id, observer = null, relate = [] } = {}) {
    // console.info('store/dispatch-document/one');
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    return api.documents.dispatch_document.get(id).then(async response => {
      // console.info('store/dispatch-document/one | api.documents.dispatch_document.one | response = ', response.data, ' | ', observer.relate, ' | ', uRelate.DISPATCH_DOCUMENT_DESTINATION);
      const item = response.data || {};
      const promises = [];
      if (uRelate.include(uRelate.PROJECT, observer.relate)) {
        promises.push(getProjectOnePromises(observer, item, item.project_id));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_CONTACT, observer.relate)) {
        promises.push(getDispatchDocumentContactOnePromises(observer, item, item.id));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_INFO, observer.relate)) {
        promises.push(getDispatchDocumentInfoOnePromises(observer, item, item.id));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_CONTENT, observer.relate)) {
        promises.push(getDispatchDocumentContentOnePromises(observer, item, item.id));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_DESTINATION, observer.relate)) {
        promises.push(getDispatchDocumentDestinationListPromises(observer, item, item.id));
      }
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_SCHEDULER_TASKS, observer.relate)) {
        promises.push(getDispatchDocumentScheduledTasksOnePromises(observer, item, item.id));
      }
      // response.data.content = await api.documents.dispatch_document.content(response.data.id).then(response => response.data);
      // response.data.layout = await api.documents.dispatch_document.layout(response.data.id, response.data.content.id).then(response => response.data);
      await Promise.all(promises);

      commit('showListDataAdd', [item]);

      commit('listItemAdd', item);
      commit('listLoading', { id, loading: false });

      return { ...item };
    }).catch(error => {
      console.error('store/dispatch-document/one | api.documents.dispatch_document.one | error = ', error);

      commit('listLoading', { id, loading: false, error });

      throw error;
    });
  },
  create (_, { data }) {
    data = { ...data };
    delete data.id;
    return api.documents.dispatch_document.post(data).then(response => {
      // console.info('api.documents.dispatch_document.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.post | error = ', error);
      throw error;
    });
  },
  edit (_, { id, data }) {
    data = { ...data };
    return api.documents.dispatch_document.put(id, data).then(response => {
      // console.info('api.documents.dispatch_document.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.put | error = ', error);
      throw error;
    });
  },
  abort (_, { id }) {
    return api.saga.dispatch.abort(id).then(response => {
      // console.info('api.saga.dispatch.abort | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.saga.dispatch.abort | error = ', error);
      throw error;
    });
  },
  async delete ({ commit }, { id }) {
    await api.scheduler.task.list(1, 9999, '', { entity_id: id })
      .then(response => response.data.Body || [])
      .then(async items => {
        await Promise.all(items.map(item => api.scheduler.task.delete(item.id)));
      });

    return api.documents.dispatch_document.delete(id).then(response => {
      // console.info('api.documents.dispatch_document.delete | response = ', response.data);
      commit('listItemDelete', { id });
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.delete | error = ', error);
      throw error;
    });
  },
  restore ({ commit }, { id }) {
    return api.documents.dispatch_document.restore(id).then(response => {
      // console.info('api.documents.dispatch_document.restore | response = ', response.data);
      commit('listItemDelete', { id });
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.restore | error = ', error);
      throw error;
    });
  },
  info (_, { id }) {
    return api.documents.dispatch_document.info(id).then(response => {
      // console.info('api.documents.dispatch_document.info | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.info | error = ', error);
      throw error;
    });
  },
  getContent ({ dispatch }, { id, observer = null, relate = [] }) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    return api.documents.dispatch_document.content(id).then(async response => {
      // console.info('api.documents.dispatch_document.content | response = ', response.data);
      const item = response.data || [];
      const promises = [];
      promises.push(getDispatchDocumentLayoutOnePromises(observer, item, item.template_id, item.id));
      if (uRelate.include(uRelate.CHANNEL, observer.relate)) {
        promises.push(getChannelOnePromises(observer, item, item.channel_id));
      }
      if (uRelate.include(uRelate.CLIENT_SENDER, observer.relate)) {
        promises.push(getClientSenderOnePromises(observer, item));
      }
      await Promise.all(promises);
      return item;
    }).catch(error => {
      console.error('api.documents.dispatch_document.content | error = ', error);
      throw error;
    });
  },
  getContentsByTemplate ({ dispatch }, { tid, observer = null, relate = [] }) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    return api.documents.template.content.list(tid, 1, 99999).then(async response => {
      // console.info('api.documents.dispatch_document.content | response = ', response.data);
      const items = response.data.Body || [];
      const promises = [];
      promises.push(...items.map(item => getDispatchDocumentLayoutOnePromises(observer, item, item.template_id, item.id)));
      if (uRelate.include(uRelate.CHANNEL, observer.relate)) {
        promises.push(...items.map(item => getChannelOnePromises(observer, item, item.channel_id)));
      }
      if (uRelate.include(uRelate.CLIENT_SENDER, observer.relate)) {
        promises.push(...items.map(item => getClientSenderOnePromises(observer, item)));
      }
      await Promise.all(promises);
      return items;
    }).catch(error => {
      console.error('api.documents.dispatch_document.content | error = ', error);
      throw error;
    });
  },
  getLayout ({ dispatch }, { tid, cid, observer = null, relate = [] }) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    return api.documents.template.content.layout
      .list(tid, cid)
      .then(response => {
      // console.info('dispatch-document/getLayout | response = ', response.data);
        return response.data || {};
      })
      .catch(error => {
        console.error('dispatch-document/getLayout | error = ', error);
        throw error;
      });
  },
  listContact ({ dispatch }, { id, observer = null, relate = [] }) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    return api.documents.dispatch_document.contacts.list(id, 1, 99999).then(async response => {
      const items = response.data.Body || [];
      const promises = [];
      if (uRelate.include(uRelate.GROUP_CONTACT, observer.relate)) {
        promises.push(...items.map(item => getGroupContactOnePromises(observer, item)));
      }
      await Promise.all(promises);
      return items;
    }).catch(error => {
      console.error('api.documents.dispatch_document.contact | error = ', error);
      throw error;
    });
  },
  dispatches (_, { dispatch_id }) {
    return api.dispatcher.dispatches.post(dispatch_id).then(response => {
      // console.info('api.dispatcher.dispatches.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.dispatcher.dispatches.post | error = ', error);
      throw error;
    });
  },
  dispatchesProgress (_, { id }) {
    return api.dispatcher.dispatches.progress(id).then(response => {
      // console.info('api.dispatcher.dispatches.progress | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.dispatcher.dispatches.progress | error = ', error);
      throw error;
    });
  },
  contactsDispatches (_, { dispatch_id, groups }) {
    return api.contacts.dispatche.post({ dispatch_id, groups }).then(response => {
      // console.info('api.dispatcher.dispatches.progress | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.dispatcher.dispatches.progress | error = ', error);
      throw error;
    });
  },
  stats (_, { dispatch_id }) {
    return api.statistics.stats.post({
      request: {
        type: 'select with count',
        output_format: 'json',
        columns_to_show: ['message_status', 'count'],
        criteria: [{
          column: 'dispatch_id',
          type: 'equal',
          parameters: [dispatch_id],
        }],
        grouping: ['message_status'],
      },
    });
  },
  statuses (_) {
    return api.documents.dispatch_document.statuses.list().then(response => {
      // console.info('api.documents.dispatch_document.statuses.list | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.statuses.list | error = ', error);
      throw error;
    });
  },
  setStatus (_, { dispatch_id, status }) {
    return api.documents.dispatch_document.statuses.put(dispatch_id, status).then(response => {
      // console.info('api.documents.dispatch_document.statuses.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.statuses.put | error = ', error);
      throw error;
    });
  },
};

export function getDispatchDocumentOnePromises (observer, item, id) {
  return new Promise(resolve => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'dispatch-document/one', { id },
        dispatch_document => {
          item.dispatch_document = dispatch_document;
          resolve();
        },
      );
    } else {
      item.dispatch_document = null;
      resolve();
    }
  });
}

export function getDispatchDocumentContentOnePromises (observer, item, id) {
  return new Promise(resolve => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'dispatch-document/getContent', { id },
        content => {
          item.content = content;
          resolve();
        },
      );
    } else {
      item.content = null;
      resolve();
    }
  });
}

export function getDispatchDocumentContactOnePromises (observer, item, id) {
  return new Promise(resolve => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'dispatch-document/listContact', { id },
        contact => {
          item.contact = contact;
          resolve();
        },
      );
    } else {
      item.contact = null;
      resolve();
    }
  });
}

export function getDispatchDocumentInfoOnePromises (observer, item, id) {
  return new Promise(resolve => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'dispatch-document/info', { id },
        info => {
          item.info = info;
          resolve();
        },
      );
    } else {
      item.info = null;
      resolve();
    }
  });
}

export function getDispatchDocumentLayoutOnePromises (observer, item, tid, cid) {
  return new Promise(resolve => {
    if (!item) {
      resolve();
    } else if (tid && cid) {
      observer.subscribeObserver(
        'dispatch-document/getLayout', { tid, cid },
        layout => {
          item.layout = layout;
          resolve();
        },
      );
    } else {
      item.layout = null;
      resolve();
    }
  });
}

const checkDispatchesProgress = async (...fnArgs) => {
  const [resolve, id, modal] = fnArgs;

  let result, processPercent;
  try {
    result = await api.contacts.dispatche.progress(id);
    processPercent = (result.data.current / result.data.total) * 100;
    // console.info('checkDispatchesProgress | id = ', id, ' | result = ', result, ' | processPercent = ', processPercent);
  } catch (e) {
    console.error('checkDispatchesProgress | id = ', id, ' | e = ', e);
    setTimeout(() => checkDispatchesProgress(...fnArgs), 3000);
    return;
  }

  $(modal.refs.elem)
    .find('div.progress-bar')
    .width(processPercent + '%')
    .attr('aria-valuenow', processPercent);

  if (processPercent !== 100) {
    setTimeout(() => checkDispatchesProgress(...fnArgs), 3000);
    return;
  }

  resolve();
};

const checkDispatchesStatus = async (...fnArgs) => {
  const [resolve, id] = fnArgs;

  let result;
  try {
    result = await api.documents.dispatch_document.dispatches.status(id);
    // console.info('checkDispatchesStatus | id = ', id, '| result = ', result);
  } catch (e) {
    console.error('checkDispatchesStatus | id = ', id, ' | e = ', e);
    setTimeout(() => checkDispatchesStatus(...fnArgs), 3000);
    return;
  }

  if (result.data.status !== 'ready') {
    setTimeout(() => checkDispatchesStatus(...fnArgs), 3000);
    return;
  }

  resolve();
};

import axios from "axios";
import _ from "lodash";
import { AUTH_COOKIE } from "./_const.js";
import { ErrorTokenEmpty } from "./errors.js";
import ReLogin from "./relogin.js";
import { AxiosResponseUse } from "../axios-response.js";
import { CatchErrorMsg, ErrorTranslation } from "../errors.js";
axios.interceptors.response.use(AxiosResponseUse);
class ErrorAuthLogin extends ErrorTranslation {
  constructor(message) {
    super("auth_login." + message);
    this.name = "ErrorAuthLogin";
    Object.setPrototypeOf(this, ErrorAuthLogin.prototype);
  }
}
class ErrorLoginFailed extends ErrorAuthLogin {
  constructor() {
    super("login_failed");
    this.name = "ErrorLoginFailed";
    Object.setPrototypeOf(this, ErrorLoginFailed.prototype);
  }
}
class ErrorTenantIsBlock extends ErrorAuthLogin {
  constructor() {
    super("tenant_is_block");
    this.name = "ErrorTenantIsBlock";
    Object.setPrototypeOf(this, ErrorTenantIsBlock.prototype);
  }
}
async function login(req) {
  var _a, _b;
  const { confirmation, ...reqData } = _.clone(req);
  const config = {
    headers: {}
  };
  if (confirmation == null ? void 0 : confirmation.length) {
    if (config.headers) {
      config.headers["Messaggio-OTP"] = confirmation;
    }
  }
  const response = await axios.post("/auth/api/v1/login", reqData, config).catch((e) => {
    var _a2;
    if (((_a2 = e.response) == null ? void 0 : _a2.status) === 403) {
      throw new ErrorLoginFailed();
    }
    const msg = CatchErrorMsg(e);
    if (msg.includes("tenant is blocked")) {
      throw new ErrorTenantIsBlock();
    }
    throw new ErrorAuthLogin(msg);
  });
  if (!((_b = (_a = response.data) == null ? void 0 : _a.success) == null ? void 0 : _b.token)) {
    throw new ErrorTokenEmpty();
  }
  ReLogin.encrypt(reqData);
  setToken(response.data.success.token);
  return response.data;
}
function setToken(token) {
  var _a;
  axios.defaults.headers.common.Authorization = "Bearer " + token;
  if (typeof window !== "undefined") {
    (_a = window == null ? void 0 : window.localStorage) == null ? void 0 : _a.setItem(AUTH_COOKIE, token);
  }
}
function relogin() {
  const req = ReLogin.decrypt();
  if (!req) {
    throw new ErrorTokenEmpty();
  }
  return login(req);
}
export {
  ErrorAuthLogin,
  ErrorLoginFailed,
  ErrorTenantIsBlock,
  login,
  relogin,
  setToken
};

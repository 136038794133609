import axios from 'axios';
import debounce from 'lodash.debounce';
import autoBind from 'auto-bind';
import saveAs from 'file-saver';
import { catchDispatch } from '~/utils/form';

// NEW - в чате только сообщения абонента, наших ответов нет
export const ChatStatusNew = 0;

// CHAT - есть хотябы один наш ответ
export const ChatStatusChat = 1;

// RESOLVED - решено
export const ChatStatusResolved = 2;

// INACTIVE - сессия кончилась для ответа
export const ChatStatusInactive = 3;

export const ChatStatusMap = {
  [ChatStatusNew]: 'new',
  [ChatStatusChat]: 'chat',
  [ChatStatusResolved]: 'resolved',
  [ChatStatusInactive]: 'inactive',
};

// Accepted from client/manager.
export const MessageStatusAccepted = 0;

// Sent to external channel.
export const MessageStatusSent = 1;

// Delivered to client.
export const MessageStatusDelivered = 2;

// TTL was expired and message wasn't delivered to client.
export const MessageStatusExpired = 3;

// Was seen by client/manager.
export const MessageStatusSeen = 4;

// Edited by client.
export const MessageStatusEdited = 5;

// Deleted by client/manager.
export const MessageStatusDeleted = 6;

// Failed to deliver.
export const MessageStatusFailed = 7;

export const MessageStatusMap = {
  [MessageStatusAccepted]: 'accepted',
  [MessageStatusSent]: 'sent',
  [MessageStatusDelivered]: 'delivered',
  [MessageStatusExpired]: 'expired',
  [MessageStatusSeen]: 'seen',
  [MessageStatusEdited]: 'edited',
  [MessageStatusDeleted]: 'deleted',
  [MessageStatusFailed]: 'failed',
};

export const TypeOutgoing = 'outgoing';
export const TypeIncoming = 'incoming';

// Sent by client.
export const EmitterClient = 0;

// Sent by tenant/manager.
export const EmitterUser = 1;

// Sent by delivery.
export const EmitterDelivery = 2;

// Sent by bot.
export const EmitterBot = 3;

class ServiceChat {
  topics = ['chat'];

  constructor () {
    autoBind(this);
  }

  Init (self) {
    this.self = self;
    this.self.$store.dispatch('chat/chatNewMessageAlerts');
  }

  onMessage ({ body: dataJson }) {
    console.log('chat.onMessage | dataJson = ', dataJson);
    switch (dataJson.meta.type) {
      case '/chat/dump': {
        this.OnDump(dataJson);
        break;
      }
      case '/message/new': {
        this.OnMessageNew(dataJson);
        break;
      }
      case '/message/status': {
        if (this.self.$store.state.chat.room.chat.id !== dataJson.body.chat_id) { break; }
        this.self.$store.commit('chat/room/updateMessageStatus', dataJson.body);
        break;
      }
      case '/error': {
        // if (this.self.$store.state.chat.room.chat.id !== dataJson.body.chat_id) break;
        this.self.$store.commit('chat/room/errorMessages', { ...dataJson.body, meta_id: dataJson.meta.id });
        break;
      }
    }

    if (['/message/typing'].includes(dataJson.meta.type) === false) {
      this.updateCountersDebounce();
    }

    if (['/message/typing'].includes(dataJson.meta.type) === false) {
      this.sortChatToLastDebounce();
    }
  }

  updateCountersDebounce = debounce(() => this.self.$store.dispatch('chat/updateCounters'), 500);
  sortChatToLastDebounce = debounce(() => this.self.$store.dispatch('chat/last/addChats'), 50);

  async OnTyping ({ chat_id }) {
    const msg = { meta: { type: '/message/typing' }, body: { chat_id } };
    // ws.send(JSON.stringify(msg));
    await axios.post('/chats/api/v1/message/typing', msg);
  }

  async OnNew ({ chat_id, uniq_seq, message }) {
    const id = Date.now();
    const user_id = this.self.$store.state.auth.user.id;
    const created_at = new Date().toUTCString();

    message = { ...message, id, user_id, created_at };
    const meta = { type: '/message/new', id };
    const body = { chat_id, uniq_seq, message };
    const msg = { meta, body };
    const meta_id = id;
    const is_sending = true;
    const retry = JSON.stringify(msg);

    this.self.$store.commit('chat/room/addMessage', {
      ...body,
      meta_id,
      retry,
      is_sending,
    });

    try {
      // ws.send(JSON.stringify(msg));
      await axios.post('/chats/api/v1/message/new', msg).then(resp => this.OnMessageNew(resp.data));
    } catch (e) {
      console.error('chat | OnNew | e = ', e, e.response.data);
      this.self.$store.commit('chat/room/errorMessages', { ...e.response.data, meta_id: msg.meta.id });
    }

    return true;
  }

  async OnRetryByMetaId (meta_id) {
    const data = this.self.$store.state.chat.root.messagesRetry[meta_id];
    if (!data) { return; }

    const msg = JSON.parse(data);

    this.self.$store.commit('chat/room/deleteMessage', { meta_id });

    const id = Date.now();
    const created_at = new Date().toUTCString();
    const uniq_seq = id + '-' + Math.random();

    msg.body.message.created_at = created_at;
    msg.body.message.id = id;
    msg.body.uniq_seq = uniq_seq;
    msg.meta.id = id;

    const is_sending = true;
    const retry = JSON.stringify(msg);

    this.self.$store.commit('chat/room/addMessage', {
      ...msg.body,
      meta_id: id,
      retry,
      is_sending,
    });

    try {
      // ws.send(JSON.stringify(msg));
      await axios.post('/chats/api/v1/message/new', msg).then(resp => this.OnMessageNew(resp.data));
    } catch (e) {
      console.error('chat | OnRetry | e = ', e);
      this.self.$store.commit('chat/room/errorMessages', { ...e.response.data, meta_id: msg.meta.id });
      return false;
    }

    return true;
  }

  async OnRetryById (message_id) {
    const msgFind = this.self.$store.state.chat.room.messages.find(m => m.id === message_id);
    this.self.$store.commit('chat/room/deleteMessage', { message_id });
    try {
      // ws.send(JSON.stringify(msg));
      await axios.post('/chats/api/v1/message/delete', {
        meta: { type: '/message/delete', id: Date.now() },
        body: { chat_id: msgFind.chat_id, message_id },
      });
    } catch (e) {
      console.error('chat | OnRetryById | /message/delete | e = ', e);
    }

    const id = Date.now();
    const user_id = this.self.$store.state.auth.user.id;
    const created_at = new Date().toUTCString();
    const uniq_seq = id + '-' + Math.random();

    const message = { ...msgFind, id, user_id, created_at };
    const body = { chat_id: msgFind.chat_id, uniq_seq, message };
    const msg = { meta: { type: '/message/new', id: message.id }, body };
    const meta_id = id;
    const retry = JSON.stringify(msg);
    const is_sending = true;

    this.self.$store.commit('chat/room/addMessage', {
      ...body,
      meta_id,
      retry,
      is_sending,
    });

    try {
      // ws.send(JSON.stringify(msg));
      await axios.post('/chats/api/v1/message/new', msg).then(resp => this.OnMessageNew(resp.data));
    } catch (e) {
      console.error('chat | OnRetryById | /message/new | e = ', e);
      this.self.$store.commit('chat/room/errorMessages', { ...e.response.data, meta_id: msg.meta.id });
      return false;
    }

    return true;
  }

  async OnRead (chat_id, message_id) {
    const id = Date.now();
    const msg = { meta: { type: '/message/read', id }, body: { chat_id, message_id } };

    this.self.$store.commit('chat/readMessage', { chat_id });

    try {
      // ws.send(JSON.stringify(msg));
      await axios.post('/chats/api/v1/message/read', msg);
    } catch (e) {
      console.error('chat | OnRead | e = ', e);
    }

    return true;
  }

  async notifyOnMessageNew ({ chat_id, message }) {
    // Не показывать нотификай если такая опция стоит
    if (!this.self.$store.state.chat.chatNewMessageAlerts) { return; }
    // Не показывать нотификай на страницах открытого чата
    if (this.self.$route.name.startsWith('chat-id')) { return; }
    // Показывать нотификай только от клиента
    if (message.emitter !== EmitterClient) { return; }

    // Поиск чата
    const chat =
      this.self.$store.state.chat.list[chat_id] ||
      await this.self.$store.dispatch('chat/one', { id: chat_id, cache: true });

    chat.message_last = message;

    const title = contactName(chat);
    const text = (message.payload.length > 100) ? message.payload.substr(0, 100) + '...' : message.payload;

    // Отображение нотификая
    window.PNotify.notice({
      title,
      text,
      hide: true,
      addClass: 'alert alert-styled-left alert-chat-notify',
      modules: {
        Confirm: {
          confirm: true,
          buttons: [{
            text: this.self.$t('chat.reply'),
            addClass: 'ui-pnotify-action-button btn btn-sm bg-primary',
            click: notice => {
              this.self.$router.push({
                name: 'chat-id',
                params: { id: chat_id },
              });
              notice.close();
            },
          }],
        },
        Buttons: {
          closer: false,
          sticker: false,
        },
        History: {
          history: false,
        },
      },
    });
  }

  OnDump ({ body, meta }) {
    if (!this.self.$store.state.chat.export.tasks[meta.id]) {
      return;
    }

    const onDownloadProgress = ({ loaded, total }) => {
      const progress = Math.round((loaded / total) * 100);
      $(`.progress .task-${meta.id}`)
        .find('div.progress-bar')
        .width(progress + '%')
        .attr('aria-valuenow', progress);
    };

    this.self.$store
      .dispatch('company/document/download', {
        category: 'documents',
        shortHash: body.short_hash,
        name: body.filename,
        onDownloadProgress,
      })
      .then(result => {
        (function (t, f, m) {
          try {
            const b = new Blob([t], { type: m });
            saveAs.saveAs(b, f);
          } catch (e) {
            window.open('data:' + m + ',' + encodeURIComponent(t), '_blank', '');
          }
        })(result, body.filename, 'text/csv');

        window.PNotify.alert({
          title: this.self.$t('chat.dump.end_title'),
          text: this.self.$t('chat.dump.end_description'),
          addClass: 'bg-success',
        });
      })
      .catch(e => catchDispatch(this.self, e))
      .finally(setTimeout(() => {
        window.PNotify.notices.find((v) => v.options.data?.taskId == meta.id)?.close();
      }, 800));
  }

  OnMessageNew ({ body, meta }) {
    body.message.chat_id = body.chat_id;
    body.meta_id = meta.id;

    if (this.self.$store.state.chat.room.chat.id === body.chat_id) {
      this.self.$store.commit('chat/room/addMessage', { ...body });
    }
    this.self.$store.dispatch('chat/addMessageToBase', body);
    this.self.$store.dispatch('chat/last/addMessage', body);

    this.notifyOnMessageNew(body);
  }

  Fin () {
    this.self.$store.commit('chat/setWsConnect', false);
  }
}

export default new ServiceChat();

export function contactName ({ phone_number, contact: c }) {
  if (c) {
    if (c.name.length) {
      return c.name;
    }

    const name = ['last_name', 'first_name', 'second_name'].map(k => c[k]).join(' ').trim();
    if (name.length) {
      return name;
    }
  }
  return '+' + phone_number;
}

export default {
  loading: 'Загрузка...',

  name: 'Название',
  tenant_id: 'Компания',
  bulk_idp: 'IDP',
  api_type: 'Тип API',
  bulk_login: 'Логин',
  bulk_secret_key: 'Секретный ключ',

  statistic: 'Статистика',

  delete_confirm: 'Вы действительно хотите удалить проект "{name}"?',
  delete_success: 'Проект "{name}" удален.',
}

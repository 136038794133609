import api from '~/api';
import {arrayToObject} from "~/utils/functions";
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({
  contracts: [],
});

export const getters = {};

export const mutations = {
  setContracts(store, contracts) {
    store.contracts = {
      ...store.contracts,
      ...contracts,
    };
  },
};

export const actions = {
  list({commit}) {
    return api.saga.contract.list(1, 99999).then(async response => {
      //console.info('api.saga.contract.list | response = ', response.data);
      const items = response.data.Body || [];
      commit('setContracts', arrayToObject(items));
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.saga.contract.list | error = ', error);
      throw error;
    });
  },
  one({commit}, {id, observer = null, relate = []} = {}) {
    return api.saga.contract.get(id).then(async response => {
      //console.info('api.saga.contract.get | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.saga.contract.get | error = ', error);
      throw error;
    });
  },
  services({commit}, {id, observer = null, relate = []} = {}) {
    return api.saga.contract.service.list(id, 1, 99999, null, (Date.now() / 1000).toFixed(0)).then(async response => {
      //console.info('api.saga.contract.services | response = ', response.data);
      return (response.data.services || []);
    }).catch(error => {
      console.error('api.saga.contract.services | error = ', error);
      throw error;
    });
  },
  serviceTariffList({dispatch, commit, state, rootState}, {
    contract_id,
    service_id,
    page = 1,
    page_size = 10,
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.saga.contract.service.tariff.list(contract_id, service_id, page, page_size, options).then(async response => {
      //console.info('api.saga.contract.service.tariff.list | response = ', response.data);
      const currency = response.data.currency;
      const items = (response.data.tariffs || []).map(tariff => ({...tariff, currency}));
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.saga.contract.service.tariff.list | error = ', error);
      throw error;
    });
  }
};

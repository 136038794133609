export default {
  file_add: 'Add phone numbers',

  q_placeholder: 'Phone number or parameter',

  id: 'ID',
  name: 'Name',
  description: 'Description',
  description_empty: 'Description not set',
  tenant: 'Company',
  row_count: 'Phone numbers quantity',
  type: 'Group type',
  status: 'Status',

  tab_contact: 'Phone numbers',

  file_size_more: 'Filesize more than {size}Mb.',
  file_type_support: 'Supported formats: txt, csv.',

  list_phone_numbers: 'List of phone numbers',

  import_contacts_title: 'Work in progress?',
  import_contacts_description: 'Complete the creation of the Contact Group',
  import_contacts_btn: 'Import contacts',

  contact_empty: 'There are no contacts in the group.',
  contact_empty_project_one_you_can: 'You can',
  contact_empty_project_one_create: 'import contacts',
  contact_empty_project_one_create_after: 'with additional parameters to create personalized message templates.',

  show_more: 'Show more',
  show_less: 'Show less',
}

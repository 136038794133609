export default {
  title: 'Dashboard',
  search_placeholder: 'Search',
  navigation: 'Navigation',

  my_account_settings: 'Account',
  my_balance: 'Billing',
  my_company: 'Company',
  my_document: 'Files',
  logout: 'Logout',

  balance: 'Credits',
  support_api: 'Documentation API',
  settings: 'Settings',
  check_settings: 'Check Settings',

  pending_approval: 'Waiting moderation',
  client_senders: 'Client Senders',
  senders_gates: 'Gate Senders',
  templates: 'Templates',
  home: 'Dashboard',

  loading: 'Loading...',
  actions: 'Actions',
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  save: 'Save',
  save_changes: 'Save changes',
  remove: 'Remove',
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  close: 'Close',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  empty: 'No data',
  for: 'for',
  continue: 'Continue',
  search: 'Search',

  status_all: 'All Statuses',
  type_all: 'All Types',

  filter_q_placeholder: 'Type to filter...',

  created_at: 'Created',
  updated_at: 'Updated',
  deleted_at: 'Deleted',

  get_tenant: 'Get Projects',

  tab_general_information: 'General information',
  tab_dispatch_document: 'Dispatches',
  tab_template: 'Templates',
  tab_client_sender: 'Client Senders',
  tab_channel_and_gate_sender: 'Gate Senders',
  tab_project: 'Projects',
  tab_statistic: 'Statistics',
  tab_group_contact: 'Contact Groups',

  table_empty_text: 'There are no records to show',

  date_time_same_today_at: 'Today at',
  date_time_same_yesterday: 'Yesterday',
  date_time_same_yesterday_at: 'Yesterday at',
  date_time_same_at: 'at',

  error401: 'Oops! Sorry, but your session has ended. Log in again.',
  error403: 'No access to this feature.',

  available_on_corporate: 'Available on Corporate tariff plan',

  error_array_max: 'Field must contain no more than items.',
  error_phone: 'Phone number is invalid.',
};

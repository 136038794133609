import form from '~/locales/en/Template/form';
import table from '~/locales/en/Template/table';
import show from '~/locales/en/Template/show';
import preset from '~/locales/en/Template/Preset';
import content_table from '~/locales/en/Template/ContentTable';
import status_change from '~/locales/en/Template/StatusChange';
import input_image from '~/locales/en/Template/InputImage';
import input_document from '~/locales/en/Template/InputDocument';
import input_location from '~/locales/en/Template/InputLocation';
import send_moderation from '~/locales/en/Template/SendModeration';
import send_testphone from '~/locales/en/Template/SendTestphone';
import card from '~/locales/en/Template/Card';
import emojione_area from '~/locales/en/Template/EmojioneArea';
import content_layout from '~/locales/en/Template/ContentLayout';

export default {
  title: 'Templates',
  title_index: 'Message templates',
  title_index_moderation: 'Templates moderation',
  title_create: 'Create template',
  title_show: 'Template {name}',
  title_show_breadcrumbs: 'View message template',
  title_edit: 'Message template',
  title_edit_breadcrumbs: 'Edit message template',

  filter_q_placeholder: 'Type to filter...',

  change_status: 'Change status',
  preset_btn: 'Preset settings',
  send_for_moderation: 'Send for moderation',
  copy_btn: 'Make a copy',

  description: 'The message template is a content you send to the recipient: the combination of the text, images, action buttons and links. Each channel provides own content layouts and messaging requirements.<br><br>' +
    'The message template moderation usually takes few hours. In case requested template meet channel messaging requirements it will be approved for dispatch.',

  view_glossary: 'View Glossary',

  template_empty: 'There are no templates in the project.',
  template_empty_project_more_you_can: 'You can',
  template_empty_project_more_create: 'create a template',
  template_empty_project_more_or: 'or',
  template_empty_project_more_select_all: 'switch project filter to All projects',
  template_empty_project_more_select_all_after: 'to see more.',
  template_empty_project_one_you_can: 'You can',
  template_empty_project_one_create: 'create a template',
  template_empty_project_one_create_after: 'for a dispatch.',

  form,
  table,
  show,
  preset,
  content_table,
  status_change,
  input_image,
  input_document,
  input_location,
  send_moderation,
  send_testphone,
  card,
  emojione_area,
  content_layout,
}

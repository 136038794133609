export default {
  title: 'Аккаунт',
  description: 'Управляйте вашим аккаунтом и настройками компании. Вы можете создать дополнительные проекты и пригласить пользователей.',

  settings: 'Настройки',
  my_account: 'Мой аккаунт',
  notification: 'Уведомления',
  gallery: 'Галерея',
  short_link: 'Короткие ссылки',
  company: 'Моя компания',
  projects: 'Проекты',
  users: 'Пользователи',
  billing: 'Платежи',
  documents: 'Документы',
  testphone: 'Тестовые телефоны',
  chat: 'Чат',
  action_history: 'История действий',
  services: 'Приложения',
  support: 'Служба поддержки',
  favorite: 'Избранное',

  title_general: 'Основные',
  title_account: 'Аккаунт',
  title_messaging: 'Рассылки',
  title_company: 'Компания',
}

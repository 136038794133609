import PhoneParamsTag from '~/locales/uk/GroupContact/Contact/PhoneParamsTag';

export default {
  ...PhoneParamsTag,

  textarea_empty: 'Немає записів',
  textarea_max_row: 'Максимум 100 000 рядків',
  file_not_select: 'Файл не обрано',

  save_success: 'Готово',
};

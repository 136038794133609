export default {
  loading: 'Завантаження...',

  name: 'Назва',
  tenant_id: 'Компанія',
  channel: 'Канал',
  status: 'Статус',
  created_at: 'Дата',

  delete_confirm: 'Ви дійсно хоете видалити шаблон "{name}"?',
  delete_success: 'Шаблон "{name}" видалено.',

  copy_confirm: 'Ви дійсно хочете cкопіювати шаблон "{name}"?',
  copy_success: 'Шаблон "{name}" скопійовано.',
}

import progress_box from '~/locales/en/GroupContact/Contact/Convert/ProgressBox';

export default {
  progress_box,

  badge: {
    title: 'Обробка',
    toottip: 'Файл, завантажений для імпорту контактів, перетворюється на формат .csv. Після завершення процесу Ви можете завершити імпорт контактів.',
  },

  modal: {
    title: 'Підготовка файлу',
  },

  final_modal: {
    title: 'Конвертація файлу завершена',
    text: 'Файл {file_name} був успішно конвертований та готовий для використання в імпорті контактів.',
    cancel_confirm: 'Ви впевнені, що хочете скасувати конвертацію?',
    btn: {
      cancel: 'Скасувати',
      confirm: 'До групи контактів',
    },
  },
};

import form_code from '~/locales/ru/Company/Testphone/ModalVerification/FormCode';
import form_phone from '~/locales/ru/Company/Testphone/ModalVerification/FormPhone';

export default {
  title: 'Привязать телефон',
  description: 'В течение одной минуты на ваш телефон поступит бесплатный звонок или SMS. Последние 4 цифры входящего номера являются кодом для привязки телефона.',

  form_code,
  form_phone,
};

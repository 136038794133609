import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  objectList({commit, state}) {
    return api.templates.object.list().then(response => {
      //console.info('api.templates.object.list | response = ', response.data);
      return response.data || [];
    }).catch(error => {
      console.error('api.templates.object.list | error = ', error);
      throw error;
    });
  },
  list({commit, state}) {
    return api.templates.preset.list().then(response => {
      //console.info('api.templates.preset.list | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.templates.preset.list | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.templates.preset.post(data).then(response => {
      //console.info('api.templates.preset.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.templates.preset.post | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.templates.preset.delete(id).then(response => {
      //console.info('api.templates.preset.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.templates.preset.put | error = ', error);
      throw error;
    });
  },
};

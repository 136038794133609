var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item",class:{'dropdown': _vm.isFolder}},[(!_vm.isFolder)?_c('a',{staticClass:"navbar-nav-link d-flex align-items-center flex-wrap",class:{'active': _vm.isActive, 'disabled':_vm.isDisabled},attrs:{"href":!_vm.isDisabled ? _vm.routerHref : '#',"router-json":"routerJson"},on:{"click":function($event){$event.preventDefault();return _vm.goTo.apply(null, arguments)}}},[_c('i',{staticClass:"mr-2 text-grey-600",class:[
        (_vm.model.icon || 'icon-home4'),
        (_vm.model.iconClassName || 'd-md-none d-lg-inline-block'),
      ]}),_vm._v(" "),_c('span',{class:_vm.model.labelClassName},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.model.name))+"\n    ")]),_vm._v(" "),(_vm.model.name === 'client_sender.title' && _vm.isUserFresh && _vm.clientSenderCountAllNew)?_c('i',{staticClass:"icon-primitive-dot text-warning ml-1 mr-0"}):_vm._e(),_vm._v(" "),(_vm.model.name === 'dispatch_document.title' && _vm.dispatchScheduledCount)?_c('span',{staticClass:"badge badge-pill bg-msg-info position-static ml-1"},[_vm._v("\n      "+_vm._s(_vm.dispatchScheduledCount >= 100 ? '...' : _vm.dispatchScheduledCount)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.model.name === 'chat.title' && _vm.chatMessageNewCount)?_c('span',{staticClass:"badge badge-pill badge-primary position-static ml-1"},[_vm._v("\n      "+_vm._s(_vm.chatMessageNewCount >= 100 ? '...' : _vm.chatMessageNewCount)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.model.name === 'company.settings.title' && _vm.companySettingsBadgeCount)?_c('span',{staticClass:"badge badge-pill bg-warning"},[_vm._v("\n      "+_vm._s(_vm.companySettingsBadgeCount >= 100 ? '...' : _vm.companySettingsBadgeCount)+"\n    ")]):_vm._e()]):_c('a',{staticClass:"navbar-nav-link dropdown-toggle",class:{'active': _vm.isActive, 'disabled': _vm.isDisabled},attrs:{"data-toggle":"dropdown"}},[_c('i',{staticClass:"mr-2 text-grey-600",class:[
        (_vm.model.icon || 'icon-home4'),
        (_vm.model.iconClassName || 'd-md-none d-lg-inline-block'),
      ]}),_vm._v(" "),_c('span',{class:_vm.model.labelClassName},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.model.name))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},_vm._l((_vm.model.children),function(model,index){return _c('widget-menu-left2',{key:index,attrs:{"model":model}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import modal_columns from '~/locales/en/Statistic/Filter/ModalColumns'

export default {
  list_preset_loading: 'Loading parameters...',

  message_date: 'Period',
  project_id: 'Project',
  project_id_all: 'Projects (All)',
  channel_id: 'Channels',
  clientsender_id: 'Sender',
  dispatch_id: 'Dispatch',
  country: 'Countries and operators',
  country_title: 'Countries',
  mobile_operator_title: 'Operators',
  columns_select: 'Report settings',
  select: 'Submit request',
  fetch_csv: 'Download CSV',
  report: 'CSV Reports',

  today: 'today',
  this_month: 'this month',
  last_month: 'last month',

  modal_columns,

  country_ru: 'Russia',
  country_ua: 'Ukraine',
  country_kz: 'Kazakhstan',
  country_md: 'Moldova',
  country_by: 'Belarus',

  grouped: 'grouped',
  custom: 'custom',

  show_more_filters: 'More filters',
  hide_more_filters: 'Less filters',

  message_date_from: 'From',
  message_date_to: 'To',
  message_date_helpdesk: 'Detailed statistic reports are available only for the last 3 months.',
}

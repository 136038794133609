import { render, staticRenderFns } from "./WidgetFieldClientSenderId.vue?vue&type=template&id=733af548&"
import script from "./WidgetFieldClientSenderId.vue?vue&type=script&lang=js&"
export * from "./WidgetFieldClientSenderId.vue?vue&type=script&lang=js&"
import style0 from "./WidgetFieldClientSenderId.vue?vue&type=style&index=0&id=733af548&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@3.4.32_babel-core@7.0.0-bridge.0_@babel+core@7.24.9__css_uv5biolnn6ywtbh3eesigpjl3u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import header from '~/locales/ru/DispatchDocument/form/Header';
import template from '~/locales/ru/DispatchDocument/form/Template';
import group_contact from '~/locales/ru/DispatchDocument/form/GroupContact';
import price from '~/locales/ru/DispatchDocument/form/Price';
import contacts_split_proportion from '~/locales/ru/DispatchDocument/form/ContactsSplitProportion';

export default {
  tenant_id: 'Компания',
  tenant_id_placeholder: 'Компания ID',
  channel_id: 'Канал',
  btn_create_template: 'Создать шаблон',

  content_select_empty_title: 'Не выбран шаблон сообщения',
  content_select_empty_dody: 'Выберите шаблон сообщения из списка справа',

  load_more_templates: 'Загрузить еще',
  select_recipients: 'Выберите получателей',

  content_id_empty: 'Сообщение не указано',
  contents_a_empty: 'Сообщение A не указано',
  contents_b_empty: 'Сообщение B не указано',
  group_contact_for_dispatch_document_empty: 'Не выбраны получатели рассылки',

  preview_btn: 'Предпросмотр',
  preview_title: 'Предварительный просмотр',
  preview_template: 'Шаблон',
  preview_content: 'Сообщение',
  preview_contents_not_select: 'Выберите шаблон сообщения и получателей рассылки',

  save_success: 'Готово',
  save_success_on_create: 'Рассылка сформирована для отправки',
  action_schedule: 'Запланировать',
  action_launch_now: 'Разослать сейчас',

  template_empty: 'В проекте нет активных шаблонов.',
  template_empty_project_more_you_can: 'Вы можете',
  template_empty_project_more_create: 'создать шаблон',
  template_empty_project_more_or: 'или',
  template_empty_project_more_select_all: 'переключить фильтр проектов на Все проекты.',
  template_empty_project_more_select_all_after: '',
  template_empty_project_one_you_can: 'Вы можете',
  template_empty_project_one_create: 'создать шаблон',
  template_empty_project_one_create_after: 'для использования в рассылке сообщений.',

  error: {
    skip_template_moderation: 'Автомодерация должна быть включена. Обратитесь в службу поддержки.',
  },

  header,
  template,
  group_contact,
  price,
  contacts_split_proportion,
};

export default {
  loading: 'Загрузка...',

  name: 'Название',
  tenant_id: 'Компания',
  channel: 'Канал',
  status: 'Статус',
  created_at: 'Дата',

  delete_confirm: 'Вы действительно хотите удалить шаблон "{name}"?',
  delete_success: 'Шаблон "{name}" удален.',

  copy_confirm: 'Вы действительно хотите cкопировать шаблон "{name}"?',
  copy_success: 'Шаблон "{name}" скопирован.',
}

export default {
  title: 'Получатели шаблона А и шаблона В',
  description: 'Укажите в процентах сколько случайно выбранных контактов должны получить шаблон А. Остальным будет отправлен шаблон В.',

  template_a: 'Шаблон А',
  template_b: 'Шаблон В',

  helpers: 'Чтобы результаты A/B теста были показательны, мы рекомендуем устанавливать % получателей шаблона А не менее 15%.<br><br>После сохранения будет создано две рассылки с указанными шаблонами. Каждую из них следует запустить самостоятельно или запланировать отправку в нужное время.',

  contacts_split_proportion: 'пропорция',
}

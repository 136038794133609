export default {
  name: 'Назва',
  name_placeholder: 'Шаблон {time}',
  select_project: 'Шаблон буде створено в проекті',
  tenant_id: 'Компанія',
  tenant_id_placeholder: 'Назва компанії',
  channel: 'Канали',
  clientsender_id: 'Надіслати',
  select_template_channels: 'Відправник повідомлення',
  channels_empty: 'В проекті немає активних відправників',
  select_project_title: 'Оберіть проект',
  project_id: 'Проекти',
  project_id_placeholder: 'Обрати проект',
  project_id_placeholder_empty: 'Відсутні проекти з активними відправниками',
  client_sender_id_title: 'Оберіть Відправник',
  client_sender_id_description: 'Ви можете створювати багатоканальні шаблони повідомлень, якщо у вас є активні відправники на різних каналах.<br>' +
    'Наприклад, формуючи шаблон повідомлення для відправки його в Viber, ви можете додати в шаблон текст SMS, котрий буде надіслано контактам, в яких не встановлено додаток Viber.',
  client_sender_id_placeholder: 'Оберіть ім`я відправника',
  type: 'тип',
  contents_client_sender_id: 'Відправника',
  status: 'Статус',
  created_at: 'Дата',
  content_layout: 'Макет',
  content_layout_language: 'Мова',
  content_layout_text: 'Текст',
  content_layout_text_image: 'Текст + Зображення',
  content_layout_text_document: 'Текст + Файл',
  content_layout_text_location: 'Текст + Локація',
  content_layout_text_image_btn: 'Текст + Кнопка + Зображення',
  content_layout_text_btn: 'Текст + Кнопка',
  content_layout_tpl: 'Шаблон',
  content_layout_body_text: 'Повідомлення',
  content_layout_body_text_tpl: 'Шаблон транзакційного повідомлення',
  content_layout_body_sample_text: 'Приклад повідомлення',
  content_layout_body_text_tpl_check: 'Перевірити шаблон',
  content_layout_body_text_link: 'Посилання',
  content_layout_btn: 'Кнопка',
  content_layout_body_title: 'Назва кнопки',
  content_layout_body_link: 'Посилання кнопки',
  content_layout_image: 'Зображення',
  content_layout_body_file_name: 'Зображення',
  content_layout_body_image_file_name: 'Посилання на зображення',
  content_layout_body_image_caption: 'Опис',
  content_layout_video: 'Відео',
  content_layout_document: 'Документ',
  content_layout_location: 'Локація',
  content_layout_contact: 'Контакт',
  content_layout_text_video_btn: 'Видео + Текст + Кнопка',
  content_layout_text_video: 'Видео + Текст',
  content_layout_template_contact_first_name: 'Ім\'я',
  content_layout_template_contact_phone_number: 'Телефонний номер',

  save_success: 'Готово',

  preview_btn: 'Попередній перегляд',
  preview_title: 'Попередній перегляд шаблону',
  preview_body: ' Оберіть канали шаблону і заповніть поля форми макету, щоб переглянути повідомлення.',

  testphone_btn: 'Надіслати на телефон',

  message_from: '{channel_name} повідомлення від {client_sender_name}',

  error_project_id_empty: 'Проект не обрано',
  error_sender_empty: 'Не вказано відправника',
  error_viber_check_tpl: 'Шаблон не співпадає з прикладом повідомлення',
  error_viber_dynamic_link_scheme: 'Потрібно вказати схему для URL',
  error_viber_template_param: 'Шаблон містить недопустимий параметр: {params}',
  error_content_layout_body_sample_text_validation: 'Приклад повідомлення не підходить під шаблон',
  error_button_row_empty: 'Рядок кнопок не може бути порожнім',

  before_route_leave_confirm: 'Ви дійсно хочете покинути сторінку створення шаблону?',

  sender_empty: 'В проекті немає активних відправників.',
  sender_empty_project_more_you_can: 'Ви можете',
  sender_empty_project_more_create: 'створити відправника',
  sender_empty_project_more_or: 'повідомлень або',
  sender_empty_project_more_select_all: 'переключити фільтр проектів на Всі проекти.',
  sender_empty_project_more_select_all_after: '',
  sender_empty_project_one_you_can: 'Ви можете',
  sender_empty_project_one_create: 'створити відправника',
  sender_empty_project_one_create_after: 'повідомлень для вашого бизнесу.',

  knowledge_step: 'Крок {step}',
  knowledge_step1_description: 'Оберіть Відправника, від імені якого проводитиметься розсилка повідомлень.',
  knowledge_step2_description: 'Сформуйте повідомлення, використовуючи можливі макети. Ви також можете використовувати змінні в тексті і посиланнях, которі заповнюються параметрами, вказаними у властивостях контактів групи для розсилок.',
  knowledge_step3_description: 'Надішліть шаблон повідомлення на свій тестовий телефон, щоб розуміти як він виглядатиме на пристрої.',
};

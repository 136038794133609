<template>
  <div class="form-group row">
    <label class="col-form-label col-lg-3 d-flex align-items-center">
      <img
        :src="logo"
        class="mr-2"
        width="15"
        height="15"
        :alt="channel.name"
      >
      {{ $t('documents.channels.' + channel.name) }}
    </label>
    <div class="col-lg-9">
      <div class="input-group">
        <div class="form-control p-0 border-0">
          <widget-field-client-sender-id
            :ajax="false"
            :data-vv-as="$t(`${tForm}.clientsender_id`).toLowerCase()"
            :data-vv-scope="formName"
            :name="`client_sender_id[${channel.id}]`"
            :list="clientSenderList"
            :value="clientSenderId"
            :channel-name="channel.name"
            :placeholder="`${tForm}.client_sender_id_placeholder`"
            template="with_logo"
            @select="updateChannel(channel, arguments[0])"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetFieldClientSenderId from '@/components/Widget/Field/WidgetFieldClientSenderId.vue';

export default {
  components: {
    WidgetFieldClientSenderId,
  },
  props: {
    tForm: {
      type: String,
      default: '',
    },
    formName: {
      type: String,
      default: '',
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    clientSenders: {
      type: Array,
      default: () => ([]),
    },
    clientSenderId: {
      type: String,
      default: '',
    },
  },
  computed: {
    logo () {
      return require(`@/static/image/sender_type/${this.channel.name.trim().toLowerCase()}.png`);
    },
    clientSenderList () {
      return this.clientSenders
        .map(clientSender => ({
          id: clientSender.id,
          text: clientSender.name,
          client_sender: clientSender,
        }))
        .sort((a, b) => {
          if (a.client_sender.is_favorite === b.client_sender.is_favorite) {
            if (a.text.toLowerCase() === b.text.toLowerCase()) {
              return 0;
            }
            return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
          }
          return a.client_sender.is_favorite > b.client_sender.is_favorite ? -1 : 1;
        });
    },
  },
  methods: {
    updateChannel (channel, [{ client_sender } = {}] = []) {
      this.$emit('update-channel', { channel, client_sender });
    },
  },
};
</script>

import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';

export const state = () => ({
  showPage: {},
});

export const getters = {};

export const mutations = {
  setShowPage (state, { gid, page }) {
    state.showPage[gid] = page;
  },
};

export const actions = {
  list ({ dispatch, commit, rootState }, {
    gid,
    page = 1,
    pagesize = 10,
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    if (options.q) {
      const { q: query, ...otherOptions } = options;

      return api
        .contacts.search.contact
        .list(page, pagesize, '', { query, group_id: gid, ...otherOptions })
        .then(({ data }) => {
          // console.info('api.contacts.search.contact.list | response = ', response.data);
          return {
            items: data.contacts.map(({ contact }) => contact),
            totalRows: data.total || 0,
          };
        });
    }

    return api.contacts.group.contact.list(gid, page, pagesize, options).then(({ data }) => {
      // console.info('api.contacts.group.contact.list | response = ', response.data);

      // Подбивать данные при расхождении
      const rowCountStore = rootState['group-contact'].list[gid]?.row_count || 0;
      const rowCountReal = data.Meta.TotalSize || 0;
      // console.info('countFix | rowCountStore = ', rowCountStore, ' | rowCountReal = ', rowCountReal);
      if (rowCountStore !== rowCountReal) {
        api.contacts.group.duplicatesRemove(gid)
          .then(() => commit('group-contact/ADD_LIST', [{
            ...rootState['group-contact'].list[gid],
            row_count: rowCountReal,
          }], { root: true }));
      }

      return {
        items: data.Body || [],
        totalRows: data.Meta.TotalSize,
      };
    }).catch((error) => {
      console.error('api.contacts.group.contact.list | error = ', error);
      throw error;
    });
  },
  one ({ dispatch }, { gid, cid }) {
    return api.contacts.group.contact.get(gid, cid).then(async (response) => {
      // console.info('api.contacts.group.contact.one | response = ', response.data);
      response.data.group = await dispatch('group-contact/one', { gid: response.data.group_id }, { root: true });
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.contact.one | error = ', error);
      throw error;
    });
  },
  create (_, { gid, cid, data }) {
    data = { ...data };
    return api.contacts.group.contact.post(gid, cid, data).then((response) => {
      // console.info('api.contacts.group.contact.post | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.contact.post | error = ', error);
      throw error;
    });
  },
  edit (_, { gid, cid, data }) {
    data = { ...data };
    return api.contacts.group.contact.put(gid, cid, data).then((response) => {
      // console.info('api.contacts.group.contact.put | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.contact.put | error = ', error);
      throw error;
    });
  },
  delete (_, { gid, cid }) {
    // console.info('gid = ', gid, ', cid = ', cid);
    return api.contacts.group.contact.delete(gid, cid).then((response) => {
      // console.info('api.contacts.group.contact.put | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.contact.put | error = ', error);
      throw error;
    });
  },
  deleteGroup (_, { gid, cids }) {
    // console.info('gid = ', gid, ', cids = ', cids);
    return Promise.all(cids.map(cid => api.contacts.group.contact.delete(gid, cid).then((response) => {
      // console.info('api.contacts.group.contact.put | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.contact.put | error = ', error);
      throw error;
    })));
  },
  addFromTextarea (_, { gid, data }) {
    return api.contacts.group.load.text.post(gid, data).then((response) => {
      // console.info('api.contacts.group.load.text.post | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.load.text.post | error = ', error);
      throw error;
    });
  },
  async uploadFromFile (_, { file }) {
    try {
      const fileForm = new FormData();
      fileForm.append('uploadfile', file);

      const result = await api.contacts.files.post(fileForm);
      if (!result.data) {
        throw new Error('file_id is empty');
      }

      return result.data;
    } catch (error) {
      console.error('api.contacts.group.post | error = ', error);
      throw error;
    }
  },
  async paramsFromFile (_, { file_id, symbol, charset }) {
    try {
      const result = await api.contacts.group.files.params
        .get('-', file_id, { symbol, charset });
      // console.info('paramsFromFile | api.contacts.group.files.params.get | result = ', result);
      return result.data;
    } catch (error) {
      console.error('paramsFromFile | api.contacts.group.files.params.get | error = ', error);
      throw error;
    }
  },
  addFromFile (_, { gid, fid, data }) {
    return api.contacts.group.files.params.post(gid, fid, data).then((response) => {
      // console.info('api.contacts.group.files.params.post | response = ', response);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.files.params.post | error = ', error);
      throw error;
    });
  },
  duplicatesRemove (_, { gid }) {
    return api.contacts.group.duplicatesRemove(gid).then((response) => {
      // console.info('api.contacts.group.duplicatesRemove | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.duplicatesRemove | error = ', error);
      throw error;
    });
  },
  copy (_, { target_group_id, source_group_id, contact_ids }) {
    return api.contacts.group.copy(target_group_id, { source_group_id, contact_ids }).then((response) => {
      // console.info('api.contacts.group.copy | response = ', response.data);
      return response.data;
    }).catch((error) => {
      console.error('api.contacts.group.copy | error = ', error);
      throw error;
    });
  },
};

export function getGroupContactRowCountOnePromises (observer, item, gid) {
  return new Promise((resolve) => {
    if (!item) {
      resolve();
    } else if (gid) {
      observer.subscribeObserver(
        'group-contact/contact/list', { gid, page: 1, pagesize: 1 },
        (contact) => {
          resolve({
            row_count: contact.totalRows,
          });
        },
      );
    } else {
      resolve({});
    }
  });
}

import login from '~/locales/en/Permission/login';
import password_recovery from '~/locales/en/Permission/PasswordRecovery';
import password_reset from '~/locales/en/Permission/PasswordReset';
import registration from '~/locales/en/Permission/registration';
import completed from '~/locales/en/Permission/completed';
import verify_email from '~/locales/en/Permission/VerifyEmail';

export default {
  login_link: 'Sign in',
  register_link: 'Register',

  login,
  password_recovery,
  password_reset,
  registration,
  completed,
  verify_email,

  welcome: {
    title: 'Multichannel<br>Messaging Platform',
    subtitle: 'Sign in or create account.',
  },

  plugin: {
    title: 'Available on Corporate tariff plan',
    description: '<p>This feature available on Corporate tariff plan.</p><p>You can try 1 month for free and enjoy extra features of Messaggio Platform!</p>',
    more_info: 'More info',
    corporate_plan: 'Corporate Plan',
  },
};

export default {
  loading: 'Loading statistics...',

  title: 'Sender statistics (this month)',
  view_all: 'View all',
  empty_data: 'There is no messaging activity for a while',

  message_status_count_sent: 'Sent',
  message_status_count_delivered: 'Delivered',
  message_status_count_watched: 'Seen',
  message_status_count_not_delivered: 'Not delivered',
}

import message_type_option from '~/locales/en/ClientSender/TrailerProfile/WhatsApp/MessageTypeOption';
import company_type_option from '~/locales/en/ClientSender/TrailerProfile/WhatsApp/CompanyTypeOption';
import message_volume_option from '~/locales/en/ClientSender/TrailerProfile/WhatsApp/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/en/ClientSender/TrailerProfile/WhatsApp/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/en/ClientSender/TrailerProfile/WhatsApp/ServiceLaunchDateOption';

export default {
  service_name_original_language: 'Service name (original language)',
  service_name_english_language: 'Service name (English)',
  website: 'Website',
  description: 'Description',
  description_helper: '(This is the description that will be written as your business description IN the WhatsApp app and will be presented as part of your business info page, that is accessible to all your message recipients) 10-400 characters.',
  logo_img: 'Sender logo',
  logo_chatscreen: 'Chatscreen',
  business_address: 'Business Address',
  business_phone_number: 'Business Phone Number',
  business_email: 'Business Email',
  message_type: 'Message type',
  message_type_option,
  service_registered_country: 'Service Registered Country',
  company_type: 'Company Type',
  company_type_option,
  message_volume: 'Messaging volume per month',
  message_volume_option,
  service_integration_type: 'Service Integration Type',
  service_integration_type_option,
  destination_countries: 'Destination countries',
  service_launch_date: 'Service Launch Date',
  service_launch_date_option,
  facebook_business_manager_id: 'Facebook Business Manager Id',
  sample_message_promotional: 'Sample Message (Promotional)',
  sample_message_transactional: 'Sample Message (Transactional)',

}

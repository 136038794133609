export default {
  label_btn: 'Зображення профілю',
  btn_modal_open: 'Оновити зображення',

  modal_title: 'Завантеження аватару',
  modal_description: 'Завантажте зображення для використання в якості аватару або згенерируйте випадковий.',

  file_title: 'Завантажити зображення',
  file_label: 'Оберіть файл для завантаження',
  file_description: 'Допустимі формати: *.jpg, *.png. Максимальний розмір файла 4Mb.',

  generate_title: 'Згенерувати випадковий аватар',
  btn_generate: 'Згенерувати',

  widget_otp_name: 'Оновлення аватару користувача',

  submit: 'Зберегти зміни',
  saving_changes: 'Збереження змін...',
}

export default {
  name: 'Назва',
  description: 'Опис',
  tenant_id: 'Компанія',
  web_page: 'Адреса сайту',

  tab_use_api: 'Налаштування API',
  bulk_login: 'Логін',
  bulk_secret_key: 'Секретний ключ',
  bulk_idp: 'IDP',
  api_type: 'Тип API',
  callback_url: 'Callback URL для статусів доставки',
  callback_url_empty: 'URL не задано',
  callback_url_two_way: 'Callback URL для вхідних повідомлень',
  callback_url_two_way_empty: 'URL не задано',

  tab_api_setting: 'Налаштування API',
}

export default {
  title: 'Служба підтримки',
  subject: 'Причина звернення',
  body: 'Опис',
  btn_submit: 'надіслати запит',

  subject_placeholder: 'Оберіть...',
  subject_messaging: 'Розсилка повідомлень',
  subject_messaging_dashboard_web_interface_issue: 'Проблеми з інтерфейсом особистого кабинету',
  subject_messaging_sender_id_moderation_issue: 'Питання щодо погодження Імені відправника',
  subject_messaging_message_template_moderation_issue: 'Питання щодо погодження шаблону повідомлення',
  subject_messaging_contacts_upload_issue: 'Проблеми з заватнаженням бази контактів',
  subject_messaging_message_delivery_issue: 'Проблеми з розсилкою повідомлень',
  subject_integration: 'Інтеграція',
  subject_integration_yclients_integration: 'Інтеграція з YClients',
  subject_integration_insales_integration: 'Інтеграція з InSales',
  subject_integration_api_integration: 'Інтеграція API',
  subject_partnership: 'Співробітницьтво',
  subject_partnership_commercial_issue: 'Комерційні питання',
  subject_partnership_new_messaging_service_request: 'Підключення нових послуг',
  subject_other: 'Інше',

  save_success_title: 'Служба підтримки',
  save_success: 'Запит надіслано до служби підтримки.',
}

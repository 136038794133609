export default {
  dispatche: 'Розіслати',
  project: 'Проект',
  template: 'Шаблон',
  name: 'Назва',
  dispatch: 'Розсилка',
  status: 'Статус',
  info_phones_in_lists: 'Кількість контактів',
  channel: 'Канал',
  contacts_dispatches_info: 'Кількість',
  statistic: 'Статистика розсилки',
  group_contact_name: 'Контактні групи',

  dispatches_modal_title: 'Відправити розсилку',
  dispatches_modal_content_title: 'Підготовка',
  dispatches_modal_content_body: 'Відправити розсилку {name} на {number} контактів?',
  dispatches_modal_content_body2: 'Контакти розсилки очищені від дублів і виключень.',

  phones_in_lists: 'Контактів для розсилки',
  duplicate_removed: 'Дубликатів видалено',
  deleted_by_blacklists: 'Виключено контактов',
  dispatch_send: 'Разом до відправки',

  dispatche_success: 'Розсилка почалась',

  dispatches_pnotify_title: 'Відправка розсилки',

  work_in_progress_title: 'Ще не готово?',
  work_in_progress_description: 'Завершіть створення розсилки',
  work_in_progress_btn_start_now: 'Відправити розсилку',

  start_title: 'Час розпочати!',
  start_description: 'Вкажіть налаштування розсилки або відправте прямо зараз',
  start_btn_setting: 'Налаштування розсилки',

  scheduled_title: 'Розсилка запланована!',
  scheduled_time: 'Старт розсилки {time}',
  scheduled_btn: 'Налаштування розвсилки',

  price: {
    title: 'Вартість розсилки',
    description: 'Вартість розрахована приблизно',
    description_completed: 'Уточнюється поки оновлюються статуси повідомлень',
    error: {
      _504: '' +
        '<p>Сплив час очікування. Приблизний розрахунок тимчасово недоступний.</p>' +
        '<p class="mb-0">Розсилку можна запустити незалежно від цієї помилки.Спробуйте зменшити кількість контактів у розсилці або спробувати пізніше.</p>',
    },
  },
};


import { defineComponent, computed } from 'vue';

import { useStore } from '~/utils/hooks';
import CorporatePlan from '~/components/Dashboard/CorporatePlanDashboardComponent.vue';
import WidgetBalance from '~/components/Widget/WidgetBalance.vue';
import WidgetFavoriteDropdown from '~/components/Widget/Favorite/WidgetFavoriteDropdown.vue';
import WidgetLangDropdown from '~/components/Widget/WidgetLangDropdown.vue';
import WidgetUserDropdown from '~/components/Widget/WidgetUserDropdown.vue';

export default defineComponent({
  components: {
    CorporatePlan,
    WidgetBalance,
    WidgetFavoriteDropdown,
    WidgetLangDropdown,
    WidgetUserDropdown,
  },
  setup() {
    const store = useStore();
    const isOnboardingDone = computed(
      () => store.getters['auth/isOnboardingDone']
    );

    return { isOnboardingDone };
  },
});

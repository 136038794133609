export default {
  progress: {
    title: 'Your profile',
  },
  left: {
    title: 'Platform usage',
    subtitle: 'Let us contact you to discuss the details of partnership.',
    subsubtitle: 'Please, write <mark>how we can contact you</mark> and <mark>your phone number.</mark>',
  },
  form: {
    connection_aim: {
      title: 'How do you intend to utilize Messaggio?',
      service_msg: 'Notify customers regarding order fulfillment or service-related events.',
      marketing_msg: 'Conduct marketing campaigns through SMS and messengers.',
      OTP_msg: 'Implement two-factor authentication for enhanced security.',
      chats_msg: 'Engage in client communication through chat platforms, all from a single interface.',
    },
    onb_source: {
      title: 'How did you find out about Messaggio?',
      adv: 'Advertising on the Internet',
      plugin: 'I saw a plugin in my CRM/CMS',
      recommend: 'Recommended by friends',
      seo: 'I found it on the Internet',
    },
    type_integration: {
      title: 'Select type of integration',
      lk: 'I will use messaging dashboard only',
      plugin: 'I will set up the integration via plugin',
      api: 'I will use messaging REST API',
    },
    channel: {
      title: 'What communication channels do you plan to use?',
      sms: 'SMS',
      viber: 'Viber',
      flashcall: 'Flashcall',
      whatsapp: 'WhatsApp',
      vk: 'VKontakte',
      bottelegram: 'Telegram',
      mobileid: 'Mobile ID',
      rcs: 'RCS',
    },
    crm_system: {
      title: 'Your CRM system',
      placeholder: 'CRM system',
      list: {
        other: 'Other',
        none: 'I don\'t use CRM',
      },
    },
    call_me: {
      title: 'Can we contact you for a presentation of the platform capabilities?',
      yes: 'Yes, sure.',
    },
  },
};

<template>
  <b-dropdown
    class="widget-user-dropdown"
    variant="link"
    right
    toggle-class="text-decoration-none navbar-nav-link toggle-class"
    data-cy="widget-user-dropdown"
  >
    <template slot="button-content">
      <b class="mr-2"><i class="icon-vcard" /></b>
      <template v-if="typeof userEmail === 'string'">
        <div class="text-truncate">
          {{ userEmail }}
        </div>
      </template>
      <template v-else>
        {{ userEmail.join('') }}
      </template>
    </template>
    <b-dropdown-item
      v-if="isOnboardingDone"
      :to="{ name: 'company-user-id', params: { id: user.id } }"
    >
      <i class="icon-vcard mr-2" />
      {{ $t('home.my_account_settings') }}
    </b-dropdown-item>
    <b-dropdown-item v-if="isOnboardingDone" :to="{ name: 'company-billing' }">
      <i class="icon-wallet mr-2" />
      {{ $t('home.my_balance') }}
    </b-dropdown-item>
    <b-dropdown-item v-if="isOnboardingDone" :to="{ name: 'company-account' }">
      <i class="icon-store2 mr-2" />
      {{ $t('home.my_company') }}
    </b-dropdown-item>
    <b-dropdown-item v-if="isOnboardingDone" :to="{ name: 'company-file' }">
      <i class="icon-files-empty mr-2" />
      <span class="mr-2">
        {{ $t('home.my_document') }}
      </span>
      <span
        v-if="documentsBadge"
        class="badge bg-indigo-400 badge-pill ml-auto"
      >
        {{ documentsBadge }}
      </span>
    </b-dropdown-item>
    <b-dropdown-divider v-if="isOnboardingDone" />
    <b-dropdown-item @click="logout">
      <i class="icon-switch2" />
      {{ $t('home.logout') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { showUserNameOrEmail } from '~/utils/functions';

export default {
  name: 'WidgetUserDropdown',
  computed: {
    isOnboardingDone() {
      return this.$store.getters['auth/isOnboardingDone'];
    },
    user() {
      return this.$store.state.auth.user;
    },
    userEmail() {
      try {
        const name = showUserNameOrEmail(this.user);
        if (name.includes(' ')) {
          const nameSplit = name.split(' ');
          return nameSplit[0] + ' ' + nameSplit[1].charAt(0) + '.';
        }

        const position = 10;
        const findIndex = name.lastIndexOf('.');
        if (findIndex < position) {
          return name;
        }
        return [name.substr(0, position) + '...', name.substr(findIndex)];
      } catch (e) {
        return '...';
      }
    },
    documentsBadge() {
      try {
        const statsTenants =
          this.$store.state.company.document.statsTenants.data;
        return Object.keys(statsTenants).reduce(
          (ret, type) => ((ret += statsTenants[type].notViewed), ret),
          0
        );
      } catch (e) {
        return 0;
      }
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');

      window.location.href = '/permission/login';
    },
  },
};
</script>

<style>
.widget-user-dropdown .toggle-class {
  display: flex;
  align-items: center;
}

@media (max-width: 470px) {
  .widget-user-dropdown .dropdown-menu {
    transform: translate3d(-4px, 50px, 0px) !important;
  }
}
</style>

import header from '~/locales/en/DispatchDocument/form/Header';
import template from '~/locales/en/DispatchDocument/form/Template';
import group_contact from '~/locales/en/DispatchDocument/form/GroupContact';
import price from '~/locales/en/DispatchDocument/form/Price';
import contacts_split_proportion from '~/locales/en/DispatchDocument/form/ContactsSplitProportion';

export default {
  tenant_id: 'Company',
  tenant_id_placeholder: 'Company ID',
  channel_id: 'Channels',
  btn_create_template: 'Create template',

  content_select_empty_title: 'Message template not set',
  content_select_empty_dody: 'Select the message template from the list on the right',

  load_more_templates: 'Load more templates',
  select_recipients: 'Select recipients',

  content_id_empty: 'Content not set',
  contents_a_empty: 'Content A not set',
  contents_b_empty: 'Content B not set',
  group_contact_for_dispatch_document_empty: 'Groups for dispatch not choosen',

  preview_btn: 'Preview',
  preview_title: 'Template preview',
  preview_template: 'Template for Dispatch',
  preview_content: 'Content',
  preview_contents_not_select: 'Select message template and contact groups',

  save_success: 'Saved successfully',
  save_success_on_create: 'The dispatch is ready to launch',
  action_schedule: 'Schedule',
  action_launch_now: 'Launch now',

  template_empty: 'There are no active templates in the project.',
  template_empty_project_more_you_can: 'You can',
  template_empty_project_more_create: 'create a template',
  template_empty_project_more_or: 'or',
  template_empty_project_more_select_all: 'switch project filter to All projects',
  template_empty_project_more_select_all_after: 'to see more.',
  template_empty_project_one_you_can: 'You can',
  template_empty_project_one_create: 'create a template',
  template_empty_project_one_create_after: 'for a dispatch.',

  select_template_channels: 'Sender of the message',
  select_project_title: 'Select project',
  project_id: 'Projects',
  project_id_placeholder: 'Select project',
  project_id_placeholder_empty: 'No any projects with active senders',
  client_sender_id_title: 'Choose sender ID',
  client_sender_id_description: 'You can create multi-channel message templates if you have active senders on different channels.<br>' +
    'For example, forming message template for sending it on Viber, you can add SMS text to the template, which will be sent to recipients who do not have a Viber application.',
  client_sender_id_placeholder: 'Select Sender',

  error: {
    skip_template_moderation: 'The automoderation option should be turned on. Please, contact support.',
  },

  header,
  template,
  group_contact,
  price,
  contacts_split_proportion,
};

import form from '~/locales/en/ClientSender/form';
import table from '~/locales/en/ClientSender/table';
import show from '~/locales/en/ClientSender/show';
import modal_info_box from '~/locales/en/ClientSender/ModalInfoBox';
import modal_comparison from '~/locales/en/ClientSender/ModalComparison';
import modal_logo from '~/locales/en/ClientSender/ModalLogo';
import modal_send_for_moderation from '~/locales/en/ClientSender/ModalSendForModeration';
import modal_scheduler_status from '~/locales/en/ClientSender/ModalSchedulerStatus';
import card from '~/locales/en/ClientSender/Card';
import widget_scheduled_status from '~/locales/en/ClientSender/WidgetScheduledStatus';
import widget_statistic from '~/locales/en/ClientSender/WidgetStatistic';
import trailer_profile from '~/locales/en/ClientSender/TrailerProfile';
import widget from '~/locales/en/ClientSender/Widget';
import list from '~/locales/en/ClientSender/List';

export default {
  title: 'Senders',
  title_create: 'Create Sender',
  title_show: 'Sender {name}',
  title_show_breadcrumbs: 'View Sender',
  title_edit: 'Sender {name}',
  title_edit_breadcrumbs: 'Edit Sender',

  create_btn: 'Create',

  description: 'This section allows you to manage your sender IDs on each channel. Depending on the channel features, you\'re able to customize the Sender ID\'s appearence: change the Sender name, upload a logo, etc.<br><br>' +
    'You can create a Sender ID on the channel you would like to use for messaging. Sender moderation process depends on channel terms and requirements.',
  btn_view_glossary: 'View Glossary',

  filter_q_placeholder: 'Type to filter...',
  all_status: 'All status',
  all_channels: 'All channels',

  sender_empty: 'There are no senders in the project.',
  sender_empty_project_more_you_can: 'You can',
  sender_empty_project_more_create: 'create a sender',
  sender_empty_project_more_or: 'or',
  sender_empty_project_more_select_all: 'switch project filter to All projects.',
  sender_empty_project_more_select_all_after: '',
  sender_empty_project_one_you_can: 'You can',
  sender_empty_project_one_create: 'create a sender',
  sender_empty_project_one_create_after: 'related to your company brand.',

  modal_logo_title: 'Change logo for {name}',
  modal_logo_image_crop_lable: 'Select image area to use in logo',

  modal_logo_chatscreen_title: 'Change chatscreen for {name}',
  modal_logo_chatscreen_image_crop_lable: 'Select image area to use in chatscreen',

  form,
  table,
  show,
  list,

  modal_info_box,
  modal_comparison,
  modal_logo,
  modal_send_for_moderation,
  modal_scheduler_status,

  card,
  widget_scheduled_status,
  widget_statistic,
  trailer_profile,
  widget,
}

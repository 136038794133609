export default {
  progress: {
    title: 'Каналы коммуникации',
  },
  left: {
    title: 'Выберите каналы рассылки',
    subtitle: 'Некоторые каналы требуют внесение абонентской платы за активацию имени отправителя.',
    subsubtitle: 'Мы создадим черновики заявок на регистрацию, далее потребуется довнести необходимую информацию и пополнить баланс.',
  },
  form: {
    name: {
      title: 'Имя отправителя (Вы сможете отредактировать название позже)',
      title_validate: 'Имя отправителя',
    },
    bottelegram_http_api_token: {
      title: 'HTTP API токен телеграм бота',
    },
    channels: {
      title: 'Какие каналы рассылки планируете использовать?',
      sms: {
        title: 'SMS',
        price: {
          uk: 'От 2 000 грн. в мес.',
          en: 'Может взиматься ежемесячная плата',
          def: 'От 5 000 руб. в мес.',
        },
      },
      viber: {
        title: 'Viber',
        price: 'От 100 Eur в месяц',
      },
      flashcall: {
        title: 'Flashcall верификация номера',
        price: 'Без абонентской платы',
      },
      whatsapp: {
        title: 'WhatsApp Business API',
        price: 'От 200 Eur в месяц',
      },
      vk: {
        title: 'ВКонтакте бизнес сообщения',
        price: 'Без абонентской платы',
      },
      bottelegram: {
        title: 'Telegram beta',
        price: 'Без абонентской платы',
        http_api_token: 'HTTP API токен телеграм бота',
      },
      mobileid: {
        title: 'Mobile ID верификация',
        price: 'Без абонентской платы',
      },
      rcs: {
        title: 'RCS Business Messaging',
        description: 'Скоро',
      },
      verified_sms: {
        title: 'Verified SMS by Google',
        description: 'Скоро',
      },
    },
    type_integration: {
      title: 'Выберите тип интеграции',
      lk: 'Буду использовать только личный кабинет',
      plugin: 'Буду использовать отправку через плагин',
      api: 'Планирую интеграцию по API',
    },
    call_me: {
      title: 'Планирую интеграцию по API',
      yes: 'Да, пожалуйста.',
    },
    after_submit_success: {
      title: 'Всё готово!',
      text: 'Завершите настройку Отправителя до конца, чтобы сформировать заявку на активацию.',
    },
  },
};

import api from '~/api';
import {getChannelOnePromises} from "~/store/channel-and-gate/channel-and-gate/channel";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10, sort = ''} = {}) {
    if (sort.length > 0) sort += ',';
    sort += 'id';
    return api.documents.gate.list(page, pagesize, sort).then(async response => {
      //console.info('api.documents.gate.list | response = ', response.data);
      let items = response.data.Body || [];
      items = await Promise.all(items.map(item => new Promise(async (resolve) => {
        item.channel = null;
        try {
          const response = await api.documents.gate.channel.get(item.id);
          //console.info('api.documents.gate.channel.get | response = ', response.data);
          item.channel = response.data.Body || [];
        } catch (e) {
          console.error('getCompanyOnePromises | dispatch company/company/one | ', e.toString());
        }
        resolve(item);
      })));

      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.gate.list | error = ', error);
      throw error;
    });
  },
  one({commit, state}, {id}) {
    return api.documents.gate.get(id).then(response => {
      //console.info('api.documents.gate.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.gate.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.gate.post(data).then(response => {
      //console.info('api.documents.gate.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.gate.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.documents.gate.put(id, data).then(response => {
      //console.info('api.documents.gate.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.gate.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.gate.delete(id).then(response => {
      //console.info('api.documents.gate.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.gate.put | error = ', error);
      throw error;
    });
  },
};

export default {
  delete_confirm: 'Ви впевнені, що хочете видалити користувача "{name}"?',
  delete_success: 'Користувача "{name}" видалено.',

  email_verified: 'ACTIVE',
  email_not_verified: 'Email не підтверджено',

  role_owner: 'Власник',
  role_manager: 'Менеджер',
}

export default {
  name: 'Назва',
  tenant_id: 'Компанія',
  type: 'Тип групи',
  row_count: 'Кількість',
  updated_at: 'Дата',

  type_whitelist: 'Контакти для розсилки',
  type_blacklist: 'Контакти для виключення',

  label_chat_contacts: 'Контакти з чатів',

  description_empty: 'Опис не задано',
  btn_dump_export: 'Експорт контактів',

  delete_confirm: 'Ви дійсно хочете видалити групу контактів "{name}"?',
  delete_success: 'Групу контактів "{name}" видалено.',
}

import { render, staticRenderFns } from "./WidgetBalanceEnded.vue?vue&type=template&id=293a4a7c&scoped=true&"
import script from "./WidgetBalanceEnded.vue?vue&type=script&lang=js&"
export * from "./WidgetBalanceEnded.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@3.4.32_babel-core@7.0.0-bridge.0_@babel+core@7.24.9__css_uv5biolnn6ywtbh3eesigpjl3u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "293a4a7c",
  null
  
)

export default component.exports
export default {
  title: 'Чаты',

  new_chat: 'Создать чат',
  description: 'В некоторых случаях нет возможности слать абоненту произвольный текст, если он не инициировал с вами переписку. <br>' +
    'Возможности переписки с абонентом зависят от Отправителя и канала отправки.',

  to: 'Кому',
  from: 'От',
  phone_number_placeholder: 'Укажите номер телефона...',
  client_sender_id_placeholder: 'Выберите отправителя...',
  message_payload: 'Сообщение',

  payload_placeholder: 'Введите сообщение...',
  payload_length_max: 'Сообщение должно содержать менее 1000 символов.',
  send: 'Отправить',
};

export default {
  progress: {
    title: 'Інформація про компанію',
  },
  left: {
    title: 'Ласкаво просимо!',
    subtitle: 'Розкажіть нам про свої завдання, щоб ми могли підібрати для вас найкраще рішення.',
    subsubtitle: 'Вкажіть, будь ласка, <mark>країну вашого знаходження</mark> и <mark>короткий опис</mark> діяльности вашої компанії.',
  },
  form: {
    locale: {
      title: 'Мова інтерфейсу',
    },
    name: {
      title: 'Назва компанії',
      placeholder: 'Моя компанія, ТОВ',
    },
    description: {
      title: 'Кілька слів про ваш бизнес',
      placeholder: 'Виберіть',
      list: {
        it_and_internet_companies: 'IT та інтернет сервіси',
        brand: 'Бренд',
        governmental: 'Державна/Недержавна організація',
        healthcare: 'Охорона здоров\'я',
        publisher: 'Видавництво',
        e_commerce: 'Інтернет-торгівля',
        local_business: 'Місцевий бізнес',
        real_estate_and_construction_companies: 'Нерухомість та будівництво',
        education: 'Освіта',
        community_organization: 'Громадська організація',
        media_agency: 'Рекламна агенція',
        commerce: 'Торгівля',
        transportation_services: 'Транспортні послуги',
        financial_institution: 'Фінансовий сервіс',
        other: 'Інше',
      },
    },
    location_country: {
      title: 'Країна знаходження',
    },
  },
};

export default {
  name: 'Название',
  name_placeholder: 'Шаблон {time}',
  select_project: 'Шаблон будет создан в проекте',
  tenant_id: 'Компания',
  tenant_id_placeholder: 'Название компании',
  channel: 'Каналы',
  clientsender_id: 'Отправить',
  select_template_channels: 'Отправитель сообщения',
  channels_empty: 'У проекта нет активных отправителей',
  select_project_title: 'Выберите проект',
  project_id: 'Проекты',
  project_id_placeholder: 'Выбрать проект',
  project_id_placeholder_empty: 'Нет проектов с активными отправителями',
  client_sender_id_title: 'Выберите Отправитель',
  client_sender_id_description: 'Вы можете создавать многоканальные шаблоны сообщений, если у вас есть активные отправители на разных каналах.<br>' +
    'Например, формируя шаблон сообщения для отправки его в Viber, вы можете добавить в шаблон текст SMS, который будет отправлен контактам, у которых нет приложения Viber.',
  client_sender_id_placeholder: 'Выберите имя отправителя',
  type: 'тип',
  contents_client_sender_id: 'Отправитель',
  status: 'Статус',
  created_at: 'Дата',
  content_layout: 'Макет',
  content_layout_template_category: 'Категория шаблона',
  content_layout_language: 'Язык',
  content_layout_text: 'Текст',
  content_layout_text_image: 'Текст + Изображение',
  content_layout_text_document: 'Текст + Файл',
  content_layout_text_location: 'Текст + Локация',
  content_layout_text_image_btn: 'Текст + Кнопка + Изображение',
  content_layout_text_btn: 'Текст + Кнопка',
  content_layout_tpl: 'Шаблон',
  content_layout_body_text: 'Сообщение',
  content_layout_body_text_tpl: 'Шаблон транзакционного сообщения',
  content_layout_body_sample_text: 'Пример сообщения',
  content_layout_body_text_tpl_check: 'Проверить шаблон',
  content_layout_body_text_link: 'Ссылка',
  content_layout_btn: 'Кнопка',
  content_layout_body_title: 'Название кнопки',
  content_layout_body_link: 'Ссылка кнопки',
  content_layout_image: 'Изображение',
  content_layout_body_file_name: 'Изображение',
  content_layout_body_image_file_name: 'Ссылка на изображение',
  content_layout_body_image_caption: 'Описание',
  content_layout_video: 'Видео',
  content_layout_document: 'Документ',
  content_layout_location: 'Локация',
  content_layout_contact: 'Контакт',
  content_layout_video_thumbnail: 'Обложка изображения',
  content_layout_video_action: 'Видео файл',
  content_layout_text_video_btn: 'Видео + Текст + Кнопка',
  content_layout_text_video: 'Видео + Текст',
  content_layout_template_contact_first_name: 'Имя',
  content_layout_template_contact_phone_number: 'Номер телефона',

  save_success: 'Готово',

  preview_btn: 'Предпросмотр',
  preview_title: 'Предварительный просмотр шаблона',
  preview_body: ' Выберите каналы шаблона и заполните поля формы макета, чтобы просмотреть сообщения.',

  testphone_btn: 'Отправить на телефон',

  message_from: '{channel_name} сообщение от {client_sender_name}',

  error_project_id_empty: 'Проект не выбран',
  error_sender_empty: 'Не указан отправитель',
  error_viber_check_tpl: 'Шаблон не совпадает с примером сообщения',
  error_viber_dynamic_link_scheme: 'Нужно указать схему для URL',
  error_viber_template_param: 'Шаблон содержит недопустимый параметр: {params}',
  error_content_layout_body_sample_text_validation: 'Пример сообщения не подходит под шаблон',
  error_button_row_empty: 'Ряд кнопок не может быть пустым',

  before_route_leave_confirm: 'Вы действительно хотите покинуть страницу создания шаблона?',

  sender_empty: 'В проекте нет активных отправителей.',
  sender_empty_project_more_you_can: 'Вы можете',
  sender_empty_project_more_create: 'создать отправителя',
  sender_empty_project_more_or: 'сообщений или',
  sender_empty_project_more_select_all: 'переключить фильтр проектов на Все проекты.',
  sender_empty_project_more_select_all_after: '',
  sender_empty_project_one_you_can: 'Вы можете',
  sender_empty_project_one_create: 'создать отправителя',
  sender_empty_project_one_create_after: 'сообщений для вашего бизнеса.',

  knowledge_step: 'Шаг {step}',
  knowledge_step1_description: 'Выберите Отправитель, от имени которого будет проводиться рассылка сообщений.',
  knowledge_step2_description: 'Сформируйте сообщение, используя возможные макеты. Вы также можете использовать переменные в тексте и ссылках, которые заполнятся параметрами, указанными в свойствах контактов группы для рассылки.',
  knowledge_step3_description: 'Отправьте шаблон сообщения на свой тестовый телефон, чтобы понимать как он будет выглядеть на устройстве.',
};

<template>
  <div>
    <b2-table
      :class="{selectedAll: isSelectedAll}"
      :fields="fields_"
      :items="items_"
      :sort-by.sync="sortBySync"
      :sort-desc.sync="sortDescSync"
      class="table-hover"
      show-empty
      stacked="sm"
    >
      <template
        slot="HEAD_isFavorite"
        slot-scope="data"
      />
      <template
        slot="HEAD_id"
        slot-scope="data"
      >
        <widget-form-checkbox
          v-if="selectedTypeCheckbox"
          v-model="selectedAll"
        />
      </template>
      <template
        slot="id"
        slot-scope="data"
      >
        <component
          :is="selectedTypeCheckboxComponent"
          v-model="selected_"
          :value="data.item.id"
        />
      </template>
      <template
        slot="isFavorite"
        slot-scope="data"
      >
        <set-favorite
          :item="data.item"
          @onSetFavorite="emitDeleteItem(data.item)"
        />
      </template>
      <template
        slot="entity_name"
        slot-scope="data"
      >
        <nuxt-link
          :to="linkShow(data.item)"
          class="text-default font-weight-semibold"
        >
          {{ data.item.entity_name }}
        </nuxt-link>
        <div class="text-muted font-size-sm">
          {{ data.item.created_at | dateTimeHuman }}
        </div>
      </template>
      <template
        slot="entity_type"
        slot-scope="data"
      >
        {{ $t('company.favorite.table.type_' + data.item.entity_type) }}
      </template>
      <actions-list
        slot="actions"
        slot-scope="data"
        :item="data.item"
        :action-show-disabled="true"
        route-id-name="id"
        route-to-name="company-testphone"
        @delete="onDelete"
      />
    </b2-table>
    <div
      v-if="totalRows > perPage"
      class="datatable-footer"
    >
      <div
        aria-live="polite"
        class="dataTables_info"
        role="status"
      />
      <div class="dataTables_paginate paging_simple_numbers">
        <b-pagination
          :hide-goto-end-buttons="true"
          :per-page="perPage"
          :total-rows="totalRows"
          :value="currentPage"
          @input="$emit('update:currentPage', arguments[0])"
        />
      </div>
    </div>
    <widget-loader v-if="loading" />
  </div>
</template>

<script>
import MixinTable from '~/components/Mixin/MixinTable';
import ActionsList from '~/components/Widget/TableSlot/ActionsListTableSlotWidget';
import { catchDispatch } from '~/utils/form';
import SetFavorite from '~/components/Company/Favorite/SetFavoriteTableFavoriteCompanyComponent';

const linkShowMap = {
  dispatch: { name: 'dispatch-id', id: 'id' },
  template: { name: 'template-id', id: 'id' },
  contact_whitelist: { name: 'contact-group-gid', id: 'gid' },
  contact_blacklist: { name: 'contact-group-gid', id: 'gid' },
  clientsender: { name: 'sender-id', id: 'id' },
  project: { name: 'project-project_id', id: 'project_id' },
};

export const linkShow = item => linkShowMap[item.entity_type]
  ? {
      name: linkShowMap[item.entity_type].name + '-show',
      params: { [linkShowMap[item.entity_type].id]: item.entity_id },
    }
  : '/404';

export default {
  components: {
    ActionsList,
    SetFavorite,
  },
  mixins: [MixinTable],
  props: {
    fields: {
      type: Array,
      default () {
        return [
          {
            key: 'isFavorite',
            tdClass: ['w-sm-32 w-100 pr-0 d-sm-table-cell d-flex align-items-center'],
          },
          { key: 'entity_name' },
          { key: 'entity_type' },
        ];
      },
    },
    nameIsLink: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      submit: false,
      fields_label: {
        isFavorite: ' ',
        entity_name: 'company.favorite.table.entity_name',
        entity_type: 'company.favorite.table.entity_type',
      },
    };
  },
  methods: {
    onDeleteItem () {
    },
    linkShow,
    setFavorite (item, is) {
      if (!confirm(this.$t('company.favorite.table.delete_confirm', { name: item.entity_name }))) {
        return false;
      }

      this.submit = true;
      try {
        this.$store.dispatch(
          'trailer/setting/delete',
          { id: item.id },
        );
      } catch (e) {
        catchDispatch(this, e);
      }
      this.submit = false;
    },
  },
};
</script>

<style scoped>

</style>

var DispatchStatus;
(function(DispatchStatus2) {
  DispatchStatus2["NEW"] = "new";
  DispatchStatus2["READY"] = "ready";
  DispatchStatus2["PROCESS"] = "process";
  DispatchStatus2["COMPLETED"] = "completed";
  DispatchStatus2["CANCELED"] = "canceled";
})(DispatchStatus || (DispatchStatus = {}));
export {
  DispatchStatus
};

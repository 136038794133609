export default {
  loading: 'Loading Dispatches...',

  name: 'Name',
  channel: 'Channel',
  quantity: 'Quantity',
  tenant_id: 'Company',
  scheduled: '',
  status: 'Status',
  created_at: 'Date',

  delete_confirm: 'Are you sure you want to delete Dispatch "{name}"?',
  delete_success: 'Dispatch "{name}" was deleted.',

  restore_confirm: 'Are you sure you want to restore Dispatch "{name}"?',
  restore_success: 'Dispatch "{name}" was restore.',

  scheduled_delete_confirm: 'Are you sure you want to canceled Dispatch scheduled "{name}"?',
  scheduled_delete_success: 'Dispatch scheduled "{name}" was canceled.',
}

import table from '~/locales/uk/Company/ActionHistory/table';

export default {
  title: 'Історія дій',
  description: '<p>Історія дій показує активність користувачів у вашому акаунті.</p>' +
    '<p>Оберіть період активності що вас цікавить і за необхідності налаштуйте фільтри по користувачу або розділу особистого кабінету.</p>',

  table,

  services_template: 'Шаблон',
  services_group_contact: 'Контакт групи',

  created_at_from_placeholder: 'Дата з',
  created_at_to_placeholder: 'Дата до',
  user_placeholder: 'Користувач',
  entity_placeholder: 'Сервіс',
};

export default {
  title: 'File info',

  name: 'Name',
  description: 'Description',
  downloaded: 'Downloaded',
  createdTime: 'Created',
  mimeType: 'MimeType',
  size: 'Size',
  category: 'Category',
  hash: 'Hash',
  shortHash: 'ShortHash',
  public: 'Public',

  delete: 'Delete',
  download: 'Download',
}

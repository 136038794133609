export default {
  title_browser: 'Регистрация',
  title: 'Создание аккаунта',
  subtitle: 'Все поля обязательны для заполнения',
  tenant_name_placeholder: 'Название компании',
  user_name_placeholder: 'Ваше имя',
  user_email_placeholder: 'Email адрес',
  user_password: 'Пароль',
  user_password_confirmed: 'Еще раз пароль',
  register_btn: 'Зарегистрироваться',
  terms_and_conditions: 'Я согласен с <a href="https://messaggio.com/ru/terms-and-conditions/" target="_blank">Условиями использования</a>',
  privacy_policy: 'Я согласен с <a href="https://messaggio.com/ru/privacy-policy/" target="_blank">Политикой конфиденциальности</a>',
  by_continuing: 'Продолжая, вы подтверждаете, что прочитали и согласны с <a href="https://messaggio.com/ru/terms-and-conditions/" target="_blank">Пользовательским соглашением</a>, <a href="https://messaggio.com/ru/privacy-policy/" target="_blank">Политикой конфиденциальности</a> и <a href="https://messaggio.com/ru/terms-and-conditions/contract-for-the-provision-of-messaging-services-offer/" target="_blank">Офертой</a>',
  or_registration_in_with: 'или зарегистрируйтесь с помощью',
};

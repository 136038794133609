import modal_upload from "~/locales/ru/Company/Gallery/ModalUpload";

export default {
  title: 'Галерея',

  load_image: 'Загрузить',

  delete_confirm: 'Вы уверены, что хотите удалить изображение?',
  delete_success: 'Изображение удалено.',

  gallery_empty: 'В галерее нет изображений.',
  gallery_empty_project_one_you_can: '',
  gallery_empty_project_one_create: 'Загрузите изображение, ',
  gallery_empty_project_one_create_after: 'соответствующее требованиям канала.',

  modal_upload,
}

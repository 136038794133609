export default {
  additional_settings: {
    title: 'Advanced settings',
    device: {
      description: "You can specify on which devices you want to deliver a Viber message: to all user devices where Viber app is authorized under recipient's account, or only to the primary mobile device.",
      phone: 'Primary mobile device',
      all: 'All devices',
    },
  },
};

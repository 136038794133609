export default {
  brand: 'Бренд',
  media_agency: 'Рекламна агенція',
  local_business: 'Місцевий бізнес',
  financial_institution: 'Фінансовий сервіс',
  community_organization: 'Громадська організація',
  governmental_non_governmental_organization: 'Державна / Недержавна організація',
  other: 'Інше',
  publisher: 'Видавництво',
  commerce: 'Рітейл',
  ecommerce: 'Інтернет рітейл',
  real_estate_and_construction_companies: 'Нерухомість і будівництво',
  it_and_internet_companies: 'IT і інтернет сервіси',
  education: 'Освіта',
  healthcare: 'Охорона здоров\'я',
  transportation_services: 'Транспортні сервіси',
};

export default {
  loading: 'Loading Projects...',

  name: 'Name',
  tenant_id: 'Company',
  bulk_idp: 'IDP',
  api_type: 'API type',
  bulk_login: 'Bulk login',
  bulk_secret_key: 'Secret key',

  statistic: 'Statistics',

  delete_confirm: 'Are you sure you want to delete Project "{name}"?',
  delete_success: 'Project "{name}" was deleted.',
}

export default {
  progress: {
    title: 'Додавання тестового номера',
  },
  left: {
    title: 'Додайте номер телефону для прийому тестових повідомлень.',
    subtitle:
      'Після додавання номера вам надійде телефонний дзвінок, останні 4 цифри номера якого – код підтвердження. Або надійде смс з кодом підтвердження.',
    subsubtitle:
      'Ви можете використовувати свій номер телефону для прийому тестових повідомлень, також ви зможете додати додаткові тестові номери в налаштуваннях облікового запису.',
  },
  form_code: {
    success: {
      text: 'Номер телефону підтверджено. Для продовження натисніть "Готово"',
    },
  },
  help: {
    btn: 'СМС не приходить',
    title: 'Напишіть нам у Telegram або WhatsApp, і ми підтвердимо ваш номер.',
    content:
      'Якщо вам зручніше написати нам з вашого мобільного пристрою, наш номер у Telegram та WhatsApp - ',
    message:
      'Вітання! Будь ласка, підтвердьте мій номер телефону. Електронна адреса, з якої я реєструвався, - {email}',
  },
  submit: {
    progress: {
      title: 'Настроюємо ваш кабінет',
    },
  },
};

export default {
  title: 'Import contacts to the group',

  tab_manual_input: 'Manual input',
  phone_numbers: 'Phone numbers',
  phone_numbers_placeholder: 'Enter your phone numbers list here',

  tab_import_csv_file: 'Import file',
  select_file_to_import: 'Select file to import',
  accepted_formats: 'Accepted formats: *.xlsx; *.csv. Max file size 20Mb',

  tab_import_csv_file_title: 'Download example of the CSV file',
  tab_import_csv_file_subtitle: 'The spreadsheets saved in the format "CSV - comma delimiter" in UTF-8 encoding are best suited for import',
  tab_import_csv_file_download: 'Download file',
  tab_import_csv_file_excel_windows: 'How to save the file in Excel for Windows',
  tab_import_csv_file_excel_macos: 'How to save the file in Excel for Mac',
  tab_import_csv_file_numbers_macos: 'How to save the file in Numbers for Mac',

  contact_handling_settings: 'Contact handling settings',
  duplicate_remove: 'Clear the list of contacts from duplicate numbers',

  separate_symbol: 'Separator',
  separate_symbol_comma: 'Comma',
  separate_symbol_semicolon: 'Semicolon',

  duplicate_cleanup_start: 'Start duplicate cleanup',
  duplicate_cleanup_deleted: 'Duplicate cleanup: {deleted}',

  phone_column_not_specified: 'Phone number column was not specified',

  charset_title: 'Contacts import encoding',
  charset_description: 'Choose another encoding for the contact list, if the data in preview is unreadable.',

  phone_number_rewrite_title: 'Phone number rewrite',
  phone_number_rewrite_description: 'The location of the company you specified as: <b>{country_name}</b><br>Enable this option if you are uploading contacts in a local format, e.g. 00xxxx or 0xxxx....',
  phone_number_rewrite_label: 'Replace local code by country code <b>{country_code}</b> when necessary',

  previous_step: 'Previous',
  next_step: 'Next step',
};

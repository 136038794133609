import { ErrorTranslation } from "../errors.js";
class ErrorAuth extends ErrorTranslation {
  constructor(message) {
    super("auth." + message);
    this.name = "ErrorAuthLogin";
    Object.setPrototypeOf(this, ErrorAuth.prototype);
  }
}
class ErrorTokenEmpty extends ErrorAuth {
  constructor() {
    super("token_empty");
    this.name = "ErrorTokenEmpty";
    Object.setPrototypeOf(this, ErrorTokenEmpty.prototype);
  }
}
export {
  ErrorAuth,
  ErrorTokenEmpty
};

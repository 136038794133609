import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';
import { arrayToObject } from '~/utils/functions';

const baseState = {
  list: {
    presetLoading: false,
    channels: {},
    gates: {},
    projects: {
      loading: false,
      data: [],
    },
    statuses: [],
  },
};

export const state = () => ({ ...baseState });

export const getters = {};

export const mutations = {
  reset (state) {
    state.list.templatePreset = [];
    state.list.projects.data = [];
  },
  presetLoading (state, loading) {
    state.list.presetLoading = loading;
  },
  presetData (state, data) {
    // console.info('presetData(state, ', data, ')');
    state.list = { ...state.list, ...data };
  },
  listDataProjectsLoading (state, loading) {
    state.list.projects.loading = loading;
  },
  listDataProjectsData (state, data) {
    state.list.projects.data = data;
  },
};

export const actions = {
  async fetch ({ commit, dispatch }, { id, checkRedirectToShow = true, observer = null, relate = [] }) {
    // console.info('store/dispatch-document/form/fetch');

    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    const clientsender = await dispatch('clientsender/one', { id }, { root: true });

    const { status, deleted_at } = clientsender;
    if (checkRedirectToShow && (
      (deleted_at && deleted_at.length) ||
      (status !== 'new')
    )) { throw 'REDIRECT_TO_SHOW'; }

    return clientsender;
  },
  reset ({ commit }) {
    commit('reset');
  },
  async getPreset ({ commit, dispatch }) {
    commit('presetLoading', true);
    try {
      const presetData = {};
      // Получаем базовые списки
      /* dispatch('clientsender/statuses', {}, {root: true}).then(result => {
        const {status} = result;
        commit('presetData', {statuses: status});
      }); */
      const promises = [
        dispatch(
          'channel-and-gate/channel-and-gate/channel/list',
          {},
          { root: true },
        ).then(result => presetData.channels = arrayToObject(result.items || {}, 'id')),
      ];
      await Promise.all(promises);
      commit('presetData', presetData);
      commit('presetLoading', false);
    } catch (e) {
      console.error('dispatch | clientsender/form/getPreset | e = ', e);
      commit('presetLoading', e);
    }
  },
  async getProjects ({ commit, dispatch }, { tenant_id }) {
    commit('listDataProjectsLoading', true);
    try {
      const result = await dispatch(
        'company/project/listByTenant',
        { tenant_id },
        { root: true },
      );
      // console.info('dispatch | clientsender/form/getProjects | result = ', result);
      commit('listDataProjectsData', result.items);
    } catch (e) {
      console.error('dispatch | clientsender/form/getProjects | e = ', e);
    }
    commit('listDataProjectsLoading', false);
  },
  create ({ commit, dispatch }, { id, ...data }) {
    let apiCall = api.documents.clientsender.post;
    const isTelegramBot = ([
      'telegram',
      'telegram1',
      'bottelegram',
    ].includes(data.channel.name));

    if (isTelegramBot) {
      apiCall = api.saga.bots.telegram.post;
    }

    data = { ...data };
    delete data.id;

    return apiCall(data).then(response => {
      // console.info('api.documents.clientsender.post | response = ', response.data);

      dispatch('clientsender/countByStatus/fetch', {}, { root: true });

      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.post | error = ', error);
      throw error;
    });
  },
  edit ({ commit, dispatch }, { id, ...data }) {
    data = { ...data };
    delete data.channel;
    delete data.tenant;
    delete data.project;
    return api.documents.clientsender.put(id, data).then(response => {
      // console.info('api.documents.clientsender.put | response = ', response.data);

      dispatch('clientsender/countByStatus/fetch', {}, { root: true });

      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.put | error = ', error);
      throw error;
    });
  },
  async detail ({ commit, dispatch }, { entity_id, data }) {
    const entity_type = 'clientsender';
    const payload = await Promise.all(Object.entries(data).map(async ([key, data]) => {
      if (data.drive_file) {
        await dispatch(
          'company/document/upload',
          {
            file: data.drive_file,
            category: 'documents',
            public: false,
            description: 'company brand letter',
          },
          { root: true },
        ).then(({ category, shortHash, name }) => {
          data.value = [category, shortHash, name].join('/');
        });
      } else if (Array.isArray(data.value)) {
        data.value = data.value.join(',');
      }

      return {
        key: 'profile.' + key,
        value: data.value,
      };
    }));

    return await dispatch(
      'trailer/setting/group/store',
      {
        entity_id,
        entity_type,
        payload,
      },
      { root: true },
    );
  },
  putStatuses ({ commit, dispatch }, { id, status }) {
    return api.documents.clientsender.put_statuses(id, status).then(response => {
      // console.info('api.documents.clientsender.put_statuses | response = ', response.data);

      dispatch('clientsender/countByStatus/fetch', {}, { root: true });

      return response.data;
    }).catch(error => {
      console.error('api.documents.clientsender.put_statuses | error = ', error);
      throw error;
    });
  },
  restrictionByName ({ commit, dispatch }, { name }) {
    return api.image.image.restrictions.list(1, 1, '', { name }).then(response => {
      // console.info('api.image.image.restrictions | response = ', response);
      const restriction = (response.data.Body || []).find(r => r.name === name);
      if (!restriction) {
        throw 'Restrictions ' + name + ' not found';
      }
      return restriction;
    }).catch(error => {
      console.error('api.image.image.restrictions | error = ', error);
      throw error;
    });
  },
  uploadImage ({ commit, dispatch }, { restriction, file }) {
    // console.info('uploadImage({commit, dispatch}, {', file, '})');

    const fileForm = new FormData();
    fileForm.append('restriction', restriction);
    fileForm.append('file', file);

    return api.image.image.post(fileForm).then(response => {
      // console.info('api.image.image.post | response = ', response.data);
      return response.data.url;
    }).catch(error => {
      console.error('api.image.image.post | error = ', error);
      throw error;
    });
  },
};

const check = async (resolve, id) => {
  let result;
  try {
    result = await api.documents.clientsender.dispatches.status(id);
  } catch (e) {
    console.error('clientsender/form check | id = ', id, ' | e = ', e);
    setTimeout(() => {
      check(resolve, id).then();
    }, 3000);
    return;
  }
  // console.info('clientsender/form | result = ', result);
  if (result.data.status === 'ready') {
    resolve({ id });
  } else {
    setTimeout(() => {
      check(resolve, id).then();
    }, 3000);
  }
};

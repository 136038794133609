export default {
  name: 'Название',
  tenant_id: 'Компания',
  type: 'Тип группы',
  row_count: 'Количество',
  updated_at: 'Дата',

  type_whitelist: 'Контакты для рассылки',
  type_blacklist: 'Контакты для исключения',

  label_chat_contacts: 'Контакты из чатов',

  description_empty: 'Описание не задано',
  btn_dump_export: 'Экспорт контактов',

  delete_confirm: 'Вы действительно хотите удалить группу контактов "{name}"?',
  delete_success: 'Группа контактов "{name}" удалена.',
}

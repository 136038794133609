export default {
  title: 'Phone search results',

  loading: 'Loading messages...',

  key: 'Parameter',
  value: 'Value',

}
//$t('statistic.phone.loading')

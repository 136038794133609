export default {
  loading: 'Завантаження...',

  name: 'Назва',
  channel: 'Канал',
  quantity: 'Кількість',
  tenant_id: 'Компанія',
  scheduled: '',
  status: 'Статус',
  created_at: 'Дата',

  delete_confirm: 'Ви дійсно хочете видалити розсилку "{name}"?',
  delete_success: 'Розсилка "{name}" видалена.',

  restore_confirm: 'Ви дійсно хочете відновити розсилку "{name}"?',
  restore_success: 'Розсилку "{name}" відновлено.',

  scheduled_delete_confirm: 'Ви дійсно хочете скасувати заплановану розсилку "{name}"?',
  scheduled_delete_success: 'Заплановану розсилку "{name}" скасовано.',
}

export function catchDispatchErrorMsg(self, error) {
  if (error && error === 'XhrError') {
    return 'Error: Network Error';
  } else if (error && error.response) {
    if (error.response.status === 403) {
      if (error?.response?.data?.error) {
        return error.response.data.error;
      }
      return self.$t('home.error403');
    } else if (
      error.response.status == 503 &&
      'result' in error.response.data
    ) {
      return error.response.data.result;
    } else if (error.response.status === 422) {
      self.errors && self.errors.clear();
      if (error.response.data.errors) {
        const message = [];
        Object.keys(error.response.data.errors).map((field) => {
          const messages = error.response.data.errors[field];
          self.errors.add(
            self.id + '.' + field,
            self.$t(messages[0]),
            'server'
          );
          message.push(self.$t(messages[0]));
        });
        return message.join('<br>');
      } else {
        return (
          error.response.data.message ||
          error.response.data.error ||
          error.response.data.status ||
          error.response.data ||
          error.response.status
        );
      }
    } else if (error.response.status == 401) {
      return self.$t('home.error401');
    } else if (error.response.data) {
      return (
        error.response.data.message ||
        error.response.data.error ||
        error.response.data.status ||
        error.response.data ||
        error.response.status
      );
    }
  } else if (error) {
    return error.toString();
  }

  return 'no error data';
}

export function catchDispatch(self, error) {
  self.loading = false;

  const title = self.$t('home.error');
  const text = catchDispatchErrorMsg(self, error);
  const addClass = 'bg-danger';

  console.error(error);
  window.PNotify.alert({ title, text, addClass, textTrusted: true });
}

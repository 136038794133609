import phone_is_empty from "~/locales/ru/Company/Notification/PhoneIsEmpty";

export default {
  messaging: 'Рассылки',
  launch_of_scheduled_dispatch: 'Запуск запланированной рассылки',
  message_template_moderation: 'Модерация шаблона',
  chatting: 'Чаты',
  chat_new_message_alerts: 'Показывать всплывающие уведомления о новых сообщениях',
  senders: 'Отправители',
  sender_moderation: 'Модерация отправителя',
  sender_activation_or_deactivation: 'Активация и деактивация отправителя',
  sender_deactivation_reminder: 'Напоминание о деактивации',
  balance_and_payments: 'Баланс и платежи',
  low_balance_reminder: 'Предупреждение о низком балансе',
  low_balance_reminder_subtitle: 'Вы получите Email уведомление на почтовый ящик, указанный в профиле.',
  low_balance_reminder_badge: 'EMAIL',
  low_balance_reminder_mobile: 'Предупреждение о низком балансе',
  low_balance_reminder_mobile_subtitle: 'Вы получите Viber или SMS уведомление на телефон, привязанный к вашему профилю.',
  low_balance_reminder_mobile_badge: 'VIBER или SMS',
  payment_status_notifications: 'Статус платежа',
  system: 'Система',
  messaging_channel_technical_failure_notification: 'Технические неполадки на канале рассылки',
  detailed_statistics_file_generation_completed: 'Готовность выгрузки детальной статистики',
  user_agreement_updates: 'Обновление пользовательского соглашения',
  scheduled_service_maintenance_reminder: 'Запланированные технические работы на платформе',
  technical_accident_notices: 'Уведомление о технических сбоях платформы',

  success: 'Уведомления',
  save_success: 'Настройки уведомлений обновлены.',

  phone_is_empty,
}

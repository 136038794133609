import account from '~/locales/uk/Company/Account';
import balance from '~/locales/uk/Company/Balance';
import billing from '~/locales/uk/Company/billing';
import gallery from '~/locales/uk/Company/Gallery';
import user from '~/locales/uk/Company/User';
import testphone from '~/locales/uk/Company/Testphone';
import menu from '~/locales/uk/Company/Menu';
import service from '~/locales/uk/Company/Service';
import notification from '~/locales/uk/Company/Notification';
import settings from '~/locales/uk/Company/settings';
import support from '~/locales/uk/Company/support';
import favorite from '~/locales/uk/Company/Favorite';
import document from '~/locales/uk/Company/Document';
import chat from '~/locales/uk/Company/Chat';
import action_history from '~/locales/uk/Company/ActionHistory';
import corp_plan from '~/locales/uk/Company/CorpPlan';

export default {
  title: 'Моя компанія',

  account,
  balance,
  billing,
  gallery,
  user,
  testphone,
  menu,
  service,
  notification,
  settings,
  support,
  favorite,
  document,
  chat,
  action_history,
  corp_plan,
};

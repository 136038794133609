import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  async startDispatch(_, {dispatch_id}) {
    try {
      const {data} = await api.saga.dispatch.start(dispatch_id);
      //console.info('store/dispatch-document/launch.startDispatch | data = ', data);
      return {dispatch_id};
    } catch (e) {
      console.error('store/dispatch-document/launch.startDispatch | e = ', e);
      throw e;
    }
  },
  async editDurationInHours(_, {dispatch_id, duration_in_hours}) {
    try {
      const {data} = await api.documents.dispatch_document.duration_in_hours.put(dispatch_id, {duration_in_hours});
      //console.info('store/dispatch-document/launch.editDurationInHours | data = ', data);
      return data;
    } catch (e) {
      console.error('store/dispatch-document/launch.editDurationInHours | e = ', e);
      throw e;
    }
  },
  async editSmart(_, {dispatch_id, enabled}) {
    try {
      const {data} = await api.documents.dispatch_document.is_smart.put(dispatch_id, {is_smart: enabled});
      //console.info('store/dispatch-document/launch.editSmart | data = ', data);
      return data;
    } catch (e) {
      console.error('store/dispatch-document/launch.editSmart | e = ', e);
      throw e;
    }
  },
  async makeDispatch(_, {dispatch_id, ...options}) {
    try {
      const {data} = await api.saga.dispatch.prepare.post(dispatch_id, options);
      //console.info('store/dispatch-document/launch.makeDispatch | data = ', data);
      return data;
    } catch (e) {
      console.error('store/dispatch-document/launch.makeDispatch | error = ', e);
      throw e;
    }
  },
  async makeDispatchProgress(_, {dispatch_id}) {
    try {
      const {data} = await api.saga.dispatch.prepare.progress(dispatch_id);
      //console.info('store/dispatch-document/launch.makeDispatchProgress | data = ', data);
      return data;
    } catch (e) {
      console.error('store/dispatch-document/launch.makeDispatchProgress | error = ', e);
      throw e;
    }
  },
};

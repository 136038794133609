var ClientSenderStatus;
(function(ClientSenderStatus2) {
  ClientSenderStatus2["NEW"] = "new";
  ClientSenderStatus2["ACTIVE"] = "active";
  ClientSenderStatus2["DEACT"] = "deact";
  ClientSenderStatus2["MODERATION"] = "moderation";
})(ClientSenderStatus || (ClientSenderStatus = {}));
var ClientSenderSenderDirection;
(function(ClientSenderSenderDirection2) {
  ClientSenderSenderDirection2["ONE"] = "one";
  ClientSenderSenderDirection2["TWO"] = "two";
})(ClientSenderSenderDirection || (ClientSenderSenderDirection = {}));
export {
  ClientSenderSenderDirection,
  ClientSenderStatus
};

import avatar from '~/locales/en/Company/User/Avatar';
import card from '~/locales/en/Company/User/Card';
import create from '~/locales/en/Company/User/Create';
import edit_password from '~/locales/en/Company/User/EditPassword';
import edit_phone from '~/locales/en/Company/User/EditPhone';
import modal_create_user from '~/locales/en/Company/User/ModalCreateUser';
import auth_otp from '~/locales/en/Company/User/AuthOTP';
import table from '~/locales/en/Company/User/table';

export default {
  title: 'My account',

  profile: 'Profile',
  profile_description: 'Specify your name and contact phone number so that the support service can quickly contact you if necessary.',
  dashboard_appearance: 'Dashboard appearance',
  dashboard_appearance_description: 'Select the interface language of the dashboard and your time zone. You will also receive email notifications from Messaggio in selected language. Scheduled dispatches are launched in accordance with the specified time zone.',
  account_access: 'Account access',

  first_name: 'First name',
  second_name: 'Second name',
  last_name: 'Last name',
  timezone: 'Time zone',
  lang: 'Language',
  phone: 'Phone #',
  email: 'Email',
  tariff_plan: 'Tariff Plan',
  password: 'Password',

  save: 'Save changes',
  save_success: 'Saved successfully',

  resend_verification_email_success: 'Verification email has been sent',

  updated_at: 'Updated at',

  verified_false_alert_warning: 'Please confirm your email address for full access to your account features.',
  resend_verification_email: 'Resend verification email',

  index_title: 'Users',
  index_description: '<p>You can create additional users to collaborate with dispatches and chats. The invited user must confirm their Email to access the platform\'s capabilities.</p>' +
    '<p>If you need to differentiate access rights and capabilities for some users, contact the Support Service.</p>',

  avatar,
  card,
  create,
  edit_password,
  edit_phone,
  modal_create_user,
  auth_otp,
  table,
};

export default {
  progress: {
    title: 'Company details',
  },
  left: {
    title: 'Welcome!',
    subtitle: 'Tell us about your needs so we can find the best solution for you.',
    subsubtitle: 'Please specify <mark>your country of residence</mark> and <mark>brief description</mark> of your business.',
  },
  form: {
    locale: {
      title: 'Interface language',
    },
    name: {
      title: 'Company name',
      placeholder: 'Brand or legal entity',
    },
    description: {
      title: 'Few words about your business',
      placeholder: 'Please select',
      list: {
        it_and_internet_companies: 'IT and internet companies',
        brand: 'Brand',
        governmental: 'Governmental / Non-governmental organization',
        healthcare: 'Healthcare',
        publisher: 'Publisher',
        e_commerce: 'E-commerce',
        local_business: 'Local business',
        real_estate_and_construction_companies: 'Real estate and construction companies',
        education: 'Education',
        community_organization: 'Community organization',
        media_agency: 'Media agency',
        commerce: 'Commerce',
        transportation_services: 'Transportation services',
        financial_institution: 'Financial institution',
        other: 'Other',
      },
    },
    location_country: {
      title: 'Company location',
    },
  },
};

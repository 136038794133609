export default {
  company: {
    title: 'Компанія і менеджмент',
    name: {
      title: 'Коротка назва юридичної особи',
    },
    fio: {
      title: 'Виконавча особа',
    },
    short_fio: {
      title: 'Виконавча особа коротко',
    },
    post: {
      title: 'Посада',
    },
    founding: {
      title: 'Підстава повноважень(Статут або довіреність)',
      title_as: 'Вкажіть підставу повноважень',
      CHARTER: 'Статут',
      TRUSTEE: 'Довіреність',
    },
    TRUSTEE_num: {
      title: 'Номер довіреності',
    },
    TRUSTEE_date: {
      title: 'Дата довереності',
    },
  },
  address: {
    title: 'Адреса',
    legal: {
      title: 'Юридична адреса',
    },
    actual: {
      title: 'Фактична адреса',
    },
    mail: {
      title: 'Поштова адреса',
    },
    index: {
      title: 'Поштовий індекс',
    },
  },
  tax: {
    title: 'Оподаткування',
    system: {
      title: 'Ваша схема оподаткування',
      vat: 'Платник ПДВ',
      VAT_SHARED: 'ПДВ на загальних засадах',
      VAT_SINGLE: 'Єдиний податок',
      NOT_VAT: 'Не платник ПДВ',
    },
    inn: {
      title: 'Податковий номер',
    },
  },
  confirm: {
    text: 'Я, {company_fio}, що діє на підставі {company_founding}, ознайомився з Договором приєднання від 01.09.2020 г. ТОВ "ЕТЕРНАЛ ГРУП", включаючи Заяву про приєднання, і прийимаю його умови.',
    company: {
      founding: {
        CHARTER: 'Статуту',
        TRUSTEE: 'Довіренності',
      },
    },
  },
};

import status from '~/locales/uk/Widget/Filter/Status';
import channel from '~/locales/uk/Widget/Filter/Channel';

export default {
  status,
  channel,
  client_sender: {
    placeholder: 'Відправник',
  },
};

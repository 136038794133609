var ChannelName;
(function(ChannelName2) {
  ChannelName2["VIBER"] = "viber";
  ChannelName2["SMS"] = "sms";
  ChannelName2["VK"] = "vk";
  ChannelName2["WHATSAPP"] = "whatsapp";
  ChannelName2["FLASHCALL"] = "flashcall";
  ChannelName2["WEBPUSH"] = "webpush";
  ChannelName2["MOBILEID"] = "mobileid";
  ChannelName2["RCS"] = "rcs";
  ChannelName2["WEBCHAT"] = "webchat";
  ChannelName2["TELEGRAM"] = "bottelegram";
})(ChannelName || (ChannelName = {}));
export {
  ChannelName
};

import type { Plugin, Context } from '@nuxt/types';
import axios, { AxiosAdapter, AxiosError } from 'axios';
import axiosCancel from 'axios-cancel';
import { Store } from 'vuex';
import { RootState } from '~/store';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from 'axios-extensions';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

export const MFETCH_STATUS_KEY = 'mfetch-status';

function setStatus(status: 'idle' | 'refreshing' | 'completed' | 'failed') {
  localStorage.setItem(MFETCH_STATUS_KEY, status);
}

function getStatus() {
  const status = localStorage.getItem(MFETCH_STATUS_KEY);
  if (!status) return null;

  if (!['idle', 'refreshing', 'completed', 'failed'].includes(status))
    return null;

  return status;
}

const plugin: Plugin = ({
  store,
  redirect,
  route,
}: Omit<Context, 'store'> & { store: Store<RootState> }) => {
  setStatus('idle');

  axios.defaults.baseURL = process.env.API_HOST || '/';
  axios.defaults.adapter = throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    }),
    { threshold: 2 * 1000 }
  );

  axiosCancel(axios);

  axios.interceptors.request.use(async (config) => {
    if (store.state.auth.token) {
      config.headers.Authorization = 'Bearer ' + store.state.auth.token;
    } else {
      await store.dispatch('auth/logout');
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.error === 'one time password is required') {
        throw new Error('MESSAGGIO_OTP');
      }
      return response;
    },
    async (error: AxiosError) => {
      if (axios.isCancel(error) || !error?.response?.status) {
        return Promise.reject(error);
      }

      if (error.response.status == 401) {
        const errorResponse = error.response.data?.error?.toString() as string;

        if (
          errorResponse.includes('token is expired') ||
          errorResponse.includes('401')
        ) {
          if (getStatus() != 'refreshing') {
            setStatus('refreshing');
            const fingerprint = await getFingerprint();
            const response = await store.dispatch('auth/refreshToken', {
              fingerprint:
                typeof fingerprint == 'string' ? fingerprint : fingerprint.hash,
            });

            if ('error' in response) {
              setStatus('failed');
              await store.dispatch('auth/logout');

              redirect(
                `/permission/login?redirect=${encodeURIComponent(
                  route.fullPath
                )}`
              );

              return Promise.reject(error);
            }

            setStatus('completed');
          } else {
            const repeatRequest = await new Promise((resolve) => {
              const interval = setInterval(() => {
                const status = getStatus();
                if (status == 'refreshing') return;
                if (status == 'failed') {
                  clearInterval(interval);
                  resolve(false);
                  return;
                }

                clearInterval(interval);
                resolve(true);
              }, 1000);
            });

            if (!repeatRequest) return Promise.reject(error);
          }

          return await axios.request(error.config);
        } else {
          await store.dispatch('auth/logout');

          redirect(
            `/permission/login?redirect=${encodeURIComponent(route.fullPath)}`
          );

          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );
};

export default plugin;

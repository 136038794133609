export default {
  title_browser: 'Register',
  title: 'Create account',
  subtitle: 'All fields are required',
  tenant_name_placeholder: 'Company name',
  user_name_placeholder: 'Full name',
  user_email_placeholder: 'E-mail Address',
  user_password: 'Password',
  user_password_confirmed: 'Repeat password',
  register_btn: 'Create account',
  terms_and_conditions: 'I agree with <a href="https://messaggio.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>',
  privacy_policy: 'I agree with <a href="https://messaggio.com/privacy-policy/" target="_blank">Privacy policy</a>',
  by_continuing: 'By continuing, you\'re confirming that you\'ve read and accepted our <a href="https://messaggio.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>, <a href="https://messaggio.com/privacy-policy/" target="_blank">Privacy policy</a> and <a href="https://messaggio.com/terms-and-conditions/contract-for-the-provision-of-messaging-services-offer/" target="_blank">Offer</a>',
  or_registration_in_with: 'or registration in with',
};

export default {
  title: 'Імпорт контактів до групи',

  tab_manual_input: 'Ввести вручну',
  phone_numbers: 'Номери телефонів',
  phone_numbers_placeholder: 'Внесіть номери телефонів, кожний на новому рядку',

  tab_import_csv_file: 'Завантажити файл',
  select_file_to_import: 'Оберіть файл для імпорту',
  accepted_formats: 'Формати що підтримуються: *.xlsx; *.csv. Максимум 20Mb',

  tab_import_csv_file_title: 'Завантажити приклад CSV файлу',
  tab_import_csv_file_subtitle: 'Краще за все для імпорту підходять таблиці, збережені в форматі "CSV - роздільник крапка з комою" в кодировці UTF-8',
  tab_import_csv_file_download: 'Завантажити файл',
  tab_import_csv_file_excel_windows: 'Як зберегти файл в Excel для Windows',
  tab_import_csv_file_excel_macos: 'Як зберегти файл в Excel для Mac',
  tab_import_csv_file_numbers_macos: 'Як зберегти файл в Numbers для Mac',

  contact_handling_settings: 'Налаштування обробки контактів',
  duplicate_remove: 'Очистити список контактів від дублів номерів',

  separate_symbol: 'Роздільник',
  separate_symbol_comma: 'Кома',
  separate_symbol_semicolon: 'Крапка з комою',

  duplicate_cleanup_start: 'Почали очищення від дублів',
  duplicate_cleanup_deleted: 'Очищення від дублів: {deleted}',

  phone_column_not_specified: 'Не визначено стовпчик номерів телефонів',

  charset_title: 'Відображення списку контактів',
  charset_description: 'Якщо дані в таблиці попереднього перегляду відображаються нечитабельно, спробуйте обрати інше кодування списку контактів.',

  phone_number_rewrite_title: 'Форматування номера телефона',
  phone_number_rewrite_description: 'В налаштуваннях акаунту ви вказали, що країна знаходження компанії: <b>{country_name}</b><br>Включіть опцію, якщо завантажуєте контакти з телефонами в локальному форматі.',
  phone_number_rewrite_label: 'Дописувати напочатку номеру телефона код країни <b>{country_code}</b> де потрібно',

  previous_step: 'Назад',
  next_step: 'Далі',
};

export default {
  loading: 'Завантаження статистики...',

  title: 'Статистика за відправником (поточний місяць)',
  view_all: 'Подивитись все',
  empty_data: 'Повідомлення відсутні',

  message_status_count_sent: 'Відправлено',
  message_status_count_delivered: 'Доставлено',
  message_status_count_not_delivered: 'Не доставлено',
  message_status_count_watched: 'Переглянуто',
}

import quick_reply from '~/locales/ru/Template/ContentLayout/Whatsapp/LayoutBody/Button/QuickReply';
import url from '~/locales/ru/Template/ContentLayout/Whatsapp/LayoutBody/Button/Url';

export default {
  title: "Кнопки",
  add_button: "Добавить действие",
  drag_helper: "Перетаскивайте элементы, чтобы расположить их в нужном порядке в сообщении",
  remove: "Удалить",
  remove_button_confirm: "Вы уверены, что хотите удалить кнопку?",
  save: "Сохранить",

  quick_reply,
  url,
}

import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";
import {getClientSenderOnePromises} from "~/store/clientsender";
import {arrayToObject} from "~/utils/functions";

let clientSenderStatusActive;
let clientSenderStatusDeact;

const baseState = {};

export const state = () => ({...baseState});

export const getters = {};

export const mutations = {};

export const actions = {
  async list({commit, state, dispatch}, {page = 1, pagesize = 10, sort = '', observer = null, ...options} = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }
    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'id asc';

    const templates = await getTemplates();

    return api.scheduler.task.list(
      page, pagesize, sort,
      {template_id: [clientSenderStatusActive.id, clientSenderStatusDeact.id], ...options},
    ).then(async response => {
      //console.info('channel-and-gate/sender/scheduled/list | api.scheduler.task.list | response = ', response.data);

      const items = await Promise.all((response.data.Body || []).map(async schedulerTask => {
        //console.info('channel-and-gate/sender/scheduled/list | api.scheduler.task.list | item = ', schedulerTask);
        try {
          schedulerTask.template = templates[schedulerTask.template_id];
          schedulerTask.client_sender = await (async () => {
            let itemNew = {
              client_sender_id: schedulerTask.input_by_operation
                .find(ibo => ibo.input_values['{clientsenderID}']).input_values['{clientsenderID}'],
            };
            //console.info('channel-and-gate/sender/scheduled/list | api.scheduler.task.list | itemNew.client_sender_id = ', itemNew.client_sender_id);
            await getClientSenderOnePromises(observer, itemNew, 'client_sender_id');
            return itemNew.client_sender;
          })();
          return schedulerTask;
        } catch (e) {
          //console.info('channel-and-gate/sender/scheduled/list | api.scheduler.task.list | e = ', e);
          return schedulerTask;
        }
      }));

      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('channel-and-gate/sender/scheduled/list | api.scheduler.task.list | error = ', error);
      return {
        items: [],
        totalRows: 0,
      };
    });
  },
  async one({commit, state}, {id}) {
    const templates = await getTemplates();
    return await api.scheduler.task.list(
      1, 9999, '',
      {template_id: [clientSenderStatusActive.id, clientSenderStatusDeact.id], entity_id: id},
    ).then(response => ({
      items: (response.data.Body || []).map(schedulerTask => {
        schedulerTask.template = templates[schedulerTask.template_id];
        return schedulerTask;
      }),
      totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
    }))
  },
  async save({commit, state}, {client_sender_id, status, scheduled_date}) {
    scheduled_date = scheduled_date.format('YYYY-MM-DD\\THH:mm:ss.SSSSSSSSS\\Z');

    //console.info('clientsender/scheduled/save | client_sender_id = ', client_sender_id, ', status = ', status, ', scheduled_date = ', scheduled_date);

    let clientSenderStatusApi;

    switch (status) {
      case 'active':
        clientSenderStatusApi = api.scheduler.template.clientSenderStatusActive.quickTask.post;
        break;
      case 'deact':
        clientSenderStatusApi = api.scheduler.template.clientSenderStatusDeact.quickTask.post;
        break;
      default:
        throw 'Status ' + status + ' not support in scheduler';
    }

    return clientSenderStatusApi({
      entity_id: client_sender_id,
      auto_start: true,
      scheduled_date,
    }).then(response => response.data).catch(error => {
      console.error('clientsender/scheduled/save | clientSenderStatusApi | client_sender_id = ', client_sender_id, ' | error = ', error);
      throw error;
    });
  },
  async delete({commit, state}, {client_sender_id}) {
    //console.info('clientsender/scheduled/delete | client_sender_id = ', client_sender_id);
    await api.scheduler.task.list(1, 9999, '', {entity_id: client_sender_id})
      .then(response => response.data.Body || [])
      .then(async items =>
        await Promise.all(items.map(item => api.scheduler.task.delete(item.id)))
      );
  },
};

async function getTemplates() {
  const promises = [];
  if (!clientSenderStatusActive) {
    promises.push(api.scheduler.template.clientSenderStatusActive.list().then(r => clientSenderStatusActive = r.data));
  }
  if (!clientSenderStatusDeact) {
    promises.push(api.scheduler.template.clientSenderStatusDeact.list().then(r => clientSenderStatusDeact = r.data));
  }
  await Promise.all(promises);
  //console.info('channel-and-gate/sender/scheduled | getTemplates | clientSenderStatusActive = ', clientSenderStatusActive);
  //console.info('channel-and-gate/sender/scheduled | getTemplates | clientSenderStatusDeact = ', clientSenderStatusDeact);

  return arrayToObject([clientSenderStatusActive, clientSenderStatusDeact]);
}

import axios from 'axios';

declare module ApiRolesUsers {
  interface AccessLevel {
    id: string,
    name: string,
    created_at: string,
    updated_at: string,
  }
}

export default {
  access_level: () => axios.get<ApiRolesUsers.AccessLevel>('/roles/api/v1/users/access_level'),
};

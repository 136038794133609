import modal_columns from '~/locales/en/Statistic/Filter/ModalColumns'

export default {
  list_preset_loading: 'Loading parameters...',

  message_date: 'Period',
  project_id: 'Project',
  project_id_all: 'Projects (All)',
  channel_id: 'Channels',
  clientsender_id: 'Sender',
  dispatch_document_id: 'Dispatch',
  country: 'Countries and operators',
  country_title: 'Countries',
  mobile_operator_title: 'Operators',
  columns_select: 'Report settings',
  select: 'Generate report',
  fetch_csv: 'Download CSV',
  report: 'CSV Reports',
  statistic: 'Statistics',

  modal_columns,

  country_ru: 'Russia',
  country_ua: 'Ukraine',
  country_kz: 'Kazakhstan',
  country_md: 'Moldova',
  country_by: 'Belarus',

  grouped: 'grouped',
  custom: 'custom',
}

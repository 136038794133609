import form_code from '~/locales/en/Company/Testphone/ModalVerification/FormCode';
import form_phone from '~/locales/en/Company/Testphone/ModalVerification/FormPhone';

export default {
  title: 'Link phone number',
  description: 'Within one minute your phone will receive a free call or SMS. The last 4 digits of the phone number calling you are the code to link your phone number.',

  form_code,
  form_phone,
};

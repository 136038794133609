export default {
  title: 'Действия с рассылкой',

  btn_info: 'Детальная информация',
  btn_restore: 'Восстановить рассылку',
  btn_settings: 'Настройки рассылки',
  btn_start_sending: 'Отправить рассылку',
  btn_start_schedule: 'Запланировать',
  btn_repeat: 'Повторить рассылку',
  btn_edit: 'Редактировать рассылку',
  btn_statistics: 'Статистика по рассылке',
}

import api from '~/api';
import {getProjectOnePromises} from "~/store/project";
import ObserverApi from "~/utils/ObserverApi";
import {getClientSenderOnePromises} from "~/store/clientsender/index";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10, id, observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.documents.route.destination.list(page, pagesize, id).then(async response => {
      //console.info('api.documents.route.destinations | response = ', response.data);
      let items = response.data.Body || [];
      items = await Promise.all(items.map((item) => getClientSenderOnePromises(observer, item)));
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.route.destinations | error = ', error);
      throw error;
    });
  },
  create({dispatch, commit, state}, {data}) {
    return api.documents.route.destination.post(data)
      .then(response => dispatch('attach', {route_id: data.route_id, destination_id: response.data.id}))
      .then(response => {
        //console.info('api.documents.route.destination.post | response = ', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('api.documents.route.destination.post | error = ', error);
        throw error;
      });
  },
  attach({commit, state}, {route_id, destination_id}) {
    return api.documents.route.destination.attach(route_id, destination_id)
      .then(response => {
        //console.info('api.documents.route.destination.post | response = ', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('api.documents.route.destination.post | error = ', error);
        throw error;
      });
  },
  edit({commit, state}, {id, data}) {
    return api.documents.route.destination.put(data.destination_id, data)
      .then(response => {
        //console.info('api.documents.route.destination.put | response = ', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('api.documents.route.destination.put | error = ', error);
        throw error;
      });
  },
  delete({dispatch, commit, state}, {id, data}) {
    return dispatch('detach', {route_id: data.route_id, destination_id: data.destination_id})
      .then(() => api.documents.route.destination.delete(data.destination_id))
      .then(response => {
        //console.info('api.documents.route.destination.delete | response = ', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('api.documents.route.destination.delete | error = ', error);
        throw error;
      });
  },
  detach({commit, state}, {route_id, destination_id}) {
    return api.documents.route.destination.detach(route_id, destination_id)
      .then(response => {
        //console.info('api.documents.route.destination.post | response = ', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('api.documents.route.destination.post | error = ', error);
        throw error;
      });
  },
};

export function getRouteDestinationOnePromises(observer, item, id_name = 'id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'channel-and-gate/route/destination/list',
        {id: item[id_name]},
        (destinations) => {
          destinations.items = destinations.items.sort((a, b) => a.weight - b.weight);
          item.destinations = destinations;
          resolve(item);
        },
      );
    } else {
      item.destinations = null;
      resolve(item);
    }
  });
}

export default {
  title: 'Копіювання контактів в групу',
  description: 'Вкажіть групу, куди будуть скопійовани обрані вами контакти.',

  btn_submit: 'Скопіювати контакти',

  duplicate_cleanup_start: 'Розпочати очищення від дублів',
  duplicate_cleanup_deleted: 'Очищення від дублів: {deleted}',

  copy_success: 'Контакти успішно скопійовані.',
}

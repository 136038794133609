export default {
  title: 'Змінити номер',
  title_btn: 'Змінити номер',
  title_btn_empty: 'Прив`язати телефон',
  title_form_code_btn: 'Ввести код підтвердження номера',

  phone_description: 'Вкажіть ваш номер телефона в міжнародному форматі.<br>Ви отримаєте код в застосунку Viber або в SMS.',
  phone: 'Номер телефону',
  phone_placeholder: 'Номер телефону',
  phone_submit: 'Отримати код',

  code_description: 'Прив`язати телефон <b>{phone}</b> до акаунту',
  code: 'Код',
  code_placeholder: 'Код',
  code_submit: 'Прив`язати телефон',
  code_change_phone_number: 'Змінити номер телефону для прив`язки',

  ok_title: 'Змінити номер',
  save_success: 'Номер прив`язано',
  save_success_exist: 'Телефон був раніше доданий як тестовий. Верифікация не потрібна.',

  delete_confirm: 'Відв`язати номер?',
  delete_success: 'Номер відв`язано ',

  on_hide_confirm: 'Вы впевнені?',

  warning_belarus_text: 'Тимчасові проблеми з доставкою авторизаційних смс. Будь ласка, натисніть кнопку "СМС не приходить" і зв\'яжіться з техпідтримкою. Протягом 5-10 хв у робочий час аккаунт буде активовано.',
  error_invalid_verification_code: 'Код підтвердження вказано невірно.',
  error_verifications_phone_session_exist: 'Ви зможете повторно запросити код через {duration} секунд.',
  error_verifications_phone_attempt_max: 'Перевищено кількість спроб прив`язки номеру. Зверніться до служби підтримки.',
  error_verifications_phone_exist: 'Номер телефону вже існує.',
  error_verifications_code: 'Вказано невірний код підтвердження. Спроба {attempt} з {attempt_max}.',
  error_verifications_code_attempt_max: 'Не вдалось прив`язати номер. Зверніться до служби підтримки.',
  error_verifications_code_session_not_found: 'Час очікування коду підтвердження вичерпано, запитайте новий код.',
}

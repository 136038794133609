import ru from './RU';
import ua from './UA';
import other from './Other';

export default {
  ru,
  ua,
  other,

  errors: {
    required: {
      contacts: {
        fio: 'Please enter your full name',
        phone: 'Enter your phone number',
      },
      company: {
        reg_num: 'VAT number',
      },
      certificate: {
        shortHash: 'Registration confirmation required',
      },
      address: {
        state: 'Enter your state',
      },
    },
  },
};

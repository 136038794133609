export default {
  today: 'Сьогодні',
  yesterday: 'Вчора',
  last_7_day: 'Останні 7 днів',
  last_30_day: 'Останні 30 днів',
  this_month: 'Цей місяць',
  last_month: 'Минулий місяць',

  apply_label: 'Застосувати',
  cancel_label: 'Відміна',
  start_label: 'Дата від',
  end_label: 'Дата по',
  custom_range_label: 'Обрати період',
  days_of_week: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  month_names: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень']
}

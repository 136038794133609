import form from '~/locales/en/GroupContact/form';
import table from '~/locales/en/GroupContact/table';
import show from '~/locales/en/GroupContact/show';
import file_upload_modal from '~/locales/en/GroupContact/FileUploadModal';
import contact from '~/locales/en/GroupContact/Contact';
import card from '~/locales/en/GroupContact/Card';
import list from '~/locales/en/GroupContact/list';

export default {
  title: 'Contact groups',
  title_create: 'Create contact group',
  title_show: 'Contact group {name}',
  title_edit: 'Edit contact group {name}',

  q_placeholder: 'Phone number or parameter',
  filter_q_placeholder: 'Type to filter...',

  wl_title: 'Contact groups for dispatch',
  bl_title: 'Contact groups for exclusion',
  search_title: 'Contact groups',

  group_contact_wl_empty: 'There are no contact groups on your account.',
  group_contact_wl_empty_project_one_you_can: 'You can',
  group_contact_wl_empty_project_one_create: 'create a contact group',
  group_contact_wl_empty_project_one_create_after: 'and add it to dispatch then.',

  group_contact_bl_empty: 'There are no contact groups for exclusion on your account.',
  group_contact_bl_empty_project_one_you_can: '',
  group_contact_bl_empty_project_one_create: 'Create a group of contacts',
  group_contact_bl_empty_project_one_create_after: 'that will be excluded from dispatch.',

  form,
  table,
  show,
  file_upload_modal,
  contact,
  card,
  list,
}

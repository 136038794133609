import axios from "axios";
import _ from "lodash";
import { SetDefaultPaginator, SetDefaultOrder } from "../../_type_request.js";
import { MList } from "../../_type_response.js";
async function list(req = {}) {
  const params = _.clone(req);
  SetDefaultPaginator(params);
  SetDefaultOrder(params);
  const { data: d } = await axios.get("/api-gateway/api/v1/contracts", { params });
  return MList(d);
}
async function tariffPlan(contractId) {
  const { data } = await axios.get(`/api-gateway/api/v1/contracts/${contractId.toString()}/tariff_plan`, {});
  return data;
}
export {
  list,
  tariffPlan
};

export default {
  title: 'Плавная отправка',
  description: 'Укажите временной интервал, в который сообщения будут отправлены.',

  send_fast: 'Разослать быстро',
  hour_1: 'час',
  hour_2: 'часа',
  hour_5: 'часов',

  send_in: 'Разослать за {hour} {hour_noun}',
}

export default {
  title: 'Дії з розсилкою',

  btn_info: 'Детальна інформація',
  btn_restore: 'Відновити розсилку',
  btn_settings: 'Налаштування розсилки',
  btn_start_sending: 'Відправити розсилку',
  btn_start_schedule: 'Запланувати',
  btn_repeat: 'Повторити розсилку',
  btn_edit: 'Редагувати розсилку',
  btn_statistics: 'Статистика розсилки',
}

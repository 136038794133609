const createFetcher = (baseUrl) => async ({ url, method, body, headers, pathParams, queryParams, signal }) => {
  var _a, _b;
  if (!((_a = window.$nuxt.$store.state.auth) == null ? void 0 : _a.token)) {
    await window.$nuxt.$router.push("/logout");
    return Promise.reject();
  }
  try {
    const requestHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.$nuxt.$store.state.auth.token}`,
      ...headers
    };
    if (requestHeaders["Content-Type"].toLowerCase().includes("multipart/form-data")) {
      delete requestHeaders["Content-Type"];
    }
    const response = await window.fetch(`${baseUrl}${resolveUrl(url, queryParams, pathParams)}`, {
      signal,
      method: method.toUpperCase(),
      body: body ? body instanceof FormData ? body : JSON.stringify(body) : void 0,
      headers: requestHeaders
    });
    if (!response.ok) {
      let error;
      try {
        error = await response.json();
      } catch (e) {
        error = {
          status: "unknown",
          payload: e instanceof Error ? `Unexpected error (${e.message})` : "Unexpected error"
        };
      }
      throw error;
    }
    if ((_b = response.headers.get("content-type")) == null ? void 0 : _b.includes("json")) {
      const value = await response.text();
      if (!value.length)
        return void 0;
      return JSON.parse(value);
    } else {
      return await response.blob();
    }
  } catch (e) {
    const errorObject = {
      name: "unknown",
      message: e instanceof Error ? `Network error (${e.message})` : "Network error",
      stack: e
    };
    throw errorObject;
  }
};
const resolveUrl = (url, queryParams = {}, pathParams = {}) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query)
    query = `?${query}`;
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
export {
  createFetcher
};

import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';
import { directoryHighlight } from '~/utils/functions';

export const stateInit = {
  list: {
    loading: false,
    data: {},
  },
};

export const state = () => stateInit;

export const getters = {
  search: (state) => (q) => {
    return Object.values(state.list.data)
      .filter((item) => {
        if (item.id && item.id.toLowerCase().indexOf(q) !== -1) {
          return true;
        }
        return false;
      })
      .map((item) => {
        const link = {
          name: 'directory-timezone-timezone-show',
          params: { timezone: item.id },
        };
        const type = 'timezone';
        const name = directoryHighlight(q, item.id);
        return { link, name, type, item };
      });
  },
};

export const mutations = {
  listLoading(state, loading) {
    state.list.loading = loading;
  },
  listData(state, data) {
    state.list.data = data;
  },
};

export const actions = {
  async listPreLoad({ commit, dispatch }) {
    commit('listLoading', true);
    dispatch('list', { all: true })
      .then((items) =>
        items.items.reduce((ret, item) => {
          ret[item.id] = item;
          return ret;
        }, {})
      )
      .then((items) => {
        commit('listData', items);
        commit('listLoading', false);
      });
  },
  async list({ commit, state }, { page = 1, pagesize = 10, all = false } = {}) {
    let items = [];
    let totalRows = 0;

    if (all) {
      page = 1;
      pagesize = 1000;
    }
    while (true) {
      try {
        const response = await api.librarium.timezone.list(
          page,
          pagesize,
          null,
          { hidden: false }
        );

        ////console.info('api.librarium.timezone.list | response = ', response.data);
        if (typeof response?.data === 'string') break;
        if (!response?.data?.length) break;

        items = items.concat(response.data);
        totalRows = parseInt(response.headers['x-total'] || 0);

        if (response.data.length < pagesize) break;
      } catch (error) {
        throw error;
      }

      if (all) {
        page++;
      } else {
        break;
      }
    }

    return { items, totalRows };
  },
  one({ commit, state }, { timezone }) {
    timezone = encodeURIComponent(timezone);
    return api.librarium.timezone.get(timezone).then((response) => {
      //console.info('api.librarium.timezone.one | response = ', response.data);
      return response?.data;
    });
  },
  one_stages({ commit, state }, { timezone }) {
    timezone = encodeURIComponent(timezone);
    return api.librarium.timezone.get_stages(timezone).then((response) => {
      //console.info('api.librarium.timezone.one_stages | response = ', response.data);
      return response?.data || [];
    });
  },
  stage_one(
    { dispatch, commit, state },
    { stage_id, observer = null, relate = true } = {}
  ) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.librarium.timezone.stage.get(stage_id).then(async (response) => {
      //console.info('api.librarium.timezone.stage_one | response = ', response.data);
      if (relate) {
      }
      return response?.data;
    });
  },
  create({ commit, state }, { data }) {
    data = { ...data };
    data = validDataSave(data);
    return api.librarium.timezone.post(data).then((response) => {
      //console.info('api.librarium.timezone.post | response = ', response.data);
      return response?.data;
    });
  },
  edit({ commit, state }, { stage_id, data }) {
    data = { ...data };
    delete data.timezone;
    data = validDataSave(data);
    return api.librarium.timezone.put(stage_id, data).then((response) => {
      //console.info('api.librarium.timezone.put | response = ', response.data);
      return response.data;
    });
  },
  delete({ commit, state }, { stage_id }) {
    return api.librarium.timezone.delete(stage_id).then((response) => {
      //console.info('api.librarium.timezone.put | response = ', response.data);
      return response?.data;
    });
  },
};

function validDataSave(data) {
  delete data.stage_id;
  delete data.created_at;

  data.raw_offset = parseInt(data.raw_offset);
  data.dst_offset = parseInt(data.dst_offset);
  data.gmt_offset = parseInt(data.gmt_offset);

  return data;
}

export default {
  start_title: 'Підготовка звіту',
  end_title: 'Звіт готовий!',
  end_description: 'Завантажте звіт або скачайте его пізніше в розділі Документи',

  btn_title: 'Вивантажити історию чатів',
  modal_title: 'Вивантажити историю чатів',
  modal_description: 'Будь ласка, задайте період. Всі прийняті і відправлені повідомлення в чаті будуть збережені у звіт.<br>Не закривайте вікно, поки готується звіт.',
  from: 'Від',
  to: 'Кому',
  create_report: 'Створити звіт',
}

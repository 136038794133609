export default {
  name: 'Name',
  tenant_id: 'Company',
  type: 'Group type',
  row_count: 'Quantity',
  updated_at: 'Date',

  type_whitelist: 'Contact group for dispatch',
  type_blacklist: 'Contact group for exclusion',

  label_chat_contacts: 'Chat contacts',

  description_empty: 'Description not set',
  btn_dump_export: 'Export contacts',

  delete_confirm: 'Are you sure you want to delete Contact Group "{name}"?',
  delete_success: 'Contact Group "{name}" was deleted.',
}

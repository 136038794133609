export default {
  open_btn: 'Link Senders',

  title: 'Select Gate Sender to link with {name}',
  table_sender_loading: 'Loading Gate Senders...',
  table_sender_filter_q_placeholder: 'Gate Sender',
  delete_msg: 'Unlink {name}',
  create_msg: 'Link the Gate Sender',
  create_success: 'Linked successfully',

  delete_confirm: 'Are you sure you want to unlink "{name}"?',
  delete_success: 'name} was unlinked.',
}

import filter from '~/locales/en/Statistic/Report/Filter';
import modal from '~/locales/en/Statistic/Report/Modal';

export default {
  title: 'List of CSV reports',

  show_only: 'Show',
  show_only_my: 'my',
  show_only_all: 'all',

  download: 'Download ZIP',
  show_request: 'Query log',

  show_request_title: 'Query',

  name: 'Report',
  user_id: 'Creator',
  progress: 'Progress',
  updated_at: 'Updated',
  status: 'Status',

  download_processing: 'Download {name}',
  processing: 'In progress',
  done: 'Done',

  delete_confirm: 'Are you sure you want to delete report "{name}"?',
  delete_success: 'Report "{name}" was deleted.',

  filter,
  modal,
}
//$t('statistic.report.name')

import login from '~/locales/ru/Permission/login';
import password_recovery from '~/locales/ru/Permission/PasswordRecovery';
import password_reset from '~/locales/ru/Permission/PasswordReset';
import registration from '~/locales/ru/Permission/registration';
import completed from '~/locales/ru/Permission/completed';
import verify_email from '~/locales/ru/Permission/VerifyEmail';

export default {
  login_link: 'Вход',
  register_link: 'Регистрация',

  login,
  password_recovery,
  password_reset,
  registration,
  completed,
  verify_email,

  welcome: {
    title: 'Мультиканальная платформа рассылки',
    subtitle: 'Войдите в личный кабинет или создайте аккаунт.',
  },

  plugin: {
    title: 'Доступно на тарифном плане Корпоративный',
    description: '<p>Эта функция доступна на тарифном плане Корпоративный.</p><p>Вы можете попробовать расширенные возможности платформы Messaggio 1 месяц бесплатно!</p>',
    more_info: 'Подробнее',
    corporate_plan: 'Корпоративный план',
  },
};

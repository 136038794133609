export default {
  title: 'Налаштування звіту',
  ok_title: 'Застосувати',
  clear_title: 'Очистити з',
  body: 'Оберіть потрібні стовпчики в деталізованому звіті повідомлень',
  tab_grouped_report: 'Агрегований звіт',
  tab_grouped_report_body: 'Агрегований звіт рахуватиме повідомлення по нижченаведеним критеріям',
  tab_grouped_report_columns_label: 'Оберіть максимум 3 стовпчики з даними щоб агрегувати статистику',
  tab_grouped_report_statuses_label: 'оберіть статуси повідомлень',
  tab_detailed_report: 'Деталізований звіт',
  tab_custom_report: 'Ручний звіт',
  custom_report_body: 'Список ручних звітів',
  custom_report_viber_gate_senders_costs: 'Viber Gate Senders Costs',
  custom_report_client_senders_traffic_summary: 'Client Senders Traffic Summary',
  custom_report_gate_sender_traffic_summary: 'Gate Sender Traffic Summary',
}

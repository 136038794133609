export const state = () => ({
  list: {},
});

export const getters = {};

export const mutations = {
  setList(state, {entity_id, favorite}) {
    state.list = {
      ...state.list,
      [entity_id]: favorite,
    };
  },
};

export const actions = {
  async check({dispatch, commit, state, rootState}, {entity_id}) {
    const favorite = await dispatch(
      'trailer/setting/list',
      {entity_id, key: `favorite.${rootState.auth.user.id}`, pagesize: 1},
      {root: true}
    ).then(trailer => trailer.items[0] || {});
    commit('setList', {entity_id, favorite});
    return favorite;
  },
  async set({dispatch, commit, state, rootState}, {id, entity_id, entity_type, is}) {
    try {
      const result = await dispatch(
        'trailer/setting/' + (is ? 'create' : 'delete'),
        {
          id: id,
          data: {
            entity_id: entity_id,
            entity_type: entity_type,
            key: 'favorite.' + rootState.auth.user.id,
            value: 'true',
          },
        },
        {root: true},
      );
      //console.info('store/widget/favorite/set | result = ', result);

      const favorite = is ? result : {};
      commit('setList', {entity_id, favorite});
      return favorite;
    } catch (e) {
      throw e;
    }
  },
};

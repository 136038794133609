export default {
  title: 'Support service',
  subject: 'Issue related to',
  body: 'Description',
  btn_submit: 'Request support',

  subject_placeholder: 'Please select...',
  subject_messaging: 'Messaging',
  subject_messaging_dashboard_web_interface_issue: 'Dashboard web-interface issue',
  subject_messaging_sender_id_moderation_issue: 'Sender ID moderation issue',
  subject_messaging_message_template_moderation_issue: 'Message Template moderation issue',
  subject_messaging_contacts_upload_issue: 'Contacts upload issue',
  subject_messaging_message_delivery_issue: 'Message delivery issue',
  subject_integration: 'Integration',
  subject_integration_yclients_integration: 'YClients integration',
  subject_integration_insales_integration: 'InSales plugin integration',
  subject_integration_api_integration: 'API integration',
  subject_partnership: 'Partnership',
  subject_partnership_commercial_issue: 'Commercial issue',
  subject_partnership_new_messaging_service_request: 'New messaging service request',
  subject_other: 'Other',

  save_success_title: 'Support service',
  save_success: 'The message to support service was sent.',
}

import table from '~/locales/en/Company/Favorite/table';

export default {
  title: 'Favorite items',

  favorite_empty: 'There are no favorite items for a while.',
  favorite_empty_project_one_you_can: 'You can add items to favorites',
  favorite_empty_project_one_create: '',
  favorite_empty_project_one_create_after: 'by clicking on a star icon for quick access.',

  table,
}

export default {
  title: 'Поиск по номеру телефона',

  loading: 'Поиск сообщений...',

  key: 'Параметр',
  value: 'Значение',

}
//$t('statistic.phone.loading')

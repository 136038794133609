export default {
  name: 'Sender',
  tenant_id: 'Company',
  channel: 'Channel',
  status: 'Status',

  sender_from_empty: 'Not linked',

  statistic_btn: 'Statistics',
  send_for_moderation_btn: 'Send for moderation',
  deactivate_btn: 'Deactivate',
  activate_btn: 'Activate',
  in_status_send_for_moderation_btn: 'Send for moderation?',

  loading: 'Loading Senders...',
  delete_confirm: 'Are you sure you want to delete Sender "{name}"?',
  delete_success: 'Sender "{name}" was deleted.',
}

export default {
  company: {
    title: 'Компания и менеджмент',
    name: {
      title: 'Краткое название юридического лица',
    },
    fio: {
      title: 'Исполнительное лицо',
    },
    short_fio: {
      title: 'Исполнительное лицо кратко',
    },
    post: {
      title: 'Должность',
    },
    founding: {
      title: 'Основание полномочий (Устав или доверенность)',
      title_as: 'Укажите основание полномочий',
      CHARTER: 'Устав',
      TRUSTEE: 'Доверенность',
    },
    TRUSTEE_num: {
      title: 'Номер доверенности',
    },
    TRUSTEE_date: {
      title: 'Дата доверенности',
    },
  },
  address: {
    title: 'Адрес',
    legal: {
      title: 'Юридический адрес',
    },
    actual: {
      title: 'Фактический адрес',
    },
    mail: {
      title: 'Почтовый адрес',
    },
    index: {
      title: 'Почтовый индекс',
    },
  },
  tax: {
    title: 'Налогообложение',
    system: {
      title: 'Ваша схема налогообложения',
      USNO: 'УСНО (Упрощенка)',
      OSNO: 'ОСНО (Плательщик НДС)',
    },
    ogrn: {
      title: 'ОГРН (регистрационный номер)',
    },
    inn: {
      title: 'ИНН',
    },
    kpp: {
      title: 'КПП',
    },
  },
  confirm: {
    text: 'Я, {company_fio}, действующий на основании {company_founding}, ознакомился с Договором присоединения от 01.09.2020 г. ООО «Миксплат Процессинг», включая Заявление о присоединении, и принимаю его условия.',
    company: {
      founding: {
        CHARTER: 'Устава',
        TRUSTEE: 'Доверенности',
      },
    },
  },
};

import form from '~/locales/en/DispatchDocument/form'
import table from '~/locales/en/DispatchDocument/table'
import table_statistic from '~/locales/en/DispatchDocument/TableStatistic'
import show from '~/locales/en/DispatchDocument/show'
import modal_start_sending from '~/locales/en/DispatchDocument/ModalStartSending'
import card from '~/locales/en/DispatchDocument/Card'
import select_recipient from '~/locales/en/DispatchDocument/SelectRecipient'
import progress_dispatch from '~/locales/en/DispatchDocument/ProgressDispatch'
import widget_statistic from '~/locales/en/DispatchDocument/WidgetStatistic'
import launch from '~/locales/en/DispatchDocument/Launch'
import calendar from '~/locales/en/DispatchDocument/Calendar'
import widget from '~/locales/en/DispatchDocument/Widget'

export default {
  title: 'Dispatches',
  title_create: 'Create dispatch',
  title_show: 'Dispatch {name}',
  title_edit: 'Dispatch {name}',

  btn_regular_dispatch: 'Regular dispatch',
  btn_ab_dispatch: 'A/B testing dispatch',
  btn_fast_dispatch: 'One page dispatch',

  filter_q_placeholder: 'Type to filter...',

  tab_all: 'All dispatches',
  tab_scheduled: 'Scheduled',
  tab_deleted: 'Deleted',

  tab_scheduled_body: 'There are no any scheduled dispatches',
  tab_deleted_body: 'There are no any deleted dispatches',

  description: 'Create and start the messaging dispatch: select activated template and contact groups. You can create multichannel dispatches in case you have activated message template with content for each channel you would like to send.<br><br>' +
    'You can resend completed dispatch, schedule it on preferable day and time, delete unused dispatch drafts.',

  view_glossary: 'View Glossary',

  dispatch_empty: 'There are no dispatches in the project.',
  dispatch_empty_project_more_you_can: 'You can',
  dispatch_empty_project_more_create: 'create a dispatch',
  dispatch_empty_project_more_or: 'or',
  dispatch_empty_project_more_select_all: 'switch project filter to All projects',
  dispatch_empty_project_more_select_all_after: 'to see more.',
  dispatch_empty_project_one_you_can: 'You can',
  dispatch_empty_project_one_create: 'create a dispatch',
  dispatch_empty_project_one_create_after: 'to send bulk messages.',

  dispatch_scheduled_empty: 'There are no scheduled dispatches.',

  form,
  table,
  table_statistic,
  modal_start_sending,
  show,
  card,
  select_recipient,
  progress_dispatch,
  widget_statistic,
  launch,
  calendar,
  widget
}

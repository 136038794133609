export default {
  title: 'Поповнити баланс',
  select_contract: 'Оберіть договір для попвнення',
  agreement_since: 'Договір від',
  auto_payment_enabled: 'Включено автоплатіж',
  contract_id_placeholder: 'Оберіть...',
  btn_checkout: 'Перейти до сплати',
  helper: 'Ви будете перенаправлені на store.messaggio.com для завершення оплати.',
  errors_amount_min_value_RUB: 'Сума платежу повинна бути не менше ніж {amount} рублів',
  errors_amount_min_value_UAH: 'Сума платежу повинна бути не менше ніж {amount} гривень',
  cid: {
    errors: {
      required: 'Будь ласка, оберіть договір для поповнення',
    },
  },
  amount: {
    errors: {
      required: 'Будь ласка, вкажіть суму для поповнення',
    },
  },
}

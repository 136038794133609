export default {
  title: 'Групи контактів',
  for_dispatch_document: 'Отримувачі розсилки',
  for_exception: 'Групи для виключення з розсилки',
  recipients: 'Отримувачі розсилки',
  excluded_contacts: 'Виключені контакти',

  list_today: {
    title: 'Останні групи (додавання в один клік)',
  },
}

export default {
  entity_name: 'Name',
  entity_type: 'Type',

  type_project: 'Project',
  type_clientsender: 'Sender',
  type_contact_whitelist: 'Contact',
  type_contact_blacklist: 'Contact',
  type_template: 'Template',
  type_dispatch: 'Dispatch',
  type_drive_file: 'Document',

  loading: 'Loading Senders...',
  delete_confirm: 'Are you sure you want to delete favorite "{name}"?',
  delete_success: 'Favorite "{name}" was deleted.',
}

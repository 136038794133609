import quick_reply from '~/locales/en/Template/ContentLayout/Whatsapp/LayoutBody/Button/QuickReply';
import url from '~/locales/en/Template/ContentLayout/Whatsapp/LayoutBody/Button/Url';

export default {
  title: 'Button',
  add_button: 'Add button action',
  drag_helper: 'Drag items to arrange them in the desired order in the message',
  remove: 'Remove',
  remove_button_confirm: 'Are you sure you want to delete button?',
  save: 'Save',

  quick_reply,
  url,
}

export default {
  title_browser: 'Completed',
  title: 'Hello! Please, complete the registration',
  subtitle: 'Create a password, use at least 8 characters.',
  hash_empty: 'Hash is empty!',
  email: 'E-mail Address',
  password: 'Password',
  password_confirmed: 'Password confirmed',
  register_btn: 'Register',

  verified_was: 'Sorry, user has already completed the registration',
}

<template>
  <div
    :class="{ie: isIE}"
    class="page-content login-cover d-flex align-items-center justify-content-center"
  >
    <picture class="login-cover-bg">
      <source
        :srcset="require(`../static/bg/msg-bg-pic${bgI}.webp`)"
        type="image/webp"
      >
      <source
        :srcset="require(`../static/bg/msg-bg-pic${bgI}.jpg`)"
        type="image/jpeg"
      >
      <img :src="require(`../static/bg/msg-bg-pic${bgI}.jpg`)">
    </picture>
    <div class="row">
      <div class="col-12 col-lg-6 d-none d-lg-flex justify-content-center flex-column">
        <img
          src="~/static/messaggio_logo_white_color_900x210.png"
          width="200"
          class="mb-3"
        >
        <h1
          class="mb-0 text-left font-weight-black text-white"
          style="font-size: 4.5vw;"
          v-html="$t('permission.welcome.title')"
        />
        <h3
          class="mb-0 text-left font-weight-light text-white"
          v-html="$t('permission.welcome.subtitle')"
        />
      </div>
      <div class="col-12 col-lg-6">
        <div class="content-wrapper">
          <div class="content d-flex justify-content-center align-items-center flex-column">
            <div class="fullscreen-card card mb-0">
              <ul
                v-show="showNavTabs"
                class="nav nav-tabs nav-justified alpha-grey mb-0"
              >
                <li class="nav-item">
                  <nuxt-link
                    :to="nuxtLinkTo({name: 'permission-login'})"
                    :active-class="'active'"
                    class="nav-link border-y-0 border-left-0"
                  >
                    <h6 class="my-1 text-nowrap">
                      <i class="icon-user-lock mr-2" />
                      {{ $t('permission.login_link') }}
                    </h6>
                  </nuxt-link>
                </li>
                <li class="nav-item">
                  <nuxt-link
                    :to="nuxtLinkTo({name: 'permission-registration'})"
                    :active-class="'active'"
                    class="nav-link border-y-0 border-right-0"
                  >
                    <h6 class="my-1 text-nowrap">
                      <i class="icon-user-plus mr-2" />
                      {{ $t('permission.register_link') }}
                    </h6>
                  </nuxt-link>
                </li>
              </ul>
              <div class="card-body">
                <nuxt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setLang } from '~/components/Widget/WidgetLangDropdown';

export default {
  data: () => ({
    isIE: false,
    userAgent: '',
    bgI: Math.floor(Math.random() * 30 + 1),
  }),
  computed: {
    showNavTabs () {
      return !this.$route.name.endsWith('-oauth2');
    },
  },
  created () {
    let locale;
    if (this.$route.query && this.$route.query.lang) {
      locale = this.$route.query.lang;
    } else if (process.browser) {
      locale = (window.localStorage.getItem('locale') || 'en');
    } else {
      locale = this.$store.state.locale;
    }
    locale && setLang.call(this, locale);
  },
  mounted () {
    if (process.browser) {
      const ua = window.navigator.userAgent;
      this.userAgent = ua;
      this.isIE = (
        ua.includes('MSIE') ||
        ua.includes('Trident/') ||
        ua.includes('V8WebKit') ||
        ua.includes('Edge/')
      );
    }
  },
  methods: {
    nuxtLinkTo (obj) {
      const { query } = this.$route;
      return { ...obj, query };
    },
  },
};
</script>

<style>
@import "../assets/fullscreen/css/iofrm-style.css";
@import "../assets/fullscreen/css/iofrm-theme15.css";

html, body {
  height: 100%;
}

#__nuxt,
#__layout {
  height: 100%;
}

.fullscreen-card {
  border: 1px solid rgba(69, 74, 94, .29) !important;
  box-shadow: 0 4px 10px rgba(93, 122, 185, .26) !important;
  min-width: 320px;
}

@media (min-width: 470px) {
  .fullscreen-card {
    width: 440px;
  }
}
</style>

<style lang="scss">
.content-wrapper {
  z-index: 999;
}

.login-cover-old {
  background: radial-gradient(ellipse at top, #e8edf5 13%, #8690a0 100%);
}

.login-cover-bg {
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.ie .login-cover-bg {
  display: none;
}
</style>

export default {
  title: 'Експорт контактів з {name}',
  description: 'Підготування файлу з контактами може зайняти деякий час.<br>Будь ласка, не закривайте вікно і не оновлюйте сторінку.',
  table_description: 'Ви вже маєте раніше сгенеровані файли контактів групи.',

  btn_export: 'Експортувати {counts}',
  btn_export_processing: 'Експорт"...',

  row_count_1: 'контакт',
  row_count_2: 'контакти',
  row_count_5: 'контактів',
}

export default {
  title: 'Статистика с {from} по {to}',

  screen_full: 'Во весь экран',
  screen_normal: 'Нормальный вид',
  show_request: 'Показать запрос',
  show_group_by_day: 'Показать по дням',

  show_request_title: 'Запрос',
  show_group_by_day_title: 'По дням',

  empty_data: 'Выберите период и фильтры статистики чтобы отобразить график',
}
//$t('statistic.chart.title')

<template>
  <div class="b-dropdown-actions text-center">
    <b-dropdown
      right
      variant="link"
      style="position: absolute; right: 0; top: 0;"
    >
      <template slot="button-content">
        <i class="icon-menu7" />
      </template>
      <b-dropdown-item
        v-if="!actionShowDisabled"
        :to="check2PathProjectSep({name: `${routeToName}-${routeIdName}-show`, params})"
      >
        <i class="icon-info3" />
        {{ $t('widget.table_slot.actions_list.show') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!actionEditDisabled"
        :to="check2PathProjectSep({name: `${routeToName}-${routeIdName}-edit`, params})"
      >
        <template v-if="$slots['edit-title']">
          <slot name="edit-title" />
        </template>
        <template v-else>
          <i class="icon-pencil5" />
          {{ $t('widget.table_slot.actions_list.edit') }}
        </template>
      </b-dropdown-item>
      <slot name="links" />
      <b-dropdown-divider v-if="!actionDividerDisabled" />
      <slot name="links_after_divider" />
      <b-dropdown-item
        v-if="!actionDeleteDisabled"
        @click="$emit('delete', item)"
      >
        <i class="icon-trash" /> {{ $t('home.delete') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { check2PathProjectSep } from '~/utils/functions';

export default {
  props: {
    item: { type: Object, required: true },
    routeToName: { type: String, required: true },
    actionShowDisabled: { type: Boolean, default: false },
    actionEditDisabled: { type: Boolean, default: false },
    actionDividerDisabled: { type: Boolean, default: false },
    actionDeleteDisabled: { type: Boolean, default: false },
    routeIdName: { type: String, default: 'id' },
    routeId: { type: String, default: 'id' },
    routeParams: { type: Object, default: () => ({}) },
  },
  computed: {
    params () {
      return {
        [this.routeIdName]: this.item[this.routeId],
        ...this.routeParams,
      };
    },
  },
  methods: {
    check2PathProjectSep,
  },
};
</script>

<style scoped>
.b-dropdown-actions {
  margin: 0 auto;
  position: relative;
  height: 36px;
  width: 26px;
}
</style>

export default {
  title: 'Импорт контактов в группу',

  tab_manual_input: 'Ручной ввод',
  phone_numbers: 'Номера телефонов',
  phone_numbers_placeholder: 'Внесите номера телефонов, каждый на новой строке',

  tab_import_csv_file: 'Загрузить файл',
  select_file_to_import: 'Выберите файл для импорта',
  accepted_formats: 'Поддерживаемые форматы файла: *.xlsx; *.csv. Максимум 20Mb',

  tab_import_csv_file_title: 'Скачать пример CSV файла',
  tab_import_csv_file_subtitle: 'Лучше всего для импорта подходят таблицы, сохраненные в формате "CSV - разделитель точка с запятой" в кодировке UTF-8',
  tab_import_csv_file_download: 'Скачать файл',
  tab_import_csv_file_excel_windows: 'Как сохранить файл в Excel для Windows',
  tab_import_csv_file_excel_macos: 'Как сохранить файл в Excel для Mac',
  tab_import_csv_file_numbers_macos: 'Как сохранить файл в Numbers для Mac',

  contact_handling_settings: 'Настройки обработки контактов',
  duplicate_remove: 'Очистить список контактов от дублей номеров',

  separate_symbol: 'Разделитель',
  separate_symbol_comma: 'Запятая',
  separate_symbol_semicolon: 'Точка с запятой',

  duplicate_cleanup_start: 'Начали очистку от дублей',
  duplicate_cleanup_deleted: 'Очистка от дублей: {deleted}',

  phone_column_not_specified: 'Не определена колонка номеров телефонов',

  charset_title: 'Отображение списка контактов',
  charset_description: 'Если данные в таблице предпросмотра отображаются нечитабельно, попробуйте выбрать другую кодировку списка контактов.',

  phone_number_rewrite_title: 'Форматирование номера телефона',
  phone_number_rewrite_description: 'В настройках аккаунта вы указали, что страна нахождения компании: <b>{country_name}</b><br>Включите опцию, если загружаете контакты с телефонами в локальном формате.',
  phone_number_rewrite_label: 'Дописывать вначале номера телефона код страны <b>{country_code}</b> где это требуется',

  previous_step: 'Назад',
  next_step: 'Далее',
};

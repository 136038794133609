import axios from 'axios';
import type { paths } from '@messaggio/client-api-user/api/auth';
import type { GetPayload, GetResponse } from '@messaggio/client-api-user/utils';

const uninterceptedAxios = axios.create();
uninterceptedAxios.defaults.baseURL = process.env.API_HOST || '/';

export type LoginPayload = GetPayload<paths, '/login', 'post'>;
export type LoginResponse = GetResponse<paths, '/login', 'post', 200>;

export async function login(params: LoginPayload & { confirmation?: string }) {
  try {
    const response = await uninterceptedAxios.post<LoginResponse>(
      `/auth/api/v1/login`,
      params,
      {
        withCredentials: true,
        ...(params.confirmation
          ? { headers: { 'Messaggio-OTP': params.confirmation } }
          : {}),
      }
    );

    return response;
  } catch (error) {
    return { error: error as Error };
  }
}

export type RefreshPayload = GetPayload<paths, '/refresh', 'post'>;
export type RefreshResponse = GetResponse<paths, '/refresh', 'post', 200>;

export async function refreshToken(params: RefreshPayload) {
  try {
    const response = await uninterceptedAxios.post<RefreshResponse>(
      `/auth/api/v1/refresh`,
      params,
      {
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    return { error: error as Error };
  }
}

export async function logout() {
  try {
    await uninterceptedAxios.get(`/auth/api/v1/logout`, {
      withCredentials: true,
    });
  } catch (error) {
    return { error: error as Error };
  }
}

export type RegisterPayload = {
  email: string;
  password: string;
  lang: string;
  company_name: string;
  utm: Record<string, unknown>;
};

export type RegisterResponse = {
  token: string;
};

export async function register(params: RegisterPayload) {
  try {
    const response = await uninterceptedAxios.post<RegisterResponse>(
      `/api-gateway/api/v1/users`,
      params,
      {
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    return { error: error as Error };
  }
}

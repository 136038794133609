export default {
  title: 'Чати',

  new_chat: 'Створити чат',
  description: 'В деяких випадках немає можливості відправляти абоненту текст у вільній формі, якщо він не ініціював з вами переписку. <br>' +
    'Можливості переписки з абонентом залежать від Відправника і каналу відправки.',

  to: 'Кому',
  from: 'Від',
  phone_number_placeholder: 'Вкажіть номер телефону...',
  client_sender_id_placeholder: 'Виберіть відправника...',
  message_payload: 'Повідомлення',

  payload_placeholder: 'Введіть повідомлення...',
  payload_length_max: 'Повідомлення повинно містити меньш ніж 1000 символів.',
  send: 'Надіслати',
};

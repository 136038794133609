<template>
  <div id="messaggio-chat-widget" />
</template>

<script>
import axios from 'axios';

export default {
  watch: {
    'axios.defaults.headers.common.Authorization'(token) {
      Object.assign(window.chatWidgetConfig, { token });
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (window.chatWidgetConfig?.mounted) {
      window.chatWidgetConfig.unMountChat();
    }
  },
  methods: {
    init() {
      const token = axios.defaults.headers?.common?.Authorization?.substr(7);
      const tenantId = this.$store.state.auth?.tenant?.id;

      if (!token || !tenantId) {
        setTimeout(this.init, 1000);
        return;
      }

      if (!window.chatWidgetConfig) {
        window.chatWidgetConfig = {};
      }

      const isDev =
        process.env.NODE_ENV !== 'production' ||
        window.location.host.startsWith('dev.');

      Object.assign(window.chatWidgetConfig, {
        url: 'https://' + (isDev ? 'dev-' : '') + 'webchat-api.messaggio.com',
        socketPath:
          'wss://' +
          (isDev ? 'dev-' : '') +
          'webchat-api.messaggio.com/ws/chats/api/v1/events/connect',
        restPath: '/chats/api/v1',
        token,
        tenantId: isDev ? 'bsl537qcsmklrcv0v4hg' : 'bsl537qcsmklrcv0v4hg',
      });

      const s = document.querySelectorAll('#webchat-script');
      if (s.length) {
        console.info('Webchat script already loaded');
        // Array.prototype.forEach.call(s, node => node.parentNode.removeChild(node));
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://messaggio.com/main.js';
      script.async = true;
      script.id = 'webchat-script';
      script.onload = () => {
        if (!window.chatWidgetConfig.mounted) {
          window.chatWidgetConfig.mountChat();
        }
      };
      document.body.appendChild(script);
    },
  },
};
</script>

export default {
  name: 'Название',
  description: 'Описание',
  tenant_id: 'Компания',
  web_page: 'Адрес сайта',

  tab_use_api: 'Настройки API',
  bulk_login: 'Логин',
  bulk_secret_key: 'Секретный ключ',
  bulk_idp: 'IDP',
  api_type: 'Тип API',
  callback_url: 'Callback URL для статусов доставки',
  callback_url_empty: 'URL не задан',
  callback_url_two_way: 'Callback URL для входящих сообщений',
  callback_url_two_way_empty: 'URL не задан',

  tab_api_setting: 'Настройки API',
}

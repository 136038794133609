import content from './Content';

export default {
  progress: {
    title: 'Присоединение к оферте',
  },
  left: {
    title: 'Заключите договор-оферту для отправки сообщений',
    subtitle: 'Messaggio оказывает услуги юридическим лицам и индивидуальным предпринимателям.',
    subsubtitle: 'Чтобы мы могли отправлять вам закрывающие документы, пожалуйста, <mark>пройдите процесс акцепта условий договора-оферты.</mark>',
    link: 'Посмотреть Договор присоединения (оферта) на рассылку сообщений',
  },
  inn: {
    title: 'Укажите тип вашего юридического лица',
    legal_entity: {
      flp: 'Индивидуальный предприниматель',
      egrpoy: 'Организация (ООО или некоммерческая)',
    },
    inn: {
      title: 'Укажите ваш ИНН',
    },
    is_individual_entrepreneur: {
      title: 'Индивидуальный предприниматель',
    },
    submit: 'Продолжить',
  },
  content,
};

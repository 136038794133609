export default {
  loading: 'Загрузка...',

  name: 'Название',
  link: 'Ссылка',
  created_at: 'Создано',

  delete_confirm: 'Вы действительно хотите удалить ссылку "{name}"?',
  delete_success: 'Ссылка "{name}" удалена.',
}

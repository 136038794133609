import avatar from '~/locales/uk/Company/User/Avatar';
import card from '~/locales/uk/Company/User/Card';
import create from '~/locales/uk/Company/User/Create';
import edit_password from '~/locales/uk/Company/User/EditPassword';
import edit_phone from '~/locales/uk/Company/User/EditPhone';
import modal_create_user from '~/locales/uk/Company/User/ModalCreateUser';
import auth_otp from '~/locales/uk/Company/User/AuthOTP';

export default {
  title: 'Мій акаунт',

  profile: 'Налаштування профіля',
  profile_description: 'Вкажіть ваше ім\'я і контактний номер телефону, щоб служба підтримки могла оперативно зв`язатись з вами за необхідності.',
  dashboard_appearance: 'Налаштування особистого кабінету',
  dashboard_appearance_description: 'Оберіть мову інтерфейсу особистого кабінету і ваш часовий пояс. Ви також отримуватимете сповіщення на email від Messaggio на обраній мові. Запуск запланованиз розсилок відбувається у відповідності з заданим часовим поясом.',
  account_access: 'Налаштування акаунту',

  first_name: 'Ім`я',
  second_name: 'По батькові',
  last_name: 'Прізвище',
  timezone: 'Часовий пояс',
  lang: 'Мова системи',
  phone: 'Контактний телефон',
  email: 'Email',
  tariff_plan: 'Тарифний план',
  password: 'Пароль',

  save: 'Зберегти',
  save_success: 'Готово',

  resend_verification_email_success: 'Лист відправлено на ваш email',

  updated_at: 'Оновлено',

  verified_false_alert_warning: 'Будь ласка, підтвердіть email для доступу до можливостей акаунта.',
  resend_verification_email: 'Надіслати лист для підтверждения email',

  index_title: 'Користувачі',
  index_description: '<p>Ви можете створити додаткових користувачів для спільної роботи з розсилками і чатами. Запрошений користувач повинен підтвердити свій Email для доступа до можливостей платформи.</p>' +
    '<p>Якщо потрібно розмежувати права доступу для деяких користувачів, зверніться в Службу підтримки.</p>',

  avatar,
  card,
  create,
  edit_password,
  edit_phone,
  modal_create_user,
  auth_otp,
};

export default {
  title: 'Попередній перегляд',
  ok_title: 'Продовжити',
  cancel_title: 'Скасувати',

  content_info: '<b>Встановіть тип даних для кожної колонки.</b><br>Ви можете завантажувати контакти з додатковими параметрами, щоб використовувати їх в змінних шаблону. Призначте ім\'я змінної для даних в колонці, вказавши його У випадаючому списку.',
  table_header: 'Заголовок таблиці',
  skip_first_row: 'Виключити перший рядок',

  phone_no_select: 'Не вказано колонку Телефон',
  file_processing: 'Обробка файлу...',

  params_phone: 'Телефон',
  params_name: 'ПІБ [[NAME]]',
  params_first_name: 'Ім`я [[FIRST_NAME]]',
  params_second_name: 'По батькові [[SECOND_NAME]]',
  params_last_name: 'Прізвище [[LAST_NAME]]',
  params_birth_day: 'День народження [[BIRTH_DAY]]',
  params_code: 'Код [[CODE]]',
  params_email: 'EMail [[EMAIL]]',
  params_date: 'Дата [[DATE]]',
  params_sum: 'Сума  [[SUM]]',
  params_param_1: 'Параметр 1 [[PARAM_1]]',
  params_param_2: 'Параметр 2 [[PARAM_2]]',
  params_param_3: 'Параметр 3 [[PARAM_3]]',

  params_phone_name: 'Телефон',
  params_name_name: 'ПІБ',
  params_first_name_name: 'Ім`я',
  params_second_name_name: 'По батькові',
  params_last_name_name: 'Прізвище',
  params_birth_day_name: 'День народження',
  params_code_name: 'Код',
  params_email_name: 'EMail',
  params_date_name: 'Дата',
  params_sum_name: 'Сума ',
  params_param_1_name: 'Параметр 1',
  params_param_2_name: 'Параметр 2',
  params_param_3_name: 'Параметр 3',

  save_success: 'Готово',
};

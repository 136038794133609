export default {
  name: 'Name',
  name_placeholder: 'New project',
  description: 'Description',
  description_placeholder: 'Describe your messaging activity',
  web_page: 'Site URL',
  web_page_placeholder: 'http://myproject.com',
  bg_color: 'Project label color',

  tab_use_api: 'API settings',
  bulk_login: 'Bulk login',
  bulk_secret_key: 'Secret key',
  bulk_idp: 'IDP',
  api_type: 'API type',
  callback_url: 'Callback URL',
  callback_url_two_way: 'Messages Callback URL',
  authorized_ip: 'List of allowed IP\'s for API requests',
  authorized_ip_placeholder: "192.168.0.1\n192.168.0.2",
  authorized_ip_help: 'Specify each IP address on a new line',

  save_success: 'Saved successfully',
}

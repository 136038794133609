import form from '~/locales/uk/Template/Preset/form';
import table from '~/locales/uk/Template/Preset/table';
import show from '~/locales/uk/Template/Preset/show';

export default {
  title: 'Presets list',
  title_create: 'Create Preset',
  title_show: 'Preset {name}',
  title_show_breadcrumbs: 'View Preset',
  title_edit: 'Preset {name}',
  title_edit_breadcrumbs: 'Edit Preset',

  form,
  table,
  show,
};

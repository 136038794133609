import change_lang from '~/locales/en/Company/Account/ChangeLang';

export default {
  title: 'Company',

  name: 'Company name',
  name_placeholder: 'Business Name LTD.',
  description: 'Company description',
  description_placeholder: 'Few words about your business',
  location_country: 'Location of head office',

  finance_doc_title: 'Financial notifications',
  finance_doc_description: 'Please provide email address of your accounting team to receive legal and financial documents.',
  email_finance_doc: 'Email for finance docs',
  email_finance_doc_placeholder: 'Email for financial correspondence',

  btn_save: 'Save changes',
  save_success: 'Save success',

  change_lang,
  accept_offer: {
    description: 'Company details saved successfully!<br>Now you should accept T&amp;C to continue.',
    btn_go: 'Go to the next step',
  },
}

import { one } from "./model/one.js";
import { quickTask } from "./model/quickTask.js";
function factory(name) {
  return {
    one: one(name),
    quickTask: quickTask(name)
  };
}
const launchADispatch = factory("launch-a-dispatch");
const clientSenderStatusActive = factory("client-sender-status-active");
const clientSenderStatusDeact = factory("client-sender-status-deact");
export {
  clientSenderStatusActive,
  clientSenderStatusDeact,
  launchADispatch
};

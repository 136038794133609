import modal_user from '~/locales/en/Chat/Manager/ModalUser';
import reassign from '~/locales/en/Chat/Manager/Reassign';
import dump from '~/locales/en/Chat/Manager/Export';
import fast_answer from '~/locales/en/Chat/Manager/FastAnswer';
import list from '~/locales/en/Chat/Manager/List';
import menu from '~/locales/en/Chat/Manager/Menu';
import one from '~/locales/en/Chat/Manager/One';

export default {
  title: 'Chat with {name}',

  mark_as_unresolved: 'Mark as unresolved',
  mark_as_resolved: 'Mark as resolved',
  assign_to_myself: 'Assign to myself and start chatting',

  modal_user,
  reassign,
  dump,
  fast_answer,
  list,
  menu,
  one,
}

import modal_upload from "~/locales/en/Company/Gallery/ModalUpload";

export default {
  title: 'Gallery',

  load_image: 'Upload',

  delete_confirm: 'Are you sure you want to delete image?',
  delete_success: 'Image deleted.',

  gallery_empty: 'There are no images in account gallery.',
  gallery_empty_project_one_you_can: '',
  gallery_empty_project_one_create: 'Upload image',
  gallery_empty_project_one_create_after: 'that meets the channel requirements.',

  modal_upload,
}

export default {
  open_btn: 'Upload file',

  title: 'Upload file to My files',
  description: 'Your file will be uploaded to the <b>My files</b> category.<br>' +
    'After upload, you can enable public access to the file.',

  file: 'File',
  category: 'Category',
  public: 'Public',
  category_documents: 'Documents',
  public_true: 'Yes',
  public_false: 'No',

  submit: 'Upload',
  save_success: 'Saved successfully',
}

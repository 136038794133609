import PhoneParamsTag from '~/locales/ru/GroupContact/Contact/PhoneParamsTag';

export default {
  ...PhoneParamsTag,

  textarea_empty: 'Нет записей',
  textarea_max_row: 'Максимум 100 000 строк',
  file_not_select: 'Файл не выбран',

  save_success: 'Готово',
}

export default {
  loading: 'Loading phone numbers...',
  remove: 'Move',

  id: '',
  phone: 'Phonenumber',
  country: 'Country',
  operator: 'Operator',
  params: 'Params',

  btn_copy: 'Copy to group',

  selected_length_1: 'contact selected',
  selected_length_2: 'contacts selected',
  selected_length_5: 'contacts selected',

  deletes_confirm1: 'Delete {ids} ',
  deletes_confirm2: 'from Group "{name}"',
  deletes_success: 'Phone numbers was deleted.',
  deletes_pnotify_title: 'Delete phone numbers',

  delete_confirm: 'Are you sure you want to delete phone number "{name}"?',
  delete_success: 'Phone number "{name}" was deleted.',
}

export default {
  title: 'Пошук за номером телефону',

  loading: 'пошук повідомлень...',

  key: 'Параметр',
  value: 'Значення',

}
//$t('statistic.phone.loading')

export default {
  title: 'Sender actions',

  btn_info: 'Sender info',
  btn_moderation: 'Send for moderation',
  btn_create_template: 'Create template with the Sender',
  btn_edit: 'Edit Sender',
  btn_statistics: 'Sender statistics',
  btn_deactivate: 'Deactivate',
  btn_activate: 'Activate',
}

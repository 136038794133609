export default {
  delete_confirm: 'Are you sure you want to delete user "{name}"?',
  delete_success: 'User "{name}" was deleted.',

  email_verified: 'ACTIVE',
  email_not_verified: 'Email not verified',

  role_owner: 'Owner',
  role_manager: 'Manager',
}

<template>
  <div class="card-body">
    <blockquote class="blockquote text-center py-2 mt-3 mb-0">
      <div class="mb-3">
        <img :src="iconStaticSrc" width="50%"/>
      </div>
      <p class="text-muted mb-5">
        {{ $t(`${transPrefix}`) }}<br>
        <template v-if="projectLengthCheck && projectLength > 1">
          {{ $t(`${transPrefix}_project_more_you_can`) }}
          <a v-if="routeCreateEvent"
             href="#"
             @click.prevent="onRouteCreateClick">
            {{ $t(`${transPrefix}_project_more_create`) }}
          </a>
          <nuxt-link v-else :to="routeCreateObj">
            {{ $t(`${transPrefix}_project_more_create`) }}
          </nuxt-link>
          {{ $t(`${transPrefix}_project_more_or`) }}
          <nuxt-link
            :to="{name: routeSelectAllProjectName}"
            @click.native="setSelectProject('')">
            {{ $t(`${transPrefix}_project_more_select_all`) }}
          </nuxt-link>
          {{ $t(`${transPrefix}_project_more_select_all_after`) }}
        </template>
        <template v-else>
          {{ $t(`${transPrefix}_project_one_you_can`) }}
          <a v-if="routeCreateEvent" href="#"
             @click.prevent="onRouteCreateClick">
            {{ $t(`${transPrefix}_project_one_create`) }}
          </a>
          <nuxt-link v-else :to="routeCreateObj">
            {{ $t(`${transPrefix}_project_one_create`) }}
          </nuxt-link>
          {{ $t(`${transPrefix}_project_one_create_after`) }}
        </template>
      </p>
    </blockquote>
  </div>
</template>

<script>
  import {check2PathProjectSep} from '~/utils/functions';

  export default {
    name: 'widget-stub-empty',
    props: {
      iconStatic: {
        type: String,
        default: null,
      },
      transPrefix: {
        type: String,
        default: null,
      },
      routeCreate: {
        type: Object,
        default: () => ({}),
      },
      routeCreateName: {
        type: String,
        default: null,
      },
      routeCreateEvent: {
        type: Boolean,
        default: false,
      },
      routeSelectAllProjectName: {
        type: String,
        default: null,
      },
      projectLengthCheck: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        check2PathProjectSep: check2PathProjectSep.bind(this),
      };
    },
    methods: {
      setSelectProject(project_id) {
        this.$store.dispatch('setSelectProject', project_id);
      },
      onRouteCreateClick() {
        this.$emit('on-route-create-click');
      },
    },
    computed: {
      projectLength() {
        return Object.keys(this.$store.state.projects).length;
      },
      routeCreateObj() {
        if (this.routeCreateName && this.routeCreateName.length) {
          return this.check2PathProjectSep({name: this.routeCreateName});
        } else {
          return this.routeCreate;
        }
      },
      iconStaticSrc() {
        return require(`~/static/${this.iconStatic}`);
      },
    },
  }
</script>

<style scoped>

</style>

/* eslint-disable */

export default ({ app }) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  // Будет работать только на стороне клиента и только когда режим разработки будет "production"
  // if (process.env.NODE_ENV !== 'production') return;

  new Promise((resolve) => {
    const check = () => {
      //console.info('Yandex Metrika: ', window.location);
      //console.info('Yandex Metrika: ', app?.store?.state?.auth?.tenant?.location_country);

      if (window.location.pathname.startsWith('/permission')) {
        // good
      } else if (app?.store?.state?.auth?.tenant?.location_country === 'ua') {
        return resolve();
      } else if (!app?.store?.state?.auth?.tenant?.location_country) {
        return setTimeout(check, 100);
      }

      //console.info('Yandex Metrika: connect');

      // Подключаем скрипт Google Analytics
      (function (m, e, t, r, i, k, a) {
        m[i] = m[i] || function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
        m[i].l = 1 * new Date();
        k = e.createElement(t);
        a = e.getElementsByTagName(t)[0];
        k.async = 1;
        k.src = r;
        a.parentNode.insertBefore(k, a);
      })(
        window,
        document,
        'script',
        'https://mc.yandex.ru/metrika/tag.js',
        'ym',
      );

      // Устанавливаем текущую страницу
      ym(process.env.YANDEX_METRIKA, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });

      return resolve();
    };
    check();
  });
}

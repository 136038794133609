export default {
  title: 'Account',
  description: 'Manage your account and company settings. You can invite additional users to your company.',

  settings: 'Settings',
  my_account: 'My account',
  notification: 'Notifications',
  gallery: 'Gallery',
  short_link: 'Short link',
  company: 'Company details',
  projects: 'Projects',
  users: 'Users',
  billing: 'Payments',
  documents: 'Files',
  testphone: 'Testphone',
  chat: 'Chat',
  action_history: 'Action history',
  services: 'Apps & Services',
  support: 'Support service',
  favorite: 'Favorite items',

  title_general: 'General',
  title_account: 'Account',
  title_messaging: 'Messaging',
  title_company: 'Company',
}

export default {
  title: 'Поиск',
  loading: 'Загрузка...',
  items_empty: 'Пусто',

  see_all: 'Посмотреть все {type}',
  result_total: '{result} всего',

  form_submit: 'Поиск',

  tab_admin: 'Админ',
  tab_messages: 'Сообщения',
  tab_system_logs: 'Логи',
}

export default {
  title: 'Изменить пароль',
  title_btn: 'Изменить пароль',

  old_password: {
    label: 'Текущий пароль',
    error: {
      wrong: 'Текущий пароль указан неверно.',
    },
  },
  new_password: 'Новый пароль',
  repeat_new_password: 'Еще раз новый пароль',

  ok_title: 'Изменить пароль',
  save_success: 'Готово',
}

import form from '~/locales/ru/Template/form';
import table from '~/locales/ru/Template/table';
import show from '~/locales/ru/Template/show';
import preset from '~/locales/ru/Template/Preset';
import content_table from '~/locales/ru/Template/ContentTable';
import status_change from '~/locales/ru/Template/StatusChange';
import input_image from '~/locales/ru/Template/InputImage';
import input_document from '~/locales/ru/Template/InputDocument';
import input_location from '~/locales/ru/Template/InputLocation';
import send_moderation from '~/locales/ru/Template/SendModeration';
import send_testphone from '~/locales/ru/Template/SendTestphone';
import card from '~/locales/ru/Template/Card';
import emojione_area from '~/locales/ru/Template/EmojioneArea';
import content_layout from '~/locales/ru/Template/ContentLayout';

export default {
  title: 'Шаблоны',
  title_index: 'Шаблоны сообщений',
  title_index_moderation: 'Модерация шаблонов',
  title_create: 'Создать шаблон',
  title_show: 'Шаблон {name}',
  title_show_breadcrumbs: 'Просмотр шаблона',
  title_edit: 'Шаблон сообщения',
  title_edit_breadcrumbs: 'Редактировать шаблон',

  filter_q_placeholder: 'Название',

  change_status: 'Изменить статус',
  preset_btn: 'Preset settings',
  send_for_moderation: 'Отправить на модерацию',
  copy_btn: 'Создать копию',

  description: 'Шаблон сообщения - это контент, который вы отправляете получателю: комбинация текста, изображений, кнопок, эмодзи и ссылок. Каждый канал предоставляет собственные требования к содержимому и формату сообщений.<br><br>' +
    'Модерация шаблона сообщения обычно занимает несколько часов. Если запрошенный шаблон соответствует требованиям канала, он будет одобрен для использования в рассылке.',

  view_glossary: 'Глоссарий',

  template_empty: 'В проекте нет шаблонов.',
  template_empty_project_more_you_can: 'Вы можете',
  template_empty_project_more_create: 'создать шаблон',
  template_empty_project_more_or: 'или',
  template_empty_project_more_select_all: 'переключить фильтр проектов на Все проекты.',
  template_empty_project_more_select_all_after: '',
  template_empty_project_one_you_can: 'Вы можете',
  template_empty_project_one_create: 'создать шаблон',
  template_empty_project_one_create_after: 'для использования в рассылке сообщений.',

  form,
  table,
  show,
  preset,
  content_table,
  status_change,
  input_image,
  input_document,
  input_location,
  send_moderation,
  send_testphone,
  card,
  emojione_area,
  content_layout,
}

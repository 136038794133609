import trailer_profile from '~/locales/en/ClientSender/TrailerProfile';

export default {
  title: 'Create sender',

  name: 'Name',
  name_placeholder_sms: 'Enter the name in English',
  token: 'Token',
  name_en: 'Name in English',
  code_api: 'Code API',
  tenant_id: 'Company',
  tenant_id_placeholder: 'Company name',
  project_id: 'Project',
  project_id_placeholder: 'Select project',
  channel_id: 'Channel',
  channel_id_placeholder: 'Select channel',
  sender_id: 'Gate Sender',
  status: 'Status',
  sender_profile_details: 'Sender profile details',
  commercial_account_details: 'Commercial Account Details',
  messaging_details: 'Messaging details',
  company_details: 'Company details',
  logo_change: 'Change logo',
  logo_chatscreen_change: 'Change chatscreen',
  da_data_inn: 'INN, OGRN or OGRNIP',

  save_success: 'Saved successfully',

  create_gate_sender: 'Create Gate Sender?',
  create: 'Create',
  trailer_profile,
};

export default {
  start_title: 'Подготовка отчета',
  end_title: 'Отчет готов!',
  end_description: 'Загрузите отчет или скачайте его позже в разделе Документы',

  btn_title: 'Выгрузить историю чатов',
  modal_title: 'Выгрузить историю чатов',
  modal_description: 'Пожалуйста, задайте период. Все принятые и отправленные сообщения в чате будут сохранены в отчет.<br>Не закрывайте окно, пока отчет готовится.',
  from: 'От',
  to: 'Кому',
  create_report: 'Создать отчет',
}

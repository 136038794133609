import enabled from '~/locales/ru/Company/User/AuthOTP/Enabled';
import disabled from '~/locales/ru/Company/User/AuthOTP/Disabled';

export default {
  title: 'Двухфакторная авторизация Google Auth',
  description: 'Вы можете дополнительно защитить свой аккаунт, настроив двухфакторную авторизацию. Скачайте приложение Google Authenticator и просканируйте QR код ниже, чтобы установить связь.<br>' +
    '<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img alt="Get it on App Store" src="https://developer.apple.com//app-store/marketing/guidelines/images/badge-example-preferred_2x.png" width="160"></a>' +
    '<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" width="200"></a>' +
    '<br>В дальнейшем, при входе в личный кабинет помимо пароля будет запрашиваться временный код из приложения Google Auth.',

  enabled,
  disabled,
}

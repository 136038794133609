export default {
  create: 'Создать',

  template: 'Шаблон',
  dispatch_document: 'Рассылка',
  group_contact: 'Группа контактов',
  clientsender: 'Отправитель',
  company_project: 'Проект',

  company_company: 'Компания',
}

import modal_columns from '~/locales/ru/Statistic/Filter/ModalColumns'

export default {
  list_preset_loading: 'Загрузка параметров...',

  message_date: 'Период',
  project_id: 'Проект',
  project_id_all: 'Все проекты',
  channel_id: 'Каналы',
  clientsender_id: 'Отправитель',
  dispatch_document_id: 'Рассылка',
  country: 'Страны и операторы',
  country_title: 'Страны',
  mobile_operator_title: 'Операторы',
  columns_select: 'Настройки отчета',
  select: 'Создать отчет',
  fetch_csv: 'Скачать CSV',
  report: 'CSV Отчеты',
  statistic: 'Статистика',

  modal_columns,

  country_ru: 'Россия',
  country_ua: 'Украина',
  country_kz: 'Казахстан',
  country_md: 'Молдова',
  country_by: 'Беларусь',

  grouped: 'сгруппированный',
  custom: 'специальный',
}

import table from '~/locales/ru/Company/Balance/LastPayment/table';

export default {
  title: 'История платежей {entity}',

  status_process: 'В обработке',
  status_success: 'Оплачено',
  status_canceled: 'Отменен',

  table,
}

export default {
  open_btn: 'Link Senders',

  title: 'Оберіть гейт сендер, щоб прив`язати до {name}',
  table_sender_loading: 'Завантаження гейт сендерів...',
  table_sender_filter_q_placeholder: 'Гейт сендер',
  delete_msg: 'відв`язати {name}',
  create_msg: 'Прив`язати гейт сендер',
  create_success: 'успішно прив`язано',

  delete_confirm: 'Ви впевнені, що хочете відв`язати "{name}"?',
  delete_success: '{name} було відв`язано.',
}

import form from '~/locales/ru/DispatchDocument/form';
import table from '~/locales/ru/DispatchDocument/table';
import table_statistic from '~/locales/ru/DispatchDocument/TableStatistic';
import show from '~/locales/ru/DispatchDocument/show';
import modal_start_sending from '~/locales/ru/DispatchDocument/ModalStartSending';
import card from '~/locales/ru/DispatchDocument/Card';
import select_recipient from '~/locales/ru/DispatchDocument/SelectRecipient';
import progress_dispatch from '~/locales/ru/DispatchDocument/ProgressDispatch';
import widget_statistic from '~/locales/ru/DispatchDocument/WidgetStatistic';
import launch from '~/locales/ru/DispatchDocument/Launch';
import calendar from '~/locales/ru/DispatchDocument/Calendar';
import widget from '~/locales/ru/DispatchDocument/Widget';

export default {
  title: 'Рассылки',
  title_create: 'Создать рассылку',
  title_show: 'Рассылка {name}',
  title_edit: 'Рассылка {name}',

  btn_regular_dispatch: 'Стандартная рассылка',
  btn_ab_dispatch: 'A/B тестирование рассылки',
  btn_fast_dispatch: 'Одностраничная рассылка',

  filter_q_placeholder: 'Название',

  tab_all: 'Все рассылки',
  tab_scheduled: 'Запланированные',
  tab_deleted: 'Удаленные',

  tab_scheduled_body: 'Нет запланированных рассылок',
  tab_deleted_body: 'Нет удаленных рассылок',

  description: 'Создайте и запустите рассылку сообщений: выберите активированный шаблон и группы контактов. Вы можете создавать многоканальные рассылки, если активировали шаблон с сообщениями для каждого канала, который хотите использовать.<br><br>' +
    'Вы можете повторно отправить завершенную рассылку, запланировать ее на желаемый день и время, а также удалить неиспользованные черновики.',

  view_glossary: 'Глоссарий',

  dispatch_empty: 'В проекте нет рассылок.',
  dispatch_empty_project_more_you_can: 'Вы можете',
  dispatch_empty_project_more_create: 'создать рассылку',
  dispatch_empty_project_more_or: 'или',
  dispatch_empty_project_more_select_all: 'переключить фильтр проектов на Все проекты.',
  dispatch_empty_project_more_select_all_after: '',
  dispatch_empty_project_one_you_can: 'Вы можете',
  dispatch_empty_project_one_create: 'создать рассылку,',
  dispatch_empty_project_one_create_after: 'чтобы массово отправить сообщения.',

  dispatch_scheduled_empty: 'Нет запланированных рассылок.',

  form,
  table,
  table_statistic,
  modal_start_sending,
  show,
  card,
  select_recipient,
  progress_dispatch,
  widget_statistic,
  launch,
  calendar,
  widget,
};

export default {
  title: 'Экспорт контактов из {name}',
  description: 'Подготовка файла с контактами может занять некоторое время.<br>Пожалуйста, не закрывайте окно и не обновляйте страницу.',
  table_description: 'У вас уже имеются ранее сгенерированные файлы контактов группы.',

  btn_export: 'Экспортировать {counts}',
  btn_export_processing: 'Экспорт"...',

  row_count_1: 'контакт',
  row_count_2: 'контакта',
  row_count_5: 'контактов',
}

import moment from 'moment';
import api from '~/api';
import * as uRelate from '~/utils/relate';
import ObserverApi from '~/utils/ObserverApi';
import { getClientSenderOnePromises } from '~/store/clientsender';
import { onlyUnique } from '~/utils/functions';
import {
  ChatStatusResolved,
  EmitterClient,
} from '~/components/Chat/ServiceChat';
import { getUserOnePromises } from '~/store/company/user';
import type { ActionTree, MutationTree } from 'vuex';
import type { RootState } from '..';

export const state = (): State => ({
  wsConnect: false,
  setting: {
    tenant_id: '',
    contact_group_id: '',
    assign_user_id: '',
    auto_response_enabled: false,
    auto_response_text: '',
    reassign_notify_enabled: false,
    reassign_notify_text: '',
    fast_answers: [],
  },
  counters: {
    all: { all: null, user: null },
    status_resolved: { all: null, user: null },
    assigned_me: { all: null, user: null },
  },
  chatNewMessageAlerts: true,
  type: 'all',
  baseCurrentPage: 1,
  base: [],
  list: {},
});

export type State = {
  wsConnect: boolean;
  setting: {
    tenant_id: string;
    contact_group_id: string;
    assign_user_id: string;
    auto_response_enabled: boolean;
    auto_response_text: string;
    reassign_notify_enabled: boolean;
    reassign_notify_text: string;
    fast_answers: [];
  };
  counters: {
    all: { all: null | number; user: null | number };
    status_resolved: { all: null | number; user: null | number };
    assigned_me: { all: null | number; user: null | number };
  };
  chatNewMessageAlerts: boolean;
  type: 'all' | 'favorite' | 'assigned_me' | 'status_resolved' | 'unread';
  baseCurrentPage: number;
  base: [];
  list: {};
};

export const getters = {};

export const mutations: MutationTree<State> = {
  setWsConnect(store, enabled: boolean) {
    store.wsConnect = enabled;
  },
  setSetting(store, setting) {
    store.setting = Object.assign(store.setting, setting);
  },
  setChatNewMessageAlerts(store, is) {
    store.chatNewMessageAlerts = is;
  },
  setCounters(store, { name, counters }) {
    store.counters[name] = { ...store.counters[name], ...counters };
  },
  readMessage(store, { chat_id }) {
    const chat = store.list[chat_id];
    if (!chat) return;

    let count_new_messages = chat.count_new_messages - 1;
    if (count_new_messages < 0) count_new_messages = 0;

    store.list = {
      ...store.list,
      [chat_id]: { ...store.list[chat_id], count_new_messages },
    };
  },
  addChats(store, chats) {
    chats.forEach((chat) => {
      store.list = {
        ...store.list,
        [chat.id]: chat,
      };
    });
  },
  setType(store, type) {
    store.type = type;
  },
  setBaseCurrentPage(store, currentPage) {
    store.baseCurrentPage = currentPage;
  },
  resetChatsToBase(store) {
    store.base = [];
  },
  addChatsToBase(store, chats) {
    store.base = chats;
  },
};

export const actions: ActionTree<State, RootState> = {
  async setting({ commit }) {
    const { data } = await api.chat.chat.setting.get();

    commit('setSetting', data);
  },
  async chatNewMessageAlerts({ dispatch, commit, state, rootState }) {
    await new Promise((resolve) => {
      const check = () => {
        if (
          rootState.auth &&
          rootState.auth.tenant &&
          rootState.auth.tenant.id
        ) {
          resolve();
        } else {
          setTimeout(check);
        }
      };
      check();
    });

    dispatch(
      'trailer/setting/slim/one',
      {
        entity_id: rootState.auth.tenant.id,
        key: 'company_notification_chat_new_message_alerts',
      },
      { root: true }
    )
      .then(({ value }) => {
        commit('setChatNewMessageAlerts', value === 'true');
      })
      .catch(() => {
        commit('setChatNewMessageAlerts', true);
      });
  },
  async list(
    { dispatch, commit, state, rootState },
    {
      page = 1,
      pagesize = 10,
      sort = '',
      favorite = false,
      observer = null,
      relate = [],
      ...options
    } = {}
  ) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    let apiCall;

    if (favorite) {
      apiCall = dispatch(
        'trailer/setting/list',
        {
          key: `favorite.${rootState.auth.user.id}`,
          entity_type: 'chat',
          page,
          pagesize,
          sort: 'created_at desc',
        },
        { root: true }
      ).then(async ({ items, totalRows }) => {
        const promises = items.map(({ entity_id }) =>
          api.chat.chat
            .get(entity_id, true)
            .then(({ data }) => ({ ...data }))
            .catch(() => null)
        );
        items = (await Promise.all(promises)).filter((r) => r !== null);
        return {
          body: items,
          meta: { total_entries_size: totalRows },
        };
      });
    } else {
      if (options.query && options.query.length) {
        apiCall = api.chat.chat
          .search(page, pagesize, '', options)
          .then(async ({ data }) => {
            const body = await Promise.all(
              data.body.messages.map((message_last) =>
                api.chat.chat
                  .get(message_last.chat_id, true)
                  .then(({ data }) => ({
                    ...data,
                    message_last,
                    _is_search: true,
                  }))
              )
            );

            return {
              body,
              meta: { total_entries_size: data.meta.total_entries_size },
            };
          });
      } else {
        apiCall = api.chat.chat
          .list(page, pagesize, '', options)
          .then(({ data }) => data);
      }
    }

    return apiCall
      .then(async ({ body, meta: { total_entries_size = 0 } = {} }) => {
        //console.info('api.chat.chat.list | body = ', body, ' | total_entries_size = ', total_entries_size);
        let items = body || [];
        items = items.map((item) => {
          if (!item._rowVariant) {
            item._rowVariant = '';
          }
          if (!item.count_new_messages) {
            item._rowVariant += ' bg-light';
          }
          return item;
        });
        const promises = [];
        if (uRelate.include(uRelate.CLIENT_SENDER, observer.relate)) {
          promises.push(
            ...items.map((item) =>
              getClientSenderOnePromises(observer, item, 'clientsender_id')
            )
          );
        }
        if (uRelate.include(uRelate.USER, observer.relate)) {
          promises.push(
            ...items.map((item) =>
              getUserOnePromises(observer, item, 'user_id')
            )
          );
        }
        await Promise.all(promises);
        commit('addChats', items);
        return {
          items,
          totalRows: total_entries_size,
        };
      })
      .catch((error) => {
        console.error('api.chat.chat.list | error = ', error);
        throw error;
      });
  },
  one(
    { dispatch, commit, state, rootState },
    { id, cache = false, observer = null, relate = [] } = {}
  ) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.chat.chat
      .get(id, cache)
      .then(async (response) => {
        //console.info('api.chat.chat.get | response = ', response.data);
        const item = response.data || {};

        const promises = [];
        if (uRelate.include(uRelate.CLIENT_SENDER, observer.relate)) {
          promises.push(
            getClientSenderOnePromises(observer, item, 'clientsender_id')
          );
        }
        if (uRelate.include(uRelate.USER, observer.relate)) {
          promises.push(getUserOnePromises(observer, item, 'user_id'));
        }
        await Promise.all(promises);
        commit('addChats', [item]);
        return item;
      })
      .catch((error) => {
        console.error('api.chat.chat.get | error = ', error);
        throw error;
      });
  },
  start(store, data) {
    return api.chat.chat
      .start(data)
      .then((response) => {
        //console.info('api.chat.chat.start | response = ', response.data);
        return response.data;
      })
      .catch((error) => {
        console.error('api.chat.chat.start | error = ', error);
        throw error;
      });
  },
  async addMessageToBase(
    { dispatch, commit, state, rootState },
    { chat_id, message: message_last }
  ) {
    let find = state.list[chat_id];
    if (!find) {
      find = await dispatch('one', { id: chat_id, cache: true });
    }
    find = { ...find, message_last };
    if (message_last.emitter === EmitterClient) {
      find.count_new_messages += 1;
    }
    commit('addChats', [find]);
    if (state.baseCurrentPage === 1) {
      dispatch('addChatsToBase', { chats: [find] });
    }
  },
  updateCounters({ commit, state }) {
    return Promise.all([
      api.chat.chat
        .counters()
        .then((response) => {
          //console.info('updateCounters | api.chat.chat.counters | response = ', response.data);
          commit('setCounters', { name: 'all', ...response.data });
          return response.data;
        }),
      api.chat.chat
        .counters({ filter_status: ChatStatusResolved })
        .then((response) => {
          //console.info('updateCounters | api.chat.chat.counters | response = ', response.data);
          commit('setCounters', { name: 'status_resolved', ...response.data });
          return response.data;
        }),
      api.chat.chat
        .counters({ filter_assigned: 'me' })
        .then((response) => {
          //console.info('updateCounters | api.chat.chat.counters | response = ', response.data);
          commit('setCounters', { name: 'assigned_me', ...response.data });
          return response.data;
        }),
    ]);
  },
  addChatsToBase({ commit, state }, { chats = [], reset = false } = {}) {
    reset && commit('resetChatsToBase');
    commit(
      'addChatsToBase',
      (state.base || [])
        .concat(chats.map((c) => c.id))
        .filter(onlyUnique)
        .sort(sortChat(state.list))
        .slice(0, 10)
    );
  },
  assign({ commit, state }, { chat_id, user_id } = {}) {
    return Promise.all([
      user_id === 'reset'
        ? Promise.resolve({})
        : api.entities.user.get(user_id).then(({ data }) => data),
      api.chat.chat.assign(chat_id, user_id),
    ]).then(([user, _]) => ({
      user_id: user_id === 'reset' ? '' : user_id,
      user,
    }));
  },
};

export const sortChat = (chats) => (a_id, b_id) => {
  const a = chats[a_id];
  const b = chats[b_id];

  const a_created_at = a.message_last
    ? a.message_last.created_at
    : a.created_at;
  const b_created_at = b.message_last
    ? b.message_last.created_at
    : b.created_at;

  const a_count_new_messages = !!a.count_new_messages;
  const b_count_new_messages = !!b.count_new_messages;

  if (a_count_new_messages !== b_count_new_messages) {
    return a_count_new_messages < b_count_new_messages ? 1 : -1;
  }

  if (a_created_at === b_created_at) {
    return 0;
  }

  const a_created_at_utc = moment(a_created_at).utc();
  const b_created_at_utc = moment(b_created_at).utc();

  return a_created_at_utc < b_created_at_utc ? 1 : -1;
};

export default {
  number_1: 'номер',
  number_2: 'номера',
  number_5: 'номеров',

  delete_1: 'удалено',
  delete_2: 'удалено',
  delete_5: 'удалено',

  double_1: 'дубль',
  double_2: 'дубля',
  double_5: 'дублей',

  result_1: 'результат',
  result_2: 'результата',
  result_5: 'результатов',

  row_1: 'строка',
  row_2: 'строки',
  row_5: 'строк',

  recipient_1: 'контакт',
  recipient_2: 'контакта',
  recipient_5: 'контактов',
};

<template>
  <b-dropdown
    class="widget-select-project"
    toggle-class="btn btn-outline-primary border-transparent dropdown-toggle"
  >
    <template slot="button-content">
      <b><i class="icon-folder-open mr-1" /></b>
      <span>{{ selectProjectName }}</span>
    </template>
    <b-dropdown-item
      v-if="!isDisabledSetSelectProjectNull"
      :to="getRoute()"
      exact-active-class="active-done"
      @click.native="setSelectProject('')"
    >
      <i class="icon-folder3" />
      {{ $t('project.widget_select_project.all') }}
    </b-dropdown-item>
    <b-dropdown-item
      v-else
      class="disabled"
    >
      <i class="icon-folder3" />
      {{ $t('project.widget_select_project.all') }}
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      v-for="project of $store.state.projects"
      :key="`project-${project.id}`"
      :to="getRoute(project.id)"
    >
      <i
        v-if="bgColor(project)"
        class="icon-folder2"
        :style="{color: bgColor(project)}"
      />
      <i
        v-else
        class="icon-folder4"
      />
      {{ project.name }}
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item :to="{name: 'company-settings'}">
      <i class="icon-folder" />
      {{ $t('project.widget_select_project.manager') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'WidgetSelectProject',
  computed: {
    selectProjectName () {
      try {
        return this.$store.state.projects[this.$store.state.select_project].name;
      } catch (e) {
        return this.$t('project.widget_select_project.all');
      }
    },
    isDisabledSetSelectProjectNull () {
      return ['project', 'project-project_id-show', 'project-project_id-edit'].includes(this.$route.name);
    },
  },
  watch: {
    '$route' () {
      const { project_id } = this.$route.params;
      if (!project_id || project_id === this.projectIdLS() || this.isDisabledSetSelectProjectNull) { return; }
      this.setSelectProject(project_id);
    },
  },
  created () {
    if (process.browser) {
      let project_id = this.projectIdLS();
      if (this.$route.params.project_id && project_id !== this.$route.params.project_id) {
        project_id = this.$route.params.project_id;
      }
      this.$store.commit('setSelectProject', project_id);
    }
  },
  methods: {
    getRoute (project_id) {
      let { name } = this.$route;
      const params = JSON.parse(JSON.stringify(this.$route.params));
      const { query } = this.$route;
      if (project_id) {
        params.project_id = project_id;
        if (!/^project-project_id-/.test(name)) {
          name = 'project-project_id-' + name;
        }
      } else {
        delete params.project_id;
        if (/^project-project_id-/.test(name)) {
          name = name.substr('project-project_id-'.length, name.length);
        }
      }
      return { name, params, query };
    },
    setSelectProject (project_id) {
      this.$store.dispatch('setSelectProject', project_id);
    },
    bgColor (project) {
      try {
        return project.trailer.style.bg_color.value;
      } catch (e) {
        return null;
      }
    },
    projectIdLS () {
      if (process.browser) {
        return window.localStorage.getItem('selectProjectId.' + this.$store.state.auth.user.id) || '';
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>

</style>

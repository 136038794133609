import modal_create_link from '~/locales/ru/Template/EmojioneArea/TemplateShortLink/ModalCreateLink';
import modal_static_link from '~/locales/ru/Template/EmojioneArea/TemplateShortLink/ModalStaticLink';

export default {
  title_btn: 'Короткая ссылка',

  create_link_btn: 'Создать ссылку',
  statistic_link_btn: 'Статическая ссылка',
  dynamic_link_btn: 'Динамическая ссылка',

  modal_create_link,
  modal_static_link,
}

<template>
  <div v-if="(show && !isOnboardingDone)">
    <div class="card card-body mb-4 alpha-primary border-primary">
      <div class="align-items-center">
        <div class="mb-3 media-body text-center text-md-left">
          <h6 class="media-title font-weight-semibold">
            {{ $t('widget.alert.title') }}
          </h6>
          {{ $t('widget.alert.description') }}
        </div>
        <nuxt-link
          :to="{name: 'sender-create'}"
          class="btn btn-block btn-primary border-primary"
        >
          {{ $t('widget.alert.btn_submit') }}
          <i class="icon-arrow-right22 ml-1" />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'WidgetCompanyFill',
  data: () => ({
    clientSenderCount: -1,
  }),
  computed: {
    show () {
      return !(/^(onboarding|sender-create)/.test(this.$route.name));
    },
    step () {
      if (this.$store.state.auth?.tenant?.location_country?.length === 0) {
        return 1;
      }
      if (this.$store.state.widget.balance.loading) {
        return false;
      }
      if (this.$store.getters['widget/balance/emptyContracts']) {
        return 2;
      }
      if (this.clientSenderCount === 0) {
        return 3;
      }
      return false;
    },
    acceptTermsAndConditionsActionTo () {
      return (this.$store.state.locale === 'ru')
        ? 'https://store.messaggio.com/ru/offer/'
        : 'https://store.messaggio.com/offer/';
    },
    isOnboardingDone () {
      return this.$store.getters['auth/isOnboardingDone'];
    },
  },
  created () {
    Promise.all([
      api.documents.clientsender
        .list(1, 1, '', {})
        .then(({ data: { Meta: { TotalSize = 0 } = {} } = {} } = {}) => {
          this.clientSenderCount = TotalSize;
        })
        .catch(e => {
          if (e.response && e.response.status && e.response.status === 404) {
            this.clientSenderCount = 0;
          } else {
            console.error('WidgetAlertCompanyFill | api.documents.clientsender.list | e = ', e);
          }
        }),
    ]);
  },
};
</script>

import Vue from 'vue';

export const events = new Vue();

class Installer {
  install(Vue, options) {
    Vue.component('widget-otp', () => import('./ModalOTP'));
    Vue.prototype.$otp = (options, callback) => {
      events.$emit('open', {...options, callback});
    };
  }
}

export default new Installer();

export default {
  channel: 'Канал',
  channel_any: 'Viber',
  channel_rcs: 'Rcs',
  client_sender_id: 'Відправник',
  client_sender_id_placeholder: 'Оберіть відправник',
  phone: 'номер телефону',
  phone_placeholder: 'Вкажите номер телефону',
  submit: 'Надіслати код прив`язання',
  submit_rsc: 'Connect RCS phone',

  error_verifications_phone_session_exist: 'Ви зможете повторно запросити код через {duration} секунд.',
}

var LayoutLanguage;
(function(LayoutLanguage2) {
  LayoutLanguage2["RU"] = "ru";
  LayoutLanguage2["EN"] = "en";
  LayoutLanguage2["UK"] = "uk";
})(LayoutLanguage || (LayoutLanguage = {}));
var LayoutDevice;
(function(LayoutDevice2) {
  LayoutDevice2["PHONE"] = "phone";
  LayoutDevice2["ALL"] = "all";
})(LayoutDevice || (LayoutDevice = {}));
export {
  LayoutDevice,
  LayoutLanguage
};

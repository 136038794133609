export default {
  brand: 'Brand',
  media_agency: 'Media agency',
  local_business: 'Local Business',
  financial_institution: 'Financial Institution',
  community_organization: 'Community organization',
  governmental_non_governmental_organization: 'Governmental / Non-Governmental Organization',
  other: 'Other',
  publisher: 'Publisher',
  commerce: 'Commerce',
  ecommerce: 'E-Commerce',
  real_estate_and_construction_companies: 'Real estate and construction companies',
  it_and_internet_companies: 'IT and internet companies',
  education: 'Education',
  healthcare: 'Healthcare',
  transportation_services: 'Transportation services',
}

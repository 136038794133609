import table from '~/locales/ru/Company/Favorite/table';

export default {
  title: 'Избранное',

  favorite_empty: 'У вас пока ничего нет в избранном.',
  favorite_empty_project_one_you_can: 'Вы можете добавить в избранное',
  favorite_empty_project_one_create: '',
  favorite_empty_project_one_create_after: 'любой элемент в системе - просто отметьте его звездочкой.',

  table,
}

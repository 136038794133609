export default {
  sending: 'Отправка...',
  canceled: 'Отмена',
  completed: 'Завершена!',
  view_statistics: 'Посмотреть статистику',

  contacts_1: 'контакт',
  contacts_2: 'контакта',
  contacts_5: 'контактов',

  abort_sending: 'Отменить рассылку',
  abort_sending_confirm: 'Вы уверены что хотите отменить рассылку "{name}"?',
  abort_sending_success: 'Рассылка "{name}" отменена.',
}

import table from '~/locales/en/Company/ActionHistory/table';

export default {
  title: 'Action history',
  description: '<p>Activity history shows the activity of users in your account.</p>' +
    '<p>Select the period of activity you are interested in and, if necessary, set filters by user or by section of Messaggio Dashboard.</p>',

  table,

  services_template: 'Template',
  services_group_contact: 'Group contact',

  created_at_from_placeholder: 'Date from',
  created_at_to_placeholder: 'Date to',
  user_placeholder: 'User',
  entity_placeholder: 'Service',
}

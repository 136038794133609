export default {
  label_btn: 'Profile pic',
  btn_modal_open: 'Update profile picture',

  modal_title: 'Profile pic update',
  modal_description: 'Upload an image or generate a random one.',

  file_title: 'Upload',
  file_label: 'Select file to upload',
  file_description: 'Accepted formats: *.jpg, *.png. Max file size 4Mb.',

  generate_title: 'Generate',
  btn_generate: 'Generate random pic',

  widget_otp_name: 'User profile pic update',

  submit: 'Save changes',
  saving_changes: 'Saving changes...',
}

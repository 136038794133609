import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';
import { promiseTriesTimeout } from '~/utils/functions';

export const state = () => ({
  statsTenants: {
    loading: false,
    error: null,
    data: {},
  },
  files: {},
});

export const getters = {};

export const mutations = {
  setStatsTenantsLoading (state, loading) {
    state.statsTenants.loading = loading;
  },
  setStatsTenantsError (state, error) {
    state.statsTenants.error = error;
  },
  setStatsTenantsData (state, data) {
    state.statsTenants.data = data;
  },
  files (state, files) {
    for (const file of files) {
      // console.info('file = ', file)
      const key = `${file.category}/${file.shortHash}/${file.name}`;
      state.files[key] = file;
    }
  },
};

export const actions = {
  list ({ dispatch, commit, state, rootState }, {
    category,
    tenant = false,
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    if (sort.length > 0) { sort += ','; }
    sort += 'id';

    if (tenant) {
      options.type = 'tenant';
    }

    return api.drive.file.list(category, page, pagesize, sort, options).then(async response => {
      if (typeof response.data === 'string') {
        throw 'Data is string, not object';
      }
      const items = response.data.content || [];
      commit('files', items);
      return {
        items,
        totalRows: response.data.stats.count,
      };
    }).catch(error => {
      console.error('api.drive.file.list | error = ', error);
      throw error;
    });
  },
  one ({ dispatch, commit, state, rootState }, { category, shortHash, name, observer = null, relate = [] } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch, relate); }

    return api.drive.file.get(category, shortHash, name).then(async response => {
      // console.info('api.drive.file.get | response = ', response.data);
      const item = response.data || {};
      commit('files', [item]);
      return item;
    }).catch(error => {
      console.error('api.drive.file.get | error = ', error);
      throw error;
    });
  },
  upload ({ commit, dispatch }, { category, file, onUploadProgress, ...meta }) {
    // console.info('upload({commit, dispatch}, {', category, ', ', file, ', ', meta, '})');

    const fileForm = new FormData();
    fileForm.append('data', file);
    fileForm.append('meta', JSON.stringify(meta));

    return api.saga.drive.file.upload(category, fileForm, onUploadProgress).then(response => {
      // console.info('api.drive.file.upload | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.drive.file.upload | error = ', error);
      throw error;
    });
  },
  edit ({ commit, state }, { category, shortHash, name, ...data }) {
    return api.drive.file.edit(category, shortHash, name, data).then(response => {
      // console.info('api.drive.file.edit | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.drive.file.edit | error = ', error);
      throw error;
    });
  },
  download ({ commit, state }, { category, shortHash, name, onDownloadProgress }) {
    return api.drive.file
      .download(category, shortHash, name, onDownloadProgress)
      .then(({ data }) => data)
      .catch(error => {
        console.error('api.drive.file.download | error = ', error);
        throw error;
      });
  },
  delete ({ commit, state }, { category, shortHash, name }) {
    return api.drive.file.delete(category, shortHash, name).then(response => {
      // console.info('api.drive.file.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.drive.file.delete | error = ', error);
      throw error;
    });
  },
  statsTenants ({ commit, state, rootState }) {
    if (state.statsTenants.loading) { return Promise.resolve(); }

    const load = () => {
      // console.info('document.statsTenants | api.drive.file.stat | fetch');
      const tenant_id = rootState.auth.user?.tenant_id;
      if (!tenant_id) {
        return Promise.reject(new Error('Tenant id is not defined'));
      }
      commit('setStatsTenantsLoading', true);
      return api.drive.file.stats('tenants', tenant_id)
        .then(response => {
          // console.info('document.statsTenants | api.drive.file.stat | data = ', response.data);
          commit('setStatsTenantsData', response.data);
        })
        .catch(error => {
          // console.error('document.statsTenants | api.drive.file.stat | error = ', error);
          commit('setStatsTenantsError', error);
        })
        .finally(() => {
          commit('setStatsTenantsLoading', false);
        });
    };

    return promiseTriesTimeout(load, 10, 1000);
  },
};

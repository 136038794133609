import preview from '~/locales/en/DispatchDocument/Launch/Preview';
import scheduled from '~/locales/en/DispatchDocument/Launch/Scheduled';
import smooth from '~/locales/en/DispatchDocument/Launch/Smooth';
import duplicates_remove from '~/locales/en/DispatchDocument/Launch/DuplicatesRemove';
import smart from '~/locales/en/DispatchDocument/Launch/Smart';
import modal_schedule_dispatch from '~/locales/en/DispatchDocument/Launch/ModalScheduleDispatch';

export default {
  title: 'Prepare to launch',
  description:
    'While the dispatch will be sending, you will not be able to abort the process.<br>Please, confirm that the information below is correct.',

  price_description:
    'The amount will be blocked for the duration of the dispatch. <br/> Part of the funds will be returned to the balance as the message statuses are updated.',

  launch_settings: 'Launch settings',
  coming_soon: 'Coming soon',

  btn_schedule_dispatch: 'Schedule the dispatch',
  btn_launch_the_dispatch: 'Launch the dispatch',

  submit_info: 'Preparing the dispatch',
  submit_success: 'Sending start',
  submit_schedule_success: 'Sending start',

  preview,
  scheduled,
  smooth,
  duplicates_remove,
  smart,
  modal_schedule_dispatch,
};

export default {
  title: 'Export contacts of {name}',
  description: 'Preparing an archive with contacts may take some time.<br>Please do not close the window or refresh the page.',
  table_description: 'You already have previously generated contact lists for this group.',

  btn_export: 'Export {counts}',
  btn_export_processing: 'Processing...',

  row_count_1: 'contact',
  row_count_2: 'contacts',
  row_count_5: 'contacts',
}

export default {
  number_1: 'number',
  number_2: 'numbers',
  number_5: 'numbers',

  delete_1: 'deleted',
  delete_2: 'deleteds',
  delete_5: 'deleteds',

  double_1: 'double',
  double_2: 'doubles',
  double_5: 'doubles',

  result_1: 'result',
  result_2: 'results',
  result_5: 'results',

  row_1: 'row',
  row_2: 'rows',
  row_5: 'rows',

  recipient_1: 'recipient',
  recipient_2: 'recipients',
  recipient_5: 'recipients',
}

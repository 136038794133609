import form from '~/locales/uk/DispatchDocument/form';
import table from '~/locales/uk/DispatchDocument/table';
import table_statistic from '~/locales/uk/DispatchDocument/TableStatistic';
import show from '~/locales/uk/DispatchDocument/show';
import modal_start_sending from '~/locales/uk/DispatchDocument/ModalStartSending';
import card from '~/locales/uk/DispatchDocument/Card';
import select_recipient from '~/locales/uk/DispatchDocument/SelectRecipient';
import progress_dispatch from '~/locales/uk/DispatchDocument/ProgressDispatch';
import widget_statistic from '~/locales/uk/DispatchDocument/WidgetStatistic';
import launch from '~/locales/uk/DispatchDocument/Launch';
import calendar from '~/locales/uk/DispatchDocument/Calendar';
import widget from '~/locales/uk/DispatchDocument/Widget';

export default {
  title: 'Розсилки',
  title_create: 'Створити розсилку',
  title_show: 'Розсилка {name}',
  title_edit: 'Розсилка {name}',

  btn_regular_dispatch: 'Стандартна розсилка',
  btn_ab_dispatch: 'A/B тестування розсилки',
  btn_fast_dispatch: 'Односторінкова розсилка',

  filter_q_placeholder: 'Назва',

  tab_all: 'Всі розсилки',
  tab_scheduled: 'Заплановані',
  tab_deleted: 'Видалені',

  tab_scheduled_body: 'Відсутні заплановані розсилки',
  tab_deleted_body: 'Немає видалених розсилок',

  description: 'Створіть і запустіть розсилку повідомлень: оберіть активний шаблон і групи контактів. Ви можете створювати многоканальні розсилки, якщо активували шаблон з повідомленнями для кажного каналу, котрий хочете використовувати.<br><br>' +
    'Ви можете повторно відправити завершену розсилку, запланувати її на бажаний день і час, а також видалити чернеткиб що не використовуються.',

  view_glossary: 'Глосарій',

  dispatch_empty: 'В проекті немає розсилок.',
  dispatch_empty_project_more_you_can: 'Ви можете',
  dispatch_empty_project_more_create: 'створити розсилку',
  dispatch_empty_project_more_or: 'або',
  dispatch_empty_project_more_select_all: 'переключити фільтр проектів на Всі проекти.',
  dispatch_empty_project_more_select_all_after: '',
  dispatch_empty_project_one_you_can: 'Ви можете',
  dispatch_empty_project_one_create: 'створити розсилку,',
  dispatch_empty_project_one_create_after: 'Щоб масово відправити повідомлення.',

  dispatch_scheduled_empty: 'Немає запланованих розсилок.',

  form,
  table,
  table_statistic,
  modal_start_sending,
  show,
  card,
  select_recipient,
  progress_dispatch,
  widget_statistic,
  launch,
  calendar,
  widget,
};

export default {
  company: {
    title: 'Укажите информацию о вашей компании',
    name: {
      title: 'Краткое название юридического лица',
    },
    reg_num: {
      title: 'Регистрационный номер юридического лица',
    },
    vat: {
      title: 'EU VAT номер',
      description: 'Укажите европейский VAT номер вашей компании, напр. DE123456789 или оставьте поле пустым.',
    },
  },
  certificate: {
    name: {
      title: 'Подтверждение регистрации юридического лица',
      description: 'Приложите документ, подтверждающий регистрацию вашего юридического лица.',
      subdescription: 'Поддерживаемые форматы файлов:',
    },
  },
  address: {
    title: 'Адрес',
    city: {
      title: 'Город',
    },
    state: {
      title: 'Штат',
    },
    index: {
      title: 'Почтовый индекс',
    },
    legal: {
      title: 'Адрес',
    },
  },
  tax: {
    title: 'Вид налогообложения',
    system: {
      title: 'Ваша схема налогообложения',
      USNO: 'УСНО (Упрощенка)',
      OSNO: 'ОСНО (Плательщик НДС)',
    },
    ogrn: {
      title: 'ОГРН (регистрационный номер)',
    },
    inn: {
      title: 'ИНН',
    },
    kpp: {
      title: 'КПП',
    },
  },
  confirm: {
    text: 'Я, законный представитель {company_name}, ознакомился с Договором присоединения Messaggio LTD. и принимаю его условия.',
  },
};

import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { resetAuthToken, setAuthToken } from '~/utils/auth';

import type { State as ChatState } from '~/store/chat';
import type { State as AuthState } from '~/store/auth';

export const state = () => ({
  locale: 'en',
  sidebar: false,
  page: {
    title: '',
    subtitle: '',
  },
  select_project: '',
  projectsLoading: true,
  projects: {},
  isError404: false,
});

export type State = {
  locale: string;
  sidebar: boolean;
  page: {
    title: string;
    subtitle: string;
  };
  select_project: string;
  projectsLoading: boolean;
  projects: Record<
    string,
    {
      id: string;
      tenant_id: string;
      name: string;
      api_type: string;
      description: string;
      bulk_login: string;
      bulk_secret_key: string;
      callback_url: string;
      callback_url_two_way: string;
      web_page: string;
      meta_info: {
        active_templates: number;
        active_client_senders: number;
        completed_dispatches: number;
      };
      authorized_ip: null;
      skip_template_moderation: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: string;
      trailer: {
        style: {
          bg_color: {
            value: string;
          };
        };
      };
      counters: {
        clientsender: number;
        'dispatch-document': number;
        template: number;
      };
    }
  >;
  isError404: boolean;
};

export const mutations: MutationTree<State> = {
  setLang(state, locale: string) {
    state.locale = locale;
    window?.localStorage?.setItem('locale', locale);
  },
  toggleSidebar(state) {
    state.sidebar = !state.sidebar;
  },
  setPageTitle(
    state,
    { title, subtitle = '' }: { title: string; subtitle: string }
  ) {
    state.page.title = title;
    state.page.subtitle = subtitle;
  },
  setPageSubTitle(state, subtitle: string) {
    state.page.subtitle = subtitle;
  },
  projectsLoading(state, loading: boolean) {
    state.projectsLoading = loading;
  },
  projects(state, projects: Record<string, unknown>) {
    state.projects = projects;
  },
  projectDelete(state, id: string) {
    Vue.delete(state.projects, id);
  },
  projectsCounters(state, { project_id, type, count }) {
    try {
      state.projects[project_id].counters[type] = count;
    } catch (e) {
      console.error(
        'projectsCounters(state, {',
        project_id,
        ', ',
        type,
        ', ',
        count,
        '}) | Error: ',
        e
      );
    }
  },
  setSelectProject(state, projectId: string) {
    state.select_project = projectId;
  },
  setError404(state, e: boolean) {
    state.isError404 = e;
  },
};

export const getters: GetterTree<State, RootState> = {
  projectExist: (state) =>
    state.projectsLoading ||
    (!state.projectsLoading && !!Object.keys(state.projects).length),
};

export const actions: ActionTree<State, RootState> = {
  nuxtServerInit({ rootState, dispatch }) {
    return new Promise((resolve) => {
      const token = rootState.auth.token;
      if (!token) {
        resetAuthToken();
        resolve(false);
        return;
      }

      setAuthToken(token);
      dispatch('auth/fetch')
        .then(() => resolve(true))
        .catch((error) => {
          console.error('Provided token is invalid:', error);
          resetAuthToken();
          resolve(false);
        });
    });
  },
  async projects({ commit, dispatch }) {
    commit('projectsLoading', true);

    const countersKey = ['clientsender', 'dispatch-document', 'template'];
    const countersKeyNum = countersKey.reduce((r, key) => {
      r[key] = null;
      return r;
    }, {});
    const { items: projects } = await dispatch('project/list', {
      pagesize: 999999,
      root: true,
    });

    const projectsObj = projects.reduce((r, project) => {
      r[project.id] = {
        ...project,
        counters: { ...countersKeyNum },
      };
      return r;
    }, {});
    commit('projects', projectsObj);
    commit('projectsLoading', false);

    // not await
    Promise.all(
      projects.reduce((r, { id: project_id }) => {
        r.push(
          countersKey.map((type) =>
            dispatch('projectsCounters', { project_id, type })
          )
        );
        return r;
      }, [])
    );

    return projectsObj;
  },
  projectsCounters({ commit, dispatch }, { project_id, type }) {
    return dispatch(
      type + '/list',
      { page: 1, pagesize: 1, project_id },
      { root: true }
    )
      .then((result) =>
        commit('projectsCounters', {
          project_id,
          type,
          count: result.totalRows,
        })
      )
      .catch(() => commit('projectsCounters', { project_id, type, count: 0 }));
  },
  setSelectProject({ commit, rootState }, project_id) {
    commit('setSelectProject', project_id);
    window?.localStorage?.setItem(
      'selectProjectId.' + rootState.auth.user.id,
      project_id
    );
  },
};

export type RootState = State & {
  chat: ChatState;
  auth: AuthState;
};

export default {
  name: 'Назва',
  name_placeholder: 'Новий проект',
  description: 'Опис',
  description_placeholder: 'Проект для мультиканальних розсилок',
  web_page: 'Адреса сайту',
  web_page_placeholder: 'http://myproject.com',
  bg_color: 'Колір мітки проекту',

  tab_use_api: 'Налаштування API',
  bulk_login: 'Логін',
  bulk_secret_key: 'Секретний ключ',
  bulk_idp: 'IDP',
  api_type: 'Тип API',
  callback_url: 'Callback URL для статусів доставки',
  callback_url_two_way: 'Callback URL для вхідних повідомлень',
  authorized_ip: 'Список дозволених IP для API запитів',
  authorized_ip_placeholder: "192.168.0.1\n192.168.0.2",
  authorized_ip_help: 'Вкажіть кожну IP адресу на новому рядку',

  save_success: 'Готово',
}

<template>

  <button type="button" class="btn btn-outline bg-primary text-orange-800 btn-icon ml-2"
          @click="setFavorite(item, false)" :disabled="submit">
    <i class="icon-star-full2"></i>
  </button>

</template>

<script>
  import MixinTable from '~/components/Mixin/MixinTable';
  import {catchDispatch} from '~/utils/form';

  export default {
    mixins: [MixinTable],
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        submit: false,
      };
    },
    methods: {
      async setFavorite(item, is) {
        const {id, entity_name: name} = item;

        if (!confirm(this.$t('company.favorite.table.delete_confirm', {name}))) {
          return false;
        }
        this.submit = true;
        try {
          await this.$store.dispatch('trailer/setting/delete', {id});
          window.PNotify.alert({
            title: this.$t('home.success'),
            text: this.$t('company.favorite.table.delete_success', {name}),
            addClass: 'bg-success',
          });
          this.$emit('onSetFavorite', true);
        } catch (e) {
          catchDispatch(this, e);
        }
        this.submit = false;
      },
    },
  };
</script>

<style scoped>

</style>

import enabled from '~/locales/en/Company/User/AuthOTP/Enabled';
import disabled from '~/locales/en/Company/User/AuthOTP/Disabled';

export default {
  title: 'Google Auth two-factor authentication',
  description: 'You can protect your account by setting up two-factor authentication. Download the Google Authenticator app and scan the QR code below to connect with Messaggio Dashboard.<br>' +
    '<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img alt="Get it on App Store" src="https://developer.apple.com//app-store/marketing/guidelines/images/badge-example-preferred_2x.png" width="160"></a>' +
    '<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" width="200"></a>' +
    '<br>When entering your account, in addition to the password, a temporary code will be requested from the Google Auth application.',

  enabled,
  disabled,
}

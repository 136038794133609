import form from '~/locales/uk/Chat/form';
import table from '~/locales/uk/Chat/table';
import dump from '~/locales/uk/Chat/export';
import last from '~/locales/uk/Chat/Last';
import manager from '~/locales/uk/Chat/Manager';
import menu from '~/locales/uk/Chat/Menu';
import recent from '~/locales/uk/Chat/Recent';

export default {
  title: 'Чати',

  filter_q_placeholder: 'Пошук...',
  reply: 'Відповісти',
  assigned_to: 'Призначити',
  btn_menu_assigned_to: 'Призначити чати',

  assigned_to_processing: 'Assigned to in process',
  assigned_to_success: 'Assigned to success',

  go_to_chat_dashboard: 'Перейти до Chat Dashboard',

  form,
  table,
  dump,
  last,
  manager,
  menu,
  recent,

  attach: {
    btn_select: 'Прикріпити медіа',
  },
  attach_image: {
    btn_select: 'Прикріпити зображення',
    title: 'Виберіть зображення',
    dropzone: {
      description: '<p> Ви можете завантажити зображення, що відповідає вимогам:</p><p> Максимальний розмір зображення 1600×1200px, до 2Мб.</p><p> Або вибрати із галереї раніше завантажених зображень.</p>',
    },
  },
  attach_video: {
    btn_select: 'Прикріпити відео',
    title: 'Виберіть відео',
    dropzone: {
      description: '<p> Ви можете завантажити відео, яке відповідає вимогам:</p><p> Розмір відео до 200 Мб, формат 3gp, m4v, mov, mp4.</p><p> Або вибрати із раніше завантажених відео.</p>',
    },
  },
  attach_document: {
    btn_select: 'Прикріпити документ',
    title: 'Виберіть документ',
    dropzone: {
      description: {
        viber: 'Ви можете завантажити файл, який відповідає вимогам:<br>' +
          'Максимальний розмір файлу 200Мб<br>' +
          'Розширення файлу: .doc, .docx, .rtf, .dot, .dotx, .odt ,odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx',
        whatsapp: 'Ви можете завантажити файл, який відповідає вимогам:<br>' +
          'Максимальний розмір файлу 100Мб',
        bottelegram: 'Ви можете завантажити файл, який відповідає вимогам: Максимальний розмір файлу 50Мб, розширення файлу .gif; .pdf; .zip, або вибрати із галереї раніше завантажених файлів.',
      },
    },
  },
};

import field from '~/locales/en/Widget/Field';
import filter from '~/locales/en/Widget/Filter';
import table_slot from '~/locales/en/Widget/TableSlot';

import content_card from '~/locales/en/Widget/ContentCard';
import search from '~/locales/en/Widget/Search';
import statistic_chart from '~/locales/en/Widget/StatisticChart';
import breadcrumb from '~/locales/en/Widget/Breadcrumb';
import company_head from '~/locales/en/Widget/CompanyHead';
import date_range from '~/locales/en/Widget/DateRange';
import phone_dropdown from '~/locales/en/Widget/PhoneDropdown';
import file_styled from '~/locales/en/Widget/FileStyled';
import select_multi from '~/locales/en/Widget/SelectMulti';
import show_list from '~/locales/en/Widget/ShowList';
import complete_profile from '~/locales/en/Widget/CompleteProfile';
import testphone_add from '~/locales/en/Widget/TestphoneAdd';
import group_contact_add from '~/locales/en/Widget/GroupContactAdd';
import favorite from '~/locales/en/Widget/Favorite';
import w404 from '~/locales/en/Widget/404';
import balance_ended from '~/locales/en/Widget/BalanceEnded';
import chat_ws_connect from '~/locales/en/Widget/ChatWsConnect';
import alert from '~/locales/en/Widget/Alert';
import select_charset from '~/locales/en/Widget/SelectCharset';
import otp from '~/locales/en/Widget/OTP';

export default {
  field,
  filter,
  table_slot,

  content_card,
  search,
  statistic_chart,
  breadcrumb,
  company_head,
  date_range,
  file_styled,
  phone_dropdown,
  select_multi,
  show_list,
  complete_profile,
  testphone_add,
  group_contact_add,
  favorite,
  w404,
  balance_ended,
  chat_ws_connect,
  alert,
  select_charset,
  otp,
}

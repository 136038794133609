export default {
  entity_name: 'Ім`я',
  entity_type: 'Тип',

  type_project: 'Проект',
  type_clientsender: 'Відправник',
  type_contact_whitelist: 'Група контактів',
  type_contact_blacklist: 'Група контактів',
  type_template: 'Шаблон',
  type_dispatch: 'Розсилка',

  loading: 'Loading Senders...',
  delete_confirm: 'Ви впевнені що хочете видалити з обраного "{name}"?',
  delete_success: 'Обране "{name}" видалено.',
}

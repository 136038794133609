import { ActionTree, GetterTree, MutationTree } from 'vuex';
import api from '~/api';
import { RootState } from '~/store';
import * as uRelate from '~/utils/relate';
import ObserverApi from '~/utils/ObserverApi';
import { onlyUnique } from '~/utils/functions';
import { getChannelOnePromises } from '~/store/channel-and-gate/channel-and-gate/channel';
import { getProjectOnePromises } from '~/store/project';
import { getClientSenderOnePromises } from '~/store/clientsender';
import { getDispatchDocumentDestinationListPromises } from '~/store/dispatch-document/destination';
import { getDispatchDocumentContactOnePromises, getDispatchDocumentContentOnePromises } from '~/store/dispatch-document';
import { TYPE_WHITELIST } from '@/components/GroupContact/Form/FormGroupContactComponent';
import { channelBaseSort } from '@/components/Template/Form/Channel/_ChannelTemplateFormTemplateComponent';

export const state = () => ({});

export type FormDispatchDocumentState = ReturnType<typeof state>

export const getters: GetterTree<FormDispatchDocumentState, RootState> = {};

export const mutations: MutationTree<FormDispatchDocumentState> = {};

export const actions: ActionTree<FormDispatchDocumentState, RootState> = {
  async fetch ({ dispatch }, { id, checkRedirectToShow = true, observer = null, relate = [] }) {
    // console.info('store/dispatch-document/form/fetch');

    relate = [
      uRelate.PROJECT,
      uRelate.DISPATCH_DOCUMENT_CONTENT,
      uRelate.DISPATCH_DOCUMENT_CONTACT,
      uRelate.DISPATCH_DOCUMENT_DESTINATION,
      uRelate.GROUP_CONTACT,
      uRelate.CHANNEL,
      uRelate.CLIENT_SENDER,
    ];

    const dispatchDocument = await dispatch('dispatch-document/one', { id }, { root: true });

    const { status, deleted_at } = dispatchDocument;
    if (checkRedirectToShow && (
      (deleted_at && deleted_at.length) ||
      (status !== 'new')
    )) { throw new Error('REDIRECT_TO_SHOW'); }

    if (!observer) { observer = new ObserverApi(dispatch, relate); }
    const { project_id } = dispatchDocument;

    await Promise.all([
      getProjectOnePromises(observer, dispatchDocument, project_id),
      getDispatchDocumentContactOnePromises(observer, dispatchDocument, id),
      getDispatchDocumentContentOnePromises(observer, dispatchDocument, id),
      getDispatchDocumentDestinationListPromises(observer, dispatchDocument, id),
    ]);

    const groupContact = {
      recipient: [],
      exception: [],
    };
    const groupContacts = dispatchDocument.contact.reduce((r, { contact: { id, type } }) => {
      const key = (type === TYPE_WHITELIST) ? 'recipient' : 'exception';
      r[key].push(id);
      return r;
    }, groupContact);
    for (const [key, value] of Object.entries(groupContacts)) {
      groupContact[key] = value.filter(onlyUnique);
    }
    dispatchDocument.group_contact = groupContact;

    dispatchDocument.contentsAndDestinations = await dispatch(
      'dispatch-document/getContentsByTemplate',
      {
        observer,
        tid: dispatchDocument.content.template_id,
        relate: [
          uRelate.CHANNEL,
          uRelate.CLIENT_SENDER,
        ],
      },
      { root: true },
    ).then(contentsList => {
      const { content, destinations: { items: destinations } } = dispatchDocument;
      const contents = [
        { ...content, send: true },
        ...destinations.map(({ content_data: c }) => ({ ...c, send: true })),
      ];
      const contentsExist = contents.map(content => content.id);
      contents.push(
        ...contentsList
          .filter(({ id }) => !contentsExist.includes(id))
          .map(c => ({ ...c, send: false })),
      );
      return contents;
    });

    return dispatchDocument;
  },
  getTemplate ({ dispatch }, { id, observer = null } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch); }
    return api.documents.template.get(id)
      .then(async ({ data }) => {
        // console.info('dispatch-document/form/getTemplates | templates.list | data = ', response.data);
        data.contents = await templateContents(observer, data);
        return data;
      })
      .catch(error => {
        console.error('dispatch-document/form/getTemplate | template.get | ', error);
        throw error;
      });
  },
  getTemplates ({ dispatch }, { pid, page = 1, observer = null, ...options } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch); }
    return api.documents.project.templates
      .list(pid, page, 5, '', { status: 'active', ...options })
      .then(async response => {
        // console.info('dispatch-document/form/getTemplates | templates.list | data = ', response.data);
        const items = await Promise.all((response.data.Body || []).map(async template => ({
          ...template,
          contents: await templateContents(observer, template),
        })));
        return {
          items,
          totalRows: response.data.Meta ? response.data.Meta.TotalSize : 10,
        };
      })
      .catch(error => {
        console.error('dispatch-document/form/getTemplates | templates.list | ', error);
        throw error;
      });
  },
  getTemplatesFavorite ({ dispatch, rootState }, { page = 1, observer = null } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }
    const pagesize = 5;
    return dispatch(
      'trailer/setting/list',
      { key: `favorite.${rootState.auth.user.id}`, entity_type: 'template', page, pagesize, sort: 'created_at desc' },
      { root: true },
    ).then(async ({ items, totalRows }) => {
      // console.info('getTemplatesFavorite | items = ', items);
      items = await Promise.all(items.map(({ entity_id }) =>
        api.documents.template.get(entity_id)
          .then(async ({ data }) => ({
            ...data,
            contents: await templateContents(observer, data),
          }))
          .catch(() => null),
      )).then(items => items.filter(item => !!item));
      return { items, totalRows };
    }).catch(error => {
      console.error('dispatch-document/form/getContents | templates.list | ', error);
      throw error;
    });
  },
  create (_, {
    name: dispatch_name, project_id,
    group_contact,
    content_id, destinationCreate = [],
  }) {
    const contact_ids = [
      ...group_contact.recipient,
      ...group_contact.exception,
    ];

    const contents = [
      { content_id, weight: 0 },
      ...destinationCreate,
    ];

    return api.saga.dispatch
      .post({ dispatch_name, project_id, contact_ids, contents })
      .then(({ data: { dispatch_document: { id } } }) => ({ id }));
  },
  async edit (_, { id, status, groupContactDelete, ...data }) {
    // console.info('dispatch-document/form/edit | data = ', data);
    // console.info('dispatch-document/form/edit | groupContactDelete = ', groupContactDelete);

    await api.documents.dispatch_document.put(id, { name: data.name });

    const promises = [
      api.documents.dispatch_document.attach.content(id, data.content_id),
    ];

    if (status === 'new') {
      if (groupContactDelete.length) {
        promises.push(
          api.documents.dispatch_document.contacts.detach(id, groupContactDelete),
        );
      }

      promises.push(
        api.documents.dispatch_document.contacts.attach(id, [
          ...data.group_contact.recipient,
          ...data.group_contact.exception,
        ].filter(onlyUnique)),
      );
    }

    // Promise
    await Promise.all(promises).then(_ => {
      // console.info('dispatch-document/form/edit | Promise | result = ', result);
    });

    // Content destination
    if (data.destinationDelete.length) {
      await Promise.all(
        data.destinationDelete.map(destination =>
          api.documents.dispatch_destination.delete(destination.id),
        ),
      ).then(_ => {
        // console.info('dispatch-document/form/edit | Promise destinationDelete | result = ', result);
      });
    }

    if (data.destinationUpdate.length) {
      await Promise.all(
        data.destinationUpdate.map(destination =>
          api.documents.dispatch_destination.put(destination.destination_id, destination),
        ),
      ).then(_ => {
        // console.info('dispatch-document/form/edit | Promise destinationUpdate | result = ', result);
      });
    }

    if (data.destinationCreate.length) {
      await Promise.all(
        data.destinationCreate.map(destination =>
          api.documents.dispatch_destination.post(destination)
            .then(result => api.documents.dispatch_document.attach.destinations(id, result.data.id)),
        ),
      ).then(_ => {
        // console.info('dispatch-document/form/edit | Promise destinationCreate | result = ', result);
      });
    }

    return { id };
  },
};

function templateContents (observer, template) {
  return api.documents.template.content
    .list(template.id, 1, 9999)
    .then(async response => {
      const contents = response.data.Body || [];
      await Promise.all([
        ...contents.map(item => getClientSenderOnePromises(observer, item)),
        ...contents.map(item => getChannelOnePromises(observer, item, item.channel_id)),
        ...contents.map(item => api.documents.template.content.layout.list(template.id, item.id).then(r => {
          item.layout = r.data;
        })),
      ]);
      channelBaseSort.forEach(name => {
        const contentIndex = contents.findIndex(({ channel: { name: channelName } }) => channelName === name);
        if (contentIndex === -1) { return; }
        const content = contents[contentIndex];
        contents.splice(contentIndex, 1);
        contents.unshift(content);
      });
      return contents;
    });
}

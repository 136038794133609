import form from './form';
import table from './table';
import show from './show';

export default {
  title: 'Короткие ссылки',
  title_create: 'Короткая ссылка',
  title_show: 'Короткая ссылка {name}',
  title_edit: 'Короткая ссылка {name}',

  link_empty: 'В вашем аккаунте нет коротких ссылок.',
  link_empty_project_one_you_can: '',
  link_empty_project_one_create: 'Создать короткую ссылку',
  link_empty_project_one_create_after: 'использовать его в шаблоне сообщения и подсчитывать конверсии при отправке.',

  create: 'Создать ссылку',

  filter_q_placeholder: 'Type to filter...',

  form,
  table,
  show,
}

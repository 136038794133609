export default {
  title: 'Статистика з {from} по {to}',

  screen_full: 'На весь екран',
  screen_normal: 'Нормальний вид',
  show_request: 'Показати запит',
  show_group_by_day: 'Показати по дням',

  show_request_title: 'Запит',
  show_group_by_day_title: 'По дням',

  empty_data: 'Оберіть період і фільтри статистики щоб відобразити графік',
}
//$t('statistic.chart.title')

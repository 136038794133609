import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.entities.tenant.put(id, data).then(response => {
      //console.info('api.entities.tenant.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.tenant.put | error = ', error);
      throw error;
    });
  },
  status({commit, state}, {id, status}) {
    return api.entities.tenant.status(id, status).then(response => {
      //console.info('api.entities.tenant.status | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.put | error = ', error);
      throw error;
    });
  },
};

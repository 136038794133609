import { ErrorMessaggioOTP } from "./errors.js";
function AxiosResponseUse(response) {
  var _a;
  if (((_a = response == null ? void 0 : response.data) == null ? void 0 : _a.error) === "one time password is required") {
    throw new ErrorMessaggioOTP();
  }
  return response;
}
export {
  AxiosResponseUse
};

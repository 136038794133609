export default {
  dispatche: 'Start',
  project: 'Project',
  template: 'Template',
  name: 'Name',
  dispatch: 'Dispatch',
  status: 'Status',
  info_phones_in_lists: 'Recipients',
  channel: 'Channel',
  contacts_dispatches_info: 'Quantity',
  statistic: 'Dispatch statistics',
  group_contact_name: 'Contact groups',

  dispatches_modal_title: 'Start dispatch',
  dispatches_modal_content_title: 'Preparation',
  dispatches_modal_content_body: 'Start dispatch {name} to {number} recipients?',
  dispatches_modal_content_body2: 'All selected Contact Groups will be matched and cleared.',

  phones_in_lists: 'Contacts for dispatch',
  duplicate_removed: 'Duplicates deleted',
  deleted_by_blacklists: 'Excluded',
  dispatch_send: 'Finally to dispatch',

  dispatche_success: 'Dispatch started',

  dispatches_pnotify_title: 'Start dispatch',

  work_in_progress_title: 'Work in progress?',
  work_in_progress_description: 'Complete the creation of the Dispatch',
  work_in_progress_btn_start_now: 'Start now',

  start_title: 'Let\'s start!',
  start_description: 'Set the messaging parameters or start sending',
  start_btn_setting: 'Dispatch settings',

  scheduled_title: 'The dispatch is scheduled!',
  scheduled_time: 'Launch at {time}',
  scheduled_btn: 'Dispatch settings',

  price: {
    title: 'Dispatch costs',
    description: 'Approximate calculation',
    description_completed: 'Refined as message statuses are updated',
    error: {
      _504: '' +
        '<p>Request timed out. Estimated cost calculation is temporarily unavailable.</p>' +
        '<p class="mb-0">Dispatch can be launched regardless of this error. Please try using less contacts in the dispatch or try again later.</p>',
    },
  },
};

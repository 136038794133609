export default {
  loading: 'Loading statistics...',

  title: 'Dispatch statistics',
  empty_data: 'There is no messaging activity for a while',

  message_status_count_impossible_to_deliver: 'Impossible to deliver',
  message_status_count_sent: 'Sent',
  message_status_count_delivered: 'Delivered',
  message_status_count_watched: 'Seen',
}

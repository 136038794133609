import modal_verification from '~/locales/ru/Company/Testphone/ModalVerification';
import form from '~/locales/ru/Company/Testphone/form';
import form_params from '~/locales/ru/Company/Testphone/FormParams';
import table from '~/locales/ru/Company/Testphone/table';

export default {
  title: 'Тестовые телефоны',
  title_create: 'Тестовый телефон',
  title_show: 'Тестовый телефон {phone}',
  title_edit: 'Тестовый телефон {phone}',

  phone_empty: 'У вас пока нет тестовых телефонов.',
  phone_empty_project_one_you_can: 'Вы можете',
  phone_empty_project_one_create: 'привязать номера телефонов',
  phone_empty_project_one_create_after: 'к аккаунту, что тестировать шаблоны сообщений.',

  create: 'Привязать телефон',

  filter_q_placeholder: 'Type to filter...',

  modal_verification,
  form,
  form_params,
  table,
}

import form from '~/locales/uk/Project/form';
import table from '~/locales/uk/Project/table';
import show from '~/locales/uk/Project/show';
import widget_select_project from '~/locales/uk/Project/WidgetSelectProject';
import card from '~/locales/uk/Project/Card';

export default {
  title: 'Проекти',
  title_create: 'Створити проект',
  title_show: 'Проект {name}',
  title_edit: 'Проект {name}',

  clientsender: 'Відправники',
  'dispatch-document': 'Розсилки',
  template: 'Шаблони',
  application: 'Додлатки',
  all_channels: 'Канали',
  agreement_no: 'Agreement No.',

  api_type_name_none: 'Not set',

  create_title: 'Створити проект',
  create_body: 'Створюйте різні проекты за напрямами вашої активності: для бізнес розсилок, верифікацій номеру, онлайн-чатів і т.п.',
  create_btn: 'Створити проект',

  updated_at: 'Оновлено',

  form,
  table,
  show,
  widget_select_project,
  card,
};

import form from '~/locales/en/Chat/form';
import table from '~/locales/en/Chat/table';
import dump from '~/locales/en/Chat/export';
import last from '~/locales/en/Chat/Last';
import manager from '~/locales/en/Chat/Manager';
import menu from '~/locales/en/Chat/Menu';
import recent from '~/locales/en/Chat/Recent';

export default {
  title: 'Chats',

  filter_q_placeholder: 'Type to filter...',
  reply: 'Reply',
  assigned_to: 'Assigned to',
  btn_menu_assigned_to: 'Assigned chats',

  assigned_to_processing: 'Assigned to in process',
  assigned_to_success: 'Assigned to success',

  go_to_chat_dashboard: 'Go to Chat Dashboard',

  form,
  table,
  dump,
  last,
  manager,
  menu,
  recent,

  attach: {
    btn_select: 'Attach media',
  },
  attach_image: {
    btn_select: 'Attach image',
    title: 'Select an image',
    dropzone: {
      description: '<p>You can upload an image that meets the requirements:</p><p>1600x1200px maximum image size, up to 2MB.</p><p>Or choose from a gallery of previously uploaded images.</p>',
    },
  },
  attach_video: {
    btn_select: 'Attach video',
    title: 'Choose a video',
    dropzone: {
      description: '<p>You can upload a video that meets the requirements:</p><p>Video file size up to 200MB, format 3gp, m4v, mov, mp4.</p><p>Or choose from previously uploaded videos.</p>',
    },
  },
  attach_document: {
    btn_select: 'Attach document',
    title: 'Choose a document',
    dropzone: {
      description: {
        viber: 'You can upload a file that meets the requirements:<br>' +
          'Maximum file size 200Mb.<br>' +
          'File extension: .doc, .docx, .rtf, .dot, .dotx, .odt ,odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx',
        whatsapp: 'You can upload a file that meets the requirements:<br>' +
          'Maximum file size 100Mb.',
        bottelegram: 'You can upload a file that meets the requirements: Maximum file size 50Mb, file extension .gif; .pdf; .zip, or choose from a gallery of previously downloaded files.',
      },
    },
  },
};

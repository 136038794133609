export default {
  progress: {
    title: 'Канали комунікації',
  },
  left: {
    title: 'Оберіть канали розсилки',
    subtitle: 'Деякі канали потребують внесення абонентської плати за активацію імені відправника.',
    subsubtitle: 'Ми створимо чернетки заявок на реєстрацію, далі потрібно довнести необходну інформацію і поповнити баланс.',
  },
  form: {
    name: {
      title: 'Ім`я відправкника (Ви зможете відредагувати назву пізніше)',
      title_validate: 'Ім`я відправкника',
    },
    bottelegram_http_api_token: {
      title: 'HTTP API токен телеграм бота',
    },
    channels: {
      title: 'Які канали розсилки плануєте використовувати?',
      sms: {
        title: 'SMS',
        price: {
          uk: 'Від 2 000 грн. в міс.',
          en: 'Може стягуватися щомісячна плата',
          def: 'Від 5 000 руб. у міс.',
        },
      },
      viber: {
        title: 'Viber',
        price: 'Від 100 Eur в місяць',
      },
      flashcall: {
        title: 'Flashcall верифікація номеру',
        price: 'Без абонентської плати',
      },
      whatsapp: {
        title: 'WhatsApp Business API',
        price: 'Від 200 Eur в місяць',
      },
      vk: {
        title: 'ВКонтакте бізнес повідомлення',
        price: 'Без абонентскої плати',
      },
      bottelegram: {
        title: 'Telegram beta',
        price: 'Без абонентскої плати',
        http_api_token: 'HTTP API токен телеграм бота',
      },
      mobileid: {
        title: 'Mobile ID верифікація',
        price: 'Без абонентскої плати',
      },
      rcs: {
        title: 'RCS Business Messaging',
        description: 'Скоро',
      },
      verified_sms: {
        title: 'Verified SMS by Google',
        description: 'Скоро',
      },
    },
    type_integration: {
      title: 'Оберіть тип інтеграції',
      lk: 'Буду використовувати тільки особистий кабінет',
      plugin: 'Буду використовувати відправку через плагін',
      api: 'Планую інтеграцію по API',
    },
    call_me: {
      title: 'Планую інтеграцію по API',
      yes: 'Так, будь ласка.',
    },
    after_submit_success: {
      title: 'Все готово!',
      text: 'Завершіть налаштування Відправника до кінця, щоб сформувати заявку на активацію.',
    },
  },
};

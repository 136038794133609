export default {
  loading: 'Загрузка...',
  remove: 'Переместить',

  id: '',
  phone: 'Номер телефона',
  country: 'Страна',
  operator: 'Оператор',
  params: 'Параметры',

  btn_copy: 'Скопировать в группу',

  selected_length_1: 'контакт выбран',
  selected_length_2: 'контакта выбрано',
  selected_length_5: 'контактов выбрано',

  deletes_confirm1: 'Удалить {ids} ',
  deletes_confirm2: 'из группы контактов "{name}"',
  deletes_success: 'Готово',
  deletes_pnotify_title: 'Удалить контакты',

  delete_confirm: 'Вы действительно хотите удалить контакт "{name}"?',
  delete_success: 'Контакт "{name}" удален.',
}

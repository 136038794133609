import api from '~/api';
import {getProjectOnePromises} from "~/store/project";
import {getChannelOnePromises} from "~/store/channel-and-gate/channel-and-gate/channel";
import {getTemplateOnePromises} from "~/store/template/index";
import {getLayoutOnePromises} from "~/store/channel-and-gate/layout";
import {getClientSenderOnePromises} from "~/store/clientsender/index";
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10, sort = '', observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    if (sort.length > 0) sort += ',';
    sort += 'id';
    return api.documents.content.list(page, pagesize, {order_by: sort}).then(async response => {
      //console.info('api.documents.content.list | response = ', response.data);
      let items = response.data.Body || [];
      items = await Promise.all(items.map((item) => getTemplateOnePromises(observer, item)));
      items = await Promise.all(items.map((item) => getClientSenderOnePromises(observer, item)));
      items = await Promise.all(items.map((item) => getChannelOnePromises(observer, item)));
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.content.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {template_id, id, observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.documents.template.content.get(template_id, id).then(async response => {
      //console.info('api.documents.content.one | response = ', response.data);
      //response.data = await getLayoutOnePromises(observer, response.data);
      //response.data = await getClientSenderOnePromises(observer, response.data);
      //response.data = await getChannelOnePromises(observer, response.data);
      //response.data = await getTemplateOnePromises(observer, response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.content.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.content.post(data).then(response => {
      //console.info('api.documents.content.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.content.post | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.content.delete(id).then(response => {
      //console.info('api.documents.content.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.content.delete | error = ', error);
      throw error;
    });
  },
  info({commit, state}, {id}) {
    return api.contacts.dispatche.info(id).then(response => {
      //console.info('api.documents.content.info | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.content.info | error = ', error);
      throw error;
    });
  },
};

export function getContentOnePromises(observer, item, id_name = 'content_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'channel-and-gate/content/one',
        {
          template_id: item.id,
          id: item[id_name],
        },
        (content) => {
          item.content = content;
          resolve(item);
        },
      );
    } else {
      item.content = null;
      resolve(item);
    }
  });
}

export function getContactsDispatchesInfoPromises(observer, item, id_name = 'id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'channel-and-gate/content/info',
        {id: item[id_name]},
        (contacts_dispatches_info) => {
          item.contacts_dispatches_info = contacts_dispatches_info;
          resolve(item);
        },
      );
    } else {
      item.contacts_dispatches_info = null;
      resolve(item);
    }
  });
}

export default {
  tariffs: 'Messaging price list',

  general: 'Credits',
  bonus: 'Bonus',
  credit: 'Overdraft',

  credit_limit: 'Overdraft limit',
  credit_limit_used: '{percent}% used',

  service_name: 'Service',
  service_terminates_at: 'Active until',
  service_tariff: 'Billing',
  service_empty: 'There are no active services',
}

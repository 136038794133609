export default {
  title: 'Smooth sending',
  description: 'Indicate the period during which messages should be sent.',

  send_fast: 'Send fast',
  hour_1: 'hour',
  hour_2: 'hours',
  hour_5: 'hours',

  send_in: 'Send in {hour} {hour_noun}',
}

import layout_body from '~/locales/ru/Template/ContentLayout/RCS/LayoutBody';

export default {
  content_type: 'Content type',
  content_type_placeholder: '',
  content_type_text: 'Text',
  content_type_media: 'Media',
  content_type_rich_card: 'Rich card',
  content_type_rich_card_carousel: 'Rich card carousel',

  layout_body,
}

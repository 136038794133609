export default {
  dispatche: 'Разослать',
  project: 'Проект',
  template: 'Шаблон',
  name: 'Название',
  dispatch: 'Рассылка',
  status: 'Статус',
  info_phones_in_lists: 'Количество контактов',
  channel: 'Канал',
  contacts_dispatches_info: 'Количество',
  statistic: 'Статистика по рассылке',
  group_contact_name: 'Контактные группы',

  dispatches_modal_title: 'Отправить рассылку',
  dispatches_modal_content_title: 'Подготовка',
  dispatches_modal_content_body: 'Отправить рассылку {name} на {number} контактов?',
  dispatches_modal_content_body2: 'Контакты рассылки очищены от дублей и исключений.',

  phones_in_lists: 'Контактов для рассылки',
  duplicate_removed: 'Дубликатов удалено',
  deleted_by_blacklists: 'Исключено контактов',
  dispatch_send: 'Итого к рассылке',

  dispatche_success: 'Рассылка началась',

  dispatches_pnotify_title: 'Отправка рассылки',

  work_in_progress_title: 'Еще не готово?',
  work_in_progress_description: 'Завершите создание рассылки',
  work_in_progress_btn_start_now: 'Отправить рассылку',

  start_title: 'Пора начать!',
  start_description: 'Укажите настройки рассылки или отправьте прямо сейчас',
  start_btn_setting: 'Настройки рассылки',

  scheduled_title: 'Рассылка запланирована!',
  scheduled_time: 'Старт рассылки {time}',
  scheduled_btn: 'Настройки рассылки',

  price: {
    title: 'Стоимость рассылки',
    description: 'Стоимость рассчитана приблизительно',
    description_completed: 'Уточняется по мере обновления статусов сообщений',
    error: {
      _504: '' +
        '<p>Истекло время ожидания. Приблизительный расчёт временно недоступен.</p>' +
        '<p class="mb-0">Рассылку возможно запустить вне зависимости от этой ошибки. Попробуйте уменьшить количество контактов в рассылке или попробовать позже.</p>',
    },
  },
};

export default {
  title_browser: 'Login',
  title: 'Login to your account',
  oauth_loading: 'OAuth loading...',
  email_placeholder: 'E-mail Address',
  password_placeholder: 'Password',
  confirmation: 'Google Auth code',
  confirmation_placeholder: 'Google Auth code',
  forgot_password: 'Forgot password?',
  sign_in_btn: 'Sign in',
  or_sign_in_with: 'or sign in with',
  oa2_google: 'Google',
  oa2_facebook: 'Continue with Facebook',
  oa2_yandex: 'Yandex',
  by_continuing: 'By continuing, you\'re confirming that you\'ve read and accepted our <a href="https://messaggio.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>, <a href="https://messaggio.com/privacy-policy/" target="_blank">Privacy policy</a> and <a href="https://messaggio.com/terms-and-conditions/contract-for-the-provision-of-messaging-services-offer/" target="_blank">Offer</a>',
  error_max_rate_limiting_reached: 'Max rate limiting reached, please try after some time',
  error_tenant_is_block: 'Please, contact support: <a href="mailto:support@messaggio.com">support@messaggio.com</a>',

  error403: {
    title: 'Authorization error',
    description: 'Login or password is incorrect. Please, check it and try again.',
  },
};

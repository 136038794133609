import axios from "axios";
var Status;
(function(Status2) {
  Status2["NEW"] = "new";
  Status2["COMPLETED"] = "completed";
})(Status || (Status = {}));
function task(taskId) {
  return axios.get(`/api-gateway/api/v1/converter/task/${taskId}?_${(/* @__PURE__ */ new Date()).getTime()}`).then(({ data: d }) => d);
}
export {
  task
};

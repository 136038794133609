import modal_detail from '~/locales/en/Company/Service/Application/ModalDetail';

export default {
  title: 'Applications',
  items_empty: 'No applications',

  scope_name_company: "Company",
  scope_name_project: "Project",
  scope_name_sender: "Sender",
  scope_name_template: "Template",
  scope_name_contact: "Contact group",
  scope_name_dispatch: "Dispatch",
  scope_name_statistic: "Statistic",

  scope_type_read: "read",
  scope_type_readWrite: "read and write",

  btn_settings: "Settings",

  modal_detail,
}

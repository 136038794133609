export default {
  name: 'Name',
  manager: 'Manager',
  message: 'Message',
  channel: 'Channel',
  status: 'Status',
  type_image: 'Image',
  type_document: 'Document',
  type_video: 'Video',

  loading: 'Loading Senders...',
  delete_confirm: 'Are you sure you want to delete Sender "{name}"?',
  delete_success: 'Sender "{name}" was deleted.',
}

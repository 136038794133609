export default {
  title: 'Search',
  loading: 'Loading data...',
  items_empty: 'Items empty',

  see_all: 'See all {type}',
  result_total: '{result} total',

  form_submit: 'Search',

  tab_admin: 'Admin',
  tab_messages: 'Messages',
  tab_system_logs: 'System Logs',
}

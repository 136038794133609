import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10, sort = '', observer = null, ...options} = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }
    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'created_at';
    return api.short_link.domain.list(page, pagesize, sort, options).then(({data}) => {
      //console.info('api.short_link.domain.list | data = ', data);
      let items = data.Body || [];
      return {
        items,
        totalRows: data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.short_link.domain.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {id, observer = null} = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }
    return api.short_link.domain.get(id).then(({data}) => {
      //console.info('api.short_link.domain.one | data = ', data);
      return data;
    }).catch(error => {
      console.error('api.short_link.domain.one | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.short_link.domain.delete(id).then(({data}) => {
      //console.info('api.short_link.domain.delete | data = ', data);
      return data;
    }).catch(error => {
      console.error('api.short_link.domain.delete | error = ', error);
      throw error;
    });
  },
};

export function getShortLinkDomainOnePromisesV2(observer, item, callBackRelate = () => ({}), id_name = 'domain_id') {
  return new Promise(async resolve => {
    if (!item) {
      resolve(null);
      return;
    }
    if (!item[id_name] || !item[id_name].length) {
      item.domain = null;
      resolve(item);
      return;
    }
    observer.subscribeObserver(
      'short-link/domain/one',
      {id: item[id_name], callBackRelate},
      (domain) => {
        item.domain = domain;
        resolve(item);
      },
    );
  });
}

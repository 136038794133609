import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {contact_group_id, page = 1, pagesize = 10, observer = null, relate = [], ...options} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.contacts.group.dump.list(contact_group_id, page, pagesize, options).then(async response => {
      //console.info('api.contacts.group.dump.list | response = ', response.data);
      let items = response.data.Body || [];
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.contacts.group.dump.list | error = ', error);
      throw error;
    });
  },
  async exportStart({commit, state}, contact_group_id) {
    return api.contacts.group.dump.post(contact_group_id).then(response => {
      //console.info('api.contacts.group.dump.post | response = ', response);
      return response.data;
    }).catch(error => {
      console.error('api.contacts.group.dump.post | error = ', error);
      throw error;
    });
  },
  async exportProcessing({commit, state}, {contact_group_id, dump_id} = {}) {
    return api.contacts.group.dump.get(contact_group_id, dump_id).then(response => {
      //console.info('api.contacts.group.dump.get | response = ', response);
      return response.data;
    }).catch(error => {
      console.error('api.contacts.group.dump.get | error = ', error);
      throw error;
    });
  },
  download({dispatch, commit, state}, {contact_group_id, dump_id} = {}) {
    return api.contacts.group.dump.download(contact_group_id, dump_id).then(async response => {
      //console.info('api.contacts.group.dump.download | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.contacts.group.dump.download | error = ', error);
      throw error;
    });
  },
};


export default {
  name: 'Group name',
  name_placeholder: 'Contact group {name}',
  description: 'Group description',
  description_placeholder: 'My contacts',
  has_dups: 'Disable duplicate checking" (If this option is enabled, attempts to send to telephone number will be made as many times as it is specified in the contact group)',
  type: 'What kind of contacts would you like to upload?',
  tenant_id: 'Company',
  file: 'File',
  row_count: 'Quantity.',
  updated_at: 'Updated',

  save_success: 'Saved successfully',

  whitelist: 'Contacts for messaging',
  blacklist: 'Contacts for stop-list',

  knowledge_step: 'Step {step}',
  knowledge_step1_description: 'Create a contact group with needed type of contacts. Specify name and description if you want.',
  knowledge_step2_description: 'Upload a CSV file of contacts or copy-paste them to the parsing form.',
  knowledge_step3_description: 'Specify columns that contain phone numbers and additional parameters of your contacts.',
  knowledge_base_body: 'Contact groups are used to form a dispatch recipients. You can create and use as many contact groups as you want for the dispatch, and for exclusion from it.<br><br>Upload contacts with additional parameters that will be used in the final text of the message template through variables.',
  knowledge_base_btn: 'Glossary',
}

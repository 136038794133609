import login from '~/locales/uk/Permission/login';
import password_recovery from '~/locales/uk/Permission/PasswordRecovery';
import password_reset from '~/locales/uk/Permission/PasswordReset';
import registration from '~/locales/uk/Permission/registration';
import completed from '~/locales/uk/Permission/completed';
import verify_email from '~/locales/uk/Permission/VerifyEmail';

export default {
  login_link: 'Вхід',
  register_link: 'Реєстрація',

  login,
  password_recovery,
  password_reset,
  registration,
  completed,
  verify_email,

  plugin: {
    title: 'Доступно на тарифному плані Корпоративний',
    description: '<p>Ця функція доступна на тарифному плані Корпоративний.</p><p>Ви можете спробувати розширені можливості платформи Messaggio 1 місяць безкоштовно!</p>',
    more_info: 'Докладніше',
    corporate_plan: 'Корпоративний план',
  },
};

export default {
  title: 'The dispatch summary',
  description: 'The final calculated amount of recipients and estimated costs.<br><br>' +
    'Click <b><i class="icon-reload-alt"></i> Recalculate</b> button if the amount of contacts in groups has been changed.',
  recipients: 'recipients',
  will_be_reserved: 'will be reserved',
  placeholder: 'As soon as the most part of messages of the dispatch are delivered the balance will be updated.',
  duplicates_has_been: 'duplicates has been',
  cleared: 'cleared',
  contacts_has_been: 'contacts has been',
  excluded: 'excluded',
}

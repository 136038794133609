export default {
  title: 'Change password',
  title_btn: 'Change password',

  old_password: {
    label: 'Current password',
    error: {
      wrong: 'Current password is invalid.',
    },
  },
  new_password: 'New password',
  repeat_new_password: 'Repeat new password',

  ok_title: 'Change password',
  save_success: 'Saved successfully',
}

export default {
  btn_upload: 'Завантажити документ',
  btn_select: 'Обрати раніше завантажений',
  description: 'Ви можете завантажити зображення, що задовольняє вимогам: Максимальний розмір зображения 1600x1200px, до 2Мб, або обрати з галереї раніше завантажених зображень.',
  video_btn_upload: 'Завантажити відео',
  video_btn_select: 'Обрати раніше завантажений',
  video_description: 'Ви можете завантажити зображення, що задовольняє вимогам: Максимальний розмір зображення 1600x1200px, до 2Мб, або обрати з галереї раніше завантажених зображень.',

  gallery_title: 'Оберіть з документів',
  items_empty: 'Без документів',
  select_gallery: 'Вибрати раніше завантажений',

  video: {
    btn_upload: 'Завантажити документ',
    btn_select: 'Обрати раніше завантажений',
    select_gallery: 'Вибрати раніше завантажений',
    description: 'Ви можете додати відео, що відповідає наступним вимогам: розмір файлу до 200 мб, формат 3gp, m4v, mov, mp4, або обрати з раніше доданих відео.',

    error: {
      video_duration_calculation_failed: 'Не вдалось визначити тривалість відео.',
    },
  },

  viber: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Выбрать ранее загруженный',
    description: 'Ви можете завантажити файл, який відповідає вимогам: Максимальний розмір файлу 200Мб; Розширення файлу: .doc, .docx, .rtf, .dot, .dotx, .odt ,odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx.',
  },

  whatsapp: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Вибрати раніше завантажений',
    description: 'Ви можете завантажити файл, який відповідає вимогам: Максимальний розмір файлу 100Мб, або вибрати із галереї раніше завантажених файлів.',
  },

  telegram: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Вибрати раніше завантажений',
    description: 'Ви можете завантажити файл, який відповідає вимогам: Максимальний розмір файлу 50Мб, розширення файлу .gif; .pdf; .zip, або вибрати із галереї раніше завантажених файлів.',
  },
};

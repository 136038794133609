export default {
  file_add:'Додати контакти',

  q_placeholder: 'Номер телефону або параметр',

  id: 'ID',
  name: 'Назва',
  description: 'Опис',
  description_empty: 'Опис не задано',
  tenant: 'Компанія',
  row_count: 'Кількість номерів',
  type: 'Тип групи',
  status: 'Статус',

  tab_contact:'Номери телефонів',

  file_size_more:'Розмір файлу більше ніж {size}Mb.',
  file_type_support:'Формати що підтримуються: txt, csv.',

  list_phone_numbers: 'Список номерів телефонів',

  import_contacts_title: 'Ще не готово?',
  import_contacts_description: 'Імпортуйте контакти до групи',
  import_contacts_btn: 'Імпортувати контакти',

  contact_empty: 'В групі немає контактів.',
  contact_empty_project_one_you_can: 'Ви можете',
  contact_empty_project_one_create: 'імпортувати контакти',
  contact_empty_project_one_create_after: 'з додатковими параметрами, щоб створювати персоналізовані шаблони повідомлень.',

  show_more: 'Розгорнути',
  show_less: 'Згорнути',
}

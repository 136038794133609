export default {
  name: 'Имя',
  manager: 'Пользователь',
  message: 'Сообщение',
  channel: 'Канал',
  status: 'Статус',
  type_image: 'Изображение',
  type_document: 'Документ',
  type_video: 'Видео',

  loading: 'Загрузка Отправителей...',
  delete_confirm: 'Вы действительно хотите удалить Отправитель "{name}"?',
  delete_success: 'Отправитель "{name}" удален.',
}

import enabled from '~/locales/uk/Company/User/AuthOTP/Enabled';
import disabled from '~/locales/uk/Company/User/AuthOTP/Disabled';

export default {
  title: 'Двофакторна авторизація Google Auth',
  description: 'Ви можете додатково захистити свій акаунт, налаштувавши двофакторну авторизацію. Завантажте застосунок Google Authenticator і проскануйте QR код нижче, щоб встановити зв`язок.<br>' +
    '<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img alt="Get it on App Store" src="https://developer.apple.com//app-store/marketing/guidelines/images/badge-example-preferred_2x.png" width="160"></a>' +
    '<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" width="200"></a>' +
    '<br>В подальшому, при вході в особистий кабінет окрім паролю буде запитуватись тимчасовий код із застосунку Google Auth.',

  enabled,
  disabled,
};

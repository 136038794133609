import message_type_option from '~/locales/ru/ClientSender/TrailerProfile/Viber/MessageTypeOption';
import company_type_option from '~/locales/ru/ClientSender/TrailerProfile/Viber/CompanyTypeOption';
import message_volume_option from '~/locales/ru/ClientSender/TrailerProfile/Viber/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/ru/ClientSender/TrailerProfile/Viber/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/ru/ClientSender/TrailerProfile/Viber/ServiceLaunchDateOption';
import commercial_account_type_option from '~/locales/ru/ClientSender/TrailerProfile/Viber/CommercialAccountTypeOption';

export default {
  commercial_account_type_option,
  service_name_original_language: 'Название (на оригинальном языке)',
  service_name_english_language: 'Название (на английском)',
  commercial_account_type: '',
  commercial_account_type_helper: 'Коммерческий Аккаунт необходимо создать, если это ваш первый отправитель или если вы не хотите связывать этого отправителя с существующим КА.',
  website: 'Адрес сайта',
  website_helper: 'Опционально. Веб-сайт для вашего бизнеса. Должен быть действующей ссылкой с указанным протоколом. До 255 символов.',
  ca_new_description: 'Описание',
  ca_new_description_helper: '(Описание для Коммерческого Аккаунта, которое будет отображаться в приложении Viber для пользователей.) 10-1000 символов.',
  ca_new_logo_img: 'Лого КА',
  ca_new_logo_img_helper: 'Это изображение будет отображаться в результате поиска по чатам. Рекомендуемое разрешение: 256x256px.',
  ca_new_logo_chatscreen: 'Обложка КА',
  ca_new_logo_chatscreen_helper: 'Это изображение будет отображаться как чатскрин на странице с детальной информации о Вашем Комерческом Аккаунте. Рекомендуемое разрешение: 360x280px.',
  ca_exist_commercial_account_id: 'ID Коммерческого Аккаунта',
  ca_exist_commercial_account_id_helper: 'ID существующего Коммерческого Аккаунта. Имя Отправителя будет закреплено за этим КА и будет отображаться в списке доступных чатов Вашей компании.<br>Если у Вас есть Коммерческий Аккаунт и Вы не знаете его ID, обратитесь в нашу техническую поддержку.',
  description: 'Описание',
  description_helper: '',
  logo_img: 'Логотип отправителя',
  logo_img_helper: 'Это изображение будет отображаться в результате поиска по чатам и в списке чатов. Рекомендуемое разрешение: 130x130px.',
  logo_chatscreen: 'Обложка',
  logo_chatscreen_helper: 'Это изображение будет отображаться как чатскрин на странице с детальной информации о Вашем Имени Отправителя. Рекомендуемое разрешение: 360x280px.',
  business_address: 'Адрес компании',
  business_address_helper: 'Адрес Вашей компании. До 255 знаков.',
  business_phone_number: 'Номер телефона компании',
  business_phone_number_helper: 'Опционально. Номер телефона Вашей компании. Может состоять из цифр, пробелов, дефисов, знаков плюса, астерисков и круглых скобок. До 20 символов.',
  business_email: 'Email компании',
  message_type: 'Тип сообщений',
  message_type_option,
  service_registered_country: 'Страна нахождения компании',
  company_type: 'Тип компании',
  company_type_option,
  message_volume: 'Объем отправляемых сообщений в месяц',
  message_volume_option,
  service_integration_type: 'Тип интеграции',
  service_integration_type_option,
  destination_countries: 'Страны рассылки',
  service_launch_date: 'Дата запуска',
  service_launch_date_option,
  tin_company: 'ИНН (Индивидуальный налоговый номер) компании',
  sample_message_promotional: 'Пример сообщения',
  sample_message_transactional: 'Пример транзакционного сообщения',
  company_official_letter: 'Письмо на бланке организации (PDF)',
};

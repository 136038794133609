import quick_reply from '~/locales/uk/Template/ContentLayout/Whatsapp/LayoutBody/Button/QuickReply';
import url from '~/locales/uk/Template/ContentLayout/Whatsapp/LayoutBody/Button/Url';

export default {
  title: 'Кнопки',
  add_button: 'Додати дію',
  drag_helper: 'Перетягуйте елементи, щоб розташувати їх у потрібному порядку у повідомленні',
  remove: 'Видалити',
  remove_button_confirm: 'Ви впевнені, що хочете видалити кнопку?',
  save: 'Зберегти',

  quick_reply,
  url,
};

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import autoBind from "auto-bind";
class HashSum {
  constructor(file2) {
    __publicField(this, "file");
    this.file = file2;
    autoBind(this);
  }
  async get() {
    const arrayBuffer = await this.fileToArrayBuffer();
    const buffer = this.arrayBufferToBuffer(arrayBuffer);
    const hash = await this.bufferToSha256(buffer);
    return this.hexString(hash);
  }
  fileToArrayBuffer() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const readerBuffer = reader.result;
        if (readerBuffer === null) {
          return reject(new Error("reader.result is null"));
        }
        if (this.file.size < 3 * 1024 * 1024) {
          return resolve(readerBuffer);
        }
        const size = 102;
        const bufferNew = new Uint8Array(size * 2);
        bufferNew.set(new Uint8Array(readerBuffer.slice(0, size)), 0);
        bufferNew.set(new Uint8Array(readerBuffer.slice(file.size - size, file.size)), size);
        resolve(bufferNew.buffer);
      });
      reader.readAsArrayBuffer(this.file);
    });
  }
  arrayBufferToBuffer(ab) {
    const buffer = Buffer.alloc(ab.byteLength);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buffer.length; i++) {
      buffer[i] = view[i];
    }
    return buffer;
  }
  bufferToSha256(buffer) {
    return crypto.subtle.digest("SHA-256", buffer);
  }
  hexString(buffer) {
    const byteArray = new Uint8Array(buffer);
    const hexCodes = [...byteArray].map((v) => v.toString(16).padStart(2, "0"));
    return hexCodes.join("");
  }
}
export {
  HashSum
};

export default {
  company: {
    title: 'Company and management',
    name: {
      title: 'Short name of the legal entity',
    },
    fio: {
      title: 'Executive person',
    },
    short_fio: {
      title: 'Executive person, shortly',
    },
    post: {
      title: 'Position',
    },
    founding: {
      title: 'Basis of authority (Charter or power of attorney)',
      title_as: 'Select the basis of authority',
      CHARTER: 'Charter',
      TRUSTEE: 'Power of attorney',
    },
    TRUSTEE_num: {
      title: 'Power of attorney No.',
    },
    TRUSTEE_date: {
      title: 'Power of attorney issue date',
    },
  },
  address: {
    title: 'Address',
    legal: {
      title: 'Legal address',
    },
    actual: {
      title: 'Actual address',
    },
    mail: {
      title: 'Post address',
    },
    index: {
      title: 'Post code',
    },
  },
  tax: {
    title: 'Taxation system',
    system: {
      title: 'Select taxation system of your company',
      USNO: 'Not a VAT payer',
      OSNO: 'VAT payer',
    },
    ogrn: {
      title: 'Company registration number',
    },
    inn: {
      title: 'Tax number',
    },
    kpp: {
      title: 'Tax registration code (KPP)',
    },
  },
  confirm: {
    text: 'I, {company_fio}, acting on the basis of {company_founding}, have read the Message Distribution Agreement dated September 1, 2020 by Mixplat Processing LLC, including the Application for Accession, and accept its terms.',
    company: {
      founding: {
        CHARTER: 'Charter',
        TRUSTEE: 'Power of attorney',
      },
    },
  },
};

import company_fill from '~/locales/ru/Widget/Alert/CompanyFill';
import accept_terms_and_conditions from '~/locales/ru/Widget/Alert/AcceptTermsAndConditions';
import sender_create from '~/locales/ru/Widget/Alert/SenderCreate';

export default {
  title: 'Пройдите процесс настройки аккаунта, чтобы начать работу',
  description: 'Messaggio позволяет отправлять сообщения через интеграцию, по API и из личного кабинета. Но прежде, чем начать работу, необходимо заполнить профиль и подтвердить условия договора-оферты. Для активации Отправителей на выбранных каналах потребуется пополнение баланса.',
  btn_submit: 'Продолжить',

  company_fill,
  accept_terms_and_conditions,
  sender_create,
};

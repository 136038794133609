import layout_body from '~/locales/en/Template/ContentLayout/Viber/LayoutBody';

export default {
  layout_body,
  show: {
    device: {
      phone: 'Primary mobile device',
    },
  },
};

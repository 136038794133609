import modal_upload from '~/locales/uk/Company/Gallery/ModalUpload';

export default {
  title: 'Галерея',

  load_image: 'Завантажити',

  delete_confirm: 'Ви впевнені, що хочете видалити зображення?',
  delete_success: 'Зображення видалено.',

  gallery_empty: 'В галереї немає зображень.',
  gallery_empty_project_one_you_can: '',
  gallery_empty_project_one_create: 'Завантажте зображення, ',
  gallery_empty_project_one_create_after: 'яке відповідає вимогам каналу.',

  modal_upload,
};

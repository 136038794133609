import avatar from '~/locales/ru/Company/User/Avatar';
import card from '~/locales/ru/Company/User/Card';
import create from '~/locales/ru/Company/User/Create';
import edit_password from '~/locales/ru/Company/User/EditPassword';
import edit_phone from '~/locales/ru/Company/User/EditPhone';
import modal_create_user from '~/locales/ru/Company/User/ModalCreateUser';
import auth_otp from '~/locales/ru/Company/User/AuthOTP';
import table from '~/locales/ru/Company/User/table';

export default {
  title: 'Мой аккаунт',

  profile: 'Настройки профиля',
  profile_description: 'Укажите ваше имя и контактный номер телефона, чтобы служба поддержки могла оперативно связаться с вами при необходимости.',
  dashboard_appearance: 'Настройки личного кабинета',
  dashboard_appearance_description: 'Выберите язык интерфейса личного кабинета и ваш часовой пояс. Вы также будете получать уведомления на email от Messaggio на выбранном языке. Запуск запланированных рассылок происходит в соответствии с заданным часовым поясом.',
  account_access: 'Настройки аккаунта',

  first_name: 'Имя',
  second_name: 'Отчество',
  last_name: 'Фамилия',
  timezone: 'Часовой пояс',
  lang: 'Язык системы',
  phone: 'Контактный телефон',
  email: 'Email',
  tariff_plan: 'Тарифный план',
  password: 'Пароль',

  save: 'Сохранить',
  save_success: 'Готово',

  resend_verification_email_success: 'Письмо отправлено на ваш email',

  updated_at: 'Обновлено',

  verified_false_alert_warning: 'Пожалуйста, подтвердите email для доступа к возможностям аккаунта.',
  resend_verification_email: 'Отправить письмо для подтверждения email',

  index_title: 'Пользователи',
  index_description: '<p>Вы можете создать дополнительных пользователей для совместной работы с рассылками и чатами. Приглашенный пользователь должен подтвердить свой Email для доступа к возможностям платформы.</p>' +
    '<p>Если требуется разграничить права доступа и возможности для некоторых пользователей, обратитесь в Службу поддержки.</p>',

  avatar,
  card,
  create,
  edit_password,
  edit_phone,
  modal_create_user,
  auth_otp,
  table,
};

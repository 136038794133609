import company from './Company';
import account from './Account';
import offer from './Offer';
import sender from './Sender';
import email_confirmation from './EmailConfirmation';
import setting_profile from './SettingProfile';
import adding_test_number from './AddingTestNumber';

export default {
  title: 'Few questions before we start',

  previous: 'Back',
  next: 'Next',
  final: 'Done',

  company,
  account,
  offer,
  sender,
  email_confirmation,
  setting_profile,
  adding_test_number,
};

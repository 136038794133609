<template>
  <li
    :class="{'dropdown': isFolder}"
    class="nav-item"
  >
    <a
      v-if="!isFolder"
      :class="{'active': isActive, 'disabled':isDisabled}"
      :href="!isDisabled ? routerHref : '#'"
      router-json="routerJson"
      class="navbar-nav-link d-flex align-items-center flex-wrap"
      @click.prevent="goTo"
    >
      <i
        :class="[
          (model.icon || 'icon-home4'),
          (model.iconClassName || 'd-md-none d-lg-inline-block'),
        ]"
        class="mr-2 text-grey-600"
      />
      <span :class="model.labelClassName">
        {{ $t(model.name) }}
      </span>
      <i
        v-if="model.name === 'client_sender.title' && isUserFresh && clientSenderCountAllNew"
        class="icon-primitive-dot text-warning ml-1 mr-0"
      />
      <span
        v-if="model.name === 'dispatch_document.title' && dispatchScheduledCount"
        class="badge badge-pill bg-msg-info position-static ml-1"
      >
        {{ dispatchScheduledCount >= 100 ? '...' : dispatchScheduledCount }}
      </span>
      <span
        v-if="model.name === 'chat.title' && chatMessageNewCount"
        class="badge badge-pill badge-primary position-static ml-1"
      >
        {{ chatMessageNewCount >= 100 ? '...' : chatMessageNewCount }}
      </span>
      <span
        v-if="model.name === 'company.settings.title' && companySettingsBadgeCount"
        class="badge badge-pill bg-warning"
      >
        {{ companySettingsBadgeCount >= 100 ? '...' : companySettingsBadgeCount }}
      </span>
    </a>
    <a
      v-else
      :class="{'active': isActive, 'disabled': isDisabled}"
      class="navbar-nav-link dropdown-toggle"
      data-toggle="dropdown"
    >
      <i
        :class="[
          (model.icon || 'icon-home4'),
          (model.iconClassName || 'd-md-none d-lg-inline-block'),
        ]"
        class="mr-2 text-grey-600"
      />
      <span :class="model.labelClassName">
        {{ $t(model.name) }}
      </span>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <widget-menu-left2
        v-for="(model, index) in model.children"
        :key="index"
        :model="model"
      />
    </div>
  </li>
</template>

<script>
import WidgetMenuLeft2 from '~/components/Widget/WidgetMenuLeft2';
import { check2PathProjectSep } from '~/utils/functions';

export default {
  name: 'WidgetMenuLeft',
  components: {
    WidgetMenuLeft2,
  },
  props: {
    model: { type: Object, default: () => ({}) },
  },
  data () {
    return {
      open: false,
      check2PathProjectSep: check2PathProjectSep.bind(this),
    };
  },
  computed: {
    user () {
      return this.$store.state.auth.user;
    },
    tenant () {
      return this.$store.state.auth.tenant;
    },
    isFolder () {
      return this.model.children && this.model.children.length;
    },
    isDisabled () {
      if (this.model.disabled === undefined) {
        return false;
      }
      return this.model.disabled;
    },
    isActive () {
      if (this.open) {
        return true;
      }
      if (this.isFolder) {
        for (let children in this.model.children) {
          children = this.model.children[children];
          // console.info('children = ', children)
          if (children.router) {
            const props = this.$router.resolve(children.router);
            // console.info('this.$router = ', this.$route.path, props.href)
            if (this.$route.path.indexOf(props.href) === 0) {
              // console.info('this.$router = ACTIVE')
              return true;
            }
          }
        }
      }
      if (this.model.router) {
        const check1 = this.$route.name === this.router.name;
        if (check1) { return true; }

        const props = this.$router.resolve(this.router);
        // console.info('this.$router2 = ', this.$route.path, props.href)
        const check2 = (this.$route.path.indexOf(props.href) === 0 && props.href !== '/');
        if (check2) { return true; }
      }
      return false;
    },
    router () {
      let r = {};
      if (this.model.router) {
        r = {
          ...r,
          ...this.model.router,
          force: true,
        };
        if (this.model.router.project_separation) {
          r = this.check2PathProjectSep(r);
        }
      }
      return r;
    },
    routerHref () {
      return this.$router.resolve(this.router).href;
    },
    routerJson () {
      return JSON.stringify(this.router);
    },
    dispatchScheduledCount () {
      return this.$store.getters['dispatch-document/scheduled/count'];
    },
    chatMessageNewCount () {
      return this.$store.state.chat.counters.all.all || 0;
    },
    companySettingsBadgeCount () {
      let count = 0;
      if (this.user && !this.user.verified) {
        count++;
      }
      if (this.tenant && !this.tenant.location_country.length) {
        count++;
      }
      return count;
    },
    isUserFresh () {
      return this.$store.getters['auth/isUserFresh'];
    },
    clientSenderCountAllNew () {
      return this.$store.getters['clientsender/countByStatus/allNew'];
    },
  },
  watch: {
    '$route' () {
      this.open = false;
    },
  },
  methods: {
    toggle () {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },
    goTo (e) {
      if (this.isDisabled) { return; }
      // console.info('goTo = ', this.routerHref, this.$router.history.current.fullPath, this.$router.history.current.path);
      if (this.routerHref === this.$router.history.current.path) {
        // this.$router.go(this.routerHref);
        window.location = this.routerHref;
      } else {
        this.$router.push(this.router);
      }
      return false;
    },
  },
};
</script>

<style scoped>
.badge {
  height: 18.5px;
}

.navbar-nav-link {
  height: 100%;
}
</style>

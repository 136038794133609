export default {
  title: 'Create link',

  project_id: 'Project',
  name_placeholder: 'Link {time}',
  select_project: 'Project',
  select_project_title: 'Select project',
  project_id_placeholder: 'Select project',
  link_shortening: 'Link shortening',
  select_domain: 'Domain',
  domain_id: 'Domain',
  uri_long: 'Uri long',
  uri_short: 'Uri short',
  uri_long_placeholder: 'Destination URL',
  uri_short_placeholder: 'Short address',

  save_success: 'Success',

  error_uri_short_duplicate: 'Uri short duplicate',
  error_attempt_max: 'New static links limit exceeded',
  error_not_active_sender: 'Not active sender',
}

export default {
  title: 'Створити розсилку',

  select_template: 'Оберіть шаблон',
  select_project: 'Вкажіть проект, з якого потрібно обрати шаблон',

  name: 'Назва',
  name_placeholder: 'Розсилка {time}',
  tenant_id: 'Компанія',
  tenant_id_placeholder: 'Компанія ID',
  project_id: 'Проект',
  project_id_placeholder: 'Оберіть проект',
  project_id_placeholder_empty: 'Відсутні проекти з активними шаблонами',
  channel_id: 'Канал',

  template_title: 'Шаблон розсилки',
  template: 'Шаблон',
  content: 'Повідомлення',
  content_select_empty_title: 'Не обрано шаблон повідомлення',
  content_select_empty_dody: 'Оберіть шаблон повідомлення зі списку праворуч',

  load_more_templates: 'Завантажити ще',
  select_recipients: 'Оберіть отримувачів',

  group_contact: 'Групи контактів',
  group_contact_for_dispatch_document: 'Отримувачі розсилки',
  group_contact_for_exception: 'Групи для виключення з розсилки',

  content_id_empty: 'Повідомлення не вказано',
  group_contact_for_dispatch_document_empty: 'Не обрані отримувачі розсилки',

  select_another_template: 'Обрати інший шаблон',

  preview_btn: 'Попередній перегляд',
  preview_title: 'Попередній перегляд',
  preview_contents_not_select: 'Оберіть шаблон повідомлення і отримувачів розсилки',

  save_success: 'Готово',

  template_empty: 'В проекті відсутні активні шаблони.',
  template_empty_project_more_you_can: 'Ви можете',
  template_empty_project_more_create: 'створити шаблон',
  template_empty_project_more_or: 'або',
  template_empty_project_more_select_all: 'переключити фільтр проектів на Всі проекти.',
  template_empty_project_more_select_all_after: '',
  template_empty_project_one_you_can: 'Ви можете',
  template_empty_project_one_create: 'створити шаблон',
  template_empty_project_one_create_after: 'для використання в розсилці повідомлень.',
}

<template>
  <b-dropdown
    v-b-tooltip.hover
    :title="$t('widget.favorite.dropdown.btn_title')"
    variant="link"
    no-caret
    toggle-class="text-decoration-none navbar-nav-link"
    menu-class="pb-0"
    data-cy="widget-favorite-dropdown"
    @show="onShow"
    @hide="onHide">
    <template slot="button-content">
      <i class="icon-star-full2"/>
    </template>

    <b-dropdown-header style="width: 300px;">
      <span class="font-weight-semibold">
        {{ $t('widget.favorite.dropdown.recently_added') }}
      </span>
    </b-dropdown-header>

    <b-dropdown-item v-if="!table.items">
      Not data
    </b-dropdown-item>
    <b-dropdown-item
      v-for="item in table.items"
      v-else
      :key="`favorite-${item.id}`"
      :to="linkShow(item)"
      class="media mt-0">
      <div class="media-body">
        <span class="media-title text-dark font-weight-semibold">
          {{ item.entity_name || '[DEL]' }}
        </span>
        <span class="d-block text-muted font-size-sm">
          {{ $t('company.favorite.table.type_' + item.entity_type) }}
        </span>
      </div>
      <div class="ml-3 align-self-center ">
        <i
          :class="[icons[item.entity_type]]"
          class="text-dark"/>
      </div>
    </b-dropdown-item>

    <b-dropdown-text class="dropdown-content-footer bg-light">
      <nuxt-link
        :to="{name: 'company-favorite'}"
        class="text-grey mr-auto">
        {{ $t('widget.favorite.dropdown.view_all', {totalRows: table.totalRows}) }}
      </nuxt-link>
    </b-dropdown-text>

    <widget-loader v-if="table.loading"/>
  </b-dropdown>
</template>

<script>
import {catchDispatch} from '~/utils/form';
import {linkShow} from "~/components/Company/Favorite/TableFavoriteCompanyComponent.vue";

export default {
  name: 'WidgetFavoriteDropdown',
  data() {
    return {
      icons: {
        'dispatch': 'icon-drawer',
        'template': 'icon-bubbles8',
        'contact_whitelist': 'icon-address-book',
        'contact_blacklist': 'icon-address-book',
        'clientsender': 'icon-price-tags2',
        'project': 'icon-folder4',
        'drive_file': 'icon-file-empty',
      },
      table: {
        loading: false,
        items: [],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  methods: {
    linkShow,
    tableFetch() {
      if (this.table.loading) return;
      this.table.loading = true;

      this.$store
          .dispatch('company/favorite/list', {
            page: 1,
            pagesize: 5,
          })
          .then(({items, totalRows}) => {
            this.table.items = items;
            this.table.totalRows = totalRows;
          })
          .catch(e => catchDispatch(this, e))
          .finally(() => this.table.loading = false);
    },
    onShow() {
      return this.tableFetch();
    },
    onHide(e) {
      //console.info('WidgetFavoriteDropdown.onHide');
      //if (!!$(e.clickEvent.target).closest("a").length) {
      //  return true;
      //}
      //return !$(e.clickEvent.target).closest(".dropdown-menu").length;
    },
  },
};
</script>

<style>
.dropdown-header {
  color: #212529;
}
</style>

<style scoped>
.media-body {
  width: calc(100% - 50px);
}

.media-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  margin-bottom: 0;
}
</style>

var SEPARATE_SYMBOL;
(function(SEPARATE_SYMBOL2) {
  SEPARATE_SYMBOL2["COMMA"] = "comma";
  SEPARATE_SYMBOL2["SEMICOLON"] = "semicolon";
})(SEPARATE_SYMBOL || (SEPARATE_SYMBOL = {}));
var CHARSET;
(function(CHARSET2) {
  CHARSET2["UTF8"] = "utf-8";
})(CHARSET || (CHARSET = {}));
export {
  CHARSET,
  SEPARATE_SYMBOL
};

export default {
  tariffs: 'Цены на рассылку сообщений',

  general: 'Баланс',
  bonus: 'Бонус',
  credit: 'Кредит',

  credit_limit: 'Кредитный лимит',
  credit_limit_used: '{percent}% исп.',

  service_name: 'Услуга',
  service_terminates_at: 'Активна до',
  service_tariff: 'Тарификация',
  service_empty: 'Нет активных сервисов',
}

<template>
  <form
    :data-vv-scope="formName"
    class="form-horizontal"
    @submit.prevent="onSubmit"
  >
    <fieldset class="content-group">
      <template v-if="isCreate">
        <h6>{{ $t(`group_contact.form.type`) }}</h6>
        <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar">
          <li
            v-for="row in (list.type || [])"
            :key="`type-${row.val}`"
            class="nav-item"
          >
            <a
              href="#"
              class="nav-link show"
              :class="{active: itemData_.type === row.val}"
              @click.prevent="itemData_.type = row.val"
            >
              <i
                v-if="row.val === TYPE_WHITELIST"
                class="icon-user-check mr-2"
              />
              <i
                v-if="row.val === TYPE_BLACKLIST"
                class="icon-user-block mr-2"
              />
              {{ $t(`group_contact.form.${row.val}`) }}
            </a>
          </li>
        </ul>
      </template>
      <div
        :class="{'has-warning': errors.has(`${formName}.name`)}"
        class="form-group"
      >
        <label class="control-label">
          {{ $t('group_contact.form.name') }}
        </label>
        <input
          v-model="itemData_.name"
          v-validate="''"
          :data-vv-as="$t('group_contact.form.name').toLowerCase()"
          :data-vv-scope="formName"
          :placeholder="name_placeholder"
          autocomplete="off"
          class="form-control input-xlg"
          name="name"
          type="text"
        >
        <span
          v-if="errors.has(`${formName}.name`)"
          class="help-block"
          v-html="errors.first(`${formName}.name`)"
        />
      </div>
      <div class="form-group">
        <label class="control-label">
          {{ $t('group_contact.form.description') }}
        </label>
        <textarea
          v-model="itemData_.description"
          :placeholder="$t('group_contact.form.description_placeholder')"
          class="form-control"
          rows="5"
        />
      </div>
      <div v-if="isCreate" class="form-group">
        <widget-form-checkbox
          v-model="itemData_.has_dups"
          v-validate="''"
          :data-vv-scope="formName"
          name="has_dups"
          data-vv-name="has_dups"
          data-vv-value-path="itemData.has_dups"
          type="checkbox"
        >
          <span v-html="$t(`group_contact.form.has_dups`)" />
        </widget-form-checkbox>
      </div>
    </fieldset>
  </form>
</template>

<script>
import moment from 'moment';

export const TYPE_WHITELIST = 'whitelist';
export const TYPE_BLACKLIST = 'blacklist';

const list = {
  type: [
    { val: TYPE_WHITELIST, text: 'Whitelist' },
    { val: TYPE_BLACKLIST, text: 'Blacklist' },
  ],
  status: [
    { val: 'active', text: 'Active' },
    { val: 'hold', text: 'Hold' },
  ],
};

export default {
  inject: ['$validator'],
  props: {
    formName: {
      type: String,
      default: '',
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    TYPE_WHITELIST,
    TYPE_BLACKLIST,
    list,
  }),
  computed: {
    itemData_ () {
      return this.itemData;
    },
    name_placeholder () {
      return this.$t('group_contact.form.name_placeholder', { name: this.$options.filters.dateTimeHuman(moment().unix()) });
    },
  },
  methods: {
    onSubmit () {
      this.$emit('submit');
    },
  },
};
</script>

export default {
  title: 'Message template',
  title_ab: 'Message template {type}',

  tab_all: 'All message templates',
  tab_favorite: 'Favorite templates',

  select_project_title: 'Select project',
  title_select_template: 'Select template',

  draggable_description: 'Select the messages you want to send using the "Send" switch.<br><br>Drag message blocks in the desired delivery order: if message # 1 is not delivered to the contact, an attempt will be made to deliver message # 2, and so on.',

  select_project: 'Select the project with the template for dispatch',
  project_id: 'Project',
  project_id_placeholder: 'Select project',
  project_id_placeholder_empty: 'No any projects with active templates',

  select_another_template: 'Select another template',

  not_delivered_send_to: 'If not delivered, then send to:',
  templates_not_found: 'The template with the specified name was not found.',

  create: {
    description: 'Select the messages you want to send using the "Send" switch.<br><br>' +
      'Drag message blocks in the desired delivery order: if message # 1 is not ' +
      'delivered to the contact, an attempt will be made to deliver message # 2, and so on.',
  },

  send: 'Send',

  btn_create: 'Create template',

  send_template_for_moderation: 'Send template for moderation?',
};

export default {
  progress: {
    title: 'Mail confirmation',
  },
  left: {
    unVerified: {
      title: 'Welcome!',
      subtitle: '<strong>Please confirm your email address</strong>',
      subsubtitle: 'Please click on the confirmation link in the email that we have sent to your email address {email}. If you do not see the email in your inbox, please check your spam folder or enter "noreply@messaggio.com" in the search bar of your email program.',
      btn: {
        resend: 'Resend mail',
        wasResend: 'Letter sent',
      },
    },
    verified: {
      title: 'Welcome!',
      subtitle: 'Mail confirmed',
      subsubtitle: 'Click Next to proceed',
    },
  },
  form: {
    locale: {
      title: 'Interface language',
    },
  },
};

export default {
  mobileid: 'MobileID',
  healthcheck: 'HealthCheck',
  rcs: 'RCS',
  sms: 'SMS',
  whatsapp: 'WhatsApp',
  vk: 'VK',
  viber: 'Viber',
  webpush: 'Web-push',
  webchat: 'Webchat',
  flashcall: 'FlashCall',
  bottelegram: 'Telegram Beta',
};

<template>
  <a
    class="dropdown-item"
    :href="routerHref"
    @click.prevent="goTo">
    <i class="mr-2" :class="model.icon || 'icon-home4'"></i>
    {{ $t(model.name) }}
  </a>
</template>

<script>
  export default {
    name: 'widget-menu-left2',
    props: {
      model: Object,
    },
    data() {
      return {
        open: false,
      };
    },
    computed: {
      isFolder() {
        return this.model.children && this.model.children.length;
      },
      isActive() {
        if (this.open) {
          return true;
        }
        if (this.isFolder) {
          for (let children in this.model.children) {
            children = this.model.children[children];
            //console.info('children = ', children)
            if (children.router) {
              let props = this.$router.resolve(children.router);
              //console.info('this.$router = ', this.$route.path, props.href)
              if (this.$route.path.indexOf(props.href) === 0) {
                //console.info('this.$router = ACTIVE')
                return true;
              }
            }
          }
        }
        if (this.model.router) {
          const check1 = this.$route.name === this.model.router.name;
          if (check1) return true;

          let props = this.$router.resolve(this.model.router);
          //console.info('this.$router2 = ', this.$route.path, props.href)
          const check2 = (this.$route.path.indexOf(props.href) === 0 && props.href !== '/');
          if (check2) return true;
        }
        return false;
      },
      router() {
        if (this.model.router) {
          return {...this.model.router, force: true};
        } else {
          return {};
        }
      },
      routerHref() {
        return this.$router.resolve(this.router).href;
      },
    },
    methods: {
      toggle() {
        if (this.isFolder) {
          this.open = !this.open;
        }
      },
      goTo(e) {
        //console.info('goTo = ', this.routerHref, this.$router.history.current.fullPath);
        if (this.routerHref === this.$router.history.current.fullPath) {
          this.$router.go(this.routerHref);
        } else {
          this.$router.push(this.router);
        }
        return false;
      },
    },
    watch: {
      '$route'() {
        this.open = false;
      },
    },
  };
</script>

<style scoped>

</style>

import Vue from 'vue';
import ModalOTP from '~/plugins/ModalOTP';
import Permission from '~/plugins/Permission';

Vue.use(ModalOTP);
Vue.use(Permission);

Vue.component('widget-status', () => import('~/components/Widget/WidgetStatus'));
Vue.component('widget-content-card', () => import('~/components/Widget/WidgetContentCard'));
Vue.component('widget-vars-to-label', () => import('~/components/Widget/WidgetVarsToLabel'));
Vue.component('widget-loader', () => import('~/components/Widget/WidgetLoader'));
Vue.component('widget-loading', () => import('~/components/Widget/WidgetLoading'));
Vue.component('widget-file-styled', () => import('~/components/Widget/WidgetFileStyled'));
Vue.component('widget-search', () => import('~/components/Widget/WidgetSearch'));
Vue.component('b2-table', () => import('~/components/Widget/BootstrapVue/table'));
Vue.component('w-btn-save', () => import('~/components/Widget/WidgetBtnSave'));
Vue.component('w-btn-delete', () => import('~/components/Widget/WidgetBtnDelete'));
Vue.component('w-form-btn-footer', () => import('~/components/Widget/WidgetFormBtnFooter'));
Vue.component('widget-v-chart', () => import('~/components/Widget/WidgetVChart'));
Vue.component('widget-form-checkbox', () => import('~/components/Widget/Form/WidgetFormCheckbox'));
Vue.component('widget-form-radio', () => import('~/components/Widget/Form/WidgetFormRadio'));
Vue.component('select2', () => import('~/components/Widget/WidgetSelect2'));
Vue.component('actions-list', () => import('~/components/Widget/TableSlot/ActionsListTableSlotWidget'));

export default {
  title: 'Chat settings',

  group_contact: {
    modal: {
      title: 'Select a contact group',
    },
  },

  contact_group_title: 'Saving contacts to a group',
  contact_group_description: 'You can save the contacts of the subscriber who contacted you through the messenger.<br>' +
    'In the future, his name and the specified additional parameters will be displayed in the chat dialog.',
  contact_group_label: 'Save all contacts to a group:',

  assign_user_title: 'Distribution of assigments in chats',
  assign_user_description: 'Specify the user who will receive all new incoming requests in chats.<br>' +
    'If a user is not specified, chats are available for reply to anyone.',
  assign_user_label: 'Assign a new chat to a user:',

  auto_response_title: 'Automatic chat responses',
  auto_response_description: 'Specify the text that the subscriber will immediately receive in response to his request.<br>' +
    'Usually, the text of the automatic response contains a request to wait a little for the operator to respond.',
  auto_response_label: 'Automatic reply to new chat:',

  reassign_notify_description: 'Specify the text that the subscriber will receive when assigning a chat to another operator.<br>' +
    'To inform the subscriber about the change of the operator in the chat, specify the text of such notification.',
  reassign_notify_label: 'Automatic reply when changing operator in the chat:',

  fast_answers_title: 'Quick replies in chat',
  fast_answers_description: 'Specify up to 10 phrases that operators can use to quickly respond to a subscriber in the chat.<br>' +
    'Ready-made answers significantly save time at the beginning and end of the dialogue.<br><br>' +
    'Drag the lines in the order in which you plan to display the quick replies in the chat dialog.',

  save_success: 'Saved successfully',
}

import account from './account';
import app_service from './AppService';
import chat from './Chat';
import client_sender from './ClientSender';
import company from './Company';
import home from './home';
import dashboard from './Dashboard';
import dispatch_document from './DispatchDocument';
import group_contact from './GroupContact';
import profile from './profile';
import project from './Project';
import search from './Search';
import statistic from './Statistic';
import support from './support';
import template from './Template';
import widget from './Widget';
import plural from './plural';
import permission from './Permission';
import documents from './Documents';
import short_link from './ShortLink';
import onboarding from './Onboarding';

export default {
  account,
  app_service,
  chat,
  client_sender,
  company,
  dispatch_document,
  group_contact,
  home,
  dashboard,
  profile,
  project,
  search,
  statistic,
  support,
  template,
  widget,
  plural,
  permission,
  documents,
  short_link,
  onboarding,

  senders: {
    title: 'Відправники',
  },
};

export default {
  loading: 'Завантаження...',

  created_at: 'Дата',
  row_count: 'Кількість',
  status: 'Статус',
  actions: 'Дії',


}

import axios from 'axios';

declare module ApiDocumentsProject {

  export interface MetaInfo {
    active_templates: number;
    active_client_senders: number;
    completed_dispatches: number;
  }

  export interface Project {
    id: string;
    tenant_id: string;
    name: string;
    api_type: string;
    description: string;
    bulk_login: string;
    bulk_secret_key: string;
    callback_url: string;
    callback_url_two_way: string;
    web_page: string;
    meta_info: MetaInfo;
    authorized_ip?: any;
    authorized_ip_: any;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
  }

}

export default {

  list: (
    page: number = 1,
    page_size: number = 10,
    order_by: string | null = null,
    options: any,
  ) => axios.get<ApiBase.List<ApiDocumentsProject.Project>>('/documents/api/v1/projects', {
    params: {page, page_size, order_by, ...options},
  }),

  get: (
    id: string,
    cache: boolean = false,
  ) => axios.get<ApiDocumentsProject.Project>(`/documents/api/v1/projects/${id}`, {
    cache,
  }),

};

export default {
  title: 'Choose a plan',
  description: 'Messages and Sender ID subscription fee are charged additionally.',
  header: {
    choose_plan: 'Features',
    free: 'Free',
    corporate: {
      title: 'Corporate',
      price: '8',
      price_old: '9',
    },
    month1: 'month',
    month2: 'months',
    month5: 'months',
    month_sm: 'mon.',
    top_value: 'Top value',
    currency: '€',
  },
  features: {
    channels: 'Messaging Channels',
    account_users: 'Account users',
    projects: 'Projects',
    test_phones: {
      title: 'Test phones',
      tooltip: 'Test how your message displayed on your and your colleagues’ devices.',
    },
    contacts_upload: 'Contacts upload',
    message_templates: 'Message templates',
    online_chats: {
      title: 'Chats Dashboard',
      tooltip: 'The interface for messaging with clients. Optimize messaging response time and make the customer care process in messagers more organized.',
    },
    template_variables: {
      title: 'Template variables',
      tooltip: 'Variables for messaging personalization: first name, last name, date, code, etc. Personalization helps to engage users and increase the click-through rate of messages.',
    },
    api_plugin_integration: {
      title: 'API & Plugin integration',
      tooltip: 'Integration of dispatches into your system. Ready-made solutions for integration with popular CRM and CMS.',
    },
    scheduling_dispatches: {
      title: 'Schedule Start',
      tooltip: 'Specify the date and time when the dispatch should begin. Create several dispatches in advance and don\'t worry — the messages will automatically be sent at the specified time.',
    },
    smooth_sending: {
      title: 'Smooth Sending',
      tooltip: 'Indicate the period during which messages should be sent. Smooth sending helps to protect the call center from a large stream of simultaneous requests.',
    },
    best_time_delivery: {
      title: 'Delivery at the Best Time',
      tooltip: 'Send messages at the moment when the user is most likely to open it. Messaggio determines the best time to send a message based on the accumulated dispatches history to the subscriber',
    },
    ab_testing: {
      title: 'A/B-Testing',
      tooltip: 'A tool for testing hypotheses and finding effective message templates. Scale solutions that get the highest subscriber engagement.',
    },
    url_shortener: {
      title: 'URL Shortener and Click Tracking',
      tooltip: 'The URL shortener allows you to display an attractive URL without UTM tags in the message. Clicks on such links are recorded and displayed in statistics — this will help you evaluate the conversion of your dispatch.',
    },
    cascading: {
      title: 'Cascading Messaging',
      tooltip: 'Resending a message in SMS if there is no selected messenger on the user\'s device.',
    },
    action_history: {
      title: 'Action History',
      tooltip: 'The history of activity in the Messaggio dashboard by users.',
    },
    oauth2: {
      title: 'Two-Factor Authentication',
      tooltip: 'Additional protection for your account via Google Auth.',
    },
    support: {
      title: 'Priority Support',
      tooltip: 'Standard response time is 1 hour. Requests from clients with the "Corporate" plan are processed within 15 minutes.',
    },
  },
  unlimited: 'Unlimited',
  products: {
    trial: {
      title: 'Try Corporate plan 1 month for free!',
      description: 'We don\'t request bank card for trial activation.<br>No extra subscription charges during trial period.',
      btn: 'Try now',
    },
    months1: {
      title: '8 / 1',
      price: '8',
    },
    months3: {
      title: '24 / 3',
      price: '24',
    },
    months6: {
      title: '40 / 6',
      price: '40',
      free: '1 month free!',
      free_sm: '1 month free!',
    },
    months12: {
      title: '80 / 12',
      price: '80',
      free: '2 months free!',
      free_sm: '2 month free!',
    },
    btn: 'Activate',
  },
  errors: {
    B187: 'Error B187. Contact <a href="mailto:support@messaggio.com">support@messaggio.com</a>',
  },
};

import api from '~/api';
import {getProjectOnePromises} from "~/store/project";
import {getChannelOnePromises} from "~/store/channel-and-gate/channel-and-gate/channel";
import {getClientSenderOnePromises} from "~/store/clientsender/index";
import ObserverApi from "~/utils/ObserverApi";
import {arrayToObject} from "~/utils/functions";
import * as uRelate from "~/utils/relate";

export const state = () => ({
  showList: {
    loading: {},
    data: [],
  },
  createdIds: [],
});

export const getters = {};

export const mutations = {
  showListLoading(state, {project_id, loading}) {
    state.showList.loading = {...state.showList.loading, [project_id]: loading};
  },
  showListDataAdd(state, items) {
    const data1 = arrayToObject(state.showList.data);
    const data2 = arrayToObject(items);
    state.showList.data = Object.values({...data1, ...data2});
  },
  addCreatedIds(store, id) {
    store.createdIds.push(id);
  },
  deleteCreatedIds(store, id) {
    store.createdIds.splice(store.createdIds.indexOf(id), 1);
  },
};

export const actions = {
  async showListLoad({dispatch, commit, state}, {project_id, page = 1, pagesize = 5}) {
    commit('showListLoading', {project_id, loading: true});
    let result = await dispatch('list', {
      project_id,
      page, pagesize,
      relate: [
        uRelate.PROJECT,
      ],
    });
    commit('showListDataAdd', result.items);
    commit('showListLoading', {project_id, loading: false});
  },
  list({dispatch, commit, state, rootState}, {
    project_id = null,
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    if (sort.length > 0) sort += ',';
    sort += 'id';

    if (project_id !== null) {
      options['project_id'] = project_id;
    }

    return api.documents.template.list(page, pagesize, sort, options).then(async response => {
      //console.info('api.documents.template.list | response = ', response.data);
      let items = response.data.Body || [];
      const promises = [];
      if (uRelate.include(uRelate.PROJECT, observer.relate)) {
        promises.push(...items.map((item) => getProjectOnePromises(observer, item, item.project_id)));
      }
      if (uRelate.include(uRelate.TEMPLATE_CONTENT, observer.relate)) {
        promises.push(...items.map((item) => getTemplateContentOnePromises(observer, item, item.id)));
      }
      await Promise.all(promises);
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.documents.template.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state, rootState}, {id, observer = null, relate = []} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.documents.template.get(id).then(async response => {
      //console.info('api.documents.template.one | response = ', response.data);
      let item = response.data || {};
      const promises = [];
      if (uRelate.include(uRelate.PROJECT, observer.relate)) {
        promises.push(getProjectOnePromises(observer, item, item.project_id));
      }
      if (uRelate.include(uRelate.TEMPLATE_CONTENT, observer.relate)) {
        promises.push(getTemplateContentOnePromises(observer, item, item.id));
      }
      await Promise.all(promises);
      return item;
    }).catch(error => {
      console.error('api.documents.template.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.template.post(data).then(response => {
      //console.info('api.documents.template.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.template.post | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.saga.template.delete(id).then(response => {
      //console.info('api.documents.template.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.template.put | error = ', error);
      throw error;
    });
  },
  listContent({dispatch, commit, state}, {id, page = 1, pagesize = 10, observer = null, relate = []} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.documents.template.content.list(id, page, pagesize).then(async response => {
      //console.info('api.documents.template.contents | response = ', response.data);
      let items = response.data.Body || [];
      const promises = [];
      if (uRelate.include(uRelate.CHANNEL, observer.relate)) {
        promises.push(...items.map((item) => getChannelOnePromises(observer, item, item.channel_id)));
      }
      if (uRelate.include(uRelate.TEMPLATE_LAYOUT, observer.relate)) {
        promises.push(...items.map((item) => getTemplateLayoutOnePromises(observer, item, id, item.id)));
      }
      if (uRelate.include(uRelate.CLIENT_SENDER, observer.relate)) {
        promises.push(...items.map((item) => getClientSenderOnePromises(observer, item)));
      }
      await Promise.all(promises);
      return items;
    }).catch(error => {
      console.error('api.documents.template.contents | error = ', error);
      throw error;
    });
  },
  listLayout({dispatch, commit, state}, {id, cid, page = 1, pagesize = 10, observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.documents.template.content.layout.list(id, cid).then(async response => {
      //console.info('api.documents.template.layout | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.template.layout | error = ', error);
      throw error;
    });
  },
  async statusChange({dispatch, commit, state}, {template_id, contents, status}) {
    //console.info('statusChange | ', contents, ', ', status, ', ', template_id);
    await Promise.all(contents.map(content_id => api.documents.content.status.put(content_id, {status})));
    await api.documents.template.status.put(template_id, {status});
    return true;
  },
  moderation({dispatch, commit, state}, {template_id}) {
    //console.info('moderation | ', template_id);
    return api.saga.template.status.moderation(template_id).then(async response => {
      //console.info('api.saga.template.status.moderation | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.saga.template.status.moderation | error = ', error);
      throw error;
    });
  },
  statuses({commit, state}) {
    return api.documents.template.statuses.get().then(response => {
      //console.info('api.documents.dispatch_document.statuses.get | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.dispatch_document.statuses.get | error = ', error);
      throw error;
    });
  },
};

export function getTemplateOnePromises(observer, item, id) {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (id) {
      observer.subscribeObserver(
        'template/one', {id},
        template => {
          item.template = template;
          resolve(item);
        },
      );
    } else {
      item.template = null;
      resolve(item);
    }
  });
}

export function getTemplateContentOnePromises(observer, item, id) {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'template/listContent', {id},
        contents => {
          item.contents = contents;
          resolve();
        },
      );
    } else {
      item.contents = null;
      resolve();
    }
  });
}

export function getTemplateLayoutOnePromises(observer, item, id, cid) {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'template/listLayout', {id, cid},
        layout => {
          item.layout = layout;
          resolve();
        },
      );
    } else {
      item.layout = null;
      resolve();
    }
  });
}

export default {
  sending: 'Sending...',
  canceled: 'Canceled',
  completed: 'Completed!',
  view_statistics: 'View statistics',

  contacts_1: 'contact',
  contacts_2: 'contacts',
  contacts_5: 'contacts',

  abort_sending: 'Abort sending',
  abort_sending_confirm: 'Are you sure you want to abort sending "{name}"?',
  abort_sending_success: 'Dispatch "{name}" was canceled.',
}

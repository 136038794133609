export default {
  progress: {
    title: 'Настройка профиля',
  },
  left: {
    title: 'Розкажіть трохи про себе',
    subtitle: 'Розкажіть нам про свої завдання, щоб ми могли підібрати для вас найкраще рішення. Ваш вибір не вплине на обмеження функціоналу Messaggio.',
    subsubtitle: '',
  },
};

export default {
  title: 'Change phone number',
  title_btn: 'Change phone number',
  title_btn_empty: 'Link phone number',
  title_form_code_btn: 'Enter verification code',

  phone_description: 'Specify your phone number with country code.<br>You will receive a verification code in Viber app or SMS.',
  phone: 'Phone',
  phone_placeholder: 'Phone',
  phone_submit: 'Get code',

  code_description: 'Link a phone <b>{phone}</b> to the account',
  code: 'Code',
  code_placeholder: 'Code',
  code_submit: 'Link phone number',
  code_change_phone_number: 'Change the phone number for verification',

  ok_title: 'Change phone number',
  save_success: 'Saved successfully',
  save_success_exist: 'Specified phone number has been already added as test phone. No verification required.',

  delete_confirm: 'Delete phone number?',
  delete_success: 'Delete successfully',

  on_hide_confirm: 'Are you sure?',

  warning_belarus_text: 'Temporary difficulties with the delivery of authorization SMS. Please click on the "The SMS is not coming" button and contact technical support. Your account will be activated within 5-10 minutes during business hours.',
  error_invalid_verification_code: 'Invalid verification code.',
  error_verifications_phone_session_exist: 'You will be able to re-request the code in {duration} seconds.',
  error_verifications_phone_attempt_max: 'Phone verification attempts limit exceded. Please, contact support service.',
  error_verifications_phone_exist: 'The phone number already exists.',
  error_verifications_code: 'Wrong confirmation code specified. Attempt {attempt} of {attempt_max}.',
  error_verifications_code_attempt_max: 'Phone verification failed. Please, contact support service.',
  error_verifications_code_session_not_found: 'Verification code is timed out. Please, request a new code.',
}

export default {
  name: 'Название группы',
  name_placeholder: 'Группа контактов {name}',
  description: 'Описание группы',
  description_placeholder: 'Мои клиенты',
  has_dups: 'Отключить проверку на дубли (если данная опция включена, попытка отправки на данный номер будет происходить столько раз, сколько он указан в книге)',
  type: 'Какой тип контактов вы планируете загрузить?',
  message_type: 'Тип сообщения',
  tenant_id: 'Компания',
  file: 'Файл',
  row_count: 'Количество.',
  updated_at: 'Обновлено',

  message_type_tel: 'Номер телефона',

  save_success: 'Готово',

  whitelist: 'Получатели рассылки',
  blacklist: 'Контакты для стоп-листа',

  knowledge_step: 'Шаг {step}',
  knowledge_step1_description: 'Создайте группу контактов с нужным типом. Укажите имя и описание группы, если хотите.',
  knowledge_step2_description: 'Загрузите CSV файл контактов или скопируйте их в форму для обработки.',
  knowledge_step3_description: 'Задайте назначение каждой колонке: укажите колонку с номерами телефонов и колонки, содержащие параметры контактов.',
  knowledge_base_body: 'Группы контактов используются для формирования списка рассылки. Вы можете создать и использовать в рассылке сколько угодно групп контактов для рассылки, так и для исключения из неё.<br><br>Загружайте контакты с дополнительными параметрами, которые будут использованы в итоговом тексте шаблона сообщения через переменные.',
  knowledge_base_btn: 'Глоссарий',
}

import dial_action from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/DialAction';
import view_location_action from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/ViewLocationAction';
import share_location_action from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/ShareLocationAction';
import open_url_action from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/OpenUrlAction';
import create_calendar_event_action
  from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/CreateCalendarEventAction';
import reply from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/Reply';
import text_and_post_data from '~/locales/uk/Template/ContentLayout/RCS/LayoutBody/Suggestion/TextAndPostData';

export default {
  title: 'Suggestions',

  dial_action,
  view_location_action,
  share_location_action,
  open_url_action,
  create_calendar_event_action,
  reply,
  text_and_post_data,

  add_suggestion: 'Add suggested action',
  drag_helper: 'Drag items to arrange them in the desired order in the message',
  remove: 'Remove',
  remove_suggestion_confirm: 'Are you sure you want to delete suggestion?',
  save: 'Save',
};

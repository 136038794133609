export default {
  tab_sender_details: 'Профіль відправника',

  project: 'Проект',
  name_en: 'Назва англійською',
  ttl: 'TTL - час життя повідомлень',
  code_api: 'Код для API',
  channel: 'Канал',
  comparison: 'Прив`язано',
  comparison_empty: 'Не прив`язано',
  status: 'Статус',
  statuses: 'Історія статусів',
  statuses_user_id: 'Користувач',
  statuses_date: 'Дата',
  statuses_status: 'Статус',

  dispatch_document: 'Розсилки',
  template: 'Шаблони',

  work_in_progress_title: 'Ще не готово?',
  work_in_progress_description: 'Завершіть створення відправника',

  info_title_status_moderation: 'Відправник на модерації',
  info_description_status_moderation: 'Будь ласка, почекайте, доки ми перевіряємо надану інформацію про відправника.<br> Зазвичай це займає кілька робочих днів.',

  scheduler_change_client_sender_status_deact_title: 'Деактивація відправника {date}',
  scheduler_change_client_sender_status_deact_description: 'Після того, як Ім`я відправника буде деактивовано, ви не зможете використовувати його для відправки повідомлень. Щоб відмінити деактивацію звертайтесь',
  scheduler_change_client_sender_status_deact_link: 'до служби підтримки',

  not_specified: 'Не зазначено',

  ttl_d: '{d} д.',
  ttl_h: '{h} г.',
  ttl_m: '{m} хв.',
}

export default {
  btn_upload: 'Загрузить документ',
  btn_select: 'Выбрать ранее загруженный',
  description: 'Вы можете загрузить изображение, удовлетворяющее требованиям: Максимальный размер изображения 1600x1200px, до 2Мб, или выбрать из галереи ранее загруженных изображений.',
  video_btn_upload: 'Загрузить видео',
  video_btn_select: 'Выбрать ранее загруженный',
  video_description: 'Вы можете загрузить изображение, удовлетворяющее требованиям: Максимальный размер изображения 1600x1200px, до 2Мб, или выбрать из галереи ранее загруженных изображений.',

  gallery_title: 'Выберите из документов',
  items_empty: 'Без документов',
  select_gallery: 'Выбрать ранее загруженный',

  video: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Выбрать ранее загруженный',
    description: 'Вы можете загрузить видео, соответствующее следующим требованиям: размер файла до 200 Мб, формат 3gp, m4v, mov, mp4, или выбрать из ранее загруженных видео.',

    error: {
      video_duration_calculation_failed: 'Не удалось подсчитать продолжительность видео.',
    },
  },

  viber: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Выбрать ранее загруженный',
    description: 'Вы можете загрузить файл, удовлетворяющий требованиям: Максимальный размер файла 200Мб; Расширение файла: .doc, .docx, .rtf, .dot, .dotx, .odt ,odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx.',
  },

  whatsapp: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Выбрать ранее загруженный',
    description: 'Вы можете загрузить файл, удовлетворяющий требованиям: Максимальный размер файла 100Мб, или выбрать из галереи ранее загруженных файлов.',
  },

  telegram: {
    btn_upload: 'Загрузить документ',
    btn_select: 'Выбрать ранее загруженный',
    select_gallery: 'Выбрать ранее загруженный',
    description: 'Вы можете загрузить файл, удовлетворяющий требованиям: Максимальный размер файла 50Мб, расширение файла .gif; .pdf; .zip, или выбрать из галереи ранее загруженных файлов.',
  },
};

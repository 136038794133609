import form from '~/locales/ru/GroupContact/form';
import table from '~/locales/ru/GroupContact/table';
import show from '~/locales/ru/GroupContact/show';
import file_upload_modal from '~/locales/ru/GroupContact/FileUploadModal';
import contact from '~/locales/ru/GroupContact/Contact';
import card from '~/locales/ru/GroupContact/Card';
import list from '~/locales/ru/GroupContact/list';

export default {
  title: 'Группы контактов',
  title_create: 'Создать группу контактов',
  title_show: 'Группа контактов {name}',
  title_edit: 'Редактирование группы {name}',

  q_placeholder: 'Номер телефона или параметр',
  filter_q_placeholder: 'Название',

  wl_title: 'Группы контактов для рассылки',
  bl_title: 'Группы контактов для исключения',
  search_title: 'Группы контактов',

  group_contact_wl_empty: 'У вашего аккаунта нет групп контактов.',
  group_contact_wl_empty_project_one_you_can: 'Вы можете',
  group_contact_wl_empty_project_one_create: 'создать группу',
  group_contact_wl_empty_project_one_create_after: 'и импортировать в нее контакты.',

  group_contact_bl_empty: 'У вашего аккаунта нет групп контактов для исключения.',
  group_contact_bl_empty_project_one_you_can: '',
  group_contact_bl_empty_project_one_create: 'Создайте группу',
  group_contact_bl_empty_project_one_create_after: 'и загрузите контакты, которые потребуется исключить из рассылок.',

  form,
  table,
  show,
  file_upload_modal,
  contact,
  card,
  list,
}

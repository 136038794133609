export const PROJECT = 'PROJECT';
export const DISPATCH_DOCUMENT_CONTENT = 'DISPATCH_DOCUMENT_CONTENT';
export const DISPATCH_DOCUMENT_CONTACT = 'DISPATCH_DOCUMENT_CONTACT';
export const DISPATCH_DOCUMENT_INFO = 'DISPATCH_DOCUMENT_INFO';
export const DISPATCH_DOCUMENT_SCHEDULER_TASKS = 'DISPATCH_DOCUMENT_SCHEDULER_TASKS';
export const DISPATCH_DOCUMENT_DESTINATION = 'DISPATCH_DOCUMENT_DESTINATION';
export const TEMPLATE_CONTENT = 'TEMPLATE_CONTENT';
export const TEMPLATE_LAYOUT = 'TEMPLATE_LAYOUT';
export const CHANNEL = 'CHANNEL';
export const CLIENT_SENDER = 'CLIENT_SENDER';
export const CLIENT_SENDER_ROW_COUNT = 'CLIENT_SENDER_ROW_COUNT';
export const GROUP_CONTACT = 'GROUP_CONTACT';
export const TRAILER = 'TRAILER';
export const SCHEDULED = 'SCHEDULED';
export const NOTIFICATION_ACTION = 'NOTIFICATION_ACTION';
export const USER = 'USER';
export const SHORT_LINK_DOMAIN = 'SHORT_LINK_DOMAIN';

export function include(array_a, array_b) {
  if (!Array.isArray(array_a)) array_a = [array_a];
  if (!Array.isArray(array_b)) array_b = [array_b];
  return array_a.every(function (val) {
    return array_b.indexOf(val) !== -1;
  });
}

import Vue from 'vue';
import VeeValidate, {Validator} from 'vee-validate';
import en from 'vee-validate/dist/locale/en';
import ru from 'vee-validate/dist/locale/ru';

Vue.use(VeeValidate, {
  inject: false,
  locale: 'en',
});

Validator.localize('ru', ru);
Validator.localize('en', en);

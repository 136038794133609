var GroupType;
(function(GroupType2) {
  GroupType2["WHITE"] = "whitelist";
  GroupType2["BLACK"] = "blacklist";
})(GroupType || (GroupType = {}));
var GroupStatus;
(function(GroupStatus2) {
  GroupStatus2["ACTIVE"] = "active";
})(GroupStatus || (GroupStatus = {}));
var GroupContactsType;
(function(GroupContactsType2) {
  GroupContactsType2["TEL"] = "tel";
})(GroupContactsType || (GroupContactsType = {}));
export {
  GroupContactsType,
  GroupStatus,
  GroupType
};

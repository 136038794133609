export default {
  title: 'Акаунт',
  description: 'Управляйте вашим акаунтом і налаштуваннями компанії. Ви можете ствроити додаткові проекти і запросити користувачів.',

  settings: 'Налаштування',
  my_account: 'Мій акаунт',
  notification: 'Сповіщення',
  gallery: 'Галерея',
  short_link: 'Короткі посилання',
  company: 'Моя компанія',
  projects: 'Проекти',
  users: 'Користувачі',
  billing: 'Платежі',
  documents: 'Документи',
  testphone: 'Тестові телефони',
  chat: 'Чат',
  action_history: 'Істория дій',
  services: 'Застосунки',
  support: 'Служба підтримки',
  favorite: 'Обране',

  title_general: 'Основні',
  title_account: 'Акаунт',
  title_messaging: 'Розсилки',
  title_company: 'Компанія',
}

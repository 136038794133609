export default {
  title: 'Start sending the dispatch',
  description: 'While the dispatch will be sending, you will not be able to abort the process.<br>Please, confirm that the information below is correct.',

  name: 'Name',
  channel: 'Channel',
  preview: 'Preview',

  schedule_title: 'Dispatch settings',
  schedule_sending: 'Schedule sending',
  schedule_description: 'Specify the date and time when the dispatch should begin.<br>You can cancel the scheduled launch before it starts.',
  cancel_dispatch_schedule: 'Cancel scheduled sending',
  start_dispatch_schedule: 'Schedule the dispatch',
  error_scheduled_date: 'Please enter a valid future date and time',

  start_dispatch: 'Start the dispatch',

  submit_info: 'Preparing the dispatch',
  submit_progress: 'Preparing contacts for dispatch',
  submit_success: 'Sending start',
  submit_schedule_success: 'Scheduled for {time}',
  cancel_schedule_success: 'Scheduled sending was canceled',
}

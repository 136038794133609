import company_fill from '~/locales/uk/Widget/Alert/CompanyFill';
import accept_terms_and_conditions from '~/locales/uk/Widget/Alert/AcceptTermsAndConditions';
import sender_create from '~/locales/uk/Widget/Alert/SenderCreate';

export default {
  title: 'Пройдіть процес налаштування облікового запису, щоб розпочати роботу',
  description: 'Messaggio дозволяє надсилати повідомлення через інтеграцію по API та з особистого кабінету але перш ніж розпочати роботу, необхідно заповнити профіль та підтвердити умови договору-оферти. Для активації Відправників на вибраних каналах потрібне поповнення балансу.',
  btn_submit: 'Продовжити',

  company_fill,
  accept_terms_and_conditions,
  sender_create,
};

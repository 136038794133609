import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";
import {getUserOnePromises} from "~/store/company/user";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    callBackRelate,
    relate = [],
    ...options
  } = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    if (sort.length > 0) sort += ',';
    sort += 'id';

    return api.tenant_action_history.event_log.list(page, pagesize, sort, options).then(async response => {
      //console.info('api.tenant_action_history.event_log.list | response = ', response.data);
      let items = response.data.Body || [];

      if (callBackRelate) {
        await Promise.all(items.map((item) => callBackRelate(observer, item)));
      } else if (relate) {
        const promises = [];
        promises.push(...items.map((item) => getUserOnePromises(observer, item)));
        //promises.push(...items.map((item) => getEntityActionHistoryOnePromises(observer, item, 'entity_id', false)));
        await Promise.all(promises);
      }

      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.tenant_action_history.event_log.list | error = ', error);
      throw error;
    });
  },
};

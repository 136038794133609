export default {
  title_active: 'Заявка на активацію імени відпарвника',
  scheduled_date_active: 'Вкажіть день активації',
  description_active: 'Якщо заявку буде схвалено, то активація імені відправника відбудеться автоматично в зазначений день.',
  submit_active: 'Запит на активацію',
  submit_active_success: 'Відправник "{name}" надіслано на активацію.',

  title_deact: 'Заявка на відключення імені відправника',
  scheduled_date_deact: 'Вкажіть день відключення',
  description_deact: 'Якщо заявку буде схвалено, то відключення імені відправника відбудеться автоматично в зазначений день.',
  submit_deact: 'Запит на відключення',
  submit_deact_success: 'Відправник "{name}" надіслано на відключення.',

  error_scheduled_date: 'Дата активації повинна бути пізніше ніж сьогодні.',
}

export default {
  additional_settings: {
    title: 'Дополнительные настройки',
    device: {
      description: 'Вы можете специально указать на какие устройства нужно осуществить доставку Viber сообщения: на все устройства пользователя, где он авторизовался под своим аккаунтом, или только на первичное мобильное устройство.',
      phone: 'Только мобильные устройства',
      all: 'Все устройства',
    },
  },
};

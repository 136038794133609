import api from '~/api';
import {getUserOnePromises} from "~/store/company/user";
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({commit, state, dispatch}, {page = 1, pagesize = 10, sort = '', observer = null, relate = [], ...options} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    if (sort.length > 0) sort += ',';
    sort += 'id';
    return api
      .statistics.reports.list(page, pagesize, sort, options).then(async ({data}) => {
        //console.info('api.statistics.reports.list | response = ', data);
        let items = data.Body || [];

        await Promise.all(items.map(item => getUserOnePromises(observer, item)));

        return {
          items,
          totalRows: data.Meta.TotalSize,
        };
      }).catch(error => {
        console.error('api.statistics.reports.list | error = ', error);
        throw error;
      });
  },
  post({dispatch, commit, state}, request = {}) {
    return api
      .statistics.stats.post({request}).then(async response => {
        //console.info('api.statistics.stats.post | response = ', response.data);
        return response.data;
      }).catch(error => {
        console.error('api.statistics.stats.post | error = ', error);
        throw error;
      });
  },
  get({dispatch, commit, state}, stat_id) {
    return api
      .statistics.reports.get(stat_id).then(async response => {
        //console.info('api.statistics.reports.get | response = ', response.data);
        return response.data;
      }).catch(error => {
        console.error('api.statistics.reports.get | error = ', error);
        throw error;
      });
  },
  delete({commit, state}, {id}) {
    return api
      .statistics.reports.delete(id)
      .then(({data}) => data)
      .catch(error => {
        console.error('api.statistics.reports.delete | error = ', error);
        throw error;
      });
  },
};

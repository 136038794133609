export default {
  title: 'Report settings',
  ok_title: 'Apply',
  clear_title: 'Clear form',
  body: 'Select columns you need in detailed message report',
  tab_grouped_report: 'Aggregated Report',
  tab_grouped_report_body: 'Aggregated report will count messages depends on criterias below',
  tab_grouped_report_columns_label: 'Select maximum 3 database columns to aggregate the statistics',
  tab_grouped_report_statuses_label: 'Select message statuses',
  tab_detailed_report: 'Detailed Report',
  tab_custom_report: 'Custom Report',
  custom_report_body: 'Custom reports list',
  custom_report_viber_gate_senders_costs: 'Viber Gate Senders Costs',
  custom_report_client_senders_traffic_summary: 'Client Senders Traffic Summary',
  custom_report_gate_sender_traffic_summary: 'Gate Sender Traffic Summary',
}

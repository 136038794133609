export default {
  title: 'Chats',

  new_chat: 'New chat',
  description: 'In some cases, you cannot send an arbitrary text to the subscriber if he has not previously started a chat session with your business account. <br>' +
    'Sending options depend on your sender\'s messaging channel.',

  to: 'To',
  from: 'From',
  phone_number_placeholder: 'Enter phone number',
  client_sender_id_placeholder: 'Select sender',
  message_payload: 'Message',

  payload_placeholder: 'Enter your message...',
  payload_length_max: 'The message should contain less than 1,000 symbols.',
  send: 'Send',
};

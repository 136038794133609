import trailer_profile from '~/locales/uk/ClientSender/TrailerProfile';

export default {
  title: 'Створення відправника',

  name: 'Назва',
  name_placeholder_sms: 'Введіть назву англійською мовою',
  token: 'Token',
  name_en: 'Назва англійською',
  code_api: 'Код для API',
  tenant_id: 'Компанія',
  tenant_id_placeholder: 'Назва компанії',
  project_id: 'Проект',
  project_id_placeholder: 'Оберіть проект',
  channel_id: 'Канал',
  channel_id_placeholder: 'Оберіть канал',
  sender_id: 'Відправник',
  sender_profile_details: 'Дані профілю відправника',
  commercial_account_details: 'Дані Комерційного Аккаунту',
  messaging_details: 'Деталі повідомлення',
  company_details: 'Інформація про компанію',
  status: 'Статус',
  logo_change: 'Оновити логотип',
  logo_chatscreen_change: 'Оновити обкладинку',
  da_data_inn: 'ИНН, ОГРН або ОГРНИП',

  save_success: 'Готово',

  create_gate_sender: 'Створити гейт сендер?',
  create: 'Створити',
  trailer_profile,
};


import { defineComponent, computed, onMounted, ref } from 'vue';
import { useI18n, useStore } from '~/utils/hooks';
import { apiGateway } from '@messaggio/api-user';
import moment from 'moment';

export default defineComponent({
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const alias = computed(() => store.state.auth.accessLevel?.name);

    const untilDate = ref('');
    onMounted(async () => {
      if (alias.value != 'corporate') return;

      const { Body: contracts } = await apiGateway.contracts.list();

      if (!contracts?.length) return;

      const tariffPlan = await apiGateway.contracts.tariffPlan(contracts[0].id);

      untilDate.value = moment(tariffPlan.terminates_at).format('DD.MM.YY');
    });

    return {
      alias,
      untilDate,
      t,
    };
  },
});

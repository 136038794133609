export default {
  channel: 'Channel',
  channel_any: 'Viber',
  channel_rcs: 'Rcs',
  client_sender_id: 'Sender',
  client_sender_id_placeholder: 'Select sender',
  phone: 'phone number',
  phone_placeholder: 'Enter the phone number',
  submit: 'Send verification code',
  submit_rsc: 'Connect RCS phone',

  error_verifications_phone_session_exist: 'You will be able to re-request the code in {duration} seconds.',
}

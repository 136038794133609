export default {
  name: 'Отправитель',
  tenant_id: 'Компания',
  channel: 'Канал',
  status: 'Статус',

  sender_from_empty: 'Not linked',

  statistic_btn: 'Статистика',
  send_for_moderation_btn: 'Отправить на модерацию',
  deactivate_btn: 'Отключить',
  activate_btn: 'Активировать',
  in_status_send_for_moderation_btn: 'Отправить на модерацию?',

  loading: 'Загрузка...',
  delete_confirm: 'Вы действительно хотите удалить отправитель "{name}"?',
  delete_success: 'Отправитель "{name}" удален.',
}

export default {
  btn: 'Надіслати на тестовий телефон',
  btn_unavailable: 'Надіслати на тестовий телефон (Тимчасово недоступно)',

  title: 'Відправка шаблону на тестові номери',

  phone_ids_empty: 'Телефони не обрано',

  submit: 'Надіслати на тестові номери',

  send_success: 'Повідомлення було надіслано на тестовий телефон.',

  show_message_preview: 'Показати повідомлення повністю',
  hide_message_preview: 'Згорнути повідомлення',
};

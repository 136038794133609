import api from '~/api';

export const state = () => ({
  countNotVerified: 0,
});

export const getters = {};

export const mutations = {
  setCountNotVerified (state, count) {
    state.countNotVerified = count;
  },
};

export const actions = {
  list (_, { page = 1, pagesize = 10 } = {}) {
    return api.entities.user.list(page, pagesize).then(response => {
      // console.info('api.entities.user.list | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.entities.user.list | error = ', error);
      throw error;
    });
  },
  one (_, { id }) {
    return api.entities.user.get(id, true).then(response => {
      // console.info('api.entities.user.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.one | error = ', error);
      throw error;
    });
  },
  profile (_) {
    return api.entities.user.profile.get().then(response => {
      // console.info('api.entities.user.profile | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.profile | error = ', error);
      throw error;
    });
  },
  create (_, { data }) {
    data = { ...data };
    delete data.id;
    return api.entities.user.post(data).then(response => {
      // console.info('api.entities.user.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.post | error = ', error);
      throw error;
    });
  },
  async edit (_, { id, avatar_image_url, ...data }) {
    let profile;

    data = { ...data };

    const promises = [];

    if (data.lang.length) {
      promises.push(
        api.enoti.sub.changeLang({ user_id: id, language: data.lang }).then(() => {
          // console.info('api.enoti.sub.changeLang | response = ', response.data);
          return true;
        }).catch(error => {
          console.error('api.enoti.sub.changeLang | error = ', error);
          throw error;
        }),
      );
    }
    promises.push(
      api.entities.user.put(id, data).then(response => {
        // console.info('api.entities.user.put | response = ', response.data);
        profile = response.data;
        return true;
      }).catch(error => {
        console.error('api.entities.user.put | error = ', error);
        throw error;
      }),
    );

    await Promise.all(promises);

    return profile;
  },
  delete (_, { id }) {
    return api.entities.user.delete(id).then(response => {
      // console.info('api.entities.user.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.delete | error = ', error);
      throw error;
    });
  },
  changeLang (_, { user_id, language }) {
    return api.enoti.sub.changeLang({ user_id, language }).then(() => {
      // console.info('api.enoti.sub.changeLang | response = ', response.data);
      return true;
    }).catch(error => {
      console.error('api.enoti.sub.changeLang | error = ', error);
      throw error;
    });
  },
  resendInvitation (_, { id }) {
    return api.entities.user.resendInvitation(id).then(response => {
      // console.info('api.entities.user.resendInvitation | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.resendInvitation | error = ', error);
      throw error;
    });
  },
  status (_, { id, status }) {
    return api.entities.user.status(id, status).then(response => {
      // console.info('api.entities.user.status | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.status | error = ', error);
      throw error;
    });
  },
  countNotVerified ({ commit }) {
    api.entities.user.list(1, 99999999).then(response => {
      // console.info('countNotVerified | response = ', response.data);
      const count = (response.data.Body || []).filter(user => !user.verified).length;
      commit('setCountNotVerified', count);
    }).catch(error => {
      console.error('countNotVerified | error = ', error);
      throw error;
    });
  },
  resendVerificationEmail (_, { user_id }) {
    return api.entities.user.verifyResend(user_id).then(({ data }) => data);
  },
};

export function getUserOnePromises (observer, item, id_name = 'user_id') {
  return new Promise(resolve => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'company/user/one',
        { id: item[id_name] },
        user => {
          item.user = user;
          resolve(item);
        },
      );
    } else {
      item.user = null;
      resolve(item);
    }
  });
}

export function getUserOnePromisesV2 (observer, item, callBackRelate = () => ({}), id_name = 'user_id') {
  return new Promise(resolve => {
    if (!item) {
      resolve(null);
      return;
    }
    if (!item[id_name] || !item[id_name].length) {
      item.user = null;
      resolve(item);
      return;
    }
    observer.subscribeObserver(
      'company/user/one',
      { id: item[id_name], callBackRelate },
      user => {
        item.user = user;
        resolve(item);
      },
    );
  });
}

import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.entities.user.passwordUpdate(id, data).then(response => {
      //console.info('api.entities.user.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.user.put | error = ', error);
      throw error;
    });
  },
};

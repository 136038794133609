export default {
  loading: 'Loading templates...',

  name: 'Name',
  tenant_id: 'Company',
  channel: 'Channel',
  status: 'Status',
  created_at: 'Date',

  delete_confirm: 'Are you sure you want to delete message template "{name}"?',
  delete_success: 'Message template "{name}" deleted.',

  copy_confirm: 'Are you sure you want to copy message template "{name}"?',
  copy_success: 'Message template "{name}" copy.',
}

import Vue from 'vue';

export const state = () => ({
  tasks: {},
  lastTask: {}, // groupid - taskid
});

export const getters = {
  groupTasks: state => gid => Object.values(state.tasks).filter(({ group_id }) => group_id === gid),
};

export const mutations = {
  SET_TASK (state, task) {
    Vue.set(state.tasks, task.task_id, task);
  },
  DELETE_TASK (state, task_id) {
    Vue.delete(state.tasks, task_id);
  },
  SET_LAST_TASK (state, {
    group_id,
    task_id,
  }) {
    Vue.set(state.lastTask, group_id, task_id);
  },
  SHOW_PROGRESS_BOX (state, task_id) {
    const task = state.tasks[task_id];
    delete task.hideProgressBox;
    Vue.set(state.tasks, task.task_id, task);
  },
  SET_TASK_PROGRESS (state, { task_id, progress, isWS }) {
    if (!state.tasks[task_id]) {
      return;
    }
    Vue.set(state.tasks, task_id, {
      ...state.tasks[task_id],
      payload: {
        ...state.tasks[task_id].payload,
        progress,
        isWS: isWS || false,
      },
    });
  },
};

export const actions = {};

export default {
  title_browser: 'Регистрация',
  title: 'Регистрация почти завершена',
  subtitle: 'Придумайте пароль не менее 8 символов.',
  hash_empty: 'Hash не указан!',
  email: 'Email адрес',
  password: 'Пароль',
  password_confirmed: 'Еще раз пароль',
  register_btn: 'Готово',

  verified_was: 'Пользователь уже завершил регистрацию',
}

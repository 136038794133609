import axios from 'axios';
import autoBind from 'auto-bind';
import { catchDispatchErrorMsg } from '~/utils/form';
import api from '~/api';

export const BUS_CONVERT_DONE = 'group-contact/convert/DONE';

enum MEMORY_STATE {
  CheckConverterTask = 'check-converter-task',
  ContactsUploadConverted = 'contacts-upload-converted',
  Done = 'done',
}

interface IUploadFileResponse {
  task_id: string;
  file_id: string;
  tenant_id: string;
  user_id: string;
}

interface IConverterTaskResponse {
  task_id: string;
  file_id: string;
  progress: number;
  converted_file_id: string;
  tenant_id: string;
  user_id: string;
  status: string;
  status_message: string;
}

interface IContactsUploadConvertedResponse {
  file_id: string;
}

export class ConvertMemory {
  private self: any;

  constructor (self) {
    autoBind(this);
    this.self = self;
  }

  async run () {
    const items = await api.trailer.settings
      .list(1, 99, 'created_at desc', { key: 'contact-group.convert' })
      .then(({ data }) => (data.Body || []));

    for (const item of items) {
      new ConvertNotify(this.self).run(JSON.parse(item.value));
    }
  }
}

interface INotify {
  state: MEMORY_STATE;
  task_id: string;
  group_id: string;
  file_name: string;
  payload: IUploadFileResponse | IConverterTaskResponse | IContactsUploadConvertedResponse;
  hideProgressBox?: boolean;
}

export class ConvertNotify {
  private self: any;
  private item: INotify;
  private modal: any;
  private progressBar: any;

  constructor (self) {
    autoBind(this);
    this.self = self;
  }

  setModal (modal) {
    this.modal = modal;
    return this;
  }

  setProgressBar (progressBar) {
    this.progressBar = progressBar;
    return this;
  }

  async run (item: INotify) {
    this.item = item;

    try {
      this.self.$store.commit('group-contact/convert/SET_TASK', this.item);

      if ([
        MEMORY_STATE.CheckConverterTask,
      ].includes(item.state)) {
        const cctr = await this.checkConverterTask(this.item.task_id);
        this.item = {
          ...this.item,
          state: MEMORY_STATE.ContactsUploadConverted,
          payload: cctr,
        };
        await api.trailer.settings.slim.store(this.item.task_id, 'contact-group.convert', {
          value: JSON.stringify(this.item),
        });
        this.self.$store.commit('group-contact/convert/SET_TASK', this.item);
      }

      if ([
        MEMORY_STATE.CheckConverterTask,
        MEMORY_STATE.ContactsUploadConverted,
      ].includes(item.state)) {
        const cucr = await this.contactsUploadConverted(this.item.payload.converted_file_id);
        this.item = {
          ...this.item,
          state: MEMORY_STATE.Done,
          payload: cucr,
        };
        await api.trailer.settings.slim.store(this.item.task_id, 'contact-group.convert', {
          value: JSON.stringify(this.item),
        });
        this.self.$store.commit('group-contact/convert/SET_TASK', this.item);
      }

      if ([
        MEMORY_STATE.CheckConverterTask,
        MEMORY_STATE.ContactsUploadConverted,
        MEMORY_STATE.Done,
      ].includes(item.state)) {
        const d = {
          group_id: this.item.group_id,
          task_id: this.item.task_id,
          file_id: this.item.payload.file_id,
          file_name: this.item.file_name,
        };
        openFinalModal.call(this.self, d);
        this.self.$bus.$emit(BUS_CONVERT_DONE, d);
        this.self.$store.commit('group-contact/convert/SET_TASK', this.item);
      }
    } catch (e) {
      removeMemoryFile.call(this.self, this.item.task_id);
      throw e;
    } finally {
      setTimeout(() => {
        this.modal?.close();
      }, 300);
    }
  }

  checkConverterTask (taskId: string): Promise<IConverterTaskResponse> {
    return new Promise((resolve, reject) => {
      const check = async () => {
        try {
          const task = this.self.$store.state['group-contact'].convert.tasks[taskId];
          console.info('checkConverterTask | isWS = ', task?.payload?.isWS);
          console.info('checkConverterTask | WS | ',
            task?.payload?.isWS, ' && ',
            task?.payload?.progress < 100,
            (task?.payload?.isWS && task?.payload?.progress < 100),
          );

          let d, setTimeoutTime;
          if (task?.payload?.isWS && task?.payload?.progress < 100) {
            console.info('checkConverterTask | ws');
            d = task?.payload;
            setTimeoutTime = 300;
          } else {
            console.info('checkConverterTask | http');
            await axios.get(`/api-gateway/api/v1/converter/task/${taskId}?_${new Date().getTime()}`).then(({ data }) => d = data);

            this.item = {
              ...this.item,
              ...task,
              payload: {
                ...this.item.payload,
                ...task.payload,
                progress: d.progress,
              },
            };
            this.self.$store.commit('group-contact/convert/SET_TASK', this.item);

            setTimeoutTime = 3000;
          }

          if (this.modal) {
            this.progressBar.style.width = d.progress + '%';
            this.progressBar.setAttribute('aria-valuenow', d.progress);
          }

          if (d.status === 'completed' || d.progress === 100) {
            return resolve(d);
          }
          if (!this.modal) {
            Object.assign(this, openModal.call(this.self));
          }
          setTimeout(check, setTimeoutTime);
        } catch (e) {
          console.error('checkConverterTask | ', e);
          if (e?.response?.status === 400) {
            reject(catchDispatchErrorMsg(this.self, e));
          } else {
            setTimeout(check, 3000);
          }
        }
      };
      check();
    });
  }

  async contactsUploadConverted (converted_file_id): Promise<IContactsUploadConvertedResponse> {
    return await axios.post(
      '/api-gateway/api/v1/contacts/upload-converted', { converted_file_id },
    ).then(({ data: d }) => d);
  }
}

export class Convert {
  private self: any;
  private modal: any;
  private progressBar: any;

  constructor (self) {
    autoBind(this);
    this.self = self;
  }

  async run (group_id: string, file: File) {
    let task_id;
    const file_name = file.name;

    try {
      Object.assign(this, openModal.call(this.self));
      const ufr = await this.uploadFile(file);
      task_id = ufr.task_id;

      await addMemoryFile.call(this.self, {
        group_id,
        file_name,
        payload: ufr,
      });

      await new ConvertNotify(this.self)
        .setModal(this.modal)
        .setProgressBar(this.progressBar)
        .run({
          state: MEMORY_STATE.CheckConverterTask,
          task_id,
          group_id,
          file_name,
          payload: ufr,
          hideProgressBox: true,
        });
    } catch (e) {
      removeMemoryFile.call(this.self, task_id);
      throw e;
    } finally {
      this.modal?.close();
    }
  }

  async uploadFile (file: File) {
    const hash_sum = await this.getHashSum(file);

    const fileForm = new FormData();
    fileForm.append('file', file);
    fileForm.append('hash_sum', hash_sum);

    return axios.post<IUploadFileResponse>(
      '/api-gateway/api/v1/converter/convert', fileForm,
    ).then(({ data: d }) => d);
  }

  async getHashSum (file: File): Promise<string> {
    function fileToArrayBuffer () {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const readerBuffer = reader.result;
          if (readerBuffer === null) {
            reject(new Error('reader.result is null'));
            return;
          }

          if (file.size < 3 * 1024 * 1024) {
            resolve(readerBuffer);
            return;
          }

          const size = 102;
          const bufferNew = new Uint8Array(size * 2);
          bufferNew.set(new Uint8Array(readerBuffer.slice(0, size)), 0);
          bufferNew.set(new Uint8Array(readerBuffer.slice(file.size - size, file.size)), size);

          resolve(bufferNew.buffer);
        });
        reader.readAsArrayBuffer(file);
      });
    }

    function arrayBufferToBuffer (ab) {
      const buffer = new Buffer(ab.byteLength);
      const view = new Uint8Array(ab);
      for (let i = 0; i < buffer.length; i++) {
        buffer[i] = view[i];
      }
      return buffer;
    }

    function bufferToSha256 (buffer) {
      return crypto.subtle.digest('SHA-256', buffer);
    }

    function hexString (buffer) {
      const byteArray = new Uint8Array(buffer);
      const hexCodes = [...byteArray].map(v => v.toString(16).padStart(2, '0'));
      return hexCodes.join('');
    }

    const buffer = await fileToArrayBuffer();
    const hash = await bufferToSha256(arrayBufferToBuffer(buffer));
    return hexString(hash);
  }
}

function openModal () {
  const modal = window.PNotify.alert({
    title: this.$t('group_contact.contact.convert.modal.title'),
    text: '<div class="progress"><div class="progress-bar bg-blue-400 progress-bar-striped progress-bar-animated" style="width: 0"><span class="sr-only">0%</span></div></div>',
    textTrusted: true,
    addClass: 'bg-primary cy-statistic-report-run-progress',
    icon: 'icon-spinner4 spinner',
    hide: false,
    modules: {
      Buttons: {
        closer: false,
        sticker: false,
      },
      History: { history: false },
    },
  });
  const progressBar = modal.refs.elem.getElementsByClassName('progress-bar')[0];

  return {
    modal,
    progressBar,
  };
}

function openFinalModal ({
  group_id,
  task_id,
  file_id,
  file_name,
}: {
  group_id: string;
  task_id: string;
  file_id: string;
  file_name: string;
}) {
  const modal = window.PNotify.alert({
    title: this.$t('group_contact.contact.convert.final_modal.title'),
    text: this.$t('group_contact.contact.convert.final_modal.text', { file_name }),
    textTrusted: true,
    addClass: 'bg-primary',
    hide: false,
    modules: {
      Confirm: {
        confirm: true,
        buttons: [{
          text: this.$t('group_contact.contact.convert.final_modal.btn.cancel'),
          textTrusted: true,
          addClass: 'btn btn-sm bg-warning-800 mr-2',
          click: notice => {
            if (!confirm(this.$t('group_contact.contact.convert.final_modal.cancel_confirm'))) {
              return;
            }
            removeMemoryFile.call(this, task_id);
            notice.close();
          },
        }, {
          text: this.$t('group_contact.contact.convert.final_modal.btn.confirm'),
          textTrusted: true,
          addClass: 'btn bg-purple-800',
          click: notice => {
            this.$router.push({
              name: 'contact-group-gid-add-phone',
              params: { gid: group_id },
              hash: '#file-xlsx-' + task_id + '-' + file_id,
            });
            notice.close();
          },
        }],
      },
      Buttons: { closer: false, sticker: false },
    },
  });
  window['group-contact/convert/SET_MODAL/' + task_id] = modal;
}

async function addMemoryFile ({
  group_id,
  file_name,
  payload,
}: {
  group_id: string;
  file_name: string;
  payload: IUploadFileResponse;
}) {
  await api.trailer.settings.slim.store(payload.task_id, 'contact-group.convert', {
    value: JSON.stringify({
      state: MEMORY_STATE.CheckConverterTask,
      task_id: payload.task_id,
      group_id,
      file_name,
      payload,
    }),
  });
  this.$store.commit('group-contact/convert/SET_LAST_TASK', {
    group_id,
    task_id: payload.task_id,
  });
}

export function removeMemoryFile (task_id: string) {
  window['group-contact/convert/SET_MODAL/' + task_id]?.close();
  this.$store.commit('group-contact/convert/DELETE_TASK', task_id);
  api.trailer.settings.slim.delete(task_id, 'contact-group.convert');
}

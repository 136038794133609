export default {
  title: 'File preview',
  ok_title: 'Continue',
  cancel_title: 'Cancel',

  content_info: '<b>Set the data type for each column.</b><br>You can load contact list with additional parameters to use them in template variables. Assign a type of variable to the data in the column by specifying it in the drop-down.',
  table_header: 'Table header',
  skip_first_row: 'Exclude first line',

  phone_no_select: 'Phone number type not set',
  file_processing: 'File processing',

  params_phone: 'PHONE',
  params_name: 'First Last Name [[NAME]]',
  params_first_name: 'First Name [[FIRST_NAME]]',
  params_second_name: 'Second Name [[SECOND_NAME]]',
  params_last_name: 'Last Name [[LAST_NAME]]',
  params_birth_day: 'Birthday [[BIRTH_DAY]]',
  params_code: 'Code [[CODE]]',
  params_email: 'EMail [[EMAIL]]',
  params_date: 'Date [[DATE]]',
  params_sum: 'Amount  [[SUM]]',
  params_param_1: 'Parameter 1 [[PARAM_1]]',
  params_param_2: 'Parameter 2 [[PARAM_2]]',
  params_param_3: 'Parameter 3 [[PARAM_3]]',

  params_phone_name: 'PHONE',
  params_name_name: 'First Last Name',
  params_first_name_name: 'First Name',
  params_second_name_name: 'Second Name',
  params_last_name_name: 'Last Name',
  params_birth_day_name: 'Birthday',
  params_code_name: 'Code',
  params_email_name: 'EMail',
  params_date_name: 'Date',
  params_sum_name: 'Amount ',
  params_param_1_name: 'Parameter 1',
  params_param_2_name: 'Parameter 2',
  params_param_3_name: 'Parameter 3',

  save_success: 'Saved successfully',
};

export function onTitleUpdate () {
  let documentTitle = '';

  if (this.chatCountMessageNew > 0) {
    documentTitle += '(' + this.chatCountMessageNew + ') ';
  }

  const { title, subtitle } = this.$store.state.page;
  if (subtitle.length) {
    documentTitle += [subtitle].map(t => this.$t && this.$t(t)).join(' | ');
  } else {
    documentTitle += [title].map(t => this.$t && this.$t(t)).join(' | ');
  }
  if (documentTitle.length) {
    documentTitle += ' | ';
  }
  documentTitle += process.env.baseTitle;
  // console.info('onTitleUpdate | {', title, ', ', subtitle, '} | documentTitle = ', documentTitle);
  if (process.browser) {
    document.title = documentTitle;
  }
  return documentTitle;
};

export default {
  head () {
    return {
      title: onTitleUpdate.call(this),
    };
  },
  mounted () {
    this.$store.commit('setPageTitle', {
      title: this.title,
      subtitle: this.subtitle || undefined,
    });
  },
  watch: {
    chatCountMessageNew () {
      onTitleUpdate.call(this);
    },
  },
  computed: {
    chatCountMessageNew () {
      return this.$store.state.chat?.counters?.all?.all || 0;
    },
  },
};

export default {
  tab_sender_details: 'Профиль отправителя',

  project: 'Проект',
  name_en: 'Название на английском',
  ttl: 'TTL - время жизни сообщений',
  code_api: 'Код Отправителя для API',
  bulk_login: 'Логин Проекта',
  bulk_secret_key: {
    title: 'Секретный ключ',
    is_empty: 'Не задан.',
    set: 'Задать секретный ключ',
  },
  api_type: 'Тип API',
  callback_url: 'Callback URL для статусов доставки',
  callback_url_two_way: 'Callback URL для входящих сообщений',
  channel: 'Канал',
  comparison: 'Linked',
  comparison_empty: 'Not linked',
  status: 'Статус',
  statuses: 'Status history',
  statuses_user_id: 'User',
  statuses_date: 'Date',
  statuses_status: 'Status',

  dispatch_document: 'Рассылки',
  template: 'Шаблоны',

  work_in_progress_title: 'Еще не готово?',
  work_in_progress_description: 'Завершите создание отправителя',

  info_title_status_moderation: 'Отправитель проходит модерацию',
  info_description_status_moderation: 'Пожалуйста, подождите, пока мы проверяем предоставленную информацию об отправителе.<br> Обычно это занимает несколько рабочих дней.',

  scheduler_change_client_sender_status_deact_title: 'Деактивация отправителя {date}',
  scheduler_change_client_sender_status_deact_description: 'После того как Имя отправителя будет деактивировано, вы не сможете использовать его для отправки сообщений. Для отмены деактивации обратитесь',
  scheduler_change_client_sender_status_deact_link: 'в службу поддержки',

  not_specified: 'Не указано',

  ttl_d: '{d} д.',
  ttl_h: '{h} ч.',
  ttl_m: '{m} мин.',
};

export default {
  phone: 'Phone number',
  name: 'First Last Name',
  first_name: 'First Name',
  second_name: 'Second Name',
  last_name: 'Last Name',
  birth_day: 'Birthday',
  code: 'Code',
  email: 'EMail',
  date: 'Date',
  sum: 'Amount',
  param_1: 'Parameter 1',
  param_2: 'Parameter 2',
  param_3: 'Parameter 3',

  name_tag: '[[NAME]]',
  first_name_tag: '[[FIRST_NAME]]',
  second_name_tag: '[[SECOND_NAME]]',
  last_name_tag: '[[LAST_NAME]]',
  birth_day_tag: '[[BIRTH_DAY]]',
  code_tag: '[[CODE]]',
  email_tag: '[[EMAIL]]',
  date_tag: '[[DATE]]',
  sum_tag: '[[SUM]]',
  param_1_tag: '[[PARAM_1]]',
  param_2_tag: '[[PARAM_2]]',
  param_3_tag: '[[PARAM_3]]',
};

export default {
  title: 'Отправить шаблон на модерацию',
  description: 'У вас не будет возможности отредактировать шаблон после отправки на модерацию.<br>Пожалуйста, подтвердите корректность информации, указанной ниже.',

  name: 'Название',
  channel: 'Канал',
  preview: 'Предпросмотр',

  cancel: 'Отмена',
  ok: 'Отправить на модерацию',

  status_change_success: 'Готово',
}

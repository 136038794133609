import progress_box from '~/locales/en/GroupContact/Contact/Convert/ProgressBox';

export default {
  progress_box,

  badge: {
    title: 'Processing',
    toottip: 'Previously uploaded file is being converted into .csv. When the process is complete you will be able to finalize the contact import.',
  },

  modal: {
    title: 'Preparing the file',
  },

  final_modal: {
    title: 'File conversion completed',
    text: 'The {file_name} file has been successfully converted and is ready to be used to import contacts.',
    cancel_confirm: 'Are you sure you want to cancel the conversion?',
    btn: {
      cancel: 'Cancel',
      confirm: 'To contact group',
    },
  },
};

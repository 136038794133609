export default {
  title: 'Создать рассылку',

  select_template: 'Выберите шаблон',
  select_project: 'Укажите проект, из которого следует выбрать шаблон',

  name: 'Название',
  name_placeholder: 'Рассылка {time}',
  tenant_id: 'Компания',
  tenant_id_placeholder: 'Компания ID',
  project_id: 'Проект',
  project_id_placeholder: 'Выберите проект',
  project_id_placeholder_empty: 'Нет проектов с активными шаблонами',
  channel_id: 'Канал',

  template_title: 'Шаблон рассылки',
  template: 'Шаблон',
  content: 'Сообщение',
  content_select_empty_title: 'Не выбран шаблон сообщения',
  content_select_empty_dody: 'Select the message template from the list on the right',

  load_more_templates: 'Загрузить еще',
  select_recipients: 'Выберите получателей',

  group_contact: 'Группы контактов',
  group_contact_for_dispatch_document: 'Получатели рассылки',
  group_contact_for_exception: 'Группы для исключения из рассылки',

  content_id_empty: 'Сообщение не указано',
  group_contact_for_dispatch_document_empty: 'Не выбраны получатели рассылки',

  select_another_template: 'Выбрать другой шаблон',

  preview_btn: 'Предпросмотр',
  preview_title: 'Предварительный просмотр',
  preview_contents_not_select: 'Выберите шаблон сообщения и получателей рассылки',

  save_success: 'Готово',

  template_empty: 'В проекте нет активных шаблонов.',
  template_empty_project_more_you_can: 'Вы можете',
  template_empty_project_more_create: 'создать шаблон',
  template_empty_project_more_or: 'или',
  template_empty_project_more_select_all: 'переключить фильтр проектов на Все проекты.',
  template_empty_project_more_select_all_after: '',
  template_empty_project_one_you_can: 'Вы можете',
  template_empty_project_one_create: 'создать шаблон',
  template_empty_project_one_create_after: 'для использования в рассылке сообщений.',
}

import PhoneParamsTag from '~/locales/en/GroupContact/Contact/PhoneParamsTag';

export default {
  ...PhoneParamsTag,

  textarea_empty: 'No rows',
  textarea_max_row: 'Max 100 000 rows',
  file_not_select: 'File not select',

  save_success: 'Saved successully',
}

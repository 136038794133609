export default {
  title: 'Перейти на корпоративный тариф',
  description: 'Воспользуйтесь дополнительными возможностями личного кабинета для мультиканальных рассылок!',
  month_1: '1 месяц',
  free: 'Активировать бесплатно!',
  month_3: '3 месяца',
  month_6: '6 месяцев',
  month_12: '12 месяцев',
  more_info: 'Подробнее',
  free_month_1: '1 месяц бесплатно!',
  free_month_2: '2 месяца бесплатно!',

  error: {
    empty_contract: 'Выберите договор для продолжения оплаты',
  },
};

export default {
  clientsender: 'Відправник',
  channel_name: 'Канал',
  message_status: 'Статус',
  phone_number: 'Контакт',
  message_id: 'ID Повідомлення',
  message_time: 'Час',
  accepted: 'Дата та час повідомлення',
  message_date: 'Дата',
  count: 'Кількість',
  operator_name: 'Оператор',
  mobile_operator: 'Оператор',

  message_status_count_total: 'Всього',
  message_status_count_sent: 'Відправлено',
  message_status_count_not_viber_user: 'Не користувач Viber',
  message_status_count_delivered: 'Доставлено',
  message_status_count_not_delivered: 'Не доставлено',
  message_status_count_watched: 'Переглянуто',
  message_status_count_clicked: 'Кліки',

  download_csv_report: 'Завантажити звіт CSV',
  open_stat: 'Відкрити',
  sender_details: 'Про відправника',
  export_to_csv: 'Експорт в CSV',

  delete_confirm: 'Ви дійсно хочете видалити розсилку "{name}"?',
  delete_success: 'Розсилку "{name}" видалено.',
}

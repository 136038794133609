import change_lang from '~/locales/ru/Company/Account/ChangeLang';

export default {
  title: 'Моя компания',

  name: 'Название компании',
  name_placeholder: 'ООО Название компании',
  description: 'Описание компании',
  description_placeholder: 'Несколько слов о вашем бизнесе',
  location_country: 'Страна нахождения',

  finance_doc_title: 'Финансовые документы и уведомления',
  finance_doc_description: 'Пожалуйста, укажите email вашей бухгалтерии для отправки закрывающих документов и прочих уведомлений финансового и юридического характера.',
  email_finance_doc: 'Email для финансовых документов',
  email_finance_doc_placeholder: 'Email для финансовых документов',

  btn_save: 'Сохранить изменения',
  save_success: 'Готово',

  change_lang,
  accept_offer: {
    description: 'Настройки аккаунта обновлены!<br>Вам следует принять условия договора-оферты для начала работы.',
    btn_go: 'Перейти далее',
  },
}

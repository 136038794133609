export default {
  title: 'Dispatch actions',

  btn_info: 'Dispatch info',
  btn_restore: 'Restore dispatch',
  btn_settings: 'Dispatch settings',
  btn_start_sending: 'Start sending',
  btn_start_schedule: 'Schedule',
  btn_repeat: 'Repeat Dispatch',
  btn_edit: 'Edit Dispatch',
  btn_statistics: 'Dispatch statistics',
}

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import aes from "../../../../node_modules/.pnpm/aes-js@3.1.2/node_modules/aes-js/index.js";
import { AUTH_COOKIE } from "./_const.js";
class ReLogin {
  constructor() {
    __publicField(this, "aesKey");
    this.aesKey = new Uint8Array([
      2,
      229,
      20,
      254,
      154,
      2,
      70,
      196,
      124,
      204,
      205,
      154,
      181,
      132,
      146,
      190,
      93,
      250,
      187,
      62,
      99,
      184,
      74,
      56,
      105,
      242,
      88,
      145,
      31,
      73,
      157,
      52
    ]);
  }
  get itemKey() {
    return AUTH_COOKIE + "-relogin";
  }
  get cryptoCtr() {
    return new aes.ModeOfOperation.ctr(this.aesKey, new aes.Counter(5));
  }
  encrypt({ email, password }) {
    var _a;
    const data = JSON.stringify({
      email,
      password
    });
    const toBytes = aes.utils.utf8.toBytes(data);
    const fromBytes = this.cryptoCtr.encrypt(toBytes);
    const encrypt = aes.utils.hex.fromBytes(fromBytes);
    if (typeof window !== "undefined") {
      (_a = window == null ? void 0 : window.localStorage) == null ? void 0 : _a.setItem(this.itemKey, encrypt);
    }
  }
  decrypt() {
    var _a;
    if (typeof window === "undefined") {
      return void 0;
    }
    const data = (_a = window == null ? void 0 : window.localStorage) == null ? void 0 : _a.getItem(this.itemKey);
    if (!data) {
      return void 0;
    }
    const toBytes = aes.utils.hex.toBytes(data);
    const fromBytes = this.cryptoCtr.decrypt(toBytes);
    const decrypt = aes.utils.utf8.fromBytes(fromBytes);
    return JSON.parse(decrypt);
  }
}
const ReLogin$1 = new ReLogin();
export {
  ReLogin,
  ReLogin$1 as default
};

<template>
  <div
    class="form-group"
    :class="{'has-warning': errors.has(`${formName}.project_id`)}"
  >
    <h6 class="card-title">
      {{ $t(`${tForm}.select_project_title`) }}
    </h6>
    <widget-field-project-id
      v-model="value_"
      v-validate="'required'"
      :ajax="false"
      :data-vv-as="$t(`${tForm}.project_id`).toLowerCase()"
      :data-vv-scope="formName"
      :disabled="disabled"
      :placeholder="`${tForm}.project_id_placeholder`"
      :lang-no-results="!projectList.length ? $t(`${tForm}.project_id_placeholder_empty`) : null"
      :list="projectList_"
      name="project_id"
    />
    <span
      v-if="errors.has(`${formName}.project_id`)"
      class="help-block"
      v-html="errors.first(`${formName}.project_id`)"
    />
  </div>
</template>

<script>
import WidgetFieldProjectId from '@/components/Widget/Field/WidgetFieldProjectId';

export default {
  components: {
    WidgetFieldProjectId,
  },
  inject: ['$validator'],
  props: {
    tForm: {
      type: String,
      default: '',
    },
    formName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    projectList: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: String,
    },
  },
  computed: {
    value_: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    projectList_ () {
      return this.projectList.map(project => ({
        id: project.id,
        text: project.name,
        project,
      }));
    },
  },
};
</script>

<style scoped>

</style>

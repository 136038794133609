import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  store(_, data) {
    return api.trailer.settings.group.store(data)
      .then(({data: {Body}}) => (Body || []))
      .catch(error => {
        console.error('api.trailer.settings.group.post | error = ', error);
        throw error;
      });
  },
};

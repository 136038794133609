export default {
  progress: {
    title: 'Add test number',
  },
  left: {
    title: 'Add a phone number to receive test messages.',
    subtitle:
      'After adding the number, you will receive a phone call with the last 4 digits of the number as the confirmation code. Or you will receive an SMS with a confirmation code.',
    subsubtitle:
      'You will be able to use use your own phone number to receive test messages, and you can also add additional test numbers in your account settings.',
  },
  form_code: {
    success: {
      text: 'The phone number has been confirmed. To continue, click "Done"',
    },
  },
  help: {
    btn: 'The SMS is not coming',
    title:
      'Write to us on Telegram or WhatsApp and we will confirm your number.',
    content:
      'If it is more convenient for you to write to us from your mobile device, our number on Telegram and WhatsApp is',
    number: '+995522220490',
    message:
      'Hello! Please, confirm my phone number. The email I used to register is {email}',
  },
  submit: {
    progress: {
      title: 'Setting up your office',
    },
  },
};

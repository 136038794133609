export default {
  title: 'Copy selected contacts to the group',
  description: 'Specify the group where the selected contacts will be copied.',

  btn_submit: 'Copy selected contacts',

  duplicate_cleanup_start: 'Start duplicate cleanup',
  duplicate_cleanup_deleted: 'Duplicate cleanup: {deleted}',

  copy_success: 'Contacts have been copied successfully.',
}

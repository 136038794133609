import button from '~/locales/ru/Template/ContentLayout/Whatsapp/LayoutBody/Button';
import location from '~/locales/ru/Template/ContentLayout/Whatsapp/LayoutBody/Location';

export default {
  button,
  location,

  template_category: {
    account_update: 'Account Update',
    alert_update: 'Alert Update',
    appointment_update: 'Appointment Update',
    auto_reply: 'Auto-Reply',
    issue_resolution: 'Issue Resolution',
    payment_update: 'Payment Update',
    personal_finance_update: 'Personal Finance Update',
    reservation_update: 'Reservation Update',
    shipping_update: 'Shipping Update',
    ticket_update: 'Ticket Update',
    transportation_update: 'Transportation Update',
    marketing: 'Транзакция',
    transactional: 'Маркетинг',
    otp: 'Одноразовый пароль',
  },
};

export default {
  today: 'Сегодня',
  yesterday: 'Вчера',
  last_7_day: 'Последние 7 дней',
  last_30_day: 'Последние 30 дней',
  this_month: 'Этот месяц',
  last_month: 'Прошлый месяц',

  apply_label: 'Применить',
  cancel_label: 'Отмена',
  start_label: 'Дата от',
  end_label: 'Дата по',
  custom_range_label: 'Выбрать период',
  days_of_week: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  month_names: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
}

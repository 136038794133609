import api from '~/api';
import {arrayToObject} from "~/utils/functions";

const baseState = {
  list: {}
};

export const state = () => ({...baseState});

export const getters = {};

export const mutations = {};

export const actions = {
  async getChannelsClientSenderByProjectID({commit, dispatch}, project_id) {
    let channels, client_senders, client_senders_original;

    await Promise.all([

      api.documents.channel
        .list(1, 9999)
        .then(r => arrayToObject(r.data.Body || []))
        .then(r => channels = r),

      api.documents.project
        .clientsenders(project_id, 1, 9999, '', {status: 'active'})
        .then(r => (r.data.Body || []))
        .then(async r => await Promise.all(r.map(cs =>
          dispatch('widget/favorite/check', {entity_id: cs.id}, {root: true})
            .then(({value: f} = {}) => ({...cs, is_favorite: f === 'true'}))
            .catch(() => ({...cs, is_favorite: false}))
        )))
        .then(r => {
          client_senders_original = r
          client_senders = r.reduce((cs, r) => {
            if (!cs[r.channel_id]) cs[r.channel_id] = [];
            cs[r.channel_id].push(r);
            return cs;
          }, {})
        }),

    ]).catch(e => {
      console.error('template/form/getChannelsClientSenderByProjectID | ', e);
      throw e;
    });

    //console.info('template/form/getChannelsClientSenderByProjectID | channels = ', channels, ' | client_senders = ', client_senders);

    return {channels, client_senders, client_senders_original};
  },
  create({commit, dispatch}, dataIn) {
    //console.info('template/form/create | dataIn = ', dataIn);
    return api.saga.template.post(dataIn).then(({data}) => data);
  },
  edit({commit, dispatch}, {id, ...dataIn}) {
    //console.info('template/form/edit | dataIn = ', dataIn);
    return api.saga.template.put(id, dataIn).then(({data}) => data);
  },
  copy({commit, dispatch}, id) {
    //console.info('template/form/copy | id = ', id);
    return api.saga.template.copy(id).then(({data}) => data);
  },
};

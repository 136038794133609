import cookie from "cookie";

export default function ({isHMR, app, store, route, params, error, redirect, req}) {
  let cookies = '';
  if (req) {
    cookies = cookie.parse(req.headers.cookie || '');
  } else if (process.browser) {
    cookies = cookie.parse(document.cookie);
  }
  if (cookies.hasOwnProperty('locale')) {
    store.commit('setLang', cookies['locale']);
    app.i18n.locale = cookies['locale']
  }
}

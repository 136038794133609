import region_option from '~/locales/uk/ClientSender/TrailerProfile/RCS/RegionOption';

export default {
  service_name_original_language: 'Назва (оригинальною мовою)',
  service_name_english_language: 'Назва (їанглийською)',
  region: 'Регіон',
  region_option,
  description: 'Опис',
  description_helper: '(до 100 знаків)',
  chat_dialog_color: 'Колір диалогу чата',
  logo_img: 'Логотип відправника (224 x 224)',
  logo_chatscreen: 'Обкладинка (1440 x 448)',
  main_phone_number: 'Номер телефону компанії',
  primary_website: 'Адреса сайту',
  primary_email: 'Email компанії',
  privacy_policy_link: 'Посилання на політику конфиденційності',
  terms_of_service_link: 'Посилання на політику використання сервісу',
};

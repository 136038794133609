export default {
  title: 'Перейти на корпоративний тариф',
  description: 'Скористайтесь додатковими можливостями особистого кабинету для мультиканальних розсилок!',
  month_1: '1 місяць',
  free: 'Активувати безкоштовно!',
  month_3: '3 місяці',
  month_6: '6 місяців',
  month_12: '12 місяців',
  more_info: 'Докладніше',
  free_month_1: '1 місяць безкоштовно!',
  free_month_2: '2 місяці безкоштовно!',

  error: {
    empty_contract: 'Оберіть договір для продовження оплати',
  },
};

export default {
  title: 'Submit template for moderation',
  description: 'While the template will be moderated, you will not be able to edit it. <br>Please, confirm that the information below is correct.',

  name: 'Name',
  channel: 'Channel',
  preview: 'Preview',

  cancel: 'Cancel',
  ok: 'Submit for moderation',

  status_change_success: 'Status change success',
}

export default {
  title_active: 'Заявка на активацию имени отправителя',
  scheduled_date_active: 'Укажите день активации',
  description_active: 'Если заявка будет одобрена, то активация имени отправителя произойдет автоматически в указанный день.',
  submit_active: 'Запросить активацию',
  submit_active_success: 'Отправитель "{name}" отправлен на активацию.',

  title_deact: 'Заявка на отключение имени отправителя',
  scheduled_date_deact: 'Укажите день отключения',
  description_deact: 'Если заявка будет одобрена, то отключение имени отправителя произойдет автоматически в указанный день.',
  submit_deact: 'Запросить отключение',
  submit_deact_success: 'Отправитель "{name}" отправлен на отключение.',

  error_scheduled_date: 'Дата активации должна быть старше сегодняшней.',
}

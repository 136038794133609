import axios from "axios";
import { ErrorTokenEmpty } from "./errors.js";
import { setToken } from "./login.js";
import { AxiosResponseUse } from "../axios-response.js";
import { CatchErrorMsg, ErrorTranslation } from "../errors.js";
axios.interceptors.response.use(AxiosResponseUse);
class ErrorAuthVerifyEmail extends ErrorTranslation {
  constructor(message) {
    super("auth_verify_email." + message);
    this.name = "ErrorAuthVerifyEmail";
    Object.setPrototypeOf(this, ErrorAuthVerifyEmail.prototype);
  }
}
class ErrorVerificationTokenExpired extends ErrorAuthVerifyEmail {
  constructor() {
    super("verification_token_expired");
    this.name = "ErrorVerificationTokenExpired";
    Object.setPrototypeOf(this, ErrorVerificationTokenExpired.prototype);
  }
}
async function verifyEmail(req) {
  var _a, _b;
  const response = await axios.post(`/entities/api/v1/users/verify/${req.token}`).catch((e) => {
    const msg = CatchErrorMsg(e);
    if (msg.match(/verification token expired/i)) {
      throw new ErrorVerificationTokenExpired();
    }
    throw new ErrorAuthVerifyEmail(msg);
  });
  if (!((_b = (_a = response.data) == null ? void 0 : _a.success) == null ? void 0 : _b.token)) {
    throw new ErrorTokenEmpty();
  }
  setToken(response.data.success.token);
  return response.data;
}
export {
  ErrorAuthVerifyEmail,
  ErrorVerificationTokenExpired,
  verifyEmail
};

import { ChatStatusResolved } from '../../components/Chat/ServiceChat';
import { sortChat } from '~/store/chat';
import { onlyUnique } from '~/utils/functions';

export const state = () => ({
  type: 'all',
  chats: undefined,
});

export const getters = {};

export const mutations = {
  setType (store, type) {
    store.type = type;
  },
  resetChats (store) {
    store.chats = [];
  },
  addChats (store, chats) {
    store.chats = chats;
  },
};

export const actions = {
  addMessage ({ dispatch, state, rootState }, { chat_id }) {
    const find = rootState.chat.list[chat_id];
    if (!find) { return; }
    if (state.type === 'all') {
      // Добавляем
    } else if (state.type === 'resolved' && find.status === ChatStatusResolved) {
      // Добавляем
    } else {
      // Пропускаем
      return;
    }
    dispatch('addChats', { chats: [find] });
  },
  addChats ({ commit, state, rootState }, { chats = [], reset = false } = {}) {
    reset && commit('resetChats');
    commit(
      'addChats',
      (state.chats || [])
        .concat((chats || []).map(c => c.id))
        .filter(onlyUnique)
        .sort(sortChat(rootState.chat.list))
        .slice(0, 10),
    );
  },
};

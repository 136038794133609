import chart from '~/locales/ru/Dashboard/chart';
import scheduled_dispatch_document from '~/locales/ru/Dashboard/ScheduledDispatchDocument';
import datepicker from '~/locales/ru/Dashboard/Datepicker';
import block_trustpilot from '~/locales/ru/Dashboard/BlockTrustpilot';
import offer_activate_trial_corporate_tariffs_for_new_users from '~/locales/ru/Dashboard/OfferActivateTrialCorporateTariffsForNewUsers';
import completion_trial_corporate_tariff from '~/locales/ru/Dashboard/CompletionTrialCorporateTariff';

export default {
  chart,
  scheduled_dispatch_document,
  datepicker,
  block_trustpilot,
  offer_activate_trial_corporate_tariffs_for_new_users,
  completion_trial_corporate_tariff,

  projects_title: 'Проекты',

  upload_contacts: 'Загрузить контакты',
  create_template: 'Создать шаблон',
  create_dispatch: 'Создать рассылку',
  check_the_stats: 'Посмотреть статистику',
  realtime_account_balance: 'Баланс вашего договора',

  info_title: 'Панель управления',
  info_body:
    'Добро пожаловать в Messaggio - мультиканальную платформу рассылки. <br><br>' +
    'Для отправки сообщений от имени вашей компании необходимо создать и активировать отправитель, сформировать шаблон сообщения и загрузить группу контактов.<br><br>' +
    'Вы также можете отправлять и получать сообщения с помощью API - настройки зависят от проекта: логин и ключ доступа, а также адрес для получения статусов доставки.',

  your_manager: 'Ваш менеджер',
  phone_number: 'Телефон',

  knowledge_base_title: 'База знаний',
  knowledge_base_body:
    'Узнайте больше о бизнес рассылках: практики применения, используемые термины и прочие особенности.',
  knowledge_base_btn_glossary: 'Глоссарий',
  knowledge_base_btn_messaging: 'Каталог стран',
  knowledge_base_url_glossary: 'https://messaggio.com/ru/glossary/',
  knowledge_base_url_messaging: 'https://messaggio.com/ru/messaging/',

  project_empty: 'Создайте проект для рассылок!',
  project_empty_description:
    'Создавайте имена отправителей, шаблоны сообщений и  мультиканальные рассылки.<br>Вы также можете задать адрес для уведомлений о статусах доставки сообщений в настройках проекта.',
  project_empty_btn: 'Создать проект',

  quick_actions: 'Быстрые действия',
  not_specified: 'Не указано',

  last_dispatch_stats: 'Недавняя рассылка',
  last_dispatch_stats_demodata: 'Демо данные. Вы пока ничего не рассылали.',
  last_dispatch_stats_sent: 'Отправлено',
  last_dispatch_stats_delivered: 'Доставлено',
  last_dispatch_stats_seen: 'Просмотрено',

  more: 'Еще...',

  corporate_plan: {
    title: 'Корпоративный тариф',
    free: {
      title: 'Стандартный тариф',
      upgrade: 'Расширить',
    },
    until: 'до',
  },

  link_guides: {
    title: 'Помощь по разделу',
  },
};

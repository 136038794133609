import modal_detail from '~/locales/uk/Company/Service/Application/ModalDetail';

export default {
  title: 'Applications',
  items_empty: 'No applications',

  scope_name_company: 'Компанії',
  scope_name_project: 'Проекти',
  scope_name_sender: 'Відправники',
  scope_name_template: 'Шаблони',
  scope_name_contact: 'Контакти',
  scope_name_dispatch: 'Розсилки',
  scope_name_statistic: 'Статистика',

  scope_type_read: 'читання',
  scope_type_readWrite: 'читання і запис',

  btn_settings: 'Налаштування',

  modal_detail,
};

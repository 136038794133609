export default {
  loading: 'Загрузка статистики...',

  title: 'Статистика по рассылке',
  empty_data: 'Нет сообщений',

  message_status_count_impossible_to_deliver: 'Доставка невозможна',
  message_status_count_sent: 'Отправлено',
  message_status_count_delivered: 'Доставлено',
  message_status_count_watched: 'Просмотрено',
}

export default {
  title: 'Інформація щодо розсилки',
  description: 'Підсумкова кількість отримувачів і приблизна вартість розсилки.<br><br>' +
    'Натисніть <b><i class="icon-reload-alt"></i> Оновити підрахунок</b> якщо список груп для розсилки і виключення змінився.',
  recipients: 'контактів',
  will_be_reserved: 'буде зарезервовано',
  placeholder: 'Як тільки більша частина повідомлень в розсилці буде доставлена баланс оновиться.',
  duplicates_has_been: 'дублів',
  cleared: 'очищено',
  contacts_has_been: 'контактів',
  excluded: 'виключено',
};

import modal_detail from '~/locales/ru/Company/Service/Application/ModalDetail';

export default {
  title: 'Applications',
  items_empty: 'No applications',

  scope_name_company: "Компании",
  scope_name_project: "Проекты",
  scope_name_sender: "Отправители",
  scope_name_template: "Шаблоны",
  scope_name_contact: "Контакты",
  scope_name_dispatch: "Рассылки",
  scope_name_statistic: "Статистика",

  scope_type_read: "чтение",
  scope_type_readWrite: "чтение и запись",

  btn_settings: "Настройки",

  modal_detail,
}

import api from '~/api';
import { getDirectoryCountryOnePromises } from '~/store/directory/country';
import ObserverApi from '~/utils/ObserverApi';

export const state = () => ({
  tenant: undefined,
});

export const getters = {};

export const mutations = {
  setTenant (store, data) {
    store.tenant = data;
  },
};

export const actions = {
  one ({ commit, state, dispatch }, { observer = null } = {}) {
    if (!observer) { observer = new ObserverApi(dispatch); }
    return api.entities.tenant.profile.get().then(async response => {
      // console.info('api.entities.tenant.profile.get | response = ', response.data);

      response.data = await getDirectoryCountryOnePromises(observer, response.data, 'location_country');

      commit('setTenant', response.data);
      commit('auth/setTenant', response.data, { root: true });

      return response.data;
    }).catch(error => {
      console.error('api.entities.tenant.profile.get | error = ', error);
      throw error;
    });
  },
  edit ({ commit, state }, data) {
    data = { ...data };
    return api.entities.tenant.profile.put(data).then(response => {
      // console.info('api.entities.tenant.profile.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.entities.tenant.profile.put | error = ', error);
      throw error;
    });
  },
};

import form from './form';
import table from './table';
import show from './show';

export default {
  title: 'Короткі посилання',
  title_create: 'Коротке посилання',
  title_show: 'Коротке посилання {name}',
  title_edit: 'Коротке посилання {name}',

  link_empty: 'У вашому акаунті немає коротких посилань.',
  link_empty_project_one_you_can: '',
  link_empty_project_one_create: 'Створити коротке посилання',
  link_empty_project_one_create_after: 'Використовувати його в шаблоні повідомлень і підраховувати конверсії при відправці.',

  create: 'Створити посилання',

  filter_q_placeholder: 'Type to filter...',

  form,
  table,
  show,
}

import { Middleware } from '@nuxt/types';

const middleware: Middleware = ({ store, redirect, route }) => {
  if (
    /^\/permission\/oauth2(\/|$)/.test(route.fullPath) ||
    /^\/permission\/verify-email/.test(route.fullPath)
  ) {
    return Promise.resolve();
  }
  const userIsLoggedIn = !!store.state.auth.user;
  const urlRequiresAuth = !/^\/permission(\/|$)/.test(route.fullPath);
  if (!userIsLoggedIn && urlRequiresAuth) {
    return redirect('/permission/login?redirect=' + encodeURI(route.fullPath));
  }
  if (userIsLoggedIn && !urlRequiresAuth && !route?.query?.['auth-token']) {
    return redirect('/');
  }
  return Promise.resolve();
};

export default middleware;

import message_type_option from '~/locales/uk/ClientSender/TrailerProfile/WhatsApp/MessageTypeOption';
import company_type_option from '~/locales/uk/ClientSender/TrailerProfile/WhatsApp/CompanyTypeOption';
import message_volume_option from '~/locales/uk/ClientSender/TrailerProfile/WhatsApp/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/uk/ClientSender/TrailerProfile/WhatsApp/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/uk/ClientSender/TrailerProfile/WhatsApp/ServiceLaunchDateOption';

export default {
  service_name_original_language: 'Назва (оригінальною мовою)',
  service_name_english_language: 'Назва (англійською)',
  website: 'Адреса сайту',
  description: 'Опис',
  description_helper: '(Опис буде відображатися в пріфілі WhatsApp акаунту у застосунку) 10-400 знаків.',
  logo_img: 'Логотип відправника',
  logo_chatscreen: 'Обкладинка',
  business_address: 'Адреса компанії',
  business_phone_number: 'Номер телефону компанії',
  business_email: 'Email компанії',
  message_type: 'Тип повідомлень',
  message_type_option,
  service_registered_country: 'Країна знаходження компанії',
  company_type: 'Тип компанії',
  company_type_option,
  message_volume: 'Об`єм відправлених повідомлень в місяць',
  message_volume_option,
  service_integration_type: 'Тип інтеграції',
  service_integration_type_option,
  destination_countries: 'Країни розсилки',
  service_launch_date: 'Дата запуску',
  service_launch_date_option,
  facebook_business_manager_id: 'Facebook Business Manager Id',
  sample_message_promotional: 'Приклад рекламного повідомлення',
  sample_message_transactional: 'Приклад транзакційного повідомлення',

};

export default {
  title: 'Главная',
  search_placeholder: 'Поиск',
  navigation: 'Меню',

  my_company: 'Моя компания',
  my_balance: 'Мой баланс',
  my_account_settings: 'Мой аккаунт',
  my_document: 'Мои документы',
  logout: 'Выход',

  balance: 'Баланс',
  support_api: 'Документация по API',
  settings: 'Настройки',
  check_settings: 'Проверить настройки',

  pending_approval: 'Ожидание модерации',
  client_senders: 'Отправители',
  senders_gates: 'Отправители',
  templates: 'Шаблоны',
  home: 'Главная',

  loading: 'Загрузка...',
  actions: 'Действия',
  create: 'Создать',
  edit: 'Редактировать',
  delete: 'Удалить',
  save: 'Сохранить',
  save_changes: 'Сохранить изменения',
  remove: 'Удалить',
  success: 'Готово',
  warning: 'Внимание',
  error: 'Ошибка',
  close: 'Закрыть',
  cancel: 'Отмена',
  yes: 'Да',
  no: 'Нет',
  empty: 'Не указано',
  for: 'for',
  continue: 'Продолжить',
  search: 'Поиск',

  status_all: 'Статусы',
  type_all: 'Типы',

  filter_q_placeholder: 'Название',

  created_at: 'Создано',
  updated_at: 'Обновлено',
  deleted_at: 'Удалено',

  get_tenant: 'Проекты',

  tab_general_information: 'Основная информация',
  tab_dispatch_document: 'Рассылки',
  tab_template: 'Шаблоны',
  tab_client_sender: 'Отправители',
  tab_channel_and_gate_sender: 'Отправители',
  tab_project: 'Проекты',
  tab_statistic: 'Статистика',
  tab_group_contact: 'Группы контактов',

  table_empty_text: 'Пусто',

  date_time_same_today_at: 'Сегодня в',
  date_time_same_yesterday_at: 'Вчера в',
  date_time_same_at: 'в',

  error401: 'Упс! Извините, но ваша сессия закончилась. Авторизуйтесь заново.',
  error403: 'Нет доступа.',

  available_on_corporate: 'Доступно на тарифном плане Corporate',

  error_array_max: 'Поле должно содержать не более элементов.',
  error_phone: 'Номер телефона недействителен.',
};

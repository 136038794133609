export default {
  name: 'Название',
  name_placeholder: 'Новый проект',
  description: 'Описание',
  description_placeholder: 'Проект для мультиканальных рассылок',
  web_page: 'Адрес сайта',
  web_page_placeholder: 'http://myproject.com',
  bg_color: 'Цвет метки проекта',

  tab_use_api: 'Настройки API',
  bulk_login: 'Логин',
  bulk_secret_key: 'Секретный ключ',
  bulk_idp: 'IDP',
  api_type: 'Тип API',
  callback_url: 'Callback URL для статусов доставки',
  callback_url_two_way: 'Callback URL для входящих сообщений',
  authorized_ip: 'Список разрешенных IP для API запросов',
  authorized_ip_placeholder: "192.168.0.1\n192.168.0.2",
  authorized_ip_help: 'Укажите каждый IP адрес на новой строке',

  save_success: 'Готово',
}

import form from '~/locales/uk/Template/form';
import table from '~/locales/uk/Template/table';
import show from '~/locales/uk/Template/show';
import preset from '~/locales/uk/Template/Preset';
import content_table from '~/locales/uk/Template/ContentTable';
import status_change from '~/locales/uk/Template/StatusChange';
import input_image from '~/locales/uk/Template/InputImage';
import input_document from '~/locales/uk/Template/InputDocument';
import input_location from '~/locales/uk/Template/InputLocation';
import send_moderation from '~/locales/uk/Template/SendModeration';
import send_testphone from '~/locales/uk/Template/SendTestphone';
import card from '~/locales/uk/Template/Card';
import emojione_area from '~/locales/uk/Template/EmojioneArea';
import content_layout from '~/locales/uk/Template/ContentLayout';

export default {
  title: 'Шаблони',
  title_index: 'Шаблони повідомлень',
  title_index_moderation: 'Модерація шаблонів',
  title_create: 'Створити шаблон',
  title_show: 'Шаблон {name}',
  title_show_breadcrumbs: 'Перегляд шаблону',
  title_edit: 'Шаблон повідомлення',
  title_edit_breadcrumbs: 'Редагувати шаблон',

  filter_q_placeholder: 'Назва',

  change_status: 'Змінити статус',
  preset_btn: 'Preset settings',
  send_for_moderation: 'Надіслати на модерацію',
  copy_btn: 'Створити копію',

  description: 'Шаблон повідомлення - це контент, який ви надсилаєте отримувачу: комбінація тексту, зображень, кнопок, емодзі і посилань. Кожний канал висуває власні вимоги до вмісту і формату повідомлень.<br><br>' +
    'Модерація шаблону повідомлення зазвичай займає кілька годин. Якщо надісланий шаблон відповідає вимогам каналу, він буде схвалений для використання в розсилці.',

  view_glossary: 'Глосарій',

  template_empty: 'В проекті немає шаблонів.',
  template_empty_project_more_you_can: 'Ви можете',
  template_empty_project_more_create: 'створити шаблон',
  template_empty_project_more_or: 'або',
  template_empty_project_more_select_all: 'переключити фільтр проектів на Всі проекты.',
  template_empty_project_more_select_all_after: '',
  template_empty_project_one_you_can: 'Ви можете',
  template_empty_project_one_create: 'створити шаблон',
  template_empty_project_one_create_after: 'для використання в розсилці повідомлень.',

  form,
  table,
  show,
  preset,
  content_table,
  status_change,
  input_image,
  input_document,
  input_location,
  send_moderation,
  send_testphone,
  card,
  emojione_area,
  content_layout,
};

export default {
  number_1: 'номер',
  number_2: 'номери',
  number_5: 'номерів',

  delete_1: 'видалено',
  delete_2: 'видалено',
  delete_5: 'видалено',

  double_1: 'дубль',
  double_2: 'дубля',
  double_5: 'дублей',

  result_1: 'результат',
  result_2: 'результати',
  result_5: 'результатів',

  row_1: 'рядок',
  row_2: 'рядки',
  row_5: 'рядків',

  recipient_1: 'контакт',
  recipient_2: 'контакти',
  recipient_5: 'контактів',
};

export default {
  title: 'Запланированная отправка',
  description:
    'Укажите дату и время, когда рассылка должна запуститься.<br>Вы всегда можете отменить запланированную рассылку до момента запуска.',

  send_right_now: 'Отправить прямо сейчас',
  send_tomorrow: 'Разослать завтра в {time} {offset}',
  send_at: 'Разослать {time} {offset}',

  no_timezone: 'Часовой пояс не установлен',
  error_no_timezone:
    'В ваших настройках не выбран часовой пояс. Пожалуйста, проверьте правильность вашего часового пояса.',
};

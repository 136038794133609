export default {
  create: 'Створити',

  template: 'Шаблон',
  dispatch_document: 'Розсилка',
  group_contact: 'Група контактів',
  clientsender: 'Відправник',
  company_project: 'Проект',

  company_company: 'Компанія',
}

export default {
  title: 'Действия с отправителем',

  btn_info: 'Детальная информация',
  btn_moderation: 'Отправить на модерацию',
  btn_create_template: 'Создать шаблон с отправителем',
  btn_edit: 'Редактировать отправителя',
  btn_statistics: 'Статистика по отправителю',
  btn_deactivate: 'Отключить',
  btn_activate: 'Активировать',
}

export default {
  loading: 'Завантаження...',
  remove: 'Перемістити',

  id: '',
  phone: 'Номер телефону',
  country: 'Країна',
  operator: 'Оператор',
  params: 'Параметри',

  btn_copy: 'Скопіювати до групи',

  selected_length_1: 'контакт обрано',
  selected_length_2: 'контакти обрано',
  selected_length_5: 'контактів обрано',

  deletes_confirm1: 'Видалити {ids} ',
  deletes_confirm2: 'з групи контактів "{name}"',
  deletes_success: 'Готово',
  deletes_pnotify_title: 'Видалити контакти',

  delete_confirm: 'Ви дійсно хочете видалити контакт "{name}"?',
  delete_success: 'Контакт "{name}" видалено.',
}

import content from './Content';

export default {
  progress: {
    title: 'Приєднання до оферти',
  },
  left: {
    title: 'Укладіть договір-оферту для відправки повідомлень',
    subtitle: 'Messaggio надає послуги юридичним особам і індивідуальним підприємцям/ФОП.',
    subsubtitle: 'Щоб ми мали змогу відправляти вам рахунки/акти, будь ласка, <mark>пройдіть процес акцепту умов договору-оферти.</mark>',
    link: 'Подивитись Договір приєднання (оферту) на розсилку повідомлень',
  },
  inn: {
    title: 'Вкажіть тип вашої юридичної особи',
    legal_entity: {
      flp: 'Індивідуальний підприємець/ФОП',
      egrpoy: 'Организація (ТОВ або некомерційна)',
    },
    inn: {
      title: 'Вкажіть ваш ІПН',
    },
    is_individual_entrepreneur: {
      title: 'Індивідуальний підприємець',
    },
    submit: 'Продовжити',
  },
  content,
};

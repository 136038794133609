import sms from '~/locales/uk/ClientSender/TrailerProfile/SMS';
import rcs from '~/locales/uk/ClientSender/TrailerProfile/RCS';
import viber from '~/locales/uk/ClientSender/TrailerProfile/Viber';
import whatsapp from '~/locales/uk/ClientSender/TrailerProfile/WhatsApp';
import mobileid from '~/locales/uk/ClientSender/TrailerProfile/MobileId';

export default {
  sms,
  rcs,
  viber,
  whatsapp,
  mobileid,

  select_placeholder: 'Оберіть...',
  on_clear_drive_file_confirm: 'Ви впевнені?',
};

import form_code from '~/locales/uk/Company/Testphone/ModalVerification/FormCode';
import form_phone from '~/locales/uk/Company/Testphone/ModalVerification/FormPhone';

export default {
  title: 'Прив`язати телефон',
  description: 'Протягом однієї хвилини на ваш телефон надійде безкоштовний дзвінок або SMS. Останні 4 цифри вхідного номера є кодом для прив\'язки телефону.',

  form_code,
  form_phone,
};

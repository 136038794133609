import company_fill from '~/locales/en/Widget/Alert/CompanyFill';
import accept_terms_and_conditions from '~/locales/en/Widget/Alert/AcceptTermsAndConditions';
import sender_create from '~/locales/en/Widget/Alert/SenderCreate';

export default {
  title: 'Go through the account setup process to get started',
  description: 'Messaggio allows you to send messages via plugin integration, messaging API and from Messaggio Dashboard. But before you start, we ask you to fill out a profile and accept the terms of the messaging agreement. To activate the Senders on the selected channels, you will need to top up the balance.',
  btn_submit: 'Continue',

  company_fill,
  accept_terms_and_conditions,
  sender_create,
};

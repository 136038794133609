export default {
  loading: 'Завантаження...',

  name: 'Назва',
  link: 'Посилання',
  created_at: 'Створено',

  delete_confirm: 'Ви дійсно хочете видалити посилання "{name}"?',
  delete_success: 'Посилання "{name}" видалено.',
}

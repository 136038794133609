export default {
  open_btn: 'Загрузить файл',

  title: 'Загрузить файл в Мои файлы',
  description: 'Ваш файл будет загружен в категорию <b>Мои документы</b>.<br>' +
    'После загрузки вы сможете включить публичный доступ к файлу по ссылке.',

  file: 'Файл',
  category: 'Категория',
  public: 'Публичный',
  category_documents: 'Documents',
  public_true: 'Да',
  public_false: 'Нет',

  submit: 'Загрузить',
  save_success: 'Успешно сохранено',
}

export default {
  progress: {
    title: 'Настройка профиля',
  },
  left: {
    title: 'Расскажите немного о себе',
    subtitle: 'Расскажите нам о своих задачах, чтобы мы могли подобрать для вас лучшее решение. Ваш выбор не повлияет на ограничение функционала Messaggio.',
    subsubtitle: '',
  },
};

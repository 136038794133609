export default {
  title: 'Змінити пароль',
  title_btn: 'Змінити пароль',

  old_password: {
    label: 'Поточний пароль',
    error: {
      wrong: 'Поточний пароль вказано невірно.',
    },
  },
  new_password: 'Новий пароль',
  repeat_new_password: 'Ще раз новий пароль',

  ok_title: 'Змінити пароль',
  save_success: 'Готово',
}

﻿phone;name;code
79012345678;John;123
79012345679;Adam;456
79012345680;Bob;789
79012345681;Eva;111
79012345682;David;222
79012345683;Tom;333
79012345684;Michael;444
79012345685;Kevin;555
79012345686;Peter;666
79012345687;Anna;777

import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  send({commit, state}, data) {
    return api.support.send.support(data).then(response => {
      //console.info('company/support/send | api.support.send.support | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('company/support/send | api.support.send.support | error = ', error);
      throw error;
    });
  },
};

export default {
  progress: {
    title: 'Профіль користувача',
  },
  left: {
    title: 'Використання платформи',
    subtitle: 'Дозвольте нам зв`язатися з вами для уточнення деталей співробітництва.',
    subsubtitle: 'Напишіть, будь ласка, <mark>як ми можемо до вас звертатись</mark> и <mark>номер телефону.</mark>',
  },
  form: {
    connection_aim: {
      title: 'Які завдання ви хочете вирішити за допомогою Messaggio?',
      service_msg: 'Повідомляти клієнтів про виконання замовлень або події в сервісі',
      marketing_msg: 'Робити маркетингові розсилки',
      OTP_msg: 'Використовуйте двофакторну автентифікацію',
      chats_msg: 'Спілкуватися з клієнтами в чатах з одного вікна',
    },
    onb_source: {
      title: 'Як ви дізналися про Messaggio?',
      adv: 'Реклама в інтернет',
      plugin: 'Побачив плагін у моїй CRM / CMS',
      recommend: 'Порекомендували знайомі',
      seo: 'Знайшов в інтернет',
    },
    type_integration: {
      title: 'Оберіть тип інтеграції',
      lk: 'Буду використовувати тільки особистий кабінет',
      plugin: 'Буду використовувати відправку через плагін',
      api: 'Планую інтеграцию по API',
    },
    channel: {
      title: 'Які канали зв\'язку ви плануєте використовувати?',
      sms: 'SMS',
      viber: 'Viber',
      flashcall: 'Flashcall',
      whatsapp: 'WhatsApp',
      vk: 'VKontakte',
      bottelegram: 'Telegram',
      mobileid: 'Mobile ID',
      rcs: 'RCS',
    },
    crm_system: {
      title: 'Ваша CRM-система',
      placeholder: 'CRM-система',
      list: {
        other: 'Інше',
        none: 'Не використовую CRM',
      },
    },
    call_me: {
      title: 'Зв`язатися з вами для презентації можливостей платформи?',
      yes: 'Так, будь ласка.',
    },
  },
};

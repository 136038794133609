export default {
  title: 'Генерація звіту',
  description: 'будь ласка, підтвердьте параметри генерації звіту',

  date_from: 'Дата з',
  date_to: 'Дата по',
  project: 'Проект',
  channels: 'Канал',
  client_sender: 'Відправник',
  dispatch: 'Розсилка',

  cancel: 'Відміна',
  generate_report: 'Створити звіт',
}

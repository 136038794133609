import table from '~/locales/uk/Company/Balance/LastPayment/table';

export default {
  title: 'Історія оплат {entity}',

  status_process: 'В обробці',
  status_success: 'Сплачено',
  status_canceled: 'Скасовано',

  table,
};

export default {
  additional_settings: {
    title: 'Додаткові налаштування',
    device: {
      description: 'Ви можете вказати на які пристрої потрібно доставити Viber повідомлення: на всі пристрої користувача, де він авторизувався під своїм акаунтом або тільки на первинний мобільний пристрій.',
      phone: 'Тільки мобільні пристрої',
      all: 'Всі пристрої',
    },
  },
};

import axios from "axios";
function upload(category, file, meta, { onUploadProgress } = {}) {
  const fileForm = new FormData();
  fileForm.append("data", file);
  fileForm.append("meta", JSON.stringify(meta));
  return axios.post(`/api-gateway/api/v1/drive/upload/files/${category}`, fileForm, { onUploadProgress }).then(({ data: d }) => d);
}
export {
  upload
};

import api from '~/api';

export const state = () => ({
  loading: false,
  error: false,
  contracts: [],
});

export const getters = {
  emptyContracts: (state) => !state.loading && !state.contracts.length,
};

export const mutations = {
  SET_LOADING(store, loading) {
    store.loading = loading;
  },
  SET_ERROR(store, error) {
    store.error = error;
  },
  SET_CONTRACTS(store, contracts) {
    store.contracts = contracts;
  },
};

export const actions = {
  one({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', false);
    return api.saga.contract
      .list(1, 99999, null, {
        skip_services: true,
        skip_props: true,
        //skip_accounts: true,
      })
      .then(({ data: { Body } }) => {
        const contracts = (Body || []).map((contract) => ({
          ...contract,
          // getBalanceSum: contract.accounts.reduce((r, a) => (r += a.amount, r), 0),
          getBalanceSum:
            contract.accounts.find((a) => a.account == 'cache')?.amount ??
            contract.accounts.reduce((r, a) => ((r += a.amount), r), 0),
        }));

        commit('SET_CONTRACTS', contracts);

        return contracts;
      })
      .catch((error) => commit('SET_ERROR', error))
      .finally(() => commit('SET_LOADING', false));
  },
};

import table from '~/locales/en/Company/Balance/LastPayment/table';

export default {
  title: 'Payment history {entity}',

  status_process: 'Process',
  status_success: 'Success',
  status_canceled: 'Canceled',

  table,
}

import form from '~/locales/ru/GroupContact/Contact/form';
import table from '~/locales/ru/GroupContact/Contact/table';
import show from '~/locales/ru/GroupContact/Contact/show';
import add_phone from '~/locales/ru/GroupContact/Contact/AddPhone';
import modal_show from '~/locales/ru/GroupContact/Contact/ModalShow';
import modal_copy from '~/locales/ru/GroupContact/Contact/ModalCopy';
import dump from '~/locales/ru/GroupContact/Contact/Dump';
import convert from '~/locales/ru/GroupContact/Contact/Convert';

export default {
  title: 'Группы контактов',
  title_create: 'Создать группу контактов',
  title_show: 'Контакт {name}',
  title_show_breadcrumbs: 'Посмотреть контакт',
  title_edit: '{phone} в {name}',
  title_edit_breadcrumbs: 'Редактировать контакт',

  form,
  table,
  show,
  add_phone,
  modal_show,
  modal_copy,
  dump,
  convert,
};

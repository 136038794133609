import form from '~/locales/ru/Company/Balance/form';
import contract_info from '~/locales/ru/Company/Balance/ContractInfo';
import contract_tariff from '~/locales/ru/Company/Balance/ContractTariff';
import last_payment from '~/locales/ru/Company/Balance/LastPayment';
import payment_recurrent from '~/locales/ru/Company/Balance/PaymentRecurrent';
import tariffs from '~/locales/ru/Company/Balance/Tariffs';

export default {
  title: 'Баланс',

  form,
  contract_info,
  contract_tariff,
  last_payment,
  payment_recurrent,
  tariffs,
};

import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({commit, state}, {page = 1, pagesize = 10, sort = '', observer = null, ...options} = {}) {
    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'key';
    return api.trailer.settings.list(page, pagesize, sort, options).then(async response => {
      //console.info('api.trailer.settings.list | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.trailer.settings.list | error = ', error);
      //throw error;
      throw error;
    });
  },
  one({commit, state}, {id}) {
    return api.trailer.settings.get(id).then(response => {
      //console.info('api.trailer.settings.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.trailer.settings.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.trailer.settings.post(data).then(response => {
      //console.info('api.trailer.settings.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.trailer.settings.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.trailer.settings.put(id, data).then(response => {
      //console.info('api.trailer.settings.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.trailer.settings.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.trailer.settings.delete(id).then(response => {
      //console.info('api.trailer.settings.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.trailer.settings.delete | error = ', error);
      throw error;
    });
  },
};

export function getTrailerSettingListPromises(observer, item, key = '*', id_name = 'id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'trailer/setting/list',
        {entity_id: item[id_name], key, pagesize: 99999},
        (trailerSetting) => {
          item.trailerSetting = trailerSetting;
          resolve(item);
        },
      );
    } else {
      item.trailerSetting = null;
      resolve(item);
    }
  });
}

import api from '~/api';

const baseState = {};

export const state = () => ({ ...baseState });

export const getters = {};

export const mutations = {};

export const actions = {
  uploadImage ({ commit, dispatch }, { restriction, file }) {
    // console.info('uploadImage({commit, dispatch}, {', restriction, ', ', file, '})');

    const fileForm = new FormData();
    fileForm.append('restriction', restriction);
    fileForm.append('file', file);

    return api.image.image.post(fileForm).then(response => {
      // console.info('api.image.image.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.image.image.post | error = ', error);
      throw error;
    });
  },
  listRestriction ({ dispatch, commit, state }, { page = 1, pagesize = 10, sort = '', ...options } = {}) {
    return api.image.image.restrictions(page, pagesize, sort, options).then(async response => {
      // console.info('api.documents.dispatch_document.restriction | response = ', response.data);
      const items = response.data.Body || [];
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.documents.dispatch_document.list | error = ', error);
      throw error;
    });
  },
  list ({ dispatch, commit, state }, { page = 1, pagesize = 10, sort = '', ...options } = {}) {
    return api.image.image.list(page, pagesize, sort, options).then(async response => {
      // console.info('api.documents.dispatch_document.list | response = ', response.data);
      const items = response.data.Body || [];
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.documents.dispatch_document.list | error = ', error);
      throw error;
    });
  },
  delete ({ commit, state }, { id }) {
    return api.image.image.delete(id).then(response => {
      // console.info('api.image.image.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.image.image.put | error = ', error);
      throw error;
    });
  },
};

import phone_is_empty from '~/locales/uk/Company/Notification/PhoneIsEmpty';

export default {
  messaging: 'Розсилки',
  launch_of_scheduled_dispatch: 'Запуск запланованої розсилки',
  message_template_moderation: 'Модерація шаблону',
  chatting: 'Чати',
  chat_new_message_alerts: 'Показувати спливаючі сповіщення про нові повідомлення',
  senders: 'Відправники',
  sender_moderation: 'Модерація відправників',
  sender_activation_or_deactivation: 'Активація і деактивація відпраника',
  sender_deactivation_reminder: 'Нагадування про деактивацію',
  balance_and_payments: 'Баланс і платежі',
  low_balance_reminder: 'Попередження про низкий баланс',
  low_balance_reminder_subtitle: 'Ви отримаєте Email сповіщення на поштову скриньку, що вказана у профілі.',
  low_balance_reminder_badge: 'EMAIL',
  low_balance_reminder_mobile: 'Попередження про низький баланс',
  low_balance_reminder_mobile_subtitle: 'Ви отримаєте Viber або SMS сповіщення на телефон, прив`язаний до вашого профілю.',
  low_balance_reminder_mobile_badge: 'VIBER або SMS',
  payment_status_notifications: 'Статус платежу',
  system: 'Система',
  messaging_channel_technical_failure_notification: 'Технічні несправності на каналі розсилки',
  detailed_statistics_file_generation_completed: 'Готовність вивантаження детальної статистики',
  user_agreement_updates: 'Оновлення угоди користувача',
  scheduled_service_maintenance_reminder: 'Заплановані техничні роботи на платформі',
  technical_accident_notices: 'Сповіщення про технічні сбої платформи',

  success: 'Сповіщення',
  save_success: 'Налаштування сповіщень оновлені.',

  phone_is_empty,
};

import table from '~/locales/en/Company/Balance/ContractTariff/table';

export default {
  table,

  filter: {
    country_name: {
      placeholder: 'Country name',
    },
    operator_name: {
      placeholder: 'Operator name',
    },
  },
}

import api from '~/api';
import {getProjectOnePromises} from "~/store/project";
import ObserverApi from "~/utils/ObserverApi";
import {getClientSenderOnePromises} from "~/store/clientsender/index";
import {getRouteDestinationOnePromises} from "~/store/channel-and-gate/route/destination";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10, sort = '', observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    if (sort.length > 0) sort += ',';
    sort += 'id';
    return api.documents.route.list(page, pagesize, {order_by: sort}).then(async response => {
      //console.info('api.documents.route.list | response = ', response.data);
      let items = response.data.Body || [];
      items = await Promise.all(items.map((item) => getProjectOnePromises(observer, item)));
      items = await Promise.all(items.map((item) => getRouteDestinationOnePromises(observer, item)));
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.route.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {id, observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.documents.route.get(id).then(async response => {
      //console.info('api.documents.route.one | response = ', response.data);
      response.data = await getProjectOnePromises(observer, response.data);
      response.data = await getRouteDestinationOnePromises(observer, response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.route.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.route.post(data).then(response => {
      //console.info('api.documents.route.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.route.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.documents.route.put(id, data).then(response => {
      //console.info('api.documents.route.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.route.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.route.delete(id).then(response => {
      //console.info('api.documents.route.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.route.put | error = ', error);
      throw error;
    });
  },
  statusChange({dispatch, commit, state}, {id, status}) {
    return api.documents.route.status.put(id, {status}).then(response => {
      //console.info('api.documents.route.status.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.route.status.put | error = ', error);
      throw error;
    });
  },
};

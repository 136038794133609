import { render, staticRenderFns } from "./WidgetMenuLeft.vue?vue&type=template&id=0a0e1411&scoped=true&"
import script from "./WidgetMenuLeft.vue?vue&type=script&lang=js&"
export * from "./WidgetMenuLeft.vue?vue&type=script&lang=js&"
import style0 from "./WidgetMenuLeft.vue?vue&type=style&index=0&id=0a0e1411&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@3.4.32_babel-core@7.0.0-bridge.0_@babel+core@7.24.9__css_uv5biolnn6ywtbh3eesigpjl3u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a0e1411",
  null
  
)

export default component.exports
export default {
  title_active: 'Submit sender for activation',
  scheduled_date_active: 'Specify the activation day',
  description_active: 'If the application is approved, then the activation of the sender will occur automatically on the specified day.',
  submit_active: 'Request activation',
  submit_active_success: 'Sender "{name}" was sent to activation.',

  title_deact: 'Submit sender for deactivation',
  scheduled_date_deact: 'Specify the deactivation day',
  description_deact: 'If the application is approved, then the deactivation of the sender will occur automatically on the specified day.',
  submit_deact: 'Request deactivation',
  submit_deact_success: 'Sender "{name}" was sent to deactivation.',

  error_scheduled_date: 'Scheduled activation date should be older than today.',
}

import { Dispatch } from 'vuex';

export default class {
  dispatch: Dispatch;
  relate: string[];
  results: any[];
  observers: any[];

  constructor (dispatch: Dispatch, relate: string[] = []) {
    this.dispatch = dispatch;
    this.relate = relate;
    this.results = [];
    this.observers = [];
  }

  subscribeObserver (type, { observer, relate, ...payload }, callBack) {
    const findF = result => result.type === type && this.isEquivalent(result.payload, payload);
    const findResults = this.results.find(findF);
    if (findResults) {
      callBack(findResults.result);
    } else {
      const findObservers = this.observers.find(findF);
      this.observers.push({ type, payload, callBack });
      if (!findObservers) {
        this
          .dispatch(type, { observer: this, ...payload }, { root: true })
          .then(result => {
            this.results.push({ type, payload, result });
            this.notifyObserver({ type, payload, result });
          })
          .catch(err => {
            console.error('ObserverApi | subscribeObserver | type = ', type, ' | payload = ', payload, ' | err = ', err);
            this.notifyObserver({ type, payload, result: null });
          });
      }
    }
  }

  notifyObserver ({ type, payload, result }) {
    this
      .observers
      .filter(observer => observer.type === type && this.isEquivalent(observer.payload, payload))
      .map(observer => observer.callBack(result));
  }

  isEquivalent (a, b) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }
}

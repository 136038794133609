export default {
  file_add:'Добавить контакты',

  q_placeholder: 'Номер телефона или параметр',

  id: 'ID',
  name: 'Название',
  description: 'Описание',
  description_empty: 'Описание не задано',
  tenant: 'Компания',
  row_count: 'Количество номеров',
  type: 'Тип группы',
  status: 'Статус',

  tab_contact:'Номера телефонов',

  file_size_more:'Размер файла больше чем {size}Mb.',
  file_type_support:'Поддерживаемые форматы: txt, csv.',

  list_phone_numbers: 'Список номеров телефонов',

  import_contacts_title: 'Еще не готово?',
  import_contacts_description: 'Импортируйте контакты в группу',
  import_contacts_btn: 'Импортировать контакты',

  contact_empty: 'В группе нет контактов.',
  contact_empty_project_one_you_can: 'Вы можете',
  contact_empty_project_one_create: 'импортировать контакты',
  contact_empty_project_one_create_after: 'с дополнительными параметрами, чтобы создавать персонализированные шаблоны сообщений.',

  show_more: 'Развернуть',
  show_less: 'Свернуть',
}

<template>
  <nuxt-link
    :to="{name: 'company-billing'}"
    :class="{'text-nowrap': contracts.length === 1}"
  >
    <slot />
    <span
      v-if="loading || error"
      :class="[error ? 'badge-danger': 'badge-light']"
      class="badge ml-xl-2 position-relative"
      style="width: 50px;"
    >&nbsp;</span>
    <template v-else>
      <span
        v-for="(contract, i) in contracts"
        :key="`contract-${i}`"
        :class="{'mb-xl-1': contracts.length > 1}"
        class="d-none d-md-inline-block badge ml-xl-2 position-relative badge-pill badge-flat border-primary text-primary-600 mb-xl-1"
      >
        {{ contract.getBalanceSum | number_format_2 }}
        {{ contract.currency_id }}
      </span>
    </template>
  </nuxt-link>
</template>

<script>
export default {
  name: 'WidgetBalance',
  computed: {
    balance () {
      return this.$store.state.widget.balance;
    },
    loading () {
      return this.balance.loading;
    },
    error () {
      return this.balance.error;
    },
    contracts () {
      return this.balance.contracts;
    },
  },
};
</script>

<style scoped>

</style>

export default {
  name: 'Ім`я',
  manager: 'Користувач',
  message: 'Повідомлення',
  channel: 'Канал',
  status: 'Статус',
  type_image: 'Зображення',
  type_document: 'Документ',
  type_video: 'Відео',

  loading: 'Завантаження Відправник...',
  delete_confirm: 'Ви дійсно хочете видалити Відправник "{name}"?',
  delete_success: 'Відправник "{name}" видалено.',
}

export default {
  open_btn: 'Завантажити файл',

  title: 'Завантажити файл в Мої файли',
  description: 'Ваш файл буде завантажено в категорію <b>Мої документи</b>.<br>' +
    'Після завантаження ви зможете включити публічний доступ до файлу за посиланням.',

  file: 'Файл',
  category: 'Категорія',
  public: 'Публічний',
  category_documents: 'Документи',
  public_true: 'Так',
  public_false: 'Ні',

  submit: 'Завантажити',
  save_success: 'Успішно збережено',
}

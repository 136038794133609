export default {
  name: 'Name',
  description: 'Description',
  tenant_id: 'Company',
  web_page: 'Site URL',

  tab_use_api: 'API settings',
  bulk_login: 'Bulk login',
  bulk_secret_key: 'Secret key',
  bulk_idp: 'IDP',
  api_type: 'API type',
  callback_url: 'Callback URL',
  callback_url_empty: 'URL not set',
  callback_url_two_way: 'Messages Callback URL',
  callback_url_two_way_empty: 'URL not set',

  tab_api_setting: 'API Settings',
}

export default {
  phone: 'Номер телефона',
  name: 'ФИО',
  first_name: 'Имя',
  second_name: 'Отчество',
  last_name: 'Фамилия',
  birth_day: 'День рождения',
  code: 'Код',
  email: 'EMail',
  date: 'Дата',
  sum: 'Сумма',
  param_1: 'Параметр 1',
  param_2: 'Параметр 2',
  param_3: 'Параметр 3',

  name_tag: '[[NAME]]',
  first_name_tag: '[[FIRST_NAME]]',
  second_name_tag: '[[SECOND_NAME]]',
  last_name_tag: '[[LAST_NAME]]',
  birth_day_tag: '[[BIRTH_DAY]]',
  code_tag: '[[CODE]]',
  email_tag: '[[EMAIL]]',
  date_tag: '[[DATE]]',
  sum_tag: '[[SUM]]',
  param_1_tag: '[[PARAM_1]]',
  param_2_tag: '[[PARAM_2]]',
  param_3_tag: '[[PARAM_3]]',
};

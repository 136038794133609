export default {
  progress: {
    title: 'Profile setup',
  },
  left: {
    title: 'Tell us a little about yourself.',
    subtitle: 'Tell us about your tasks so we can find the best solution for you. Your choice will not affect the Messaggio functionality limit.',
    subsubtitle: '',
  },
};

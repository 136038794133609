export default {
  title: 'Створити посилання',

  project_id: 'Проект',
  select_project: 'Проект',
  select_project_title: 'Оберіть проект',
  link_shortening: 'Коротке посилання',
  select_domain: 'Домен',
  domain_id: 'Домен',
  uri_long: 'Довгий URL',
  uri_short: 'Короткий URL',
  created_at: 'Створено',
  updated_at: 'Оновлено',

  save_success: 'Готово',
}

export default {
  title: 'Служба поддержки',
  subject: 'Причина обращения',
  body: 'Описание',
  btn_submit: 'Отправить запрос',

  subject_placeholder: 'Выберите...',
  subject_messaging: 'Рассылка сообщений',
  subject_messaging_dashboard_web_interface_issue: 'Проблемы с интерфейсом личного кабинета',
  subject_messaging_sender_id_moderation_issue: 'Вопросы по согласованию Имени отправителя',
  subject_messaging_message_template_moderation_issue: 'Вопросы по согласованию шаблона сообщения',
  subject_messaging_contacts_upload_issue: 'Проблемы с загрузкой базы контактов',
  subject_messaging_message_delivery_issue: 'Проблемы с рассылкой сообщений',
  subject_integration: 'Интеграция',
  subject_integration_yclients_integration: 'Интеграция с YClients',
  subject_integration_insales_integration: 'Интеграция с InSales',
  subject_integration_api_integration: 'Интеграция API',
  subject_partnership: 'Сотрудничество',
  subject_partnership_commercial_issue: 'Коммерческие вопросы',
  subject_partnership_new_messaging_service_request: 'Подключение новых услуг',
  subject_other: 'Другое',

  save_success_title: 'Служба поддержки',
  save_success: 'Запрос отправлен в службу поддержки.',
}

<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h5 class="card-title">
        {{ $t(`${tForm}.select_template_channels`) }}
      </h5>
      <div class="header-elements" />
    </div>
    <widget-stub-empty
      v-if="showSenderEmpty"
      icon-static="image/scheme/sender.png"
      trans-prefix="client_sender.sender_empty"
      route-create-name="sender-create"
      route-select-all-project-name="sender"
    />
    <template v-else>
      <div class="card-body">
        <form-project
          v-model="projectId_"
          :t-form="tForm"
          :form-name="formName"
          :disabled="isEdit"
          :project-list="projectList"
        />
        <form-channels
          v-if="!!projectId && !!channels"
          :t-form="tForm"
          :form-name="formName"
          :list-channels="channels"
          :list-client-senders="client_senders"
          :contents="contents"
          @update-channel="onUpdateChannel"
        />
      </div>
      <slot name="footer" />
      <div
        v-if="!hideFooterSubmit"
        class="card-footer text-right"
      >
        <w-btn-save
          :disabled="submit"
          :method="method"
        />
      </div>
    </template>
    <widget-loader v-if="loading" />
  </div>
</template>

<script>
import { catchDispatch } from '@/utils/form';
import WidgetStubEmpty from '@/components/Widget/WidgetStubEmpty';
import FormChannels from '@/components/Template/Form/Channel/ChannelsBaseFormTemplateComponent';
import FormProject from '@/components/Template/Form/Channel/ProjectBaseFormTemplateComponent';

export const channelBaseSort = ['viber', 'sms'].reverse();

export default {
  name: 'ChannelTemplate',
  components: {
    FormChannels,
    FormProject,
    WidgetStubEmpty,
  },
  props: {
    tForm: {
      type: String,
      default: 'template.form',
    },
    formName: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: '',
    },
    projectList: {
      type: Array,
      default: () => ([]),
    },
    projectId: {
      type: String,
      default: '',
    },
    contents: {
      type: Array,
      default: () => ([]),
    },
    submit: {
      type: Boolean,
      default: false,
    },
    hideFooterSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    channels: undefined,
    client_senders: {},
  }),
  computed: {
    projectId_: {
      get () {
        return this.projectId;
      },
      set (id) {
        this.$emit('update:project-id', id);
      },
    },
    isEdit () {
      return this.method === 'edit';
    },
    showSenderEmpty () {
      return !this.$props.projectList.length;
    },
  },
  watch: {
    projectId () {
      this.channels = undefined;
      this.loadClientSender();
    },
  },
  created () {
    this.projectId.length && this.loadClientSender();
  },
  methods: {
    loadClientSender () {
      if (this.loading || this.projectId === '') { return; }
      this.loading = true;

      this.$store
        .dispatch('template/form/getChannelsClientSenderByProjectID', this.projectId)
        .then(({ channels, client_senders, client_senders_original }) => {
          // console.info('channels', channels)
          channels = Object.values(channels)
            .filter(({ id }) => !!client_senders[id])
            .sort((a, b) => {
              if (a.name < b.name) { return -1; }
              if (a.name > b.name) { return 1; }
              return 0;
            });
          channelBaseSort.forEach(name => {
            const channelIndex = channels.findIndex(({ name: channelName }) => channelName === name);
            if (channelIndex === -1) { return; }
            const channel = channels[channelIndex];
            channels.splice(channelIndex, 1);
            channels.unshift(channel);
          });
          this.channels = channels;
          this.client_senders = client_senders;

          if (client_senders_original.length === 1) {
            const client_sender = client_senders_original[0];
            const channel = channels[client_sender.channel_id];

            this.$emit('update-channel', { channel, client_sender });
          }
        })
        .catch(e => catchDispatch(this, e))
        .finally(() => { this.loading = false; });
    },
    onUpdateChannel (data) {
      this.$emit('update-channel', data);
    },
  },
};
</script>

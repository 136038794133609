export default {
  clientsender: 'Sender',
  channel_name: 'Channel',
  message_status: 'Status Code',
  phone_number: 'Contact Phone',
  message_id: 'Message ID',
  message_time: 'Time',
  accepted: 'Accepted Date Time',
  message_date: 'Date',
  count: 'Quantity',
  operator_name: 'Operator',
  mobile_operator: 'Operator',

  message_status_count_total: 'Total',
  message_status_count_sent: 'Sent',
  message_status_count_not_viber_user: 'Not Viber User',
  message_status_count_delivered: 'Delivered',
  message_status_count_not_delivered: 'Not delivered',
  message_status_count_watched: 'Seen',
  message_status_count_clicked: 'Clicked',

  download_csv_report: 'Download CSV Report',
  open_stat: 'Open stat',
  sender_details: 'Sender details',
  export_to_csv: 'Export to .csv',

  delete_confirm: 'Are you sure you want to delete Dispatch "{name}"?',
  delete_success: 'Dispatch "{name}" was deleted.',
}

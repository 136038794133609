export default {
  title: 'Предварительный просмотр',
  ok_title: 'Продолжить',
  cancel_title: 'Отменить',

  content_info: '<b>Установите тип данных для каждой колонки.</b><br>Вы можете загружать контакты с дополнительными параметрами, чтобы использовать их в переменных шаблона. Назначьте имя переменной для данных в колонке, указав его в выпадающем списке.',
  table_header: 'Заголовок таблицы',
  skip_first_row: 'Исключить первую строку',

  phone_no_select: 'Не указана колонка Телефон',
  file_processing: 'Обработка файла...',

  params_phone: 'Телефон',
  params_name: 'ФИО [[NAME]]',
  params_first_name: 'Имя [[FIRST_NAME]]',
  params_second_name: 'Отчество [[SECOND_NAME]]',
  params_last_name: 'Фамилия [[LAST_NAME]]',
  params_birth_day: 'День рождения [[BIRTH_DAY]]',
  params_code: 'Код [[CODE]]',
  params_email: 'EMail [[EMAIL]]',
  params_date: 'Дата [[DATE]]',
  params_sum: 'Сумма  [[SUM]]',
  params_param_1: 'Параметр 1 [[PARAM_1]]',
  params_param_2: 'Параметр 2 [[PARAM_2]]',
  params_param_3: 'Параметр 3 [[PARAM_3]]',

  params_phone_name: 'Телефон',
  params_name_name: 'ФИО',
  params_first_name_name: 'Имя',
  params_second_name_name: 'Отчество',
  params_last_name_name: 'Фамилия',
  params_birth_day_name: 'День рождения',
  params_code_name: 'Код',
  params_email_name: 'EMail',
  params_date_name: 'Дата',
  params_sum_name: 'Сумма ',
  params_param_1_name: 'Параметр 1',
  params_param_2_name: 'Параметр 2',
  params_param_3_name: 'Параметр 3',

  save_success: 'Готово',
};

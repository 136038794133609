export default {
  tab_sender_details: 'Sender details',

  project: 'Project',
  name_en: 'Name in English',
  ttl: 'TTL (Time To Live) of messages',
  code_api: 'Sender code for API',
  bulk_login: 'Project Login',
  bulk_secret_key: {
    title: 'Secret key',
    is_empty: 'Not set.',
    set: 'Set secret key',
  },
  api_type: 'Type API',
  callback_url: 'Callback URL for delivery statuses',
  callback_url_two_way: 'Callback URL for incoming messages',
  channel: 'Channel',
  comparison: 'Linked',
  comparison_empty: 'Not linked',
  status: 'Status',
  statuses: 'Status history',
  statuses_user_id: 'User',
  statuses_date: 'Date',
  statuses_status: 'Status',

  dispatch_document: 'Dispatches',
  template: 'Templates',

  work_in_progress_title: 'Work in progress?',
  work_in_progress_description: 'Complete the creation of the Sender',

  info_title_status_moderation: 'The sender goes through a moderation process',
  info_description_status_moderation: 'Please wait while we verify the provided sender information. It usually takes several business days.',

  scheduler_change_client_sender_status_deact_title: 'Sender deactivation at {date}',
  scheduler_change_client_sender_status_deact_description: 'Once the Sender is deactivated, you cannot use it to send messages. To cancel deactivation,',
  scheduler_change_client_sender_status_deact_link: 'contact support service',

  not_specified: 'Not specified',

  ttl_d: '{d} d.',
  ttl_h: '{h} h.',
  ttl_m: '{m} min.',
};

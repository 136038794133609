export default {
  label_btn: 'Изображение профиля',
  btn_modal_open: 'Обновить изображение',

  modal_title: 'Загрузка аватара',
  modal_description: 'Загрузите изображение для использования в качестве аватара или сгенерируйте случайный.',

  file_title: 'Загрузить изображение',
  file_label: 'Выберите файл для загрузки',
  file_description: 'Допустимые форматы: *.jpg, *.png. Максимальный размер файла 4Mb.',

  generate_title: 'Сгенерировать случайный аватар',
  btn_generate: 'Сгенерировать',

  widget_otp_name: 'Обновление аватары пользователя',

  submit: 'Сохранить изменения',
  saving_changes: 'Сохранение изменений...',
}

export default {
  loading: 'Завантаження статистики...',

  title: 'Статистика розсилки',
  empty_data: 'Повідомлення відсутні',

  message_status_count_impossible_to_deliver: 'Доставка неможлива',
  message_status_count_sent: 'Надіслано',
  message_status_count_delivered: 'Доставлено',
  message_status_count_watched: 'Переглянуто',
}

import content from './Content';

export default {
  progress: {
    title: 'Message distribution agreement',
  },
  left: {
    title: 'Accept our terms and conditions to send messages',
    subtitle: 'Messaggio provides services to legal entities and individual entrepreneurs.',
    subsubtitle: 'Please, <mark>go through the process of accepting the terms and conditions.</mark>',
    link: 'View the Message distribution contract',
  },
  inn: {
    title: 'Specify the type of your legal entity',
    legal_entity: {
      flp: 'Individual entrepreneur',
      egrpoy: 'Organization (LTD, LLC or non-commerce)',
    },
    inn: {
      title: 'Specify your tax number',
    },
    is_individual_entrepreneur: {
      title: 'Individual entrepreneur',
    },
    submit: 'Continue',
  },
  content,
};

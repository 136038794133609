import region_option from '~/locales/en/ClientSender/TrailerProfile/RCS/RegionOption';

export default {
  service_name_original_language: 'Service name (original language)',
  service_name_english_language: 'Service name (English)',
  region: 'Region',
  region_option,
  description: 'Description',
  description_helper: '(up to 100 any characters)',
  chat_dialog_color: 'Chat dialog color',
  logo_img: 'Sender logo (224 x 224)',
  logo_chatscreen: 'Large banner image (1440 x 448)',
  main_phone_number: 'Main phone number',
  primary_website: 'Primary website',
  primary_email: 'Primary email',
  privacy_policy_link: 'Privacy policy link',
  terms_of_service_link: 'Terms of service link',
}

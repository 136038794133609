export default {
  tariffs: 'Ціни на розсилку повідомлень',

  general: 'Баланс',
  bonus: 'Бонус',
  credit: 'Кредит',

  credit_limit: 'Кредитний ліміт',
  credit_limit_used: '{percent}% вик.',

  service_name: 'Послуга',
  service_terminates_at: 'Активна до',
  service_tariff: 'Тарифікація',
  service_empty: 'Активні сервіси відсутні',
}

import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';
import {directoryHighlight} from '~/utils/functions';

export const stateInit = {
  list: {
    loading: false,
    data: {}
  },
};

export const state = () => stateInit;

export const getters = {
  search: state => q => {
    return Object.values(state.list.data).filter(item => {
      if (item.code && item.code.toLowerCase().indexOf(q) !== -1) {
        return true;
      } else if (item.name && item.name.toLowerCase().indexOf(q) !== -1) {
        return true;
      } else if (item.name_i18n && item.name_i18n.ru && item.name_i18n.ru.toLowerCase().indexOf(q) !== -1) {
        return true;
      } else if (item.name_i18n && item.name_i18n.uk && item.name_i18n.uk.toLowerCase().indexOf(q) !== -1) {
        return true;
      } else if (item.short_name && item.short_name.toLowerCase().indexOf(q) !== -1) {
        return true;
      } else if (item.short_name_i18n && item.short_name_i18n.ru && item.short_name_i18n.ru.toLowerCase().indexOf(q) !== -1) {
        return true;
      } else if (item.short_name_i18n && item.short_name_i18n.uk && item.short_name_i18n.uk.toLowerCase().indexOf(q) !== -1) {
        return true;
      }
      return false;
    }).map(item => {
      const link = {name: 'directory-country-group-code-show', params: {code: item.code}};
      const type = 'country-group';
      const name = directoryHighlight(q, item.name);
      return {link, name, type, item};
    });
  },
};

export const mutations = {
  listLoading(state, loading) {
    state.list.loading = loading;
  },
  listData(state, data) {
    state.list.data = data;
  }
};

export const actions = {
  async listPreLoad({commit, dispatch}) {
    commit('listLoading', true);
    dispatch('list', {all: true})
      .then(items => items.items.reduce((ret, item) => {
        ret[item.code] = item;
        return ret;
      }, {}))
      .then(items => {
        commit('listData', items);
        commit('listLoading', false);
      });
  },
  async list({commit, state}, {page = 1, pagesize = 10, all = false} = {}) {
    let items = [];
    let totalRows = 0;

    if (all) {
      page = 1;
      pagesize = 1000;
    }
    while (true) {
      try {
        const response = await api.librarium.country_group.list(page, pagesize);

        //console.info('api.librarium.country_group.list | response = ', response.data);
        if (!response.data.length) break;

        items = items.concat(response.data);
        totalRows = parseInt(response.headers['x-total'] || 0);
      } catch (error) {
        console.error('api.librarium.country_group.list | error = ', error);
        throw error;
      }

      if (all) {
        page++;
      } else {
        break;
      }
    }

    return {items, totalRows};
  },
  one({commit, state}, {code}) {
    return api.librarium.country_group.get(code).then(response => {
      //console.info('api.librarium.country_group.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.country_group.one | error = ', error);
      throw error;
    });
  },
  one_stages({commit, state}, {code}) {
    return api.librarium.country_group.get_stages(code).then(response => {
      //console.info('api.librarium.country_group.one_stages | response = ', response.data);
      return response.data || [];
    }).catch(error => {
      console.error('api.librarium.country_group.one_stages | error = ', error);
      throw error;
    });
  },
  stage_one({dispatch, commit, state}, {stage_id, observer = null, relate = true} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.librarium.country_group.stage.get(stage_id).then(async response => {
      //console.info('api.librarium.country_group.one | response = ', response.data);
      if (relate) {
      }
      return response.data;
    }).catch(error => {
      console.error('api.librarium.country_group.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    data = validDataSave(data);
    return api.librarium.country_group.stage.post(data).then(response => {
      //console.info('api.librarium.country_group.stage.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.country_group.stage.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {stage_id, data}) {
    data = {...data};
    delete data.code;
    data = validDataSave(data);
    return api.librarium.country_group.stage.put(stage_id, data).then(response => {
      //console.info('api.librarium.country_group.stage.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.country_group.stage.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {stage_id}) {
    return api.librarium.country_group.stage.delete(stage_id).then(response => {
      //console.info('api.librarium.country_group.stage.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.country_group.stage.put | error = ', error);
      throw error;
    });
  },
};

function validDataSave(data) {
  delete data.stage_id;
  delete data.created_at;

  return data;
}

import viber from '~/locales/uk/Template/ContentLayout/Viber';
import rcs from '~/locales/uk/Template/ContentLayout/RCS';
import telegram from '~/locales/uk/Template/ContentLayout/Telegram';
import whatsapp from '~/locales/uk/Template/ContentLayout/Whatsapp';

export default {
  viber,
  rcs,
  telegram,
  whatsapp,
};

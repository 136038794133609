export default {
  title: 'Application details',
  visit_application_website: 'Visit application website',
  connected_by: 'Connected by {name}',
  access_granted: 'Access granted',
  btn_delete_app: 'Delete app',

  delete_confirm: 'Are you sure you want to delete app "{name}"?',
  delete_success: 'App "{name}" was deleted.',
}

export default {
  title: 'Report generation',
  description: 'Please confirm the launch of the report generation',

  date_from: 'Date from',
  date_to: 'Date to',
  project: 'Project',
  channels: 'Channels',
  client_sender: 'Sender',
  dispatch: 'Dispatch',
  all: 'All',

  cancel: 'Cancel',
  generate_report: 'Generate report',
}

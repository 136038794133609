export default {
  title: 'Створити посилання',

  project_id: 'Проект',
  name_placeholder: 'Посилання {time}',
  select_project: 'Проект',
  select_project_title: 'Оберіть проект',
  project_id_placeholder: 'Оберіть проект',
  link_shortening: 'Коротке посилання',
  select_domain: 'Домен',
  domain_id: 'Домен',
  uri_long: 'Довгий URL',
  uri_short: 'Короткий URL',
  uri_long_placeholder: 'Ваш довгий URL',
  uri_short_placeholder: 'Коротка адреса',

  save_success: 'Готово',

  error_uri_short_duplicate: 'Url вже існує',
  error_attempt_max: 'Перевищено кількість нових статичних посилань на день',
  error_not_active_sender: 'Немає активованого відправника',
}

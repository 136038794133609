import axios from "axios";
import _ from "lodash";
function quickTask(modelID) {
  return (req) => {
    return axios.post(`/documents-scheduler/api/v1/templates/${modelID}/quick-task`, _.clone(req)).then(({ data: d }) => d);
  };
}
export {
  quickTask
};

export default {
  loading: 'Загрузка...',

  name: 'Name',
  link: 'Link',
  created_at: 'Created',
  select_btn: 'Select',

  chart_click: 'click',

  delete_confirm: 'Вы действительно хотите удалить ссылку "{name}"?',
  delete_success: 'Ссылк "{name}" удалена.',
}

export default {
  clientsender: 'Отправитель',
  channel_name: 'Канал',
  message_status: 'Статус',
  phone_number: 'Контакт',
  message_id: 'ID Сообщения',
  message_time: 'Время',
  accepted: 'Дата и время сообщения',
  message_date: 'Дата',
  count: 'Количество',
  operator_name: 'Оператор',
  mobile_operator: 'Оператор',

  message_status_count_total: 'Всего',
  message_status_count_sent: 'Отправлено',
  message_status_count_not_viber_user: 'Не пользователь Viber',
  message_status_count_delivered: 'Доставлено',
  message_status_count_not_delivered: 'Не доставлено',
  message_status_count_watched: 'Просмотрено',
  message_status_count_clicked: 'Клики',

  download_csv_report: 'Скачать отчет CSV',
  open_stat: 'Открыть',
  sender_details: 'Об отправителе',
  export_to_csv: 'Экспорт в CSV',

  delete_confirm: 'Вы действительно хотите удалить рассылку "{name}"?',
  delete_success: 'Рассылка "{name}" удалена.',
}

export default {
  title: 'Подготовка к отправке рассылки',
  description: 'Пока рассылка будет в процессе, вы не сможете её остановить.<br>Пожалуйста, подтвердите, что указанная ниже информация верна.',

  name: 'Название',
  channel: 'Канал',
  preview: 'Предпросмотр',

  schedule_title: 'Настройки рассылки',
  schedule_sending: 'Запланировать отправку',
  schedule_description: 'Укажите дату и время старта рассылки.<br>Вы сможете отменить запланированную рассылку до момента ее отправки.',
  cancel_dispatch_schedule: 'Отменить отправку',
  start_dispatch_schedule: 'Запланировать рассылку',
  error_scheduled_date: 'Укажите корректную будущую дату и время',

  start_dispatch: 'Разослать',

  submit_info: 'Подготовка к рассылке',
  submit_progress: 'Подготовка контактов к рассылке',
  submit_success: 'Рассылка отправляется',
  submit_schedule_success: 'Запланирована на {time}',
  cancel_schedule_success: 'Планирование рассылки отменено',
}

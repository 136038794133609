export default {
  progress: {
    title: 'Профиль пользователя',
  },
  left: {
    title: 'Использование платформы',
    subtitle: 'Позвольте нам связаться с вами для уточнения деталей сотрудничества.',
    subsubtitle: 'Напишите, пожалуйста, <mark>как мы можем к вам обращаться</mark> и <mark>номер телефона.</mark>',
  },
  form: {
    connection_aim: {
      title: 'Какие задачи хотите решить с Messaggio?',
      service_msg: 'Уведомлять клиентов о выполнении заказов или событиях в сервисе',
      marketing_msg: 'Делать маркетинговые рассылки',
      OTP_msg: 'Использовать двух-факторную аутентификацию',
      chats_msg: 'Общаться с клиентами в чатах из одного окна',
    },
    onb_source: {
      title: 'Как вы узнали про Messaggio?',
      adv: 'Реклама в интернет',
      plugin: 'Увидел плагин в моей CRM/CMS',
      recommend: 'Порекомендовали знакомые',
      seo: 'Нашел в интернет',
    },
    type_integration: {
      title: 'Выберите тип интеграции',
      lk: 'Буду использовать только личный кабинет',
      plugin: 'Буду использовать отправку через плагин',
      api: 'Планирую интеграцию по API',
    },
    channel: {
      title: 'Какие каналы связи планируете использовать?',
      sms: 'SMS',
      viber: 'Viber',
      flashcall: 'Flashcall',
      whatsapp: 'WhatsApp',
      vk: 'ВКонтакте',
      bottelegram: 'Telegram beta',
      mobileid: 'Mobile ID',
      rcs: 'RCS',
    },
    crm_system: {
      title: 'Ваша CRM-система',
      placeholder: 'CRM-система',
      list: {
        other: 'Другое',
        none: 'Не использую CRM',
      },
    },
    call_me: {
      title: 'Связаться с вами для презентации возможностей платформы?',
      yes: 'Да, пожалуйста.',
    },
  },
};

var TAG;
(function(TAG2) {
  TAG2["MOBILE"] = "mobile";
  TAG2["TEMPLATE"] = "template";
  TAG2["CLIENTSENDER"] = "clientsender";
  TAG2["LOW_BALANCE"] = "low_balance";
  TAG2["LOW_BALANCE_MOBILE"] = "low_balance_mobile";
  TAG2["ZERO_BALANCE_MOBILE"] = "zero_balance_mobile";
  TAG2["PAYMENT"] = "payment";
  TAG2["FIN_DOC"] = "fin_doc";
})(TAG || (TAG = {}));
export {
  TAG
};

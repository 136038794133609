export default {
  title: 'Шаблон розсилки',
  title_ab: 'Шаблон {type}',

  tab_all: 'Всі шаблони повідомлень',
  tab_favorite: 'Обрані шаблони',

  select_project_title: 'Оберіть проект',
  title_select_template: 'Оберіть шаблон',

  draggable_description: 'Оберіть для відправки необхідні повідомлення, використовуючи перемикач "Надіслати". <br><br>Перетягніть блоки повідомлень в потрібній черговості доставки: якщо контакту не буде доставлено повідомлення #1, то буде зроблена спроба доставити повідомлення #2 і так далі.',

  select_project: 'Вкажіть проект, з якого слід обрати шаблон',
  project_id: 'Проект',
  project_id_placeholder: 'Оберіть проект',
  project_id_placeholder_empty: 'Немає проектів з активними шаблонами',

  select_another_template: 'Обрати інший шаблон',

  not_delivered_send_to: 'Якщо не доставлено, відправиться:',
  templates_not_found: 'Не вдалось знайти шаблон з такою назвою.',

  create: {
    description: 'Оберіть повідомлення, які ви хочете відправити, використовуючи перемикач «Надіслати».<br><br>' +
      'Перетягніть блоки повідомлень в бажаному порядку доставки: якщо повідомлення №1 не доставлено контакту, ' +
      'буде зроблена спроба доставити повідомлення №2 і так далі.',
  },

  send: 'Надіслати',

  btn_create: 'Створити шаблон',

  send_template_for_moderation: 'Надіслати шаблон на модерацію?',
};

import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";
import {getUserOnePromises} from "~/store/company/user";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {page = 1, pagesize = 10, observer = null, relate = []} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);
    return api.oauth2_app.client.acceess.list(page, pagesize).then(async response => {
      //console.info('api.oauth2_app.client.acceess.list | response = ', response.data);
      let items = response.data.Body || [];
      const promises = [];
      //promises.push(...items.map(item => {
      //  return new Promise(async resolve => {
      //    item.token = await getUserOnePromises(observer, item.token, 'UserID');
      //    resolve(item);
      //  });
      //}));
      await Promise.all(promises);
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.entities.user.list | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.oauth2_app.client.acceess.delete(id).then(response => {
      //console.info('api.oauth2_app.client.acceess.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.oauth2_app.client.acceess.delete | error = ', error);
      throw error;
    });
  },
};

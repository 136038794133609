import api from '~/api';
import * as uRelate from "~/utils/relate";
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  listSubs({dispatch, rootState}, {page = 1, pagesize = 10, sort = '', observer = null, relate = [], ...options}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.enoti.sub.list(page , pagesize, '').then(async response => {
      //console.info('api.enoti.sub.list | response = ', response.data);
      let items = (response.data.Body || []).filter(({user_id}) => user_id === rootState.auth.user.id);
      const promises = [];
      if (uRelate.include(uRelate.NOTIFICATION_ACTION, observer.relate)) {
        promises.push(...items.map((item) => getNotificationActionOnePromises(observer, item, item.project_id)));
      }
      await Promise.all(promises);
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.enoti.sub.list | error = ', error);
      throw error;
    });
  },
  oneAction(_, {id}) {
    return api.enoti.action.get(id).then(response => {
      //console.info('api.enoti.action.get | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.enoti.action.get | error = ', error);
      throw error;
    });
  },
  sub({commit, state}, {tagName, ...options}) {
    return api.enoti.action.tag.sub.post(tagName, options).then(response => {
      //console.info('api.enoti.action.tag.sub.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.enoti.action.tag.sub.post | error = ', error);
      throw error;
    });
  },
  unsub({commit, state}, {tagName, ...options}) {
    return api.enoti.action.tag.unsub.post(tagName, options).then(response => {
      //console.info('api.enoti.action.tag.unsub.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.enoti.action.tag.unsub.post | error = ', error);
      throw error;
    });
  },
};

export function getNotificationActionOnePromises(observer, item, id_name = 'action_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'company/notification/oneAction', {id: item[id_name]},
        (action) => {
          item.action = action;
          resolve(item);
        },
      );
    } else {
      item.action = null;
      resolve(item);
    }
  });
}

<template>
  <b-dropdown
    :variant="variant"
    :toggle-class="toggleClass"
    :right="right"
    data-cy="widget-lang-dropdown"
  >
    <template slot="button-content">
      {{ localeCurrent.code.toUpperCase() }}
    </template>

    <b-dropdown-item
      v-for="(locale, code) of locales"
      :key="`locale-${code}`"
      :data-code="code"
      @click.prevent="setLang(code)"
    >
      <span class="badge badge-light mr-2">
        {{ code.toUpperCase() }}
      </span>
      {{ locale.title }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import moment from 'moment';
import { Validator } from 'vee-validate';
import { loadLanguageAsync } from '~/plugins/i18n';

export async function setLang (language) {
  await loadLanguageAsync(language);

  this.$store.commit('setLang', language);

  this.$i18n.locale = language;
  Validator.localize(language);
  moment.locale(language);

  if (this.$store.state.auth.user && this.$store.state.auth.user.id) {
    const { id: user_id } = this.$store.state.auth.user;
    this.$store.dispatch('company/user/changeLang', { user_id, language });
  }
}

export default {
  name: 'WidgetLangDropdown',
  props: {
    btnStyle: {
      type: String,
      default: 'link',
      validator: v => ['link', 'button'].includes(v),
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    locales: {
      en: { title: 'English', flag: 'gb', code: 'en' },
      ru: { title: 'Русский', flag: 'ru', code: 'ru' },
      uk: { title: 'Українська', flag: 'uk', code: 'uk' },
    },
  }),
  computed: {
    locale () {
      return this.$store.state.locale;
    },
    localeCurrent () {
      return this.locales[this.$store.state.locale];
    },
    variant () {
      if (this.btnStyle === 'button') {
        return 'secondary';
      }
      return 'link';
    },
    toggleClass () {
      if (this.btnStyle === 'button') {
        return ['btn-light'];
      }
      return ['text-decoration-none', 'navbar-nav-link'];
    },
  },
  methods: {
    setLang,
  },
};
</script>

<style scoped>

</style>

<style>
.text-dark .btn-link {
  color: #293a50 !important;
}
</style>

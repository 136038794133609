import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";
import * as uRelate from "~/utils/relate";
import {getChannelOnePromises} from "~/store/channel-and-gate/channel-and-gate/channel";
import {getClientSenderOnePromises} from "~/store/clientsender";
import {getDispatchDocumentLayoutOnePromises} from "~/store/dispatch-document";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {id, page = 1, pagesize = 10, sort = '', observer = null, relate = [], ...options} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    if (sort.length > 0) sort += ',';
    sort += 'id';

    return api.documents.dispatch_document.destination.list(id, page, pagesize, sort, options).then(async response => {
      //console.info('api.documents.dispatch_document.destination.list | response = ', response.data);
      let items = response.data.Body || [];
      const promises = [];
      if (uRelate.include(uRelate.DISPATCH_DOCUMENT_CONTENT, observer.relate)) {
        promises.push(
          ...items.map(async (item) => {
            let content_data = item.content_data || {};
            const promises = [];
            promises.push(getDispatchDocumentLayoutOnePromises(observer, content_data, content_data.template_id, content_data.id));
            if (uRelate.include(uRelate.CHANNEL, observer.relate)) {
              promises.push(getChannelOnePromises(observer, content_data, content_data.channel_id));
            }
            if (uRelate.include(uRelate.CLIENT_SENDER, observer.relate)) {
              promises.push(getClientSenderOnePromises(observer, content_data));
            }
            await Promise.all(promises);
            return {
              ...item,
              content_data,
            };
          })
        );
      }
      await Promise.all(promises);
      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 10,
      };
    }).catch(error => {
      console.error('api.documents.dispatch_document.destination.list | error = ', error);
      throw error;
    });
  },
};

export function getDispatchDocumentDestinationListPromises(observer, item, id) {
  //console.info('getDispatchDocumentDestinationListPromises(observer, ', item, ', ', id, ')');
  return new Promise(async (resolve) => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'dispatch-document/destination/list', {id, pagesize: 9999},
        destinations => {
          item.destinations = destinations;
          resolve();
        },
      );
    } else {
      item.destinations = null;
      resolve();
    }
  });
}

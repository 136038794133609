export default {
  loading: 'Загрузка статистики...',

  title: 'Статистика по отправителю (текущий месяц)',
  view_all: 'Посмотреть все',
  empty_data: 'Нет сообщений',

  message_status_count_sent: 'Отправлено',
  message_status_count_delivered: 'Доставлено',
  message_status_count_not_delivered: 'Не доставлено',
  message_status_count_watched: 'Просмотрено',
}

import axios from "axios";
import { HashSum } from "./hashSum.js";
async function convert(file) {
  const hash_sum = await new HashSum(file).get();
  const fileForm = new FormData();
  fileForm.append("file", file);
  fileForm.append("hash_sum", hash_sum);
  return axios.post("/api-gateway/api/v1/converter/convert", fileForm).then(({ data: d }) => d);
}
export {
  convert
};

export default {
  channel: 'Канал',
  channel_any: 'Viber',
  channel_rcs: 'Rcs',
  client_sender_id: 'Отправитель',
  client_sender_id_placeholder: 'Выберите отправителя',
  phone: 'номер телефона',
  phone_placeholder: 'Укажите номер телефона',
  submit: 'Отправить код привязки',
  submit_rsc: 'Connect RCS phone',

  error_verifications_phone_session_exist: 'Вы сможете повторно запросить код через {duration} секунд.',
}

import form from '~/locales/uk/ClientSender/form';
import table from '~/locales/uk/ClientSender/table';
import show from '~/locales/uk/ClientSender/show';
import modal_info_box from '~/locales/uk/ClientSender/ModalInfoBox';
import modal_comparison from '~/locales/uk/ClientSender/ModalComparison';
import modal_logo from '~/locales/uk/ClientSender/ModalLogo';
import modal_send_for_moderation from '~/locales/uk/ClientSender/ModalSendForModeration';
import modal_scheduler_status from '~/locales/uk/ClientSender/ModalSchedulerStatus';
import card from '~/locales/uk/ClientSender/Card';
import widget_scheduled_status from '~/locales/uk/ClientSender/WidgetScheduledStatus';
import widget_statistic from '~/locales/uk/ClientSender/WidgetStatistic';
import trailer_profile from '~/locales/uk/ClientSender/TrailerProfile';
import widget from '~/locales/uk/ClientSender/Widget';
import list from '~/locales/uk/ClientSender/List';

export default {
  title: 'Відправники',
  title_create: 'Створити відправника',
  title_show: 'Відправник {name}',
  title_show_breadcrumbs: 'Детальна інформація',
  title_edit: 'Відправник {name}',
  title_edit_breadcrumbs: 'Редагувати відправника',

  create_btn: 'Створити',

  description: 'Цей розділ дозволяє вам керувати своїми відправниками повідомлент на кожному каналі. В залежності від можливостей кожного каналу ви можете налаштувати зовнішній вигляд відправника: змінити назву, оновити логотип и т.д.<br><br>' +
    'Ви можете створити відправника на каналі, котрий ви хотіли б використовувати для відпрваки повідомлень своїм клієнтам. Модерація відправника включає в себе, в тому числі, перевірку на відповідність умовам і вимогам каналу розсилки.',
  btn_view_glossary: 'Глосарій',

  filter_q_placeholder: 'Назва',
  all_status: 'Статус',
  all_channels: 'Канал',

  sender_empty: 'В проекті відстуні відправники.',
  sender_empty_project_more_you_can: 'Ви можете',
  sender_empty_project_more_create: 'створити відправника',
  sender_empty_project_more_or: 'повідомлення або',
  sender_empty_project_more_select_all: 'переключити фільтр проектів на Всі проекти.',
  sender_empty_project_more_select_all_after: '',
  sender_empty_project_one_you_can: 'Ви можете',
  sender_empty_project_one_create: 'створити відправника',
  sender_empty_project_one_create_after: 'повідомлень для вашого бизнесу.',

  modal_logo_title: 'Змінити логотип для {name}',
  modal_logo_image_crop_lable: 'Оберіть область зображення для використання в логотипі',

  modal_logo_chatscreen_title: 'Змініть обкладинку для {name}',
  modal_logo_chatscreen_image_crop_lable: 'Оберіть область зображення для використання в обкладинці',

  form,
  table,
  show,
  list,

  modal_info_box,
  modal_comparison,
  modal_logo,
  modal_send_for_moderation,
  modal_scheduler_status,

  card,
  widget_scheduled_status,
  widget_statistic,
  trailer_profile,
  widget,
};

<template>
  <div v-if="show" class="mr-3" id="spinner-light-2">
    <i class="icon-spinner2 spinner mr-2"></i>
    {{ $t('widget.chat_ws_connect.connecting') }}
  </div>
</template>

<script>
  export default {
    name: 'widget-chat-ws-connect',
    computed: {
      show() {
        return false;
        return this.$store.state.chat.wsConnect === false;
      },
    }
  }
</script>

<style scoped>

</style>

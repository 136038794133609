import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    callBackRelate,
    ...options
  } = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'created_at';
    return api.short_link.link.list(page, pagesize, sort, options).then(async ({data}) => {
      //console.info('api.short_link.link.list | data = ', data);
      let items = data.Body || [];
      callBackRelate && await Promise.all(items.map((item) => callBackRelate(observer, item)));
      return {
        items,
        totalRows: data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.short_link.link.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {id, observer = null, callBackRelate} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.short_link.link.get(id).then(async ({data}) => {
      //console.info('api.short_link.link.one | data = ', data);
      let item = data;
      callBackRelate && await Promise.all([item].map((item) => callBackRelate(observer, item)));
      return item;
    }).catch(error => {
      console.error('api.short_link.link.one | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.short_link.link.delete(id).then(({data}) => {
      //console.info('api.short_link.link.delete | data = ', data);
      return data;
    }).catch(error => {
      console.error('api.short_link.link.delete | error = ', error);
      throw error;
    });
  },
  statistic({commit, state}, {id, ...period}) {
    return api.short_link.link.statistic(id, period).then(({data}) => {
      //console.info('api.short_link.link.statistic | data = ', data);
      return data || [];
    }).catch(error => {
      console.error('api.short_link.link.statistic | error = ', error);
      throw error;
    });
  },
};

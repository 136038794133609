import message_type_option from '~/locales/uk/ClientSender/TrailerProfile/MobileId/MessageTypeOption';
import company_type_option from '~/locales/uk/ClientSender/TrailerProfile/MobileId/CompanyTypeOption';
import message_volume_option from '~/locales/uk/ClientSender/TrailerProfile/MobileId/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/uk/ClientSender/TrailerProfile/MobileId/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/uk/ClientSender/TrailerProfile/MobileId/ServiceLaunchDateOption';

export default {
  service_name_original_language: 'Назва (оригинальною мовою)',
  service_name_english_language: 'Назва (англійською)',
  website: 'Адреса сайту',
  description: 'Опис',
  description_helper: '',
  logo_img: 'Логотип відправника',
  logo_chatscreen: 'Обкладинка',
  business_address: 'Адреса компанії',
  business_phone_number: 'Номер телефону компанії',
  business_email: 'Email компанії',
  message_type: 'Тип повідомлень',
  message_type_option,
  service_registered_country: 'Країна знаходження компанії',
  company_type: 'Тип компанії',
  company_type_option,
  message_volume: 'Об`єм відпарвлений повідомлень в місяць',
  message_volume_option,
  service_integration_type: 'Тип інтеграції',
  service_integration_type_option,
  destination_countries: 'Країни розсилки',
  service_launch_date: 'Дата запуску',
  service_launch_date_option,
  tin_company: 'ЄДРПОУ або ІПН(номер в Єдиному державному реєстрі підприємств та організацій України або Індивідуальний Податковий номер для ФОП) компанії',
  sample_message_promotional: 'Приклад рекламного повідомлення',
  sample_message_transactional: 'Приклад транзакційного повідомлення',

};

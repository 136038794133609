import { computed, onMounted, ref, watch } from 'vue';
import {
  useStore as NuxtUseStore,
  useRoute as NuxtUseRoute,
  useRouter as NuxtUseRouter,
} from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import type { RootState } from '~/store';
import type { Store } from 'vuex';

/**
 * Здесь содержатся хуки, которые в будущем должны будут
 * замениться хуками из соответствующих пакетов.
 */

export const useStore = () => {
  return NuxtUseStore<RootState>();
};

type UseI18nOptions = {
  locale?: string;
};

export const useI18n = (options?: UseI18nOptions) => {
  return {
    t: (key: string, values?: VueI18n.Values) =>
      window.$nuxt.$t(
        key,
        options?.locale ??
          (window.$nuxt.$store as Store<RootState>).state.locale,
        values
      ) as string,
  };
};

export const useRoute = () => {
  return NuxtUseRoute();
};

export const useRouter = () => {
  return NuxtUseRouter();
};

/**
 * Здесь нормальные хуки
 */

export type TitleHookProps = {
  title?: string;
  subtitle?: string;
};

export const useTitle = (props: TitleHookProps = {}) => {
  const { title, subtitle } = props;

  const store = useStore();
  const { t } = useI18n();

  const chatCount = computed(() => store.state.chat.counters.all.all ?? 0);

  onMounted(() => {
    store.commit('setPageTitle', { title, subtitle });
  });

  watch<[string, string, number]>(
    () => [store.state.page.title, store.state.page.subtitle, chatCount.value],
    ([title, subtitle, count]) => {
      let documentTitle = '';

      if (count > 0) documentTitle += `(${count}) `;
      if (subtitle?.length) documentTitle += `${t(subtitle)} | `;
      if (title?.length) documentTitle += `${t(title)} | `;

      documentTitle += process.env.baseTitle;
      document.title = documentTitle;
    }
  );
};

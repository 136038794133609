import moment from 'moment';
import api from '~/api';
import * as uRelate from '~/utils/relate';

export const SCROLL_DIRECTION_TOP = 'top';
export const SCROLL_DIRECTION_BOTTOM = 'bottom';

const stateBase = () => ({
  loadingFirst: true,
  loadingChat: false,
  loadingMessage: false,

  scrollDirection: SCROLL_DIRECTION_TOP,
  scrollFinishedTop: false,
  scrollFinishedBottom: false,

  chat: {
    id: '',
    status: 'loading',
    phone_number: '...',
    user_id: '',
    user: {},
  },
  messageMax: 0,
  messageIdSearch: '',
  messages: [],
  messagesRetry: {},
});

export const state = stateBase;

export const getters = {
  isScrollDirectionTop: state => state.scrollDirection === SCROLL_DIRECTION_TOP,
  isScrollDirectionBottom: state => state.scrollDirection === SCROLL_DIRECTION_BOTTOM,
};

export const mutations = {
  reset (store) {
    Object.assign(store, stateBase());
  },
  setLoadingFirst (store, loading) {
    store.loadingFirst = loading;
  },
  setLoadingChat (store, loading) {
    store.loadingChat = loading;
  },
  setChat (store, chat) {
    store.chat = chat;
  },
  setLoadingMessage (store, loading) {
    store.loadingMessage = loading;
  },
  setScrollDirection (store, scrollDirection) {
    store.scrollDirection = scrollDirection;
  },
  setScrollFinishedTop (store, scrollFinishedTop) {
    store.scrollFinishedTop = scrollFinishedTop;
  },
  setScrollFinishedBottom (store, scrollFinishedBottom) {
    store.scrollFinishedBottom = scrollFinishedBottom;
  },
  setMessageMax (store, max) {
    store.messageMax = max;
  },
  addMessages (store, { messages, ...data }) {
    addMessage(store, data, messages);
  },
  addMessage (store, { message, ...data }) {
    addMessage(store, data, [message]);
  },
  setMessageIdSearch (store, messageIdSearch) {
    store.messageIdSearch = messageIdSearch || '';
  },
  updateMessageStatus (store, { message_id, status }) {
    store.messages = store.messages
      .map(message => (message.id === message_id) ? { ...message, status } : message);
  },
  deleteMessage (store, { message_id = null, meta_id = null }) {
    store.messages = store.messages
      .filter(messageOld => !(
        (message_id != null && messageOld.id === message_id) ||
        (meta_id !== null && messageOld.meta_id === meta_id)
      ));
  },
  errorMessages (store, { meta_id = null, message_id = null, error = 'undefined' }) {
    store.messages = store.messages.map(messageOld =>
      (messageOld.id === message_id || messageOld.meta_id === meta_id)
        ? { ...messageOld, is_sending: false, error }
        : messageOld,
    );
  },
};

export const actions = {
  async fetchChat ({ dispatch, commit }, { id }) {
    const relate = [
      uRelate.CLIENT_SENDER,
      uRelate.USER,
    ];

    try {
      commit('setLoadingChat', true);
      await dispatch('chat/one', { id, relate }, { root: true }).then(chat => commit('setChat', chat));
      await dispatch('fetchMessage');
      commit('setLoadingChat', false);
    } catch (e) {
      console.error('chat.room.fetchChat | e = ', e);
      commit('setLoadingChat', e);
    }
  },
  async fetchMessageOnNewMessage ({ commit, state }) {
    const {
      loadingMessage,
      messageIdSearch,
      chat: { id: chat_id },
      messageMax,
    } = state;

    if (loadingMessage) { return; }

    const payload = {
      chat_id,
      offset: messageMax - 21,
      count: 20,
    };

    commit('setScrollFinishedBottom', true);

    const { messages: messagesNew, total_entries_size } = await api.chat.messages
      .history(payload.chat_id, null, payload.offset, payload.count)
      .then(messagesHistoryThen(payload.chat_id, messageIdSearch))
      .catch(e => {
        console.error('chat.room.fetchMessageOnNewMessage | e = ', e);
        commit('setLoadingMessage', e);
        throw e;
      });

    if (messagesNew && !messagesNew.length) {
      messagesNew.split(-1);
    }

    commit('setMessageMax', total_entries_size);
    commit('addMessages', { messages: messagesNew });
  },
  async fetchMessage ({ commit, state, getters }, { direction = SCROLL_DIRECTION_TOP } = {}) {
    commit('setScrollDirection', direction);

    const {
      loadingFirst,
      loadingMessage,
      finishedTop,
      finishedBottom,
      messageIdSearch,
      chat: { id: chat_id },
      messages,
    } = state;

    const {
      isScrollDirectionTop,
      isScrollDirectionBottom,
    } = getters;

    if (isScrollDirectionTop && finishedTop) { return; }
    if (isScrollDirectionBottom && finishedBottom) { return; }

    if (loadingMessage) { return; }

    const payload = {
      chat_id,
      offset: 0,
      count: 20,
    };

    if (loadingFirst) {
      if (messageIdSearch.length) {
        payload.start_message_id = messageIdSearch;
        payload.offset = -10;
      }
    } else if (messages.length) {
      if (isScrollDirectionTop) {
        payload.start_message_id = messages[0].id;
        payload.offset = -20;
      }
      if (isScrollDirectionBottom) {
        payload.start_message_id = messages
          .slice(0).reverse()
          .find(({ id }) => typeof id === 'string').id;
      }
    } else {
      isScrollDirectionTop && commit('setScrollFinishedTop', true);
      isScrollDirectionBottom && commit('setScrollFinishedBottom', true);
      return;
    }

    commit('setLoadingMessage', true);

    const { messages: messagesNew, total_entries_size } = await api.chat.messages
      .history(payload.chat_id, payload.start_message_id, payload.offset, payload.count)
      .then(messagesHistoryThen(payload.chat_id, messageIdSearch))
      .catch(e => {
        console.error('chat.room.fetchMessage | e = ', e);
        commit('setLoadingMessage', e);
        throw e;
      });

    commit('setMessageMax', total_entries_size);

    if (isScrollDirectionTop && !messagesNew.length) {
      commit('setScrollFinishedTop', true);
      commit('setLoadingMessage', false);
      loadingFirst && commit('setLoadingFirst', false);
      return;
    }
    if (isScrollDirectionBottom && (
      !messagesNew.length ||
      (messagesNew.length && payload.start_message_id === messagesNew.slice(-1).pop().id)
    )) {
      commit('setScrollFinishedBottom', false);
      commit('setLoadingMessage', false);
      loadingFirst && commit('setLoadingFirst', false);
    }
    if (loadingFirst) {
      if (messageIdSearch.length) {
        const messageIdSearchIndex = messagesNew.findIndex(({ id }) => id === messageIdSearch);
        if (messageIdSearchIndex < payload.count / 2) {
          commit('setScrollFinishedTop', true);
        }
        if (messagesNew.length - messageIdSearchIndex < payload.count / 2) {
          commit('setScrollFinishedBottom', true);
        }
      } else {
        commit('setScrollFinishedBottom', true);
      }
    }

    commit('addMessages', { messages: messagesNew });

    commit('setLoadingMessage', false);
    loadingFirst && commit('setLoadingFirst', false);
  },
};

function addMessage (store, {
  chat_id = null,
  meta_id = null,
  is_sending = false,
  retry = undefined,
}, messagesNew) {
  let messagesStoreNew = [...store.messages];

  messagesNew.forEach(message => {
    let messagesFind = false;

    const messageNew = {
      ...message,
      chat_id: message.chat_id || chat_id,
      meta_id,
      is_sending,
      error: false,
    };

    messagesStoreNew = messagesStoreNew.map(messageOld => {
      if (
        messageOld.id === message.id ||
        (meta_id !== null && messageOld.meta_id === meta_id)
      ) {
        messagesFind = true;
        if (meta_id !== null && messageOld.meta_id === meta_id) {
          messageNew.index = messageOld.index;
        }
        return messageNew;
      } else {
        return messageOld;
      }
    });

    if (messagesFind === false) {
      if (!messageNew.index) {
        messageNew.index = store.messageMax;
        store.messageMax++;
      }
      messagesStoreNew.push(messageNew);
    }
  });

  messagesStoreNew = Object.values(messagesStoreNew.reduce((ret, msg) => {
    ret[msg.id] = msg;
    return ret;
  }, {}));

  store.messages = messagesStoreNew
    .sort((a, b) => {
      if (a.created_at === b.created_at) { return 0; }
      return moment(a.created_at).utc() > moment(b.created_at).utc() ? 1 : -1;
    });

  if (retry) {
    store.messagesRetry[meta_id] = retry;
  }
}

const messagesHistoryThen = (chatId, messageIdSearch) => ({ data: { body, meta: { offset, total_entries_size } } }) => ({
  messages: (body || []).map((message, index) => ({
    ...message,
    _is_message_search: messageIdSearch === message.id,
    chat_id: chatId,
    index: offset + index,
  })),
  total_entries_size,
});

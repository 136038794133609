import { uniq, difference, intersection } from 'lodash';
import { catchDispatch } from '~/utils/form';
import { check2PathProjectSep } from '~/utils/functions';

export default {
  props: {
    loading: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    totalRows: { type: Number, default: 0 },
    currentPage: { type: Number, default: 1 },
    perPage: { type: Number, default: 10 },
    sortBy: { type: String, default: 'id' },
    sortDesc: { type: Boolean, default: false },
    selected: { type: [Array, String], default: () => [] },
    selectedType: { type: String, default: 'checkbox' },
    hidePageOnOne: { type: Boolean, default: false },
  },
  methods: {
    check2PathProjectSep,
    onTableFetch() {
      this.$emit('tableFetch');
    },
    onDelete(item) {
      this.onDeleteItem
        .call(this, item)
        .then((r) => r && this.emitDeleteItem(item))
        .catch((e) => catchDispatch(this, e));
    },
    emitDeleteItem(item) {
      this.$emit(
        'update:items',
        this.items_.filter((item_) => item_.id !== item.id)
      );
    },
    rowClicked(item, index, e) {
      e.preventDefault();

      if (!this.isSelectedAll || this.selectedType !== 'checkbox') return;
      //console.info('rowClicked(', item, ', ', index, ', ', e, ')');
      const indexOf = this.selected_.indexOf(item.id);
      if (indexOf === -1) {
        this.selected_.push(item.id);
      } else {
        this.selected_.splice(indexOf, 1);
      }
    },
    currentPageUpdate(currentPage) {
      if (this.loading) return;
      this.currentPage_ = currentPage;
    },
  },
  computed: {
    itemIds() {
      return this.items.map((item) => item.id);
    },
    items_() {
      if (!this.$props.items) return false;
      return this.$props.items.map((item) => {
        let _rowVariant = item._rowVariant ? item._rowVariant.split(' ') : [];
        if (this.selected.indexOf(item.id) >= 0) {
          !_rowVariant.includes('selected') && _rowVariant.push('selected');
        } else {
          const index = _rowVariant.indexOf('selected');
          index >= 0 && _rowVariant.splice(index, 1);
        }
        item._rowVariant = _rowVariant.join(' ');
        return item;
      });
    },
    selected_: {
      get() {
        return this.$props.selected;
      },
      set(selected) {
        this.$emit('update:selected', selected);
      },
    },
    currentPage_: {
      get() {
        return this.$props.currentPage;
      },
      set(currentPage) {
        this.$emit('update:currentPage', currentPage);
      },
    },
    fields_() {
      return this.fields.map((field) => {
        field = { ...field };
        if (!field.label && this.fields_label[field.key]) {
          field.label = this.$t(this.fields_label[field.key]);
        } else {
          delete field.label;
        }
        if (field.key === 'actions') {
          field.thClass = field.thClass + ' text-center';
          field.label =
            '<i class="icon-arrow-down12" style="padding-right: 10px;"></i>';
        }
        return field;
      });
    },
    sortBySync: {
      get() {
        return this.sortBy;
      },
      set(sortBy) {
        this.$emit('update:sortBy', sortBy);
      },
    },
    sortDescSync: {
      get() {
        return this.sortDesc;
      },
      set(sortDesc) {
        this.$emit('update:sortDesc', sortDesc);
      },
    },
    isSelectedAll() {
      return !!this.fields.find((field) => field.key === 'id');
    },
    showPaginate() {
      if (!this.$props.hidePageOnOne) return true;
      return this.perPage < this.totalRows;
    },
    selectedTypeCheckbox() {
      return this.selectedType === 'checkbox';
    },
    selectedTypeCheckboxComponent() {
      return this.selectedTypeCheckbox
        ? 'widget-form-checkbox'
        : 'widget-form-radio';
    },
    selectedAll: {
      get() {
        return (
          intersection(this.selected_, this.itemIds).length == this.perPage
        );
      },
      set(select) {
        if (select) {
          this.selected_ = uniq([...this.itemIds, ...this.selected_]);
        } else {
          this.selected_ = difference(this.selected_, this.itemIds);
        }
      },
    },
  },
  watch: {
    items(v) {
      this.$emit('update:loading', false);
    },
    currentPage(currentPage) {
      this.$emit('update:loading', true);
      this.$emit('update:currentPage', currentPage);
    },
  },
};

import upload from '~/locales/uk/Company/Document/Upload';
import table from '~/locales/uk/Company/Document/table';
import modal_info from '~/locales/uk/Company/Document/ModalInfo';
import public_access from '~/locales/uk/Company/Document/PublicAccess';
import widget_space from '~/locales/uk/Company/Document/WidgetSpace';

export default {
  title: 'Документи',

  description: '<p> Ви можете прикріпляти документи до ваших повідомлень, якщо обраний канал розсилки дозволяє це робити. Також, важливі документи від Messaggio будут знаходитись тут для скачування.</p>',

  tab_doc_my: 'Мої документи',
  tab_doc_company: 'Документи компанії',
  tab_doc_mes: 'Документи в повідомленнях',

  upload,
  table,
  modal_info,
  public_access,
  widget_space,
};

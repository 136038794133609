<template>
  <div v-if="false"
       class="alert alert-warning alert-styled-left alert-dismissible mb-0 mr-3">
    <nuxt-link :to="{name: 'company-billing'}" class="font-weight-normal">
      <span class="font-weight-semibold">
        {{ $t('widget.balance_ended.title') }}
      </span>
      {{ $t('widget.balance_ended.description') }}
    </nuxt-link>
  </div>
</template>

<script>
  export default {
    name: 'widget-balance-ended',
    computed: {
      contracts() {
        return this.$store.state.widget.balance.contracts;
      },
      show() {
        return !!this.contracts.find(c => c.getBalanceSum < 1);
      },
    }
  }
</script>

<style scoped>

</style>

export default {
  title: 'Statistics from {from} to {to}',

  screen_full: 'Full screen',
  screen_normal: 'Normal screen',
  show_request: 'Show query',
  show_group_by_day: 'Show by date',

  show_request_title: 'Query',
  show_group_by_day_title: 'By date',

  empty_data: 'Filter the statistics to see messaging activity chart',
}
//$t('statistic.chart.title')

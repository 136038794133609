import table from '~/locales/en/Statistic/table';
import filter from '~/locales/en/Statistic/Filter';
import chart from '~/locales/en/Statistic/Chart';
import report from '~/locales/en/Statistic/Report';
import phone from '~/locales/en/Statistic/Phone';
import widget_status_count_top from '~/locales/en/Statistic/WidgetStatusCountTop';

export default {
  title: 'Statistics',
  title_table: 'Statistics for {clientsender_name} {message_date_from} — {message_date_to}',

  table,
  filter,
  chart,
  report,
  phone,
  widget_status_count_top,

  message_status: 'Status Code',
  phone_number: 'Contact',
  message_id: 'Message ID',
  message_time: 'Date',

  csv_alert_title: 'File preparation',
  csv_alert_done: 'File is ready',
  csv_alert_failed: 'Error generating file',
  csv_alert_download: 'Download',

  link_statistic: 'Statistics',
  link_report: 'CSV Reports',
  search_placeholder: 'Search by phone number or message id',
}

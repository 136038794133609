import modal_columns from '~/locales/uk/Statistic/Filter/ModalColumns';

export default {
  list_preset_loading: 'Завантаження параметрів...',

  message_date: 'Період',
  project_id: 'Проект',
  project_id_all: 'Всі проекти',
  channel_id: 'Канали',
  clientsender_id: 'Відправник',
  dispatch_document_id: 'Розсилка',
  country: 'Країни і оператори',
  country_title: 'Країни',
  mobile_operator_title: 'Оператори',
  columns_select: 'Налаштування звіту',
  select: 'Створити звіт',
  fetch_csv: 'Завантажити CSV',
  report: 'CSV звіти',
  statistic: 'Статистика',

  modal_columns,

  country_ru: 'Росія',
  country_ua: 'Україна',
  country_kz: 'Казахстан',
  country_md: 'Молдова',
  country_by: 'Білорусь',

  grouped: 'згрупований',
  custom: 'спеціальный',
};

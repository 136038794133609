export default {
  layout: 'Layout',
  text: 'Text',
  media: 'Media',
  text_and_media: 'Text + Media',
  title: 'Title',
  description: 'Description',

  card_title_default: 'Card title {num}',
}

export default {
  progress: {
    title: 'Підтвердження пошти',
  },
  left: {
    unVerified: {
      title: 'Ласкаво просимо!',
      subtitle: '<strong>Будь ласка, підтвердіть Ваш email</strong>',
      subsubtitle: 'Натисніть на посилання підтвердження в листі, який ми відправили вам на на пошту {email}. Якщо листи немає в папці Вхідні, перевірте папку спам або введіть в рядок пошуку в поштовій програмі "noreply@messaggio.com".',
      btn: {
        resend: 'Надіслати повторний лист',
        wasResend: 'Лист відправлено',
      },
    },
    verified: {
      title: 'Ласкаво просимо!',
      subtitle: 'Пошта підтверджена',
      subsubtitle: 'Натисніть Далі для продовження',
    },
  },
  form: {
    locale: {
      title: 'Мова інтерфейсу',
    },
  },
};

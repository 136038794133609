import modal_columns from '~/locales/ru/Statistic/Filter/ModalColumns'

export default {
  list_preset_loading: 'Загрузка параметров...',

  message_date: 'Период',
  project_id: 'Проект',
  project_id_all: 'Все проекты',
  channel_id: 'Каналы',
  clientsender_id: 'Отправитель',
  dispatch_id: 'Рассылка',
  country: 'Страны и операторы',
  country_title: 'Страны',
  mobile_operator_title: 'Операторы',
  columns_select: 'Настройки отчета',
  select: 'Выбрать',
  fetch_csv: 'Скачать CSV',
  report: 'CSV Отчеты',

  today: 'сегодня',
  this_month: 'месяц',
  last_month: 'прошлый месяц',

  modal_columns,

  country_ru: 'Россия',
  country_ua: 'Украина',
  country_kz: 'Казахстан',
  country_md: 'Молдова',
  country_by: 'Беларусь',

  grouped: 'сгруппированный',
  custom: 'специальный',

  show_more_filters: 'Еще фильтры',
  hide_more_filters: 'Свернуть',

  message_date_from: 'От',
  message_date_to: 'До',
  message_date_helpdesk: 'Детальная статистика доступна только за последние 3 месяца.',
}

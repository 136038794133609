export default {
  add_suggestion_title: 'View a location',

  item_title: 'View map',
  item_empty: 'Location is not specified',

  title: 'View location — suggested action',
  gmap_autocomplete_placeholder: 'Enter address',
  label: 'Label',
}

import table from '~/locales/ru/Statistic/table';
import filter from '~/locales/ru/Statistic/Filter';
import chart from '~/locales/ru/Statistic/Chart';
import report from '~/locales/ru/Statistic/Report';
import phone from '~/locales/ru/Statistic/Phone';
import widget_status_count_top from '~/locales/ru/Statistic/WidgetStatusCountTop';

export default {
  title: 'Статистика',
  title_table: 'Статистика по {clientsender_name} {message_date_from} — {message_date_to}',

  table,
  filter,
  chart,
  report,
  phone,
  widget_status_count_top,

  message_status: 'Статус',
  phone_number: 'Контакт',
  message_id: 'ID Сообщения',
  message_time: 'Дата',

  csv_alert_title: 'Подготовка файла',
  csv_alert_done: 'Файл готов',
  csv_alert_failed: 'Ошибка при генерации файла',
  csv_alert_download: 'Скачать отчет',

  link_statistic: 'Статистика',
  link_report: 'CSV Отчеты',
  search_placeholder: 'Поиск по номеру телефона или ID сообщения',
}

import table from '~/locales/ru/Company/ActionHistory/table';

export default {
  title: 'История действий',
  description: '<p>История действий показывает активность пользователей в вашем аккаунте.</p>' +
    '<p>Выберите интересующий вас период активности и при необходимости задайте фильтры по пользователю или разделу личного кабинета.</p>',

  table,

  services_template: 'Шаблон',
  services_group_contact: 'Контакт группы',

  created_at_from_placeholder: 'Дата с',
  created_at_to_placeholder: 'Дата до',
  user_placeholder: 'Пользователь',
  entity_placeholder: 'Сервис',
}

export default {
  title: 'Плавне відправлення',
  description: 'Вкажіть часовий проміжок, в який повідомлення будуть надіслані.',

  send_fast: 'Розіслати швидко',
  hour_1: 'година',
  hour_2: 'години',
  hour_5: 'годин',

  send_in: 'Розіслати за {hour} {hour_noun}',
}

import filter from '~/locales/ru/Statistic/Report/Filter';
import modal from '~/locales/ru/Statistic/Report/Modal';

export default {
  title: 'Отчеты',

  show_only: 'Show',
  show_only_my: 'my',
  show_only_all: 'all',

  download: 'Скачать ZIP',
  show_request: 'Query log',

  show_request_title: 'Query',

  name: 'Отчет',
  user_id: 'Пользователь',
  progress: 'Прогресс',
  updated_at: 'Updated',
  status: 'Статус',

  processing: 'В работе',
  done: 'Готово',

  delete_confirm: 'Are you sure you want to delete report "{name}"?',
  delete_success: 'Report "{name}" was deleted.',

  filter,
  modal,
}
//$t('statistic.report.name')

import moment from 'moment';
import _ from 'lodash';
import { noun } from 'plural-ru';
import saveAs from 'file-saver';
import { getMoment, numberFormat } from '~/plugins/filters';

export const showUserNameOrEmail = (user) => {
  if (!user) {
    return '';
  }
  if (user.first_name || user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  } else {
    return user.email;
  }
};

/**
 * groups = groups.filter(onlyUnique);
 *
 * @param value
 * @param index
 * @param self
 * @returns {boolean}
 */
export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const arrayUnique = (array, key) => _.uniqBy(array, key);

export const arrayToObject = (array, keyField = 'id') =>
  array.reduce((obj, item) => {
    try {
      obj[item[keyField]] = item;
    } catch (e) {
      console.error('arrayToObject: ', e.toString());
    }
    return obj;
  }, {});

export const arrayToObjectKV = (
  array,
  keyField = 'key',
  valueField = 'value'
) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item[valueField];
    return obj;
  }, {});

export const sortString = (table) => {
  let sort = '';
  if (table.sortBy) {
    sort += table.sortBy + ' ';
    if (table.sortDesc) {
      sort += 'desc';
    } else {
      sort += 'asc';
    }
  }
  return sort;
};

export const convertStatisticToItem = (response) => {
  return response.rows.map((item) => {
    return item.reduce((ret, item, index) => {
      const name = response.columns[index];
      ret[name] = item;
      return ret;
    }, {});
  });
};

export const sortByCol =
  (col, type = 'asc') =>
  (a, b) => {
    if (a[col] === b[col]) {
      return 0;
    } else if (type.toLowerCase() === 'asc') {
      return a[col] < b[col] ? -1 : 1;
    } else {
      return a[col] > b[col] ? -1 : 1;
    }
  };

export const fileSizeString = (fileSizeInBytes) => {
  let i = -1;
  const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + ' ' + byteUnits[i];
};

export function numberString(num) {
  return (
    numberFormat(num, 0, '.', ' ') +
    ' ' +
    noun(
      num,
      this.$t('plural.number_1'),
      this.$t('plural.number_2'),
      this.$t('plural.number_5')
    )
  );
}

export function arr_diff(a1, a2) {
  const a = [];
  const diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (const k in a) {
    diff.push(k);
  }

  return diff;
}

export function directoryHighlight(q, content) {
  if (!content) {
    return content;
  }
  if (typeof content !== 'string') {
    content = JSON.stringify(content, null, ' ');
  }
  return content.replace(
    new RegExp(q, 'gi'),
    (match) => '<b class="text-danger">' + match + '</b>'
  );
}

export const checkPathProjectSep = (route, $route, project_id = null) => {
  if (/^project-project_id-/.test($route.name) && $route.params.project_id) {
    route.name = 'project-project_id-' + route.name;
    if (project_id !== null) {
      route.params = { ...route.params, project_id };
    } else {
      route.params = { ...route.params, project_id: $route.params.project_id };
    }
  }
  return route;
};

export function check2PathProjectSep(route, project_id = null) {
  const newRoute = { ...route };
  if (this.$store.state.select_project !== '') {
    newRoute.name = 'project-project_id-' + route.name;
    if (project_id === null) {
      project_id = this.$store.state.select_project;
    }
    newRoute.params = { ...newRoute.params, project_id };

    const link = this.$router.resolve(newRoute);
    if (link && link.href !== '/') {
      return newRoute;
    }
  }
  return route;
}

export const pathProjectSep = (route, project_id) => {
  route.name = 'project-project_id-' + route.name;
  route.params = { ...route.params, project_id };
  return route;
};

export function dateTimeSameHuman(timestamp) {
  if (!timestamp) {
    return '';
  }
  const gm = getMoment(timestamp);
  const diff = moment()
    .startOf('day')
    .diff(gm.clone().startOf('day').format('YYYY-MM-DD'), 'days');
  // console.info('dateTimeSameHuman | gm = ', gm, ' | diff = ', diff);
  switch (diff) {
    case 0:
      return this.$t('home.date_time_same_today_at') + ' ' + gm.format('HH:mm');
    case 1:
      return (
        this.$t('home.date_time_same_yesterday_at') + ' ' + gm.format('HH:mm')
      );
    default:
      if (moment().format('YYYY') === gm.format('YYYY')) {
        return (
          gm.format('DD MMMM') +
          ' ' +
          this.$t('home.date_time_same_at') +
          ' ' +
          gm.format('HH:mm')
        );
      } else {
        return (
          gm.format('DD MMMM YYYY') +
          ' ' +
          this.$t('home.date_time_same_at') +
          ' ' +
          gm.format('HH:mm')
        );
      }
  }
}

export function toHash(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

String.prototype.hashCode = function () {
  let hash = 0;
  if (this.length === 0) {
    return hash;
  }
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export function makeid(length) {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function dataURLToBlob(dataURL) {
  const BASE64_MARKER = ';base64,';
  if (!dataURL.includes(BASE64_MARKER)) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = parts[1];

    return new Blob([raw], { type: contentType });
  } else {
    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;

    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }
}

export const trimObjBadChar = decodeURIComponent(
  escape(String.fromCharCode(226, 129, 163))
);

export function trimObj(obj) {
  if (!Array.isArray(obj) && typeof obj !== 'object') {
    return obj;
  }
  if (typeof obj === 'object' && obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((obj_) => trimObj(obj_));
  }
  return Object.keys(obj).reduce(function (acc, key) {
    if (typeof obj[key] === 'number') {
      acc[key.trim()] = obj[key];
    } else if (typeof obj[key] === 'string') {
      acc[key.trim()] = obj[key]
        .trim()
        .replace(/&#8291;/g, '')
        .replace(/\u00A0/g, ' ')
        .replace(new RegExp('^' + trimObjBadChar), '')
        .replace(new RegExp(trimObjBadChar + '$'), '')
        .trim();
    } else if (typeof obj[key] === 'object') {
      acc[key.trim()] = trimObj(obj[key]);
    }
    return acc;
  }, {});
}

export function messageStatusCount(countGroup = {}, statuses = []) {
  countGroup = { ...countGroup };
  let counter = 0;
  if (!countGroup) {
    return counter;
  }

  let [statusesIn, statusesOut] = statuses;

  if (statusesIn) {
    if (!Array.isArray(statusesIn)) {
      statusesIn = [statusesIn];
    }
    statusesIn = statusesIn.map((s) => parseInt(s, 10));
    counter += statusesIn.reduce((ret, status) => {
      ret += countGroup[status] || 0;
      return ret;
    }, 0);
  }

  /*
  if (statusesOut) {
    if (!Array.isArray(statusesOut)) {
      statusesOut = [statusesOut];
    }
    if (statusesOut.length) {
      statusesOut = statusesOut.map(s => parseInt(s, 10));
      statusesOut = [
        ...statusesOut,
        ...statusesIn,
      ];
      counter += Object.keys(countGroup).reduce((ret, mstatus) => {
        mstatus = parseInt(mstatus, 10);
        if (!statusesOut.includes(mstatus)) {
          ret += countGroup[mstatus];
        }
        return ret;
      }, 0);
    }
  }
*/

  return counter;
}

// [[Включить статусы], [Исключить статусы]]
export const messageStatus = {
  impossible_to_deliver: [[92, 91, 90]],
  sent: [
    [100, 97, 94, 92, 91, 90],
    [200, 199, 198, 75, 74, 70, 69, 68, 67, 60],
  ],
  sentWhatsApp: [
    [100, 97, 94, 92, 90],
    [200, 199, 198, 75, 74, 70, 69, 68, 67, 60],
  ],
  delivered: [[70]],
  watched: [[69]],
  clicked: [[67]],
  not_delivered: [
    [97, 94, 92, 91, 90, 68],
    [200, 199, 100, 97, 75, 74, 70, 69, 67],
  ],
  total: [[100, 92, 91, 90]],
  not_viber_user: [[91]],
};

const saveAsFileExtType = {
  zip: 'application/zip',
  csv: 'text/csv',
  txt: 'text/txt',
};

export function saveAsFile(content, name, ext = 'zip') {
  console.info('saveAsFile(content, ', name, ', ', ext, ')');
  (function (t, f, m) {
    try {
      const b = new Blob([t], { type: m });
      saveAs.saveAs(b, f);
    } catch (e) {
      window.open('data:' + m + ',' + encodeURIComponent(t), '_blank', '');
    }
  })(content, name + '.' + ext, saveAsFileExtType[ext]);
}

export function saveAsCSV(content, name, ext = 'zip') {
  (function (t, f, m) {
    try {
      saveAs.saveAs(new Blob(['\uFEFF', t], { type: m }), f);
    } catch (e) {
      window.open('data:' + m + ',' + encodeURIComponent(t), '_blank', '');
    }
  })(content, name + '.csv', 'text/csv');
}

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const promiseTriesTimeout = (fn, tries, timeout) => {
  return new Promise(function self(resolve, reject) {
    fn()
      .then(resolve)
      .catch((e) => {
        console.info('createPromise | tries = ', tries);
        if (--tries > 0) {
          setTimeout(function () {
            self(resolve, reject);
          }, timeout);
        } else {
          reject(e);
        }
      });
  });
};

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }
    )
  );
}

export function throttle(callback, limit) {
  let waiting = false; // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      callback.apply(this, arguments); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
}

export default {
  title: 'Дії з відправником',

  btn_info: 'Детальна інформація',
  btn_moderation: 'Відправити на модерацію',
  btn_create_template: 'Створити шаблон з відправником',
  btn_edit: 'Редагувати відправника',
  btn_statistics: 'Статистика за відправником',
  btn_deactivate: 'Відключити',
  btn_activate: 'Активувати',
}

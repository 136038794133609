export default {
  loading: 'Loading dumps...',

  created_at: 'Date',
  row_count: 'Quantity',
  status: 'Status',
  actions: 'Actions',


}

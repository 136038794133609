export default {
  title: 'Recipients of Template A and Template B',
  description: 'Specify as a percentage how many randomly selected contacts should receive template A. The rest will receive template B.',

  template_a: 'Template А',
  template_b: 'Template В',

  helpers: 'For A / B test results to be indicative, we recommend setting the % of template A recipients to at least 15%.<br><br>After saving, two dispatches with the specified templates will be created. Each of them should be started on its own or scheduled to launch at the right time.',

  contacts_split_proportion: 'proportion',
}

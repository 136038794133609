import layout_body from '~/locales/uk/Template/ContentLayout/Viber/LayoutBody';

export default {
  layout_body,
  show: {
    device: {
      phone: 'Тільки мобільні пристрої',
    },
  },
};

export default {
  title: 'Создать ссылку',

  project_id: 'Project',
  select_project: 'Project',
  select_project_title: 'Select project',
  link_shortening: 'Link shortening',
  select_domain: 'Domain',
  domain_id: 'Domain',
  uri_long: 'Uri long',
  uri_short: 'Uri short',
  created_at: 'Created',
  updated_at: 'Updated',

  save_success: 'Готово',
}

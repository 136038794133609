import modal_create_link from '~/locales/uk/Template/EmojioneArea/TemplateShortLink/ModalCreateLink';
import modal_static_link from '~/locales/uk/Template/EmojioneArea/TemplateShortLink/ModalStaticLink';

export default {
  title_btn: 'Коротке посилання',

  create_link_btn: 'Створити посилання',
  statistic_link_btn: 'Статичне посилання',
  dynamic_link_btn: 'Динамічне посилання',

  modal_create_link,
  modal_static_link,
};

import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';
import { ActionTree, GetterTree, MutationTree } from '~/node_modules/vuex';
import { RootState } from '~/store';

export const state = () => ({
  list: {},
  lastAdd: [],
});

export type GroupContactState = ReturnType<typeof state>

export const getters: GetterTree<GroupContactState, RootState> = {};

export const mutations: MutationTree<GroupContactState> = {
  ADD_LIST (state, items) {
    state.list = {
      ...state.list,
      ...items.reduce((r, item) => {
        r[item.id] = item;
        return r;
      }, {}),
    };
  },
  SET_LAST_ADD (state, ids) {
    state.lastAdd = ids;
  },
};

export const actions: ActionTree<GroupContactState, RootState> = {
  list ({ dispatch, commit }, {
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch, relate);
    }

    return api.contacts.group
      .list(page, pagesize, sort, options)
      .then(response => {
        // console.info('api.contacts.group.list | response = ', response.data);
        const items = response.data.Body || [];
        commit('ADD_LIST', items);
        return {
          items,
          totalRows: response.data.Meta.TotalSize,
        };
      })
      .catch(error => {
        console.error('store/group-contact/list | error = ', error);
        throw error;
      });
  },
  listFavorite ({ dispatch, rootState }, {
    page = 1,
    pagesize = 10,
    type,
    observer = null,
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }
    return dispatch(
      'trailer/setting/list',
      {
        key: `favorite.${rootState.auth.user.id}`,
        entity_type: 'contact_' + type,
        page,
        pagesize,
        sort: 'created_at desc',
      },
      { root: true },
    ).then(async ({ items, totalRows }) => {
      // console.info('listFavorite | items = ', items);
      items = await Promise.all(items.map(({ entity_id: gid }) => dispatch('one', { observer, gid })));
      return { items, totalRows };
    }).catch(error => {
      console.error('store/group-contact/listFavorite | listFavorite | ', error);
      throw error;
    });
  },
  search ({ dispatch }, {
    page = 1,
    pagesize = 10,
    observer = null,
    relate = [],
    ...options
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch, relate);
    }

    const { q: query, ...otherOptions } = options;

    return api
      .contacts.search.contact
      .list(page, pagesize, '', { query, ...otherOptions })
      .then(async ({ data }) => {
        // console.info('store/group-contact/search | response = ', data);

        const items = await Promise
          .all((data.contacts || []).map(({ contact }) =>
            api.contacts.group.get(contact.group_id, true)
              .then(({ data }) => ({ ...data, contact }))
              .catch(() => null),
          )).then(r => r.filter(i => !!i));
        const totalRows = data.total || 0;

        return { items, totalRows };
      }).catch(error => {
        console.error('store/group-contact/search | error = ', error);
        throw error;
      });
  },
  one ({ commit }, { gid } = {}) {
    return api.contacts.group.get(gid)
      .then(({ data }) => {
        // console.info('api.contacts.group.one | response = ', data);
        commit('ADD_LIST', [data]);
        return data;
      })
      .catch(error => {
        console.error('store/group-contact/one | error = ', error);
        throw error;
      });
  },
  create ({ commit }, { data }) {
    data = { ...data };
    delete data.id;
    delete data.gid;
    return api.contacts.group.post(data)
      .then(({ data }) => {
        // console.info(' api.contacts.group.post | response = ', response);
        commit('ADD_LIST', [data]);
        return data;
      })
      .catch(error => {
        console.error('store/group-contact/create | error = ', error);
        throw error;
      });
  },
  edit ({ commit }, { gid, data }) {
    data = { ...data };
    return api.contacts.group.put(gid, data)
      .then(([data]) => {
        // console.info('api.contacts.group.put | response = ', response);
        commit('ADD_LIST', [data]);
        return data;
      })
      .catch(error => {
        console.error('store/group-contact/edit | error = ', error);
        throw error;
      });
  },
  delete (_, { id }) {
    return api.contacts.group.delete(id)
      .then(({ data }) => {
        // console.info('api.contacts.group.delete | response = ', data);
        return data;
      })
      .catch(error => {
        console.error('store/group-contact/delete | error = ', error);
        throw error;
      });
  },
  createParamsProcessing (_, { gid, fid, pid }) {
    return api.contacts.group.files.processing.get(gid, fid, pid)
      .then(({ data }) => {
        // console.info('api.contacts.group.files.processing.get | response = ', response);
        return data;
      })
      .catch(error => {
        console.error('store/group-contact/createParamsProcessing | error = ', error);
        throw error;
      });
  },
};

export function getGroupContactOnePromises (observer, item, idName = 'contact_id') {
  return new Promise(resolve => {
    if (!item) {
      resolve(null);
    } else if (item[idName]) {
      observer.subscribeObserver(
        'group-contact/one',
        { gid: item[idName] },
        contact => {
          item.contact = contact;
          resolve(item);
        },
      );
    } else {
      item.contact = null;
      resolve(item);
    }
  });
}

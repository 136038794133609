import form from '~/locales/uk/GroupContact/Contact/form';
import table from '~/locales/uk/GroupContact/Contact/table';
import show from '~/locales/uk/GroupContact/Contact/show';
import add_phone from '~/locales/uk/GroupContact/Contact/AddPhone';
import modal_show from '~/locales/uk/GroupContact/Contact/ModalShow';
import modal_copy from '~/locales/uk/GroupContact/Contact/ModalCopy';
import dump from '~/locales/uk/GroupContact/Contact/Dump';
import convert from '~/locales/uk/GroupContact/Contact/Convert';

export default {
  title: 'Групи контактів',
  title_create: 'Створити групу контактів',
  title_show: 'Контакт {name}',
  title_show_breadcrumbs: 'Подивитись контакт',
  title_edit: '{phone} в {name}',
  title_edit_breadcrumbs: 'Редагувати контакт',

  form,
  table,
  show,
  add_phone,
  modal_show,
  modal_copy,
  dump,
  convert,
};

export default {
  loading: 'Завантаження...',

  name: 'Назва',
  tenant_id: 'Компанія',
  bulk_idp: 'IDP',
  api_type: 'Тип API',
  bulk_login: 'Логін',
  bulk_secret_key: 'Секретний ключ',

  statistic: 'Статистика',

  delete_confirm: 'Ви дійсно хочете видалити проект "{name}"?',
  delete_success: 'Проект "{name}" видалено.',
}

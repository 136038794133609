import message_type_option from '~/locales/ru/ClientSender/TrailerProfile/MobileId/MessageTypeOption';
import company_type_option from '~/locales/ru/ClientSender/TrailerProfile/MobileId/CompanyTypeOption';
import message_volume_option from '~/locales/ru/ClientSender/TrailerProfile/MobileId/MessageVolumeOption';
import service_integration_type_option
  from '~/locales/ru/ClientSender/TrailerProfile/MobileId/ServiceIntegrationTypeOption';
import service_launch_date_option from '~/locales/ru/ClientSender/TrailerProfile/MobileId/ServiceLaunchDateOption';

export default {
  service_name_original_language: 'Название (на оригинальном языке)',
  service_name_english_language: 'Название (на английском)',
  website: 'Адрес сайта',
  description: 'Описание',
  description_helper: '',
  logo_img: 'Логотип отправителя',
  logo_chatscreen: 'Обложка',
  business_address: 'Адрес компании',
  business_phone_number: 'Номер телефона компании',
  business_email: 'Email компании',
  message_type: 'Тип сообщений',
  message_type_option,
  service_registered_country: 'Страна нахождения компании',
  company_type: 'Тип компании',
  company_type_option,
  message_volume: 'Объем отправляемых сообщений в месяц',
  message_volume_option,
  service_integration_type: 'Тип интеграции',
  service_integration_type_option,
  destination_countries: 'Страны рассылки',
  service_launch_date: 'Дата запуска',
  service_launch_date_option,
  tin_company: 'ИНН (Индивидуальный налоговый номер) компании',
  sample_message_promotional: 'Пример рекламного сообщения',
  sample_message_transactional: 'Пример транзакционного сообщения',

}

var ContentStatus;
(function(ContentStatus2) {
  ContentStatus2["NEW"] = "new";
})(ContentStatus || (ContentStatus = {}));
var ContentContentType;
(function(ContentContentType2) {
  ContentContentType2["PROMO"] = "promo";
  ContentContentType2["TRANSACTION"] = "transaction";
})(ContentContentType || (ContentContentType = {}));
export {
  ContentContentType,
  ContentStatus
};

function SetDefaultPaginator(req) {
  if (!req.page) {
    req.page = 1;
  }
  if (!req.page_size) {
    req.page_size = 10;
  }
}
function SetDefaultPaginatorLibrarium(req) {
  if (!req.page) {
    req.page = 1;
  }
  if (!req.per_page) {
    req.per_page = 10;
  }
}
function SetDefaultOrder(req) {
  if (!req.order_by) {
    req.order_by = "created_at desc";
  }
}
export {
  SetDefaultOrder,
  SetDefaultPaginator,
  SetDefaultPaginatorLibrarium
};

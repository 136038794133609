export default {
  company: {
    title: 'Company details',
    name: {
      title: 'Legal entity name',
    },
    reg_num: {
      title: 'Registration number of the legal entity',
    },
    vat: {
      title: 'EU VAT number',
      description: 'Enter the European VAT number of your company, e.g. DE123456789 or leave the field blank.',
    },
  },
  certificate: {
    name: {
      title: 'Entity registration document',
      description: 'Attach a document that prove a registration of your legal entity.',
      subdescription: 'Supported file extensions:',
    },
  },
  address: {
    title: 'Address',
    city: {
      title: 'City',
    },
    state: {
      title: 'State',
    },
    index: {
      title: 'Post code',
    },
    legal: {
      title: 'Address',
    },
  },
  tax: {
    title: 'Taxation system',
    system: {
      title: 'Select taxation system of your company',
      USNO: 'Not a VAT payer',
      OSNO: 'VAT payer',
    },
    ogrn: {
      title: 'Company registration number',
    },
    inn: {
      title: 'Tax number',
    },
    kpp: {
      title: 'Tax registration code (KPP)',
    },
  },
  confirm: {
    text: 'I, legal representative of {company_name}, got acquainted with the Message Distribution Agreement of Messaggio LTD. and accept its terms.',
  },
};

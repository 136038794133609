<template>
  <select2
    v-bind="options"
    :name="name"
    v-model="value_"
    @select="$emit('select', arguments[0])"/>
</template>

<script>
import axios from 'axios';

export default {
  name: 'widget-field-project-id',
  props: {
    list: {type: Array, default: () => ([])},
    name: {type: String},
    value: {type: [Array, String], default: () => ([])},
    placeholder: {type: String, default: 'widget.field.project_id.placeholder'},
    multiple: {type: Boolean, default: false},
    dropdownParent: {type: Boolean, default: false},
    allowClear: {type: Boolean, default: true},
    ajax: {type: Boolean, default: true},
  },
  computed: {
    value_: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    options() {
      const options = {
        ...this.$props,
      };
      if (this.ajax) {
        options.ajax = {
          url: axios.defaults.baseURL + "documents/api/v1/projects",
          dataType: 'json',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.token}`,
          },
          delay: 250,
          type: 'GET',
          data: params => ({
            order_by: 'name asc',
            q: params.term,
            page: 1,
            page_size: 10,
          }),
          processResults: ({Body}) => ({
            results: (Body || []).map(project => ({
              id: project.id,
              text: project.name || `id: ${project.id}`,
              project,
            })),
          }),
          cache: true,
        };
      }

      //console.info('WidgetFieldProjectId | options = ', options);

      return options;
    },
  },
};
</script>

<style scoped>

</style>

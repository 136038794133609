export default {
  name: 'Name',
  name_placeholder: 'Template {time}',
  select_project: 'The template will be related to the project',
  tenant_id: 'Company',
  tenant_id_placeholder: 'Company name',
  clientsender_id: 'Sender',
  channel: 'Channels',
  select_template_channels: 'Sender of the message',
  channels_empty: 'There are no any active senders in the project',
  select_project_title: 'Select project',
  project_id: 'Projects',
  project_id_placeholder: 'Select project',
  project_id_placeholder_empty: 'No any projects with active senders',
  client_sender_id_title: 'Choose sender ID',
  client_sender_id_description: 'You can create multi-channel message templates if you have active senders on different channels.<br>' +
    'For example, forming message template for sending it on Viber, you can add SMS text to the template, which will be sent to recipients who do not have a Viber application.',
  client_sender_id_placeholder: 'Select Sender',
  type: 'type',
  contents_client_sender_id: 'Client Sender',
  status: 'Status',
  created_at: 'Date',
  content_layout: 'Layout',
  content_layout_template_category: 'Template content category',
  content_layout_language: 'Language',
  content_layout_text: 'Text',
  content_layout_text_image: 'Text + Image',
  content_layout_text_document: 'Text + File',
  content_layout_text_location: 'Text + Location',
  content_layout_text_image_btn: 'Text + Button + Image',
  content_layout_text_btn: 'Text + Button',
  content_layout_tpl: 'Template',
  content_layout_body_text: 'Message',
  content_layout_body_text_tpl: 'Message transactional template',
  content_layout_body_sample_text: 'Message example',
  content_layout_body_text_tpl_check: 'Check template for a match',
  content_layout_body_text_link: 'Link',
  content_layout_btn: 'Button',
  content_layout_body_title: 'Name',
  content_layout_body_link: 'Link',
  content_layout_image: 'Image',
  content_layout_body_file_name: 'Image file',
  content_layout_body_image_file_name: 'Link',
  content_layout_body_image_caption: 'Description',
  content_layout_video: 'Video',
  content_layout_document: 'Document',
  content_layout_location: 'Location',
  content_layout_contact: 'Contact',
  content_layout_video_thumbnail: 'Image cover',
  content_layout_video_action: 'Video file',
  content_layout_text_video_btn: 'Video + Text + Button',
  content_layout_text_video: 'Video + Text',
  content_layout_template_contact_first_name: 'First name',
  content_layout_template_contact_phone_number: 'Phone number',

  save_success: 'Saved successfully',

  preview_btn: 'Preview',
  preview_title: 'Template preview',
  preview_body: ' Select template channels and fill up content into fields to watch the preview of the message.',

  testphone_btn: 'Send to phone',

  message_from: '{channel_name} message from {client_sender_name}',

  error_project_id_empty: 'Project not select',
  error_sender_empty: 'Sender not selected',
  error_viber_check_tpl: 'Template does not match with the example text',
  error_viber_dynamic_link_scheme: 'You need to specify a scheme for URL',
  error_viber_template_param: 'Template contains illegal parameter: {params}',
  error_content_layout_body_sample_text_validation: 'Sample message does not match the template',
  error_button_row_empty: 'Button row may not be empty',

  before_route_leave_confirm: 'Do you really want to leave the template constructor?',

  sender_empty: 'There are no active senders in the project.',
  sender_empty_project_more_you_can: 'You can',
  sender_empty_project_more_create: 'create a sender',
  sender_empty_project_more_or: 'or',
  sender_empty_project_more_select_all: 'switch project filter to All projects.',
  sender_empty_project_more_select_all_after: '',
  sender_empty_project_one_you_can: 'You can',
  sender_empty_project_one_create: 'create a sender',
  sender_empty_project_one_create_after: 'related to your company brand.',

  knowledge_step: 'Step {step}',
  knowledge_step1_description: 'Select the Sender on whose behalf you will send messages.',
  knowledge_step2_description: 'Compose a message template using possible layouts. You can also use variables in the text and links, which will be filled with the parameters specified in the contact properties of your group for dispatch.',
  knowledge_step3_description: 'Send a message template to your test phone to understand how it will look on the device.',
};

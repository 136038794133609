export default {
  sending: 'Надсиланя...',
  canceled: 'Скасування',
  completed: 'Завершено!',
  view_statistics: 'Переглянути статистику',

  contacts_1: 'контакт',
  contacts_2: 'контакти',
  contacts_5: 'контактів',

  abort_sending: 'Скасувати розсилку',
  abort_sending_confirm: 'Ви впевнені, що хочете скасувати розсилку "{name}"?',
  abort_sending_success: 'Розсилку "{name}" скасовано.',
}

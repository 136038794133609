import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  one (_, { entity_id, key }) {
    return api.trailer.settings.slim.get(entity_id, key)
      .then(({ data }) => data)
      .catch(error => {
        console.error('api.trailer.settings.slim.get | error = ', error);
        throw error;
      });
  },
  store (_, { entity_id, key, data }) {
    return api.trailer.settings.slim.store(entity_id, key, data)
      .then(({ data }) => data)
      .catch(error => {
        console.error('api.trailer.settings.slim.post | error = ', error);
        throw error;
      });
  },
  delete (_, { entity_id, key }) {
    return api.trailer.settings.slim.delete(entity_id, key)
      .then(({ data }) => data)
      .catch(error => {
        console.error('api.trailer.settings.slim.delete | error = ', error);
        throw error;
      });
  },
};

export default {
  title: 'Create user',

  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  password: 'Password',
  repeat_password: 'Repeat password',
  role: 'Role',

  ok_title: 'Send invitation',
  save_success: 'Saved successfully',
}

import form from '~/locales/en/Project/form';
import table from '~/locales/en/Project/table';
import show from '~/locales/en/Project/show';
import widget_select_project from '~/locales/en/Project/WidgetSelectProject';
import card from '~/locales/en/Project/Card';

export default {
  title: 'Projects',
  title_create: 'Create Project',
  title_show: 'Project {name}',
  title_edit: 'Project {name}',

  clientsender: 'Senders',
  'dispatch-document': 'Dispatches',
  template: 'Templates',
  application: 'Apps',
  all_channels: 'All channels',
  agreement_no: 'Agreement No.',

  api_type_name_none: 'Not set',

  create_title: 'Create project',
  create_body: 'To keep separate records of traffic, message templates, or various types of APIs',
  create_btn: 'Create project',

  updated_at: 'Updated at',

  form,
  table,
  show,
  widget_select_project,
  card,
}

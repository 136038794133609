import amplitude from 'amplitude-js';
import { showUserNameOrEmail } from '~/utils/functions';

const API_KEY = '6712e229a2df9bc6a61c32f7b9843433';
let globalUserProperties = {};

export function amplitudeUpdateOptions(user, tenant) {
  console.info('amplitudeUpdateOptions (', user, ', ', tenant, ')');

  if (!user || !tenant) {
    return;
  }

  window.amplitude_options_user = {
    country: tenant.location_country,
  };

  amplitude.getInstance().init(API_KEY, null, {
    forceHttps: false,
    apiEndpoint: window.location.host + '/edutilpma/',
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
  });
  amplitude.getInstance().setUserId(user.id);
  Object.assign(globalUserProperties, {
    email: user.email,
    name: showUserNameOrEmail(user),
    country: tenant.location_country,
    tenant_id: tenant.id,
    tenant_name: tenant.name,
    account_id: tenant.id + '__fe-spa',
  });
  amplitude.getInstance().setGlobalUserProperties(globalUserProperties);
}

export function globalUserPropertiesReset() {
  globalUserProperties = {};
}

export function globalUserPropertiesOnboardingAb(ONBOARDING_AB) {
  Object.assign(globalUserProperties, { ONBOARDING_AB });
  amplitude.getInstance().setGlobalUserProperties(globalUserProperties);
}

export function amplitudeTrack(activity, module, org, user) {
  const utms = getUTM();
  amplitude
    .getInstance()
    .logEventWithGroups(activity, { ...org, ...user, ...utms }, { module });
}

function getUTM() {
  let utms = {};

  try {
    utms = JSON.parse(localStorage.getItem('utms') || '{}');
  } catch (e) {}

  utms = {
    ...utms,
    utm_source: localStorage.getItem('utm_source'),
    utm_campaign: localStorage.getItem('utm_campaign'),
    utm_medium: localStorage.getItem('utm_medium'),
    utm_term: localStorage.getItem('utm_term'),
    utm_content: localStorage.getItem('utm_content'),
    utm_referrer: localStorage.getItem('utm_referrer'),
  };

  return utms;
}

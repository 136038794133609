<template>
  <div
    v-if="show"
    class="alert alert-warning alert-styled-left mb-0 mr-3"
  >
    <nuxt-link :to="{name: 'company-user-id', params: {id: user.id}}">
      <span class="font-weight-semibold">
        {{ $t('company.user.verified_false_alert_warning') }}
      </span>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    show () {
      return !this.user.verified;
    },
  },
};
</script>

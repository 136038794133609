export default {
  title: 'Підготовка до відправки розсилки',
  description: 'Поки розсилка буде в процесі, ви не зможете її зупинити.<br>Будь ласка, підтвердьте, що вказана нижче інформація вірна.',

  name: 'Назва',
  channel: 'Канал',
  preview: 'Перегляд',

  schedule_title: 'Налаштування розсилки',
  schedule_sending: 'Запланувати відправку',
  schedule_description: 'Вкажіть дату і час старту розсилки.<br>Ви зможете скасувати заплановану розсилку до моменту її відправки.',
  cancel_dispatch_schedule: 'Скасувати відправку',
  start_dispatch_schedule: 'Запланувати розсилку',
  error_scheduled_date: 'Вкажіть коректну дату і час',

  start_dispatch: 'Розіслати',

  submit_info: 'Підготовка до розсилки',
  submit_progress: 'Підготовка контактів до розсилки',
  submit_success: 'Розсилка відправляється',
  submit_schedule_success: 'Заплановано на {time}',
  cancel_schedule_success: 'Планування розсилки скасовано',
}

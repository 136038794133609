import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({commit, state}, {page = 1, pagesize = 10} = {}) {
    return api.documents.channel.list(page, pagesize, null, {cache: true}).then(response => {
      //console.info('api.documents.channel.list | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.channel.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {id, observer = null, relate = []}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.documents.channel.get(id).then(async response => {
      //console.info('api.documents.channel.one | response = ', response.data);
      let item = response.data;
      return item;
    }).catch(error => {
      console.error('api.documents.channel.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.channel.post(data).then(response => {
      //console.info('api.documents.channel.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.channel.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.documents.channel.put(id, data).then(response => {
      //console.info('api.documents.channel.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.channel.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.channel.delete(id).then(response => {
      //console.info('api.documents.channel.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.channel.put | error = ', error);
      throw error;
    });
  },
  gates({commit, state}, {id}) {
    return api.documents.channel.gates(id).then(response => {
      //console.info('api.documents.channel.gates | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.channel.gates | error = ', error);
      throw error;
    });
  },
};

export function getChannelOnePromises(observer, item, id) {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (id) {
      observer.subscribeObserver(
        'channel-and-gate/channel-and-gate/channel/one',
        {id},
        (channel) => {
          item.channel = channel;
          resolve(item);
        },
      );
    } else {
      item.channel = null;
      resolve(item);
    }
  });
}

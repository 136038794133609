import table from '~/locales/uk/Statistic/table';
import filter from '~/locales/uk/Statistic/Filter';
import chart from '~/locales/uk/Statistic/Chart';
import report from '~/locales/uk/Statistic/Report';
import phone from '~/locales/uk/Statistic/Phone';
import widget_status_count_top from '~/locales/uk/Statistic/WidgetStatusCountTop';

export default {
  title: 'Статистика',
  title_table: 'Статистика по {clientsender_name} {message_date_from} — {message_date_to}',

  table,
  filter,
  chart,
  report,
  phone,
  widget_status_count_top,

  message_status: 'Статус',
  phone_number: 'Контакт',
  message_id: 'ID Повідомлення',
  message_time: 'Дата',

  csv_alert_title: 'Підготовка файлу',
  csv_alert_done: 'Файл готовий',
  csv_alert_failed: 'Помилка при генерації файлу',
  csv_alert_download: 'Завантажити звіт',

  link_statistic: 'Статистика',
  link_report: 'CSV Звіти',
  search_placeholder: 'Пошук за номером телефону або ID повідомлення',
};

import modal_columns from '~/locales/uk/Statistic/Filter/ModalColumns';

export default {
  list_preset_loading: 'Завантаження параметрів...',

  message_date: 'Період',
  project_id: 'Проект',
  project_id_all: 'Всі проекти',
  channel_id: 'Канали',
  clientsender_id: 'Відправник',
  dispatch_id: 'розсилка',
  country: 'Країни і оператори',
  country_title: 'Країни',
  mobile_operator_title: 'Оператори',
  columns_select: 'Налаштування звіту',
  select: 'обрати',
  fetch_csv: 'Завантажити CSV',
  report: 'CSV Звіти',

  today: 'сьогодні',
  this_month: 'місяць',
  last_month: 'минулий місяць',

  modal_columns,

  country_ru: 'Росія',
  country_ua: 'Україна',
  country_kz: 'Казахстан',
  country_md: 'Молдова',
  country_by: 'Білорусь',

  grouped: 'згрупований',
  custom: 'спеціальний',

  show_more_filters: 'Ще фільтри',
  hide_more_filters: 'Згорнути',

  message_date_from: 'Від',
  message_date_to: 'До',
  message_date_helpdesk: 'Детальна статистика доступна тільки за останні 3 месяці.',
};

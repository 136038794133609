import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  create({commit, state}, {...data}) {
    return api.short_link.domain.post(data).then(({data}) => {
      //console.info('api.short_link.domain.post | data = ', data);
      return data;
    }).catch(error => {
      console.error('api.short_link.domain.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {id, ...data}) {
    return api.short_link.domain.put(id, data).then(({data}) => {
      //console.info('api.short_link.domain.put | data = ', data);
      return data;
    }).catch(error => {
      console.error('api.short_link.domain.put | error = ', error);
      throw error;
    });
  },
};

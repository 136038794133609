import header from '~/locales/uk/DispatchDocument/form/Header';
import template from '~/locales/uk/DispatchDocument/form/Template';
import group_contact from '~/locales/uk/DispatchDocument/form/GroupContact';
import price from '~/locales/uk/DispatchDocument/form/Price';
import contacts_split_proportion from '~/locales/uk/DispatchDocument/form/ContactsSplitProportion';

export default {
  tenant_id: 'Компанія',
  tenant_id_placeholder: 'Компанія ID',
  channel_id: 'Канал',
  btn_create_template: 'Створити шаблон',

  content_select_empty_title: 'Не обрано шаблон повідомлення',
  content_select_empty_dody: 'Оберіть шаблон повідомлення зі списку праворуч',

  load_more_templates: 'Завантажити ще',
  select_recipients: 'Оберіть отримувачів',

  content_id_empty: 'Повідомлення не вказано',
  contents_a_empty: 'Повідомлення A не вказано',
  contents_b_empty: 'Повідомлення B не вказано',
  group_contact_for_dispatch_document_empty: 'Не обрані отримувачі розсилки',

  preview_btn: 'Попередній перегляд',
  preview_title: 'Попередній перегляд',
  preview_template: 'Шаблон',
  preview_content: 'Повідомлення',
  preview_contents_not_select: 'Оберіть шаблон повідомлення і отримувачів розсилки',

  save_success: 'Готово',
  save_success_on_create: 'Розсилка сформована для відправки',
  action_schedule: 'Запланувати',
  action_launch_now: 'Розіслати зараз',

  template_empty: 'В проекті відсутні активні шаблони.',
  template_empty_project_more_you_can: 'Ви можете',
  template_empty_project_more_create: 'створити шаблон',
  template_empty_project_more_or: 'або',
  template_empty_project_more_select_all: 'переключити фільтр проектів на Всі проекти.',
  template_empty_project_more_select_all_after: '',
  template_empty_project_one_you_can: 'Ви можете',
  template_empty_project_one_create: 'створити шаблон',
  template_empty_project_one_create_after: 'для використання в розсилці повідомлень.',

  error: {
    skip_template_moderation: 'Автомодерація повинна бути включена. Зверніться до служби підтримки.',
  },

  header,
  template,
  group_contact,
  price,
  contacts_split_proportion,
};

export default {
  whitelist_title: 'Оберіть групи контактів для розсилки',
  whitelist_btn: 'Оберіть групи контактів',

  blacklist_title: 'Оберіть групи контактів для виключення',
  blacklist_btn: 'Оберіть групи контактів',

  q_placeholder: 'Пошук групи контактів',

  name: 'Назва',
  quantity: 'Кількість',

  whitelist_modal_title: 'Групи контактів для розсилки',
  whitelist_modal_body: 'Оберіть групи контактів для розсилки',

  blacklist_modal_title: 'Групи контактів для виключення з розсилки',
  blacklist_modal_body: 'Оберіть групи контактів для виключення з розсилки',

  add_selected: 'Додати обрані',

  tab_all: 'Всі контакти',
  tab_favorite: 'Обрані контакти',

  clear_confirm: 'Ви дійсно хочете очистити список груп контактів?',

  create_group: 'Створити групу',

  contacts_1: 'контакт',
  contacts_2: 'контакти',
  contacts_5: 'контактів',
}

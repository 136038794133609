export default {
  name: 'Відправник',
  tenant_id: 'Компанія',
  channel: 'Канал',
  status: 'Статус',

  sender_from_empty: 'НЕ прив`язано',

  statistic_btn: 'Статистика',
  send_for_moderation_btn: 'Надіслати на модерацію',
  deactivate_btn: 'Відключити',
  activate_btn: 'Активувати',
  in_status_send_for_moderation_btn: 'Надіслати на модерацію?',

  loading: 'Завантаження...',
  delete_confirm: 'Ви дійсно хочете видалити відправника "{name}"?',
  delete_success: 'Відправника "{name}" видалено.',
}

export default {
  progress: {
    title: 'Messaging channels',
  },
  left: {
    title: 'Select messaging channels',
    subtitle: 'Some channels require a monthly fee to keep Sender ID live.',
    subsubtitle: 'We will create drafts of Sender IDs, then you should to complete the ones and deposit the balance.',
  },
  form: {
    name: {
      title: 'Sender ID (You can edit it later)',
      title_validate: 'Sender ID',
    },
    bottelegram_http_api_token: {
      title: 'Telegram bot HTTP API token',
    },
    channels: {
      title: 'What messaging channels do you plan to use?',
      sms: {
        title: 'SMS',
        price: {
          uk: 'From UAH 2 000/mo',
          en: 'May be subject to a monthly fee',
          def: 'From 5 000 rub/mo',
        },
      },
      viber: {
        title: 'Viber',
        price: 'From 100 Eur/mo',
      },
      flashcall: {
        title: 'Flashcall phone number verification',
        price: 'No monthly fee',
      },
      whatsapp: {
        title: 'WhatsApp Business API',
        price: 'From 200 Eur/mo',
      },
      vk: {
        title: 'VKontakte Business Messages',
        price: 'No monthly fee',
      },
      bottelegram: {
        title: 'Telegram beta',
        price: 'No monthly fee',
        http_api_token: 'Telegram bot HTTP API token',
      },
      mobileid: {
        title: 'Mobile ID verification',
        price: 'No monthly fee',
      },
      rcs: {
        title: 'RCS Business Messaging',
        description: 'Soon',
      },
      verified_sms: {
        title: 'Verified SMS by Google',
        description: 'Soon',
      },
    },
    type_integration: {
      title: 'Select type of integration',
      lk: 'I will use messaging dashboard only',
      plugin: 'I will set up the integration via plugin',
      api: 'I will use messaging REST API',
    },
    call_me: {
      title: 'Can we contact you for a presentation of the platform capabilities?',
      yes: 'Yes, sure.',
    },
    after_submit_success: {
      title: 'All set!',
      text: 'Complete the Sender ID setup and request a moderation.',
    },
  },
};

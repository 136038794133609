import api from '~/api';
import { track } from '~/plugins/tracker/tracker';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  domainFetch() {
    return api.short_link.domain
      .list(1, 9999, 'domain asc', {})
      .then(({data: {Body: items = [], Meta: {TotalSize: totalRows = 0} = {}} = {}}) => {
        //console.info('api.short_link.domain.list | data = ', arguments);

        items = items.map(item => {
          if (item.domain.substr(-1) !== '/') {
            item.domain += '/';
          }
          return item;
        });

        return {items, totalRows};
      }).catch(error => {
        console.error('api.short_link.domain.list | error = ', error);
        throw error;
      });
  },
  create({commit, state}, {...data}) {
    return api.short_link.link.post(data).then(({data}) => {
      //console.info('api.short_link.link.post | data = ', data);
      return data;
    }).then(() => {
      track('Create a Short Link', 'Using');
    })
  },
  edit({commit, state}, {id, ...data}) {
    return api.short_link.link.put(id, data).then(({data}) => {
      //console.info('api.short_link.link.put | data = ', data);
      return data;
    }).catch(error => {
      console.error('api.short_link.link.put | error = ', error);
      throw error;
    });
  },
};

<template>
  <select2
    v-model="value_"
    v-bind="options"
    :name="name"
    @select="$emit('select', arguments[0])"
  />
</template>

<script>
import axios from 'axios';

const bgByChannel = {
  rcs: ['bg-blue-300'],
  vk: ['bg-indigo-300'],
  viber: ['bg-violet-300'],
  whatsapp: ['bg-green-600'],
  sms: ['bg-slate-300'],
  default: ['bg-slate-300'],
};

export default {
  name: 'WidgetFieldClientSenderId',
  props: {
    list: { type: Array, default: () => ([]) },
    name: { type: String, default: 'sender_id' },
    value: { type: [Array, String], default: () => ([]) },
    placeholder: { type: String, default: 'widget.field.client_sender_id.placeholder' },
    multiple: { type: Boolean, default: false },
    dropdownParent: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: true },
    ajax: { type: Boolean, default: true },

    template: { type: String, default: 'default' },
    channelName: { type: String, default: 'default' },

    tenantId: { type: [String, Array], default: undefined },
    projectId: { type: [String, Array], default: undefined },
    channelId: { type: [String, Array], default: undefined },
    gateId: { type: Array, default: undefined },
    senderId: { type: Array, default: undefined },
    status: { type: String, default: undefined },
    direction: { type: String, default: undefined },
  },
  computed: {
    value_: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    options () {
      const options = {
        ...this.$props,
        dropdownCssClass: 'widget-field-client-sender-id',
      };
      if (this.ajax) {
        options.ajax = {
          url: axios.defaults.baseURL + 'documents/api/v1/clientsenders',
          dataType: 'json',
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
          delay: 250,
          type: 'GET',
          data: params => ({
            order_by: 'name asc',
            tenant: this.tenantId,
            project: this.projectId,
            channel: this.channelId,
            gate: this.gateId,
            sender: this.senderId,
            status: this.status,
            sender_direction: this.direction,
            q: params.term,
            page: 1,
            page_size: 10,
          }),
          processResults: ({ Body }) => ({
            results: (Body || []).map(clientSender => ({
              id: clientSender.id,
              text: clientSender.name || `id: ${clientSender.id}`,
              client_sender: clientSender,
            })),
          }),
          cache: true,
        };
      }

      switch (this.template) {
        case 'with_logo':
          options.templateResult = this.templateResultWithLogo;
          options.templateSelection = this.templateSelectionWithLogo;
          break;
      }

      // console.info('WidgetFieldClientSenderId | options = ', options);

      return options;
    },
  },
  methods: {
    templateResultWithLogo (opt) {
      if (!opt.id) { return opt.text; }

      const div = document.createElement('div');
      div.innerHTML = opt.client_sender.name;

      const name = div.innerText.trim();

      const iconClassName = (bgByChannel[this.channelName] || bgByChannel.default).join(' ');
      const nameCrop = (name.length ? name.substr(0, 2).toUpperCase() : '');

      div.innerHTML = '' +
        '<div class="d-flex align-items-center text-truncate">' +
          '<span class="flex-1">' +
            '<span class="btn rounded-round btn-icon btn-sm ' + iconClassName + ' mr-2">' +
              '<span class="letter-icon">' + nameCrop + '</span>' +
            '</span> ' +
            '<span>' + name + '</span>' +
          '</span>' +
          ((opt.client_sender.is_favorite) ? '<span class="text-orange"><i class="icon-star-full2"></i></span>' : '') +
        '</div>';

      return div;
    },
    templateSelectionWithLogo (opt) {
      if (!opt.id) { return opt.text; }

      const div = document.createElement('span');
      div.innerHTML = opt.client_sender.name;

      const name = div.innerText.trim();

      const iconClassName = (bgByChannel[this.channelName] || bgByChannel.default).join(' ');
      const nameCrop = (name.length ? name.substr(0, 2).toUpperCase() : '');

      div.innerHTML = '' +
        '<span class="btn rounded-round btn-icon btn-sm ' + iconClassName + ' mr-2">' +
          '<span class="letter-icon">' + nameCrop + '</span>' +
        '</span> ' +
        '<span>' + name + '</span>';

      return div;
    },
  },
};
</script>

<style>
.select2-dropdown.widget-field-client-sender-id {
  min-width: 300px;
}
</style>

import phone_is_empty from "~/locales/en/Company/Notification/PhoneIsEmpty";

export default {
  messaging: 'Messaging',
  launch_of_scheduled_dispatch: 'Launch of scheduled dispatch',
  message_template_moderation: 'Message template moderation',
  chatting: 'Chatting',
  chat_new_message_alerts: 'Display new message alerts in Dashboard',
  senders: 'Senders',
  sender_moderation: 'Sender moderation',
  sender_activation_or_deactivation: 'Sender activation or deactivation',
  sender_deactivation_reminder: 'Sender deactivation reminder',
  balance_and_payments: 'Balance and payments',
  low_balance_reminder: 'Low balance reminder',
  low_balance_reminder_subtitle: 'We will send an Email notification to your verified email address.',
  low_balance_reminder_badge: 'EMAIL',
  low_balance_reminder_mobile: 'Low balance reminder',
  low_balance_reminder_mobile_subtitle: 'We will send a Viber or SMS notification to your phone number, linked with your profile.',
  low_balance_reminder_mobile_badge: 'VIBER OR SMS',
  payment_status_notifications: 'Payment status notifications',
  system: 'System',
  messaging_channel_technical_failure_notification: 'Messaging channel technical failure notification',
  detailed_statistics_file_generation_completed: 'Detailed statistics file generation completed',
  user_agreement_updates: 'User agreement updates',
  scheduled_service_maintenance_reminder: 'Scheduled service maintenance reminder',
  technical_accident_notices: 'Technical accident notices',

  success: 'Notifications',
  save_success: 'Notification settings updated.',

  phone_is_empty,
}

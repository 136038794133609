import filter from '~/locales/uk/Statistic/Report/Filter';
import modal from '~/locales/uk/Statistic/Report/Modal';

export default {
  title: 'Звіти',

  show_only: 'Показати',
  show_only_my: 'Мої',
  show_only_all: 'Всі',

  download: 'Скачати ZIP',
  show_request: 'Логи',

  show_request_title: 'Query',

  name: 'Звіт',
  user_id: 'Користувач',
  progress: 'Прогрес',
  updated_at: 'Updated',
  status: 'Статус',

  processing: 'В роботі',
  done: 'Готово',

  delete_confirm: 'Ви впевнені що хочете видалити звіт "{name}"?',
  delete_success: 'Звіт "{name}" видалено.',

  filter,
  modal,
};
// $t('statistic.report.name')

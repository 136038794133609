import form from '~/locales/ru/ClientSender/form';
import table from '~/locales/ru/ClientSender/table';
import show from '~/locales/ru/ClientSender/show';
import modal_info_box from '~/locales/ru/ClientSender/ModalInfoBox';
import modal_comparison from '~/locales/ru/ClientSender/ModalComparison';
import modal_logo from '~/locales/ru/ClientSender/ModalLogo';
import modal_send_for_moderation from '~/locales/ru/ClientSender/ModalSendForModeration';
import modal_scheduler_status from '~/locales/ru/ClientSender/ModalSchedulerStatus';
import card from '~/locales/ru/ClientSender/Card';
import widget_scheduled_status from '~/locales/ru/ClientSender/WidgetScheduledStatus';
import widget_statistic from '~/locales/ru/ClientSender/WidgetStatistic';
import trailer_profile from '~/locales/ru/ClientSender/TrailerProfile';
import widget from '~/locales/ru/ClientSender/Widget';
import list from '~/locales/ru/ClientSender/List';

export default {
  title: 'Отправители',
  title_create: 'Создать отправителя',
  title_show: 'Отправитель {name}',
  title_show_breadcrumbs: 'Детальная информация',
  title_edit: 'Отправитель {name}',
  title_edit_breadcrumbs: 'Редактировать отправителя',

  create_btn: 'Создать',

  description: 'Этот раздел позволяет вам управлять своими отправителями сообщений на каждом канале. В зависимости от возможностей каждого канала вы можете настроить внешний вид отправителя: изменить название, обновить логотип и т.д.<br><br>' +
    'Вы можете создать отправитель на канале, который вы хотели бы использовать для отправки сообщений своим клиентам. Модерация отправителя включает в себя в том числе проверку на соответствие условиям и требованиям канала рассылки.',
  btn_view_glossary: 'Глоссарий',

  filter_q_placeholder: 'Название',
  all_status: 'Статус',
  all_channels: 'Канал',

  sender_empty: 'В проекте нет отправителей.',
  sender_empty_project_more_you_can: 'Вы можете',
  sender_empty_project_more_create: 'создать отправителя',
  sender_empty_project_more_or: 'сообщений или',
  sender_empty_project_more_select_all: 'переключить фильтр проектов на Все проекты.',
  sender_empty_project_more_select_all_after: '',
  sender_empty_project_one_you_can: 'Вы можете',
  sender_empty_project_one_create: 'создать отправителя',
  sender_empty_project_one_create_after: 'сообщений для вашего бизнеса.',

  modal_logo_title: 'Изменить логотип для {name}',
  modal_logo_image_crop_lable: 'Выберите область изображения для использования в логотипе',

  modal_logo_chatscreen_title: 'Изменить обложку для {name}',
  modal_logo_chatscreen_image_crop_lable: 'Выберите область изображения для использования в обложке',

  form,
  table,
  show,
  list,

  modal_info_box,
  modal_comparison,
  modal_logo,
  modal_send_for_moderation,
  modal_scheduler_status,

  card,
  widget_scheduled_status,
  widget_statistic,
  trailer_profile,
  widget,
}

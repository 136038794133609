import form from './form';
import table from './table';
import show from './show';

export default {
  title: 'Short link',
  title_create: 'Short link',
  title_show: 'Short link {name}',
  title_edit: 'Short link {name}',

  link_empty: 'There are no short links created on your account.',
  link_empty_project_one_you_can: '',
  link_empty_project_one_create: 'Create a short link',
  link_empty_project_one_create_after: 'to use it in a message template and count dispatch conversions.',

  create: 'Create short link',

  filter_q_placeholder: 'Type to filter...',

  form,
  table,
  show,
}

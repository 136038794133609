export default {
  title: 'Пошук',
  loading: 'Завантаження...',
  items_empty: 'Пусто',

  see_all: 'Подивитись все {type}',
  result_total: '{result} всього',

  form_submit: 'Пошук',

  tab_admin: 'Адмін',
  tab_messages: 'Повідомлення',
  tab_system_logs: 'Логи',
}

export default {
  title: 'Інформація про файл',

  name: 'Ім`я',
  description: 'Опис',
  downloaded: 'Завантажено',
  createdTime: 'Створено',
  mimeType: 'MimeType',
  size: 'Розмір',
  category: 'Категорія',
  hash: 'Хеш',
  shortHash: 'ShortHash',
  public: 'Public',

  delete: 'Видалити',
  download: 'Завантажити',
}

/* eslint-disable */

export default ({ app }) => {
  // Будет работать только на стороне клиента и только когда режим разработки будет "production"
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  // Подключаем скрипт Google Analytics
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments);
    };
    i[r].l = 1 * new Date();
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga',
  );

  // Подключаем скрипт Global site tag
  (function (i, s, o, g, r, a, m) {
    i['GoogleTagManagerObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments);
    };
    i[r].l = 1 * new Date();
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.googletagmanager.com/gtag/js?id=' + process.env.GOOGLE_TAG,
    'gtag',
  );

  // Устанавливаем текущую страницу
  ga('create', process.env.GOOGLE_ANALYTICS, 'auto');

  ga('set', 'hostname', window.location.host);

  // Вызывается каждый раз после смены роута (при инициализации тоже)
  app.router.afterEach((to, from) => {
    // We tell Google Analytics to add a `pageview`
    ga('set', 'page', to.fullPath);
    ga('send', 'pageview');
  });

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    dataLayer.push(arguments);
  };

  gtag('js', new Date());
  gtag('config', process.env.GOOGLE_TAG);
}

import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store';

export const state = () => ({});

export type FastFormDispatchDocumentState = ReturnType<typeof state>

export const getters: GetterTree<FastFormDispatchDocumentState, RootState> = {};

export const mutations: MutationTree<FastFormDispatchDocumentState> = {};

export const actions: ActionTree<FastFormDispatchDocumentState, RootState> = {};

export default {
  delete_confirm: 'Вы уверены, что хотите удалить пользователя "{name}"?',
  delete_success: 'Пользователь "{name}" был удален.',

  email_verified: 'ACTIVE',
  email_not_verified: 'Email не подтвержден',

  role_owner: 'Владелец',
  role_manager: 'Менеджер',
}

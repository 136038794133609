import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

const apiCallMap = {
  "dispatch": api.documents.dispatch_document.get,
  "template": api.documents.template.get,
  "contact_whitelist": api.contacts.group.get,
  "contact_blacklist": api.contacts.group.get,
  "clientsender": api.documents.clientsender.get,
  "project": api.documents.project.get,
};

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state, rootState}, {page = 1, pagesize = 10, observer = null, relate = []} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);
    return dispatch(
      'trailer/setting/list',
      {key: `favorite.${rootState.auth.user.id}`, page, pagesize, sort: 'created_at desc'},
      {root: true},
    ).then(async settings => {
      settings.items = await Promise.all(
        settings.items.map(async item => {
          if (item.entity_type === 'drive_file') {
            const [category, shortHash, name] = item.entity_id.split('/');
            await api.drive.file.get(category, shortHash, name)
              .then(apiCallResult => item.entity_name = apiCallResult.data.name)
              .catch(e => ({}));
          } else if (apiCallMap[item.entity_type]) {
            await apiCallMap[item.entity_type](item.entity_id)
              .then(apiCallResult => item.entity_name = apiCallResult.data.name)
              .catch(e => ({}));
          }
          return item;
        })
      );
      return settings;
    });
  },
  delete({commit, state}, {id}) {
    return api.testphone.phones.delete(id).then(response => {
      //console.info('api.testphone.phones.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.testphone.phones.delete | error = ', error);
      throw error;
    });
  },
};

export default {
  entity_name: 'Имя',
  entity_type: 'Тип',

  type_project: 'Проект',
  type_clientsender: 'Отправитель',
  type_contact_whitelist: 'Группа контактов',
  type_contact_blacklist: 'Группа контактов',
  type_template: 'Шаблон',
  type_dispatch: 'Рассылка',

  loading: 'Loading Senders...',
  delete_confirm: 'Вы уверены что хотите удалить из избраного "{name}"?',
  delete_success: 'Избраное "{name}" удалено.',
}

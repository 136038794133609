import channel_id from '~/locales/en/Widget/Field/ChannelId';
import clientsender_id from '~/locales/en/Widget/Field/ClientsenderId';
import dispatch_document_id from '~/locales/en/Widget/Field/DispatchDocumentId';
import images_restriction_id from '~/locales/en/Widget/Field/ImagesRestrictionId';
import project_id from '~/locales/en/Widget/Field/ProjectId';
import sender_id from '~/locales/en/Widget/Field/SenderId';
import template_id from '~/locales/en/Widget/Field/TemplateId';
import tenant_id from '~/locales/en/Widget/Field/TenantId';
import librarium_country_id from '~/locales/en/Widget/Field/LibrariumCountryId';
import librarium_language_id from '~/locales/en/Widget/Field/LibrariumLanguageId';
import role_id from '~/locales/en/Widget/Field/RoleId';
import da_data from '~/locales/en/Widget/Field/DaData';

export default {
  channel_id,
  clientsender_id,
  dispatch_document_id,
  images_restriction_id,
  project_id,
  sender_id,
  template_id,
  tenant_id,
  librarium_country_id,
  librarium_language_id,
  role_id,
  da_data,
}

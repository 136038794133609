import camelCase from 'lodash.camelcase';
import debounce from 'lodash.debounce';
import flattenDeep from 'lodash.flattendeep';
import api from '~/api';
import { catchDispatch } from '@/utils/form';

const storePath = 'permission';

const store = {
  namespaced: true,
  state: () => ({
    loading: [],
    fetch: [],
    handlers: {},
  }),
  getters: {
    can: state => paths => {
      for (let i = paths.length - 1; i >= 0; i--) {
        if (state.handlers[paths[i]] === undefined) {
          return;
        }
        if (state.handlers[paths[i]] === false) {
          return false;
        }
      }
      return true;
    },
  },
  mutations: {
    SET_LOADING (state, handlers) {
      state.loading = state.loading.concat(handlers);
    },
    UNSET_LOADING (state, handlers) {
      state.loading = state.loading.filter(h => !handlers.includes(h));
    },
    SET_FETCH (state, handlers) {
      state.fetch = state.fetch.concat(handlers);
    },
    UNSET_FETCH (state, handlers) {
      state.fetch = state.fetch.filter(h => !handlers.includes(h));
    },
    SET_HANDLERS (state, handlers) {
      state.handlers = {
        ...state.handlers,
        ...handlers,
      };
    },
  },
  actions: {
    ADD_PATHS ({ dispatch, commit, state }, paths) {
      const { loading: l, handlers: h } = state;
      paths = paths.filter(p => !l[p] && h[p] === undefined);

      commit('SET_LOADING', paths);
      commit('SET_FETCH', paths);
      dispatch('FETCH_DEBOUNCE');
    },
    FETCH_DEBOUNCE: debounce(({ dispatch }) => dispatch('FETCH'), 0),
    FETCH ({ commit, state }) {
      const paths = state.fetch;
      if (!paths.length) {
        return;
      }

      commit('UNSET_FETCH', paths);
      return api.saga.handler.post({ paths })
        .then(({ data: { available_handlers: h } }) => commit('SET_HANDLERS', h))
        .catch(error => {
          console.error('plugins/Permission/fetch | error =', error);
          throw error;
        })
        .finally(() => commit('UNSET_LOADING', paths));
    },
  },
};

const permissionMixin = {
  beforeCreate () {
    const options = this.$options;

    const vals = options.permissions;
    if (!vals) {
      return;
    }

    if (!this.$store.hasModule(storePath)) {
      this.$store.registerModule(storePath, store);
    }

    try {
      this.$store.dispatch(storePath + '/ADD_PATHS', flattenDeep(Object.values(vals)));
    } catch (e) {
      catchDispatch(this, e);
    }

    if (!options.computed) {
      options.computed = {};
    }
    Object.keys(vals).forEach(key => {
      options.computed[camelCase('can_' + key)] = () => this.$store.getters[storePath + '/can'](flattenDeep(vals[key]));
    });
  },
};

function Permissions (Vue) {
  Vue.mixin(permissionMixin);
  Vue.component('WidgetPermissions', () => import('./Permissions'));
}

export { Permissions, permissionMixin };
export default Permissions;

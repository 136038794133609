export default {
  title: 'Группы контактов',
  for_dispatch_document: 'Получатели рассылки',
  for_exception: 'Группы для исключения из рассылки',
  recipients: 'Получатели рассылки',
  excluded_contacts: 'Исключенные контакты',

  list_today: {
    title: 'Недавние группы (добавление в один клик)',
  },
}

export default {
  title: 'Створити користувача',

  first_name: 'Ім`я',
  last_name: 'Фамілія',
  email: 'Email',
  password: 'Пароль',
  repeat_password: 'Повторіть пароль',
  role: 'Ролі',

  ok_title: 'Відправити запрошення',
  save_success: 'Успішно збережено',
}

import api from '~/api';

const stateBase = () => ({
  status: {},
});

export const state = stateBase;

export const getters = {};

export const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  }
};

export const actions = {
  async ping({commit}) {
    await api.chat.user.status.put(1)
    await api.chat.user.status.get().then(({data: {status}}) => commit('SET_STATUS', status))
  },
}

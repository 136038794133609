export default {
  title: 'Виберіть тариф',
  description: 'Повідомлення та абонентська плата за ім\'я відправника сплачуються окремо.',
  header: {
    choose_plan: 'Оберіть підписку',
    free: 'Стандартний',
    corporate: {
      title: 'Корпоративний',
      price: '270',
      price_old: '370',
    },
    month1: 'місяц',
    month2: 'місяці',
    month5: 'місяців',
    month_sm: 'міс.',
    top_value: 'ВИГІДНО',
    currency: '₴',
  },
  features: {
    channels: 'Канали розсилки',
    account_users: 'Користувачі',
    projects: 'Проекти',
    test_phones: {
      title: 'Тестові номери',
      tooltip: 'Тестування розсилки на вашому пристрої та пристроях ваших колег.',
    },
    contacts_upload: 'Контакти',
    message_templates: 'Шаблони повідомлень',
    online_chats: {
      title: 'Онлайн-чати',
      tooltip: 'Інтерфейс обміну повідомленнями з клієнтами. Оптимізуйте час реакції на звернення до компанії та зробіть процес підтримки клієнтів у месенджерах організованішим.',
    },
    template_variables: {
      title: 'Персоналізація повідомлень',
      tooltip: 'Змінні для персоналізації розсилки: ім\'я, прізвище, дата, промокод та ін. Персоналізація допомагає залучати користувачів та підвищувати клікабельність повідомлень.',
    },
    api_plugin_integration: {
      title: 'API & Plugin інтеграції',
      tooltip: 'Інтеграція розсилок у вашу систему. Готові рішення щодо інтеграції з популярними CRM та CMS.',
    },
    scheduling_dispatches: {
      title: 'Заплановане відправлення',
      tooltip: 'Розсилку можна запланувати на конкретну дату та годину. Створіть кілька розсилок заздалегідь і не турбуйтеся - повідомлення автоматично надішлються у вказаний час.',
    },
    smooth_sending: {
      title: 'Плавне відправлення',
      tooltip: 'Розсилку можна надіслати протягом кількох годин. Плавне надсилання повідомлень допомагає захистити call-центр від великого потоку одночасних заявок.',
    },
    best_time_delivery: {
      title: 'Доставка у найкращий час',
      tooltip: 'Надсилання повідомлень у момент, коли користувач з найбільшою ймовірністю відкриє розсилку. Messaggio визначає найкращий час для надсилання повідомлення враховуючи накопичену історію розсилок абоненту.',
    },
    ab_testing: {
      title: 'A/B-тестування розсилок',
      tooltip: 'Інструмент для перевірки гіпотез та пошуку ефективних шаблонів повідомлень. Масштабуйте рішення, які отримують найвищий відгук одержувачів.',
    },
    url_shortener: {
      title: 'Короткі посилання та відстеження кліків',
      tooltip: 'Скорочувач посилань дозволяє відобразити у повідомленні привабливий URL без UTM-міток. Кліки за такими посиланнями фіксуються та відображаються у статистиці – це допоможе оцінити конверсію розсилки.',
    },
    cascading: {
      title: 'Каскадні розсилки',
      tooltip: 'Історія дій на обліковому записі Messaggio з прив\'язкою до користувача.',
    },
    action_history: {
      title: 'Історія дій',
      tooltip: 'История действий в аккаунте Messaggio с привязкой к пользователю.',
    },
    oauth2: {
      title: 'Двофакторна авторизація',
      tooltip: 'Додатковий захист облікового запису Messaggio через Google Auth.',
    },
    support: {
      title: 'Пріоритетна підтримка',
      tooltip: 'Стандартний час відповіді – 1 год. Звернення від клієнтів із тарифом «Корпоративний» обробляються протягом 15 хвилин.',
    },
  },
  unlimited: 'Не обмежено',
  products: {
    trial: {
      title: 'Спробуйте один місяць тарифу Corporate безкоштовно!',
      description: 'Для активації пробного періоду не потрібна банківська картка.<br>Протягом та після закінчення пробного періоду додаткова плата за підписку стягуватися не буде.',
      btn: 'Сбробувати зараз',
    },
    months1: {
      title: '270 / 1',
      price: '270',
    },
    months3: {
      title: '810 / 3',
      price: '810',
    },
    months6: {
      title: '1350 / 6',
      price: '1350',
      free: '1 місяць безкоштовно!',
      free_sm: '1 міс. безкоштовно!',
    },
    months12: {
      title: '2700 / 12',
      price: '2700',
      free: '2 місяці безкоштовно!',
      free_sm: '2 міс. безкоштовно!',
    },
    btn: 'Активувати',
  },
  errors: {
    B187: 'Помилка B187. Напишіть на <a href="mailto:support@messaggio.com">support@messaggio.com</a>',
  },
};

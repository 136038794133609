import form from '~/locales/ru/Chat/form';
import table from '~/locales/ru/Chat/table';
import dump from '~/locales/ru/Chat/export';
import last from '~/locales/ru/Chat/Last';
import manager from '~/locales/ru/Chat/Manager';
import menu from '~/locales/ru/Chat/Menu';
import recent from '~/locales/ru/Chat/Recent';

export default {
  title: 'Чаты',

  filter_q_placeholder: 'Поиск...',
  reply: 'Ответить',
  assigned_to: 'Назначить',
  btn_menu_assigned_to: 'Назначить чаты',

  assigned_to_processing: 'Assigned to in process',
  assigned_to_success: 'Assigned to success',

  go_to_chat_dashboard: 'Перейти в Chat Dashboard',

  form,
  table,
  dump,
  last,
  manager,
  menu,
  recent,

  attach: {
    btn_select: 'Прикрепить медиа',
  },
  attach_image: {
    btn_select: 'Прикрепить изображение',
    title: 'Выберите изображение',
    dropzone: {
      description: '<p>Вы можете загрузить изображение, удовлетворяющее требованиям:</p><p>Максимальный размер изображения 1600x1200px, до 2Мб.</p><p>Или выбрать из галереи ранее загруженных изображений.</p>',
    },
  },
  attach_video: {
    btn_select: 'Прикрепить видео',
    title: 'Выберите видео',
    dropzone: {
      description: '<p>Вы можете загрузить видео, удовлетворяющее требованиям:</p><p>Размер видеофайла до 200 Мб, формат 3gp, m4v, mov, mp4.</p><p>Или выбрать из ранее загруженных видео.</p>',
    },
  },
  attach_document: {
    btn_select: 'Прикрепить документ',
    title: 'Выберите документ',
    dropzone: {
      description: {
        viber: 'Вы можете загрузить файл, удовлетворяющий требованиям:<br>' +
          'Максимальный размер файла 200Мб<br>' +
          'Расширение файла: .doc, .docx, .rtf, .dot, .dotx, .odt ,odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx',
        whatsapp: 'Вы можете загрузить файл, удовлетворяющий требованиям:<br>' +
          'Максимальный размер файла 100Мб',
        bottelegram: 'Вы можете загрузить файл, удовлетворяющий требованиям: Максимальный размер файла 50Мб, расширение файла .gif; .pdf; .zip, или выбрать из галереи ранее загруженных файлов.',
      },
    },
  },
};

import Vue from 'vue';
import moment from 'moment';
import {capitalize} from "@/utils/functions";

Vue.filter('dateTimeSecHuman', timestamp => {
  if (!timestamp) return '';
  return getMoment(timestamp).format('DD.MM.YYYY HH:mm:ss');
});

Vue.filter('dateTimeHuman', timestamp => {
  if (!timestamp) return '';
  return getMoment(timestamp).format('DD.MM.YYYY HH:mm');
});

Vue.filter('dateHuman', timestamp => {
  if (!timestamp) return '';
  return getMoment(timestamp).format('DD.MM.YYYY');
});

Vue.filter('dateMMMMHuman', timestamp => {
  if (!timestamp) return '';
  return getMoment(timestamp).format('DD MMMM YYYY');
});

Vue.filter('dateOrTimeHuman', timestamp => {
  if (!timestamp) return '';
  let time = getMoment(timestamp);
  if (moment().isSame(time, 'd')) {
    return time.format('HH:mm');
  } else {
    if (moment().isSame(time, 'year')) {
      return time.format('DD MMM');
    } else {
      return time.format('MMM YYYY');
    }
  }
});

export function getMoment(timestamp) {
  if (timestamp instanceof moment) {
    return timestamp;
  } else if (/^[0-9]+$/.test(timestamp)) {
    return moment.utc(timestamp, 'X');
  } else {
    return moment.utc(timestamp);
  }
}

export function numberFormat(number, decimals, dec_point, thousands_sep) {
  // Format a number with grouped thousands
  //
  // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +	 bugfix by: Michael White (http://crestidg.com)

  let i, j, kw, kd, km;

  // input sanitation & defaults
  if (isNaN(decimals = Math.abs(decimals))) {
    decimals = 2;
  }
  if (dec_point === undefined) {
    dec_point = ",";
  }
  if (thousands_sep === undefined) {
    thousands_sep = ".";
  }

  i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

  if ((j = i.length) > 3) {
    j = j % 3;
  } else {
    j = 0;
  }

  km = (j ? i.substr(0, j) + thousands_sep : "");
  kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
  //kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "");
  kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

  return km + kw + kd;
}

Vue.filter('number_format', number => {
  return numberFormat(number, 0, '.', ' ')
});

Vue.filter('number_format_2', number => {
  return numberFormat(number, 2, '.', ' ')
});

Vue.filter('capitalize', str => capitalize(str));

Vue.filter('JSONSTR', obj => {
  return JSON.stringify(obj)
});

Vue.filter('formatBytes', bytes => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const decimals = 0;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
});

import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({commit, state}, {page = 1, pagesize = 10, sort = '', ...options} = {}) {
    if (sort.length > 0) sort += ',';
    sort += 'id asc';
    return api.documents.sender.list(page, pagesize, sort, options).then(response => {
      //console.info('api.documents.sender.list | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.sender.list | error = ', error);
      throw error;
    });
  },
  one({commit, state}, {id}) {
    return api.documents.sender.get(id).then(response => {
      //console.info('api.documents.sender.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.sender.one | error = ', error);
      throw error;
    });
  },
  async create({commit, state, dispatch}, {data}) {
    data = {...data};
    delete data.id;
    const result = await api.documents.sender.post(data).then(response => {
      //console.info('api.documents.sender.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.sender.post | error = ', error);
      throw error;
    });
    await dispatch('put_statuses', {id: result.id, status: data.status});
    return result;
  },
  edit({commit, state, dispatch}, {id, data}) {
    data = {...data};
    return Promise.all([
      api.documents.sender.put(id, data).then(response => {
        //console.info('api.documents.sender.put | response = ', response.data);
        return response.data;
      }).catch(error => {
        console.error('api.documents.sender.put | error = ', error);
        throw error;
      }),
      dispatch('put_statuses', {id, status: data.status}),
    ]);
  },
  put_statuses({commit, dispatch}, {id, status}) {
    return api.documents.sender.put_statuses(id, status).then(response => {
      //console.info('api.documents.sender.put_statuses | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.sender.put_statuses | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.sender.delete(id).then(response => {
      //console.info('api.documents.sender.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.sender.put | error = ', error);
      throw error;
    });
  },
};

export function getSenderByServiceIdOnePromises(observer, item, id_name = 'service_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'channel-and-gate/sender/list',
        {service: item[id_name]},
        (sender) => {
          item.sender = sender.items[0] || {};
          resolve(item);
        },
      );
    } else {
      item.sender = null;
      resolve(item);
    }
  });
}

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import _ from "lodash";
function errMsg(error) {
  var _a, _b, _c;
  return ((_a = error.response.data) == null ? void 0 : _a.message) || ((_b = error.response.data) == null ? void 0 : _b.error) || ((_c = error.response.data) == null ? void 0 : _c.status) || error.response.data || "Error: status code " + error.response.status;
}
function CatchErrorMsg(error) {
  var _a, _b;
  if (!error) {
    return "Error: no data";
  }
  if (error === "XhrError") {
    return "Error: network error";
  }
  if (error instanceof ErrorMessaggioOTP) {
    throw error;
  }
  if (!error.response) {
    return error.toString();
  }
  switch (error.response.status) {
    case 403: {
      if ((_a = error.response.data) == null ? void 0 : _a.error) {
        return "error." + _.snakeCase(_.trim(error.response.data.error));
      }
      break;
    }
    case 422: {
      if ((_b = error.response.data) == null ? void 0 : _b.errors) {
        throw new ErrorValidation("validation", error.response.data.errors);
      }
      throw new ErrorValidation(errMsg(error));
    }
  }
  const msg = errMsg(error);
  if (msg.includes("max rate limiting reached")) {
    throw new ErrorMaxRateLimiting();
  }
  return msg;
}
class ErrorTranslation extends Error {
  constructor(message) {
    super(message);
    __publicField(this, "params");
    this.name = "ErrorTranslation";
    Object.setPrototypeOf(this, ErrorTranslation.prototype);
  }
  toString() {
    return `error.${this.message}`;
  }
  getParams() {
    return this.params || {};
  }
  setParams(params) {
    this.params = params || {};
  }
}
class ErrorValidation extends ErrorTranslation {
  constructor(message, errors) {
    super(message);
    __publicField(this, "errors");
    this.errors = errors || {};
    this.name = "ErrorValidation";
    Object.setPrototypeOf(this, ErrorValidation.prototype);
  }
  toErrors() {
    return this.errors;
  }
}
const ErrorMessaggioOTPName = "ErrorMessaggioOTP";
class ErrorMessaggioOTP extends ErrorTranslation {
  constructor() {
    super("messaggio_otp");
    this.name = ErrorMessaggioOTPName;
    Object.setPrototypeOf(this, ErrorMessaggioOTP.prototype);
  }
}
class ErrorMaxRateLimiting extends ErrorTranslation {
  constructor() {
    super("max_rate_limiting");
    this.name = "ErrorMaxRateLimiting";
    Object.setPrototypeOf(this, ErrorMaxRateLimiting.prototype);
  }
}
export {
  CatchErrorMsg,
  ErrorMaxRateLimiting,
  ErrorMessaggioOTP,
  ErrorMessaggioOTPName,
  ErrorTranslation,
  ErrorValidation
};

export default {
  progress: {
    title: 'Информация о компании',
  },
  left: {
    title: 'Добро пожаловать!',
    subtitle: 'Расскажите нам о своих задачах, чтобы мы могли подобрать для вас лучшее решение.',
    subsubtitle: 'Укажите, пожалуйста, <mark>страну вашего нахождения</mark> и <mark>краткое описание</mark> деятельности вашей компании.',
  },
  form: {
    locale: {
      title: 'Язык интерфейса',
    },
    name: {
      title: 'Название компании',
      placeholder: 'Моя компания, ООО',
    },
    description: {
      title: 'Пару слов о вашем бизнесе',
      placeholder: 'Выберите',
      list: {
        it_and_internet_companies: 'IT и интернет сервисы',
        brand: 'Бренд',
        governmental: 'Государственная / Негосударственная организация',
        healthcare: 'Здравохранение',
        publisher: 'Издательство',
        e_commerce: 'Интернет-торговля',
        local_business: 'Местный бизнес',
        real_estate_and_construction_companies: 'Недвижимость и строительство',
        education: 'Образование',
        community_organization: 'Общественная организация',
        media_agency: 'Рекламное агентство',
        commerce: 'Торговля',
        transportation_services: 'Транспортные сервисы',
        financial_institution: 'Финансовый сервис',
        other: 'Другое',
      },
    },
    location_country: {
      title: 'Страна нахождения',
    },
  },
};

export default {
  loading: 'Загрузка...',

  name: 'Название',
  channel: 'Канал',
  quantity: 'Количество',
  tenant_id: 'Компания',
  scheduled: '',
  status: 'Статус',
  created_at: 'Дата',

  delete_confirm: 'Вы действительно хотите удалить рассылку "{name}"?',
  delete_success: 'Рассылка "{name}" удалена.',

  restore_confirm: 'Вы действительно хотите восстановить рассылку "{name}"?',
  restore_success: 'Рассылка "{name}" восстановлена.',

  scheduled_delete_confirm: 'Вы действительно хотите отменить запланированую рассылку "{name}"?',
  scheduled_delete_success: 'Запланированая рассылка "{name}" отменена.',
}

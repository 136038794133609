export default {
  progress: {
    title: 'Подтверждение почты',
  },
  left: {
    unVerified: {
      title: 'Добро пожаловать!',
      subtitle: '<strong>Пожалуйста, подтвердите ваш email</strong>',
      subsubtitle: 'Нажмите на ссылку подтверждения в письме, которое мы отправили вам на почту {email}.<br><br>Если письма нет в папке входящие, проверьте папку спам или введите в строку поиска в почтовой программе “noreply@messaggio.com”.',
      btn: {
        resend: 'Отправить повторное письмо',
        wasResend: 'Письмо отправлено',
      },
    },
    verified: {
      title: 'Добро пожаловать!',
      subtitle: 'Почта подтверждена',
      subsubtitle: 'Нажмите далее для продолжения',
    },
  },
  form: {
    locale: {
      title: 'Язык интерфейса',
    },
  },
};

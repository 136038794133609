import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state}, {
    page = 1,
    pagesize = 10,
    sort = '',
    relate = true,
    observer = null,
    ...options
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }
    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'id';
    return api.tenant_action_history.entity.list(page, pagesize, sort, options).then(async response => {
      //console.info('api.tenant_action_history.entity.list | response = ', response.data);
      let items = response.data.Body || [];
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.tenant_action_history.entity.list | error = ', error);
      throw error;
    });
  },
  one({commit, state, dispatch}, {id, observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);
    return api.tenant_action_history.entity.get(id).then(response => {
      //console.info('api.tenant_action_history.entity.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.tenant_action_history.entity.one | error = ', error);
      throw error;
    });
  },
  delete({commit, state, dispatch}, {id} = {}) {
    return api.tenant_action_history.entity.delete(id).then(response => {
      //console.info('api.tenant_action_history.entity.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.tenant_action_history.entity.one | error = ', error);
      throw error;
    });
  },
};

export function getEntityActionHistoryOnePromises(observer, item, id_name = 'entity_id', relate = true) {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[id_name]) {
      observer.subscribeObserver(
        'company/action-history/entity/one',
        {id: item[id_name], relate},
        (entity) => {
          item.entity = entity;
          resolve(item);
        },
      );
    } else {
      item.entity = null;
      resolve(item);
    }
  });
}

export function getEntityActionHistoryOnePromisesV2(observer, item, callBackRelate = () => ({}), id_name = 'entity_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
      return;
    }
    if (!item[id_name] || !item[id_name].length) {
      item.entity = null;
      resolve(item);
      return;
    }
    observer.subscribeObserver(
      'company/action-history/entity/one',
      {id: item[id_name], callBackRelate},
      entity => {
        item.entity = entity;
        resolve(item);
      },
    );
  });
}

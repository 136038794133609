export default {
  title: 'Копирование контактов в группу',
  description: 'Укажите группу, куда будут скопированы выбранные вами контакты.',

  btn_submit: 'Скопировать контакты',

  duplicate_cleanup_start: 'Начали очистку от дублей',
  duplicate_cleanup_deleted: 'Очистка от дублей: {deleted}',

  copy_success: 'Контакты успешно скопированы.',
}

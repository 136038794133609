import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({dispatch, commit, state, rootState}, {contract_id, page = 1, pagesize = 10, sort = '', observer = null, relate = [], ...options} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    if (sort.length > 0) sort += ',';
    sort += 'id';
    return api.saga.contract.payment.list(contract_id, page, pagesize, sort, options).then(async response => {
      //console.info('api.contract.get | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.saga.proxy.payments.list | error = ', error);
      throw error;
    });
  },
};

export default {
  title: 'Генерация отчета',
  description: 'Пожалуйста, подтвердите параметры генерации отчета',

  date_from: 'Дата с',
  date_to: 'Дата по',
  project: 'Проект',
  channels: 'Канал',
  client_sender: 'Отправитель',
  dispatch: 'Рассылка',

  cancel: 'Отмена',
  generate_report: 'Создать отчет',
}

export default {
  add_suggestion_title: 'Calendar event',

  item_title: 'Add event',
  item_empty: 'Details are not specified',

  title: 'Calendar event — suggested action',
  start_time: 'Start time',
  end_time: 'End time',
  action_title: 'Event title',
  action_description: 'Event description',
}

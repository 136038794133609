export default {
  title: 'Налаштування чатів',

  group_contact: {
    modal: {
      title: 'Оберіть групу контактів',
    },
  },

  contact_group_title: 'Збереження контактів до групи',
  contact_group_description: 'Ви можете зберегти контакти абонента, який звернувся до вас через месенджер.<br>' +
    'В подальшому його ім`я і вказані додаткові параметри відображатимуться в діалозі чату.',
  contact_group_label: 'Зберегти всі контакти до групи',

  assign_user_title: 'Розподілення звернень в чатах',
  assign_user_description: 'Вкажіть користувача системи, який отримуватиме всі нові звернення в чатах.<br>' +
    'Якщо конкретний користувач не вказаний, чати доступні для відповіді будь якому користувачу.',
  assign_user_label: 'Призначити новий чат на оператора',

  auto_response_title: 'Автоматичні відповіді системи',
  auto_response_description: 'Вкажіть текст, який відразу отримає абонент у відповідь на свое звернення.<br>' +
    'Зазвичай в тексті автоматичної відповіді міститься прохання трохи почекати для відповіді оператора.',
  auto_response_label: 'Автоматична відповідь на новый чат',

  reassign_notify_description: 'Вкажіть текст, який отримає абонент при призначенні чату на іншого оператора.<br>' +
    'Щоб проінформувати абонента про зміну співрозмовника задайте текст сповіщення про підключення нового оператора до переписки.',
  reassign_notify_label: 'Автоматична відповідь при зміні оператора в чаті',

  fast_answers_title: 'Швидкі відповіді в чаті',
  fast_answers_description: 'Вкажіть до 10 фраз, які оператори можуть використовувати для швидкої відповіді абоненту в переписці.<br>' +
    'Готові відповіді суттєво економлять час на початку і при завершенні діалогу.<br><br>' +
    'Перетягніть строки в тому порядку, в якому плануєте відобразити підказки в диалозі чату.',

  save_success: 'Успішно збережено',
};

import axios from 'axios';
import {
  amplitudeTrack,
  amplitudeUpdateOptions,
} from '~/plugins/tracker/amplitude';

export function updateOptions(user, tenant) {
  amplitudeUpdateOptions(user, tenant);
}

export function track(activity, module, org?, user?) {
  try {
    amplitudeTrack(activity, module, org, user);
  } catch (e) {}
}

interface ActivityList {
  ru: number;
  uk: number;
  en: number;
}

enum Activity {
  ACCEPT_TERMS_EN = 67,
  ACCEPT_TERMS_RU = 50,
  ACCEPT_TERMS_UK = 62,

  SPECIFY_COMPANY_DETAILS_EN = 65,
  SPECIFY_COMPANY_DETAILS_RU = 48,
  SPECIFY_COMPANY_DETAILS_UK = 60,

  SPECIFY_PROFILE_DETAILS_EN = 66,
  SPECIFY_PROFILE_DETAILS_RU = 49,
  SPECIFY_PROFILE_DETAILS_UK = 61,

  CREATE_SENDER_EN = 84,
  CREATE_SENDER_RU = 81,
  CREATE_SENDER_UK = 85,

  SEND_SENDER_FOR_MODERATION_EN = 90,
  SEND_SENDER_FOR_MODERATION_RU = 89,
  SEND_SENDER_FOR_MODERATION_UK = 91,

  ACTIVATE_CORPORATE_PLAN_EN = 93,
  ACTIVATE_CORPORATE_PLAN_RU = 92,
  ACTIVATE_CORPORATE_PLAN_UK = 94,

  ACTIVATE_CORPORATE_PLAN_TRIAL_EN = 87,
  ACTIVATE_CORPORATE_PLAN_TRIAL_RU = 86,
  ACTIVATE_CORPORATE_PLAN_TRIAL_UK = 88,

  BILLING_SUBSCRIPTION_ENDS_EN = 96,
  BILLING_SUBSCRIPTION_ENDS_RU = 95,
  BILLING_SUBSCRIPTION_ENDS_UK = 97,

  VERIFIDED_USERS_EN = 118,
  VERIFIDED_USERS_RU = 117,
  VERIFIDED_USERS_UK = 119,

  CREATE_CONTACT_GROUP_EN = 127,
  CREATE_CONTACT_GROUP_RU = 126,
  CREATE_CONTACT_GROUP_UK = 128,

  IMPORT_CONTACTS_TO_GROUP_EN = 130,
  IMPORT_CONTACTS_TO_GROUP_RU = 129,
  IMPORT_CONTACTS_TO_GROUP_UK = 131,

  CREATE_TEMPLATE_EN = 121,
  CREATE_TEMPLATE_RU = 120,
  CREATE_TEMPLATE_UK = 122,

  MODERATION_TEMPLATE_EN = 124,
  MODERATION_TEMPLATE_RU = 123,
  MODERATION_TEMPLATE_UK = 125,

  LAUNCH_DISPATCH_EN = 139,
  LAUNCH_DISPATCH_RU = 138,
  LAUNCH_DISPATCH_UK = 140,

  CRM_HUBSPOT_EN = 190,
}

export function trackSpecifyCompanyDetails(location_country) {
  track('Specify Company Details', 'Onboarding');
  changeSendinblueUserFromLk(location_country, {
    en: Activity.SPECIFY_COMPANY_DETAILS_EN,
    ru: Activity.SPECIFY_COMPANY_DETAILS_RU,
    uk: Activity.SPECIFY_COMPANY_DETAILS_UK,
  });
  changeSendinblueUserFromLk(location_country, {
    en: Activity.VERIFIDED_USERS_EN,
    ru: Activity.VERIFIDED_USERS_RU,
    uk: Activity.VERIFIDED_USERS_UK,
  });
}

export function trackSpecifyProfileDetails(location_country) {
  track('Specify Profile Details', 'Onboarding');
  changeSendinblueUserFromLk(location_country, {
    en: Activity.SPECIFY_PROFILE_DETAILS_EN,
    ru: Activity.SPECIFY_PROFILE_DETAILS_RU,
    uk: Activity.SPECIFY_PROFILE_DETAILS_UK,
  });
}

export function trackAcceptTerms(location_country) {
  track('Accept Terms', 'Onboarding');
  changeSendinblueUserFromLk(location_country, {
    en: Activity.ACCEPT_TERMS_EN,
    ru: Activity.ACCEPT_TERMS_RU,
    uk: Activity.ACCEPT_TERMS_UK,
  });
}

export function trackSenderCreate(location_country, channelName) {
  track('Create Sender', 'Setting', {
    sender_type: channelName,
    channel: channelName,
  });
  changeSendinblueUserFromLk(location_country, {
    en: Activity.CREATE_SENDER_EN,
    ru: Activity.CREATE_SENDER_RU,
    uk: Activity.CREATE_SENDER_UK,
  });
}

export function trackSenderSendForModeration(location_country, channelName) {
  track('Send Sender For Moderation', 'Setting', {
    sender_type: channelName.value,
    channel: channelName.value,
  });
  changeSendinblueUserFromLk(location_country, {
    en: Activity.SEND_SENDER_FOR_MODERATION_EN,
    ru: Activity.SEND_SENDER_FOR_MODERATION_RU,
    uk: Activity.SEND_SENDER_FOR_MODERATION_UK,
  });
}

export function trackCreateContactGroup(location_country) {
  track('Create Contact Group', 'Setting');
  changeSendinblueUserFromLk(location_country, {
    en: Activity.CREATE_CONTACT_GROUP_EN,
    ru: Activity.CREATE_CONTACT_GROUP_RU,
    uk: Activity.CREATE_CONTACT_GROUP_UK,
  });
}

export function trackImportContactsToGroup(location_country) {
  track('Import Contacts To Group', 'Setting');
  changeSendinblueUserFromLk(location_country, {
    en: Activity.IMPORT_CONTACTS_TO_GROUP_EN,
    ru: Activity.IMPORT_CONTACTS_TO_GROUP_RU,
    uk: Activity.IMPORT_CONTACTS_TO_GROUP_UK,
  });
}

export function trackCreateTemplate(location_country, contents) {
  contents.forEach((content) => {
    track('Create Template', 'Setting', {
      sender_type: content.channel.name,
      channel: content.channel.name,
    });
  });
  changeSendinblueUserFromLk(location_country, {
    en: Activity.CREATE_TEMPLATE_EN,
    ru: Activity.CREATE_TEMPLATE_RU,
    uk: Activity.CREATE_TEMPLATE_UK,
  });
}

export function trackModerationTemplate(location_country, contents) {
  contents.forEach((content) => {
    track('Send Template For Moderation', 'Setting', {
      sender_type: content.channel.name,
      channel: content.channel.name,
    });
  });
  changeSendinblueUserFromLk(location_country, {
    en: Activity.MODERATION_TEMPLATE_EN,
    ru: Activity.MODERATION_TEMPLATE_RU,
    uk: Activity.MODERATION_TEMPLATE_UK,
  });
}

export function trackLaunchDispatch(location_country, type, price) {
  track('Launch Dispatch', 'Setting', {
    dispatch_type: type,
    sum: price,
  });
  changeSendinblueUserFromLk(location_country, {
    en: Activity.LAUNCH_DISPATCH_EN,
    ru: Activity.LAUNCH_DISPATCH_RU,
    uk: Activity.LAUNCH_DISPATCH_UK,
  });
}

export function trackCrmHubspotEn() {
  trackAddListIds([Activity.CRM_HUBSPOT_EN]);
}

function changeSendinblueUserFromLk(
  location_country: string,
  { en, ru, uk }: ActivityList
) {
  const listIdMap = {
    ru,
    by: ru,
    ua: uk,
  };

  try {
    trackAddListIds([listIdMap[location_country] || en]);
  } catch (e) {}
}

function trackAddListIds(listIds) {
  let url =
    'https://store.messaggio.com/messaggio-api/offer/index.php?action=change-sendinblue-user-from-lk';
  if (isDev()) {
    url += '&env=dev';
  }
  axios.post(url, { listIds });
}

function isDev() {
  return (
    process.env.NODE_ENV !== 'production' ||
    window.location.host.startsWith('dev.')
  );
}

import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({commit, state}, {page = 1, pagesize = 10} = {}) {
    return api.documents.code.list(page, pagesize).then(response => {
      //console.info('api.documents.code.list | response = ', response.data);
      return {
        items: response.data.Body || [],
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch(error => {
      console.error('api.documents.code.list | error = ', error);
      throw error;
    });
  },
  one({commit, state}, {id}) {
    return api.documents.code.get(id).then(response => {
      //console.info('api.documents.code.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.code.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    delete data.id;
    return api.documents.code.post(data).then(response => {
      //console.info('api.documents.code.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.code.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {id, data}) {
    data = {...data};
    return api.documents.code.put(id, data).then(response => {
      //console.info('api.documents.code.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.code.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.documents.code.delete(id).then(response => {
      //console.info('api.documents.code.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.documents.code.put | error = ', error);
      throw error;
    });
  },
};

export default {
  title: 'Schedule start',
  description:
    'Specify the date and time when the dispatch should begin.<br>You can cancel the scheduled launch before it starts.',

  send_right_now: 'Send right now',
  send_tomorrow: 'Start tomorrow at {time} {offset}',
  send_at: 'Start {time} {offset}',

  no_timezone: 'Timezone is not set',
  error_no_timezone:
    'There is no timezone selected in your settings. Please check if your timezone is correct.',
};

import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  one({dispatch, commit, state, rootState}, {observer = null, relate = [], ...options} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    return api.chat.chat.setting.get().then(async response => {
      //console.info('api.chat.chat.setting.get | response = ', response.data);
      let item = response.data;

      item.fast_answers = item.fast_answers.map(t => t.trim()).filter(t => !!t.length);
      if (item.fast_answers.length < 10) {
        item.fast_answers = item.fast_answers.concat(
          Array.apply(null, Array(10 - item.fast_answers.length)).map(() => '')
        );
      }

      const promises = [];
      promises.push(getGroupContactOnePromises(observer, item, 'contact_group_id'));
      promises.push(getAssignUserOnePromises(observer, item, 'assign_user_id'));
      await Promise.all(promises);

      return item;
    }).catch(error => {
      console.error('api.chat.chat.setting.get | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, data) {
    return api.chat.chat.setting.edit({
      ...data,
      fast_answers: data.fast_answers.map(t => t.trim()).filter(t => !!t.length),
    }).then(response => {
      //console.info('api.chat.chat.setting.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.chat.chat.setting.put | error = ', error);
      throw error;
    });
  },
};

export function getGroupContactOnePromises(observer, item, contact_group_id_name = 'contact_group_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[contact_group_id_name]) {
      observer.subscribeObserver(
        'group-contact/one',
        {gid: item[contact_group_id_name]},
        (contact_group) => {
          item.contact_group = contact_group;
          resolve(item);
        },
      );
    } else {
      item.contact_group = null;
      resolve(item);
    }
  });
}

export function getAssignUserOnePromises(observer, item, assign_user_id_name = 'assign_user_id') {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve(null);
    } else if (item[assign_user_id_name]) {
      observer.subscribeObserver(
        'company/user/one',
        {id: item[assign_user_id_name]},
        (assign_user) => {
          item.assign_user = assign_user;
          resolve(item);
        },
      );
    } else {
      item.assign_user = null;
      resolve(item);
    }
  });
}

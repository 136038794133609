import axios from "axios";
import { CatchErrorMsg, ErrorTranslation } from "../../../errors.js";
function moderation(id) {
  return axios.post(`/api-gateway/api/v1/templates/${id}/status/moderation`).then(({ data: d }) => d).catch((e) => {
    const msg = CatchErrorMsg(e);
    if (msg.match(/template is not new/i)) {
      throw new ErrorTemplateNotNew();
    }
    throw new ErrorApiGatewayTemplatesStatusModeration(msg);
  });
}
class ErrorApiGatewayTemplatesStatusModeration extends ErrorTranslation {
  constructor(message) {
    super("api_gateway_templates_status_moderation." + message);
    this.name = "ErrorApiGatewayTemplatesStatusModeration";
    Object.setPrototypeOf(this, ErrorApiGatewayTemplatesStatusModeration.prototype);
  }
}
class ErrorTemplateNotNew extends ErrorApiGatewayTemplatesStatusModeration {
  constructor() {
    super("not_new");
    this.name = "ErrorTemplateNotNew";
    Object.setPrototypeOf(this, ErrorTemplateNotNew.prototype);
  }
}
export {
  ErrorApiGatewayTemplatesStatusModeration,
  ErrorTemplateNotNew,
  moderation
};

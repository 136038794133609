import modal_verification from '~/locales/uk/Company/Testphone/ModalVerification';
import form from '~/locales/uk/Company/Testphone/form';
import form_params from '~/locales/uk/Company/Testphone/FormParams';
import table from '~/locales/uk/Company/Testphone/table';

export default {
  title: 'Тестові телефони',
  title_create: 'Тестовий телефон',
  title_show: 'Тестовий телефон {phone}',
  title_edit: 'Тестовий телефон {phone}',

  phone_empty: 'У вас ще немає тестових телефонів.',
  phone_empty_project_one_you_can: 'Ви можете',
  phone_empty_project_one_create: 'прив`язати номери телефонів',
  phone_empty_project_one_create_after: 'до акаунту, щоб тестувати шаблони повідомлень.',

  create: 'Прив`язати телефон',

  filter_q_placeholder: 'Type to filter...',

  modal_verification,
  form,
  form_params,
  table,
};

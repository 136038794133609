import text from '~/locales/ru/Template/ContentLayout/RCS/LayoutBody/Text';
import media from '~/locales/ru/Template/ContentLayout/RCS/LayoutBody/Media';
import rich_card from '~/locales/ru/Template/ContentLayout/RCS/LayoutBody/RichCard';
import rich_card_carousel from '~/locales/ru/Template/ContentLayout/RCS/LayoutBody/RichCardCarousel';
import suggestion from '~/locales/ru/Template/ContentLayout/RCS/LayoutBody/Suggestion';

export default {
  text,
  media,
  rich_card,
  rich_card_carousel,
  suggestion,
}

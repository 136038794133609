import api from '~/api';
import ObserverApi from '~/utils/ObserverApi';
import { getDispatchDocumentOnePromises } from '~/store/dispatch-document';
import { throttle } from '@/utils/functions';
import { createQuickTask } from '~/api/template/quick-task';

const SCHEDULED_DATE_FORMAT = 'YYYY-MM-DD\\THH:mm:ss.SSSSSSSSS\\Z';

let launchADispatch;
const launchADispatchFetch = () =>
  api.scheduler.template.launchADispatch
    .list()
    .then((response) => (launchADispatch = response?.data?.id));

export const state = () => ({
  list: {},
  count: 0,
});

export const getters = {
  count: ({ count }) => count,
};

export const mutations = {
  setList(store, list) {
    store.list = list;
  },
  updateCount(store, count) {
    store.count = count;
  },
};

export const actions = {
  async list(
    { dispatch, commit, state },
    {
      page = 1,
      pagesize = 10,
      sort = '',
      observer = null,
      relate = [],
      ...options
    } = {}
  ) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    if (sort.length > 0) sort += ',';
    sort += 'id';

    if (!launchADispatch) await launchADispatchFetch();

    const response = await api.scheduler.task
      .list(page, pagesize, sort, { template_id: launchADispatch, ...options })
      .catch((error) => {
        return {
          items: [],
          totalRows: 0,
        };
      });

    const items = await Promise.all(
      (response.data?.Body || []).map(async (schedulerTask) => {
        try {
          let itemNew = {};
          const itemId = schedulerTask.input_by_operation.find(
            ({ input_values }) => input_values['{dispatchID}']
          ).input_values['{dispatchID}'];
          await getDispatchDocumentOnePromises(observer, itemNew, itemId);
          itemNew = itemNew.dispatch_document;
          if (!itemNew.scheduled) {
            itemNew.scheduled = {
              items: [schedulerTask],
              totalRows: 1,
            };
          }
          return itemNew;
        } catch (e) {
          return false;
        }
      })
    ).then((items) => items.filter((it) => !!it));

    return {
      items,
      totalRows: response.data?.Meta ? response.data.Meta.TotalSize : 0,
    };
  },
  delete({ commit, state }, { dispatch_id }) {
    return Promise.all([
      api.scheduler.task
        .list(1, 9999, '', { entity_id: dispatch_id })
        .then(
          async ({ data }) =>
            await Promise.all(
              (data.Body || []).map(({ id }) => api.scheduler.task.delete(id))
            )
        ),
    ]);
  },
  async addOrUpdate(
    { commit, state, dispatch },
    { dispatch_id: entity_id, scheduled_date }
  ) {
    const schedulers = await dispatch('tasks', { entity_id });
    if (!schedulers.items.length) {
      return dispatch('create', {
        dispatch_id: entity_id,
        scheduled_date,
      });
    }
    return dispatch('edit', {
      ...schedulers.items[0],
      scheduled_date,
    }).then((task) => ({ ...task, status: 'pending' }));
  },
  async create(
    { dispatch, rootState },
    { dispatch_id: entity_id, scheduled_date }
  ) {
    scheduled_date = scheduled_date.format(SCHEDULED_DATE_FORMAT);
    const auto_start = true;

    await dispatch('auth/fetch', undefined, { root: true });

    const response = await createQuickTask('launch-a-dispatch', {
      auto_start,
      entity_id,
      scheduled_date,
      location: rootState.auth.user.timezone,
    });

    return response.data;
  },
  async edit({ dispatch, rootState }, { id, ...data }) {
    data.scheduled_date = data.scheduled_date.format(SCHEDULED_DATE_FORMAT);

    await dispatch('auth/fetch', undefined, { root: true });

    await api.scheduler.task.cancel(id);
    const task = await api.scheduler.task
      .put(id, {
        ...data,
        location: rootState.auth.user.timezone,
      })
      .then(({ data }) => data);
    await api.scheduler.task.activate(id);

    return task;
  },
  async tasks({ commit, state }, { entity_id }) {
    return api.scheduler.task
      .list(1, 10, '', { entity_id })
      .then(({ data }) => ({
        items: data.Body || [],
        totalRows: data.Meta ? data.Meta.TotalSize : 0,
      }));
  },
  getCount: throttle(async function ({ commit }) {
    if (!launchADispatch) await launchADispatchFetch();

    return api.scheduler.task
      .list(1, 999, '', {
        template_id: launchADispatch,
        status: 'pending',
      })
      .then(({ data: { Body = [], Meta: { TotalSize = 0 } } = {} } = {}) => {
        const list = (Body || []).reduce((r, el) => {
          try {
            const id = el.input_by_operation[0].input_values['{dispatchID}'];
            r[id] = el;
          } catch (e) {}
          return r;
        }, {});

        commit('setList', list);
        commit('updateCount', TotalSize);
        return list;
      });
  }, 2000),
};

export function getDispatchDocumentScheduledTasksOnePromises(
  observer,
  item,
  id
) {
  return new Promise(async (resolve) => {
    if (!item) {
      resolve();
    } else if (id) {
      observer.subscribeObserver(
        'dispatch-document/scheduled/tasks',
        { entity_id: id },
        (scheduled) => {
          item.scheduled = scheduled;
          resolve();
        }
      );
    } else {
      item.scheduled = null;
      resolve();
    }
  });
}

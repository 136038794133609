import upload from '~/locales/ru/Company/Document/Upload';
import table from '~/locales/ru/Company/Document/table';
import modal_info from '~/locales/ru/Company/Document/ModalInfo';
import public_access from '~/locales/ru/Company/Document/PublicAccess';
import widget_space from '~/locales/ru/Company/Document/WidgetSpace';

export default {
  title: 'Документы',

  description: '<p> Вы можете прикреплять документы к вашим сообщениям, если это позволяет делать используемый канал рассылки. Также, важные документы от Messaggio будут находиться здесь для скачивания.</p>',

  tab_doc_my: 'Мои документы',
  tab_doc_company: 'Документы компании',
  tab_doc_mes: 'Документы в сообщениях',

  upload,
  table,
  modal_info,
  public_access,
  widget_space,
}

import trailer_profile from '~/locales/ru/ClientSender/TrailerProfile';

export default {
  title: 'Создание отправителя',

  name: 'Название',
  name_placeholder_sms: 'Введите название на английском языке',
  token: 'Token',
  name_en: 'Название на английском',
  code_api: 'Код для API',
  tenant_id: 'Компания',
  tenant_id_placeholder: 'Название компании',
  project_id: 'Проект',
  project_id_placeholder: 'Выберите проект',
  channel_id: 'Канал',
  channel_id_placeholder: 'Выберите канал',
  sender_id: 'Отправитель',
  sender_profile_details: 'Данные профиля отправителя',
  commercial_account_details: 'Данные Коммерческого Аккаунта',
  messaging_details: 'Детали сообщения',
  company_details: 'Сведения о компании',
  status: 'Статус',
  logo_change: 'Обновить логотип',
  logo_chatscreen_change: 'Обновить обложку',
  da_data_inn: 'ИНН, ОГРН или ОГРНИП',

  save_success: 'Готово',

  create_gate_sender: 'Создать гейт сендер?',
  create: 'Создать',
  trailer_profile,
};

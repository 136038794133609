import ru from './RU';
import ua from './UA';
import other from './Other';

export default {
  ru,
  ua,
  other,

  errors: {
    required: {
      contacts: {
        fio: 'Вкажіть ПІБ',
        phone: 'Вкажіть номер телефону',
      },
      company: {
        reg_num: 'VAT номер',
      },
      certificate: {
        shortHash: 'Підтвердження реєстрації обов\'язкове',
      },
      address: {
        state: 'Вкажіть штат',
      },
    },
  },
};

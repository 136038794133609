import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import autoBind from 'auto-bind';
import axios from 'axios';
import { debounce } from 'lodash';

interface WebsocketsGatewayProvider {
  topics: string[];
  onMessage: (data: any) => void;
  Fin: () => void;
}

let ws, pingInterval;

class WebsocketsGateway {
  private self: any;
  private providers: Record<string, WebsocketsGatewayProvider>;

  constructor() {
    autoBind(this);
    this.providers = {};
  }

  addProvider(name, provider) {
    this.providers[name] = provider;
  }

  Init(self) {
    this.self = self;
    this.connect();
  }

  Fin() {
    Object.values(this.providers).forEach((provider) => {
      if (typeof provider.Fin === 'function') {
        provider.Fin();
      }
    });
    ws?.close();
    pingInterval && clearInterval(pingInterval);
  }

  connect() {
    let wsHost;
    if (process.env.API_HOST && process.env.API_HOST.length) {
      wsHost =
        (process.env.API_HOST.startsWith('https') ? 'wss' : 'ws') +
        '://' +
        process.env.API_HOST.substr(7);
    } else {
      wsHost =
        (window.location.protocol.startsWith('https') ? 'wss' : 'ws') +
        '://' +
        window.location.host +
        '/';
    }
    if (!axios.defaults.headers.common || !this.self.$store.state.auth.token) {
      return false;
    }

    wsHost +=
      'ws/websockets-gateway/api/v1/events/connect?t=' +
      this.self.$store.state.auth.token;

    ws = new WebSocket(wsHost);

    ws.onopen = () => {
      this.onOpen();
    };

    ws.onclose = (err) => {
      this.reconnect(err);
    };

    ws.onerror = (err) => {
      this.reconnect(err);
    };

    ws.onmessage = this.onMessage;
  }

  ping() {
    const pingCall = () => {};
    pingInterval && clearInterval(pingInterval);
    pingInterval = setInterval(pingCall, 5 * 60 * 1000);
    pingCall();
  }

  reconnect = debounce(async (err) => {
    if (err.code === 1006) {
      const fingerprint = await getFingerprint();
      await this.self.$store.dispatch('auth/refreshToken', {
        fingerprint:
          typeof fingerprint == 'string' ? fingerprint : fingerprint.hash,
      });
    }

    ws = undefined;
    this.connect();
  }, 5000);

  keepAlive(token) {
    if (!ws) {
      return;
    }
    try {
      ws.send(
        JSON.stringify({
          type: 'keep-alive',
          header: {
            authenticate: 'Bearer ' + token,
            request_id: Date.now() + '-' + Math.random(),
          },
        })
      );
    } catch (e) {}
  }

  onOpen() {
    try {
      const topics = ['converter'];
      Object.values(this.providers).forEach((provider) => {
        if (Array.isArray(provider.topics)) {
          topics.push(...provider.topics);
        } else {
          topics.push(provider.topics);
        }
      });
      ws.send(
        JSON.stringify({
          type: 'subscribe',
          header: {
            topics,
            authenticate: this.self.$store.state.auth.token,
            request_id: Date.now() + '-' + Math.random(),
          },
        })
      );
    } catch (e) {}
  }

  onMessage({ data }) {
    const dataJson = JSON.parse(data);

    if (this.providers[dataJson.header.topic]) {
      this.providers[dataJson.header.topic].onMessage(dataJson);
    } else if (dataJson.header.topic === 'converter') {
      if (dataJson.type === 'notify') {
        this.self.$store.commit('group-contact/convert/SET_TASK_PROGRESS', {
          task_id: dataJson.body.task_id,
          progress: dataJson.body.progress,
          isWS: true,
        });
      }
    }
  }
}

export default new WebsocketsGateway();

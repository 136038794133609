import axios from 'axios';

/**
 * Модель, содержащая в себе входные параметры для некоторого ```task```
 */
export type OperationInput = {
  operation_id?: string;
  /**
   * Входные значения для ```task``` в формате ```{field}:{value}```, где ```{field}``` - поля из массива ```parameters``` модели ```operation_data```
   */
  input_values?: Record<string, any>;
};

/**
 * Некоторая сущность, с которой работает бизнес-процесс
 */
export type Entity = {
  entity_id?: string;
};

/**
 * Сущность, выполняющая некоторый бизнес-процесс.
 * Может быть запланирована (```scheduled_date```) и/или выполнена при создании (```auto_start```)
 */
export type Task = {
  id?: string;
  /**
   * ```id``` бизнес-процесса (```template```)
   */
  template_id?: string;
  /**
   * Дата отложенного выполнения ```task``` в UTC-формате ```0001-01-01T00:00:00Z```
   *
   * @format int64
   */
  scheduled_date?: string;
  /**
   * Если ```true```, то ```task``` будет запущен сразу после создания
   *
   * @format boolean
   */
  auto_start?: boolean;
  /**
   * Входящие значения для каждой ```operation```, участвующей в бизнес-процессе.
   */
  input_by_operation?: OperationInput[];
  /**
   * Список сущностей, участвующих в бизнес-процессе
   */
  entities?: Entity[];
  /**
   * ```body```, которое возвращает последняя по порядку ```operation``` в бизнес-процессе
   */
  output_data?: Record<string, any>;
  /**
   * Статус, в котором находится ```task``` в данный момент времени
   */
  status?: string;
  /**
   * Автоматически проставляется при создании нового ```task``` клиентскими и админскими ручками
   */
  user_id?: string;
  /**
   * Автоматически проставляется при создании нового ```task``` клиентскими ручками
   */
  tenant_id?: string;
  /**
   * @format int64
   */
  created_at?: string;
  /**
   * @format int64
   */
  updated_at?: string;
};

export type CreateQuickTaskV2 = {
  /**
   * @format int64
   */
  scheduled_date?: string;
  entity_id?: string;
  /**
   * @format boolean
   */
  auto_start?: boolean;
  location?: string;
};

export async function createQuickTask(
  templateID: string,
  payload: CreateQuickTaskV2
) {
  return axios.post<Task>(
    `/documents-scheduler/api/v1/templates/${templateID}/quick-task/v2`,
    payload
  );
}

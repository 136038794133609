export default {
  today: 'Today',
  yesterday: 'Yesterday',
  last_7_day: 'Last 7 days',
  last_30_day: 'Last 30 days',
  this_month: 'This month',
  last_month: 'Previous month',

  apply_label: 'Submit',
  cancel_label: 'Cancel',
  start_label: 'Start date',
  end_label: 'End date',
  custom_range_label: 'Select date',
  days_of_week: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
  month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
}

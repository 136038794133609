import api from '~/api';

const baseState = {
  loading: false,
  count: {
    new: undefined,
    all: undefined,
  },
};

export const state = () => ({ ...baseState });

export const getters = {
  allNew: state => !state.loading && state.count.new > 0 && state.count.new === state.count.all,
};

export const mutations = {
  loading (state, loading) {
    state.loading = loading;
  },
  setCount (state, count) {
    state.count = { ...state.count, ...count };
  },
};

export const actions = {
  async fetch ({ commit }) {
    commit('loading', true);

    const count = {
      new: undefined,
      all: undefined,
    };

    await Promise.all([
      api.documents.clientsender.list(1, 1, '', { status: 'new' })
        .then(({ data: { Meta: { TotalSize = 0 } } }) => {
          count.new = TotalSize || 0;
        }),

      api.documents.clientsender.list(1, 1, '')
        .then(({ data: { Meta: { TotalSize = 0 } } }) => {
          count.all = TotalSize || 0;
        }),
    ]);
    commit('setCount', count);

    commit('loading', false);
  },
};

<template>
  <div class="navbar navbar-expand-md navbar-light px-0 border-top-0">
    <div
      v-if="isOnboardingDone"
      class="container position-relative"
    >
      <div class="text-center d-md-none w-100">
        <button
          v-b-toggle.navbar-navigation
          class="navbar-toggler dropdown-toggle"
          type="button"
        >
          <i class="icon-menu7 mr-2" />
          {{ $t('home.navigation') }}
        </button>
      </div>

      <b-collapse
        id="navbar-navigation"
        class="navbar-collapse collapse"
      >
        <ul class="navbar-nav navbar-nav-highlight">
          <widget-menu-left
            v-for="(model, index) in menu_left"
            :key="`menu-left-${index}`"
            :model="model"
          />
        </ul>

        <ul class="navbar-nav navbar-nav-highlight ml-md-auto">
          <li class="nav-item">
            <b-dropdown
              variant="link"
              right
              toggle-class="text-decoration-none navbar-nav-link"
              data-cy="widget-user-dropdown"
            >
              <template slot="button-content">
                {{ $t('dashboard.more') }}
              </template>
              <b-dropdown-item :to="{name: 'company-settings'}">
                <i class="icon-cog3 mr-2" />
                {{ $t("company.settings.title") }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :to="{name: 'short-link'}">
                <i class="icon-unlink mr-2" />
                {{ $t("company.menu.short_link") }}
              </b-dropdown-item>
              <b-dropdown-item :to="{name: 'company-file'}">
                <i class="icon-files-empty mr-2" />
                <span class="mr-2">
                  {{ $t('home.my_document') }}
                </span>
                <span
                  v-if="documentsBadge"
                  class="badge bg-indigo-400 badge-pill ml-auto"
                >
                  {{ documentsBadge }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import WidgetMenuLeft from '@/components/Widget/WidgetMenuLeft';

const menu_left = [
  {
    name: 'home.title',
    router: { name: 'index' },
    icon: 'icon-home4',
  },
  {
    name: 'client_sender.title',
    router: {
      name: 'sender',
      project_separation: true,
    },
    icon: 'icon-price-tags2',
  },
  {
    name: 'chat.title',
    router: { name: 'chat' },
    icon: 'icon-comment-discussion',
  },
  {
    name: 'dispatch_document.title',
    router: {
      name: 'dispatch',
      project_separation: true,
    },
    icon: 'icon-drawer',
  },
  {
    name: 'template.title',
    router: {
      name: 'template',
      project_separation: true,
    },
    icon: 'icon-bubbles8',
  },
  {
    name: 'group_contact.title',
    router: { name: 'contact-group' },
    icon: 'icon-address-book',
  },
  {
    name: 'statistic.title',
    router: {
      name: 'statistic',
      project_separation: true,
    },
    icon: 'icon-stats-bars2',
    iconClassName: 'd-inline-block',
    labelClassName: '',
  },
];

const menu_right = [
  // {name: 'app_service.title', router: {name: 'app-service'}, icon: 'icon-cube4', align: 'right', disabled: true},
  {
    name: 'company.settings.title',
    router: { name: 'company-settings' },
    icon: 'icon-cog3',
    align: 'right',
    iconClassName: 'd-inline-block',
    labelClassName: 'd-none d-xl-inline',
  },
];

export const menu = [
  ...menu_left,
  ...menu_right,
];

export default {
  components: { WidgetMenuLeft },
  data: () => ({
    menu_left,
    menu_right,
  }),
  computed: {
    isOnboardingDone () {
      return this.$store.getters['auth/isOnboardingDone'];
    },
    documentsBadge () {
      try {
        const statsTenants = this.$store.state.company.document.statsTenants.data;
        return Object.keys(statsTenants).reduce((ret, type) => (ret += statsTenants[type].notViewed, ret), 0);
      } catch (e) {
        return 0;
      }
    },
  },
};
</script>

<style>
.navbar-nav-link.active > i {
  color: #2196F3 !important;
}
</style>

<style scoped>
.navbar {
  font-size: 0.9rem;
}
</style>

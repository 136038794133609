export default {
  title: 'Отримувачі шаблону А і шаблону В',
  description: 'Вкажіть в процентах скільки випадково обраних контактів повинні отримати шаблон А. Іншим буде відправлено шаблон В.',

  template_a: 'Шаблон А',
  template_b: 'Шаблон В',

  helpers: 'Щоб результати A/B тесту були показовими, ми рекомендуємо встановлювати % отримувачів шаблону А не меньше ніж 15%.<br><br>Після збереження буде створено дві розсилки зі вказаними шаблонами. Кожну з них потрібно запустити самостійно або запланувати відправку у потрібний час.',

  contacts_split_proportion: 'пропорція',
}

import api from '~/api';

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  list({commit, state}, {page = 1, pagesize = 10} = {}) {
    return api.librarium.viber_rate.list(page, pagesize).then(response => {
      //console.info('api.librarium.viber_rate.list | response = ', response);
      return {
        items: response.data,
        totalRows: parseInt(response.headers['x-total'] || 0),
      };
    }).catch(error => {
      console.error('api.librarium.viber_rate.list | error = ', error);
      throw error;
    });
  },
  one({commit, state}, {stage_id}) {
    return api.librarium.viber_rate.get(stage_id).then(response => {
      //console.info('api.librarium.viber_rate.one | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.viber_rate.one | error = ', error);
      throw error;
    });
  },
  create({commit, state}, {data}) {
    data = {...data};
    data = validDataSave(data);
    return api.librarium.viber_rate.post(data).then(response => {
      //console.info('api.librarium.viber_rate.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.viber_rate.post | error = ', error);
      throw error;
    });
  },
  edit({commit, state}, {stage_id, data}) {
    data = {...data};
    delete data.code;
    data = validDataSave(data);
    return api.librarium.viber_rate.put(stage_id, data).then(response => {
      //console.info('api.librarium.viber_rate.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.viber_rate.put | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {stage_id}) {
    return api.librarium.viber_rate.delete(stage_id).then(response => {
      //console.info('api.librarium.viber_rate.put | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.librarium.viber_rate.put | error = ', error);
      throw error;
    });
  },
};


function validDataSave(data) {
  delete data.stage_id;
  delete data.created_at;

  return data;
}

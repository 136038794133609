export default {
  whitelist_title: 'Выберите группы контактов для рассылки',
  whitelist_btn: 'Выберите группы контактов',

  blacklist_title: 'Выберите группы контактов для исключения',
  blacklist_btn: 'Выберите группы контактов',

  q_placeholder: 'Поиск группы контактов',

  name: 'Название',
  quantity: 'Количество',

  whitelist_modal_title: 'Группы контактов для рассылки',
  whitelist_modal_body: 'Выберите группы контактов для рассылки',

  blacklist_modal_title: 'Группы контактов для исключения из рассылки',
  blacklist_modal_body: 'Выберите группы контактов для исключения из рассылки',

  add_selected: 'Добавить выбранные',

  tab_all: 'Все контакты',
  tab_favorite: 'Избраные контакты',

  clear_confirm: 'Вы действительно хотите очистить список групп контактов?',

  create_group: 'Создать группу',

  contacts_1: 'контакт',
  contacts_2: 'контакта',
  contacts_5: 'контактов',
}

export default {
  title: 'Contact Groups',
  for_dispatch_document: 'Contact groups - recipients',
  for_exception: 'Contact groups to exclude',
  recipients: 'Recipients',
  excluded_contacts: 'Excluded contacts',

  list_today: {
    title: 'Recent contact groups (one-click add)',
  },
}

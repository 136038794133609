import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';
import axios from 'axios';
import { Validator } from 'vee-validate';

import en from '~/locales/en/index';
import uk from '~/locales/uk/index';
import ru from '~/locales/ru/index';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, uk, ru },
  silentTranslationWarn: true,
});

export default ({ app, store }) => {
  const { locale } = store.state;

  moment.locale(locale);
  Validator.localize(locale);
  i18n.locale = locale;

  loadLanguageAsync(locale).then(() => (app.i18n = i18n));
};

const loadedLanguages = []; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  process.browser && document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(`~/locales/${lang}/index.js`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}

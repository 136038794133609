export default {
  whitelist_title: 'Select contact groups to deliver the message',
  whitelist_btn: 'Select contact groups',

  blacklist_title: 'Select contact groups to exclude recipients',
  blacklist_btn: 'Select contact groups',

  q_placeholder: 'Search contact group',

  name: 'Name',
  quantity: 'Quantity',

  whitelist_modal_title: 'Contact groups for dispatch',
  whitelist_modal_body: 'Select contact groups as recipients of the dispatch',

  blacklist_modal_title: 'Contact groups to exclude from dispatch',
  blacklist_modal_body: 'Select contact groups you don\'t want to deliver messages (blacklists)',

  add_selected: 'Add selected',

  tab_all: 'All contacts',
  tab_favorite: 'Favorite contacts',

  clear_confirm: 'Do you really want to clear contact group list?',

  create_group: 'Create group',

  contacts_1: 'contact',
  contacts_2: 'contacts',
  contacts_5: 'contacts',
}

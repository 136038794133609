export default {
  name: 'Назва групи',
  name_placeholder: 'Група контактів {name}',
  description: 'Опис групи',
  description_placeholder: 'Мої клієнти',
  has_dups: 'Вимкнути перевірку на дублікати" (Якщо ця опція увімкнена, спроба відправки на цей номер буде здійснюватися стільки разів, скільки вказано в книзі)',
  type: 'Який тип контактів ви плануєте завантажити?',
  message_type: 'Тип повідомлення',
  tenant_id: 'Компанія',
  file: 'Файл',
  row_count: 'Кількість.',
  updated_at: 'Оновлено',

  message_type_tel: 'Номер телефону',

  save_success: 'Готово',

  whitelist: 'Отримувачі розсилки',
  blacklist: 'Контакти для стоп-листа',

  knowledge_step: 'Крок {step}',
  knowledge_step1_description: 'Створіть групу контактів з потрібним типом. Вкажіть ім`я і опис групи, якщо хочете.',
  knowledge_step2_description: 'Завантажте CSV файл контактів або скопіюйте їх в форму для обробки.',
  knowledge_step3_description: 'Задайте призначення кожній колонці: вкажіть колонку з номерами телефонів і колонки, що містять параметри контактів.',
  knowledge_base_body: 'Групи контактів використовуються для формування списку розсилки. Ви можете створити і використовувати в розсилці скільки завгодно груп контактів для розсилки, так і для виключення з неї.<br><br>Завантажуйте контакти з додатковими параметрами, які використовуватимуться в фінальному тексті шаблона повідомлень через змінні.',
  knowledge_base_btn: 'Глосарій',
}

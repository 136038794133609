import modal_create_link from '~/locales/en/Template/EmojioneArea/TemplateShortLink/ModalCreateLink';
import modal_static_link from '~/locales/en/Template/EmojioneArea/TemplateShortLink/ModalStaticLink';

export default {
  title_btn: 'Short link',

  create_link_btn: 'Create link',
  statistic_link_btn: 'Static link',
  dynamic_link_btn: 'Dynamic link',

  modal_create_link,
  modal_static_link,
}

import region_option from '~/locales/ru/ClientSender/TrailerProfile/RCS/RegionOption';

export default {
  service_name_original_language: 'Название (на оригинальном языке)',
  service_name_english_language: 'Название (на английском)',
  region: 'Регион',
  region_option,
  description: 'Описание',
  description_helper: '(до 100 знаков)',
  chat_dialog_color: 'Цвет диалога чата',
  logo_img: 'Логотип отправителя (224 x 224)',
  logo_chatscreen: 'Обложка (1440 x 448)',
  main_phone_number: 'Номер телефона компании',
  primary_website: 'Адрес сайта',
  primary_email: 'Email компании',
  privacy_policy_link: 'Ссылка на политику конфиденциальности',
  terms_of_service_link: 'Ссылка на политику использования сервиса',
}

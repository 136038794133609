export default {
  title: 'Шаблон рассылки',
  title_ab: 'Шаблон {type}',

  tab_all: 'Все шаблоны сообщений',
  tab_favorite: 'Избранные шаблоны',

  select_project_title: 'Выберите проект',
  title_select_template: 'Выберите шаблон',

  draggable_description: 'Выберите для отправки необходимые сообщения, используя переключатель "Отправить". <br><br>Перетащите блоки сообщений в нужной очередности доставки: если контакту не будет доставлено сообщение #1, то будет сделана попытка доставить сообщение #2 и так далее.',

  select_project: 'Укажите проект, из которого следует выбрать шаблон',
  project_id: 'Проект',
  project_id_placeholder: 'Выберите проект',
  project_id_placeholder_empty: 'Нет проектов с активными шаблонами',

  select_another_template: 'Выбрать другой шаблон',

  not_delivered_send_to: 'Если не доставлено, отправится:',
  templates_not_found: 'Не удалось найти шаблон с таким названием.',

  create: {
    description: 'Выберите сообщения, которые вы хотите отправить, используя переключатель «Отправить».<br><br>' +
      'Перетащите блоки сообщений в желаемом порядке доставки: если сообщение №1 не доставлено контакту, ' +
      'будет сделана попытка доставить сообщение №2 и так далее.',
  },

  send: 'Отправить',

  btn_create: 'Создать шаблон',

  send_template_for_moderation: 'Отправить шаблон на модерацию?',
};

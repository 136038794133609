export default {
  title: 'Создать ссылку',

  project_id: 'Проект',
  select_project: 'Проект',
  select_project_title: 'Выберите проект',
  link_shortening: 'Короткая ссылка',
  select_domain: 'Домен',
  domain_id: 'Домен',
  uri_long: 'Длинный URL',
  uri_short: 'Короткий URL',
  created_at: 'Создано',
  updated_at: 'Обновлено',

  save_success: 'Готово',
}

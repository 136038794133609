import modal_verification from '~/locales/en/Company/Testphone/ModalVerification';
import form from '~/locales/en/Company/Testphone/form';
import form_params from '~/locales/en/Company/Testphone/FormParams';
import table from '~/locales/en/Company/Testphone/table';

export default {
  title: 'Test phone',
  title_create: 'Test phone',
  title_show: 'Test phone {phone}',
  title_edit: 'Test phone {phone}',

  phone_empty: 'There are no test phones linked to your account.',
  phone_empty_project_one_you_can: '',
  phone_empty_project_one_create: 'Link your phone number',
  phone_empty_project_one_create_after: 'to the account to send message templates without moderation.',

  create: 'Link phone number',

  filter_q_placeholder: 'Type to filter...',

  modal_verification,
  form,
  form_params,
  table,
}

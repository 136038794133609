import table from '~/locales/uk/Company/Favorite/table';

export default {
  title: 'Обране',

  favorite_empty: 'У вас ще нічого немає в обраному.',
  favorite_empty_project_one_you_can: 'Ви можете додати до обраного',
  favorite_empty_project_one_create: '',
  favorite_empty_project_one_create_after: 'будь-який елемент в системі - просто відмітьте його зірочкою.',

  table,
};

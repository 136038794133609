import form from '~/locales/uk/GroupContact/form';
import table from '~/locales/uk/GroupContact/table';
import show from '~/locales/uk/GroupContact/show';
import file_upload_modal from '~/locales/uk/GroupContact/FileUploadModal';
import contact from '~/locales/uk/GroupContact/Contact';
import card from '~/locales/uk/GroupContact/Card';
import list from '~/locales/uk/GroupContact/list';

export default {
  title: 'Групи контактів',
  title_create: 'Створити групу контактів',
  title_show: 'Група контактів {name}',
  title_edit: 'Редагування групи {name}',

  q_placeholder: 'Номер телефону або параметр',
  filter_q_placeholder: 'Назва',

  wl_title: 'Групи контактів для розсилки',
  bl_title: 'Групи контактів для вилучення',
  search_title: 'Групи контактів',

  group_contact_wl_empty: 'У вашого аккаунту немає груп контактів.',
  group_contact_wl_empty_project_one_you_can: 'Ви можете',
  group_contact_wl_empty_project_one_create: 'створити групу',
  group_contact_wl_empty_project_one_create_after: 'та імпортиртувати в нії контакти.',

  group_contact_bl_empty: 'У вашего аккаунту немає груп контактів для виключення.',
  group_contact_bl_empty_project_one_you_can: '',
  group_contact_bl_empty_project_one_create: 'Створіть групу',
  group_contact_bl_empty_project_one_create_after: 'і завантажте контакти, які потрібно вилучити з розсилок.',

  form,
  table,
  show,
  file_upload_modal,
  contact,
  card,
  list,
};

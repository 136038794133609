export default {
  company: {
    title: 'Вкажіть інформацію про вашу компанию',
    name: {
      title: 'Коротка назва юридичної особи',
    },
    reg_num: {
      title: 'Реєстраційний номер юридичної особи',
    },
    vat: {
      title: 'EU VAT номер',
      description: 'Вкажіть європейський VAT номер вашої компанії, напр. DE123456789 або залиште поле порожнім.',
    },
  },
  certificate: {
    name: {
      title: 'Підтвердження реєстрації юридичної особи',
      description: 'Додайте документ, що підтверджує реєстрацію вашої юридичної особи.',
      subdescription: 'Формати файлів, що підтримуються:',
    },
  },
  address: {
    title: 'Адреса',
    city: {
      title: 'Місто',
    },
    state: {
      title: 'Штат',
    },
    index: {
      title: 'Поштовий індекс',
    },
    legal: {
      title: 'Адреса',
    },
  },
  tax: {
    title: 'Вид оподаткування',
    system: {
      title: 'Ваша схема оподаткування',
      USNO: 'Спрощена/єдиний податок',
      OSNO: 'На загальних засадах (Платник ПДВ)',
    },
    ogrn: {
      title: 'ЭДРПОУ (реэстраційний номер)',
    },
    inn: {
      title: 'ІПН',
    },
    kpp: {
      title: 'КПП',
    },
  },
  confirm: {
    text: 'Я, законний представник {company_name}, ознайомився з Договором приєднання Messaggio LTD. і прийимаю его умови.',
  },
};

<template>
  <div class="layout-default-bg">
    <div v-if="!isAuth" class="page-content">
      <div class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
          <div class="flex-fill">
            <div class="text-center mb-3">
              <div class="theme_corners">
                <img
                  src="~/static/messaggio_logo_logo.png"
                  class="flash"
                  width="64"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="full-height">
      <div class="page-header page-header-light border-bottom-0">
        <!-- Main navbar -->
        <menu-main />
        <!-- /main navbar -->

        <!-- Page header content -->
        <div class="page-header-content container header-elements-md-inline">
          <div
            class="header-elements bg-transparent py-0 border-0 mb-3 mb-md-0"
          >
            <widget-chat-ws-connect />
            <div class="alert-default-top">
              <widget-balance-ended />
              <widget-user-verified
                v-if="showWidgetUserVerified"
                :user="user"
              />
            </div>
          </div>
        </div>
        <!-- /page header content -->
        <!-- Secondary navbar -->
        <menu-secondary />
        <!-- /secondary navbar -->
      </div>
      <div class="page-content container pt-0">
        <!-- Main content -->
        <div :class="{ 'content-wrapper': isContentWrapper }">
          <!-- Content area -->
          <div class="default content">
            <div
              v-if="showPageTitle"
              class="page-title d-flex justify-content-between justify-content-sm-start"
            >
              <h4>
                {{ $t($store.state.page.title) }}
              </h4>
              <small
                v-if="$store.state.page.subtitle.length"
                class="font-size-base opacity-50"
              >
                {{ $t($store.state.page.subtitle) }}
              </small>
              <widget-select-project
                v-show="showWidgetSelectProject"
                class="ml-sm-3"
              />
            </div>
            <div v-else class="d-none d-sm-block" style="height: 20px" />
            <widget-alert-company-fill />
            <nuxt-child />
          </div>
          <!-- /content area -->
        </div>
        <!-- /main content -->
      </div>
    </div>
    <widget-otp />
    <widget-web-chat />
  </div>
</template>

<script>
import moment from 'moment';
import { resetAuthToken, setAuthToken } from '~/utils/auth';
import { onTitleUpdate } from '~/components/Mixin/MixinTitle';
import WidgetSelectProject from '~/components/Project/WidgetSelectProjectComponent.vue';
import ServiceChat from '~/components/Chat/ServiceChat';
import WidgetBalanceEnded from '~/components/Widget/WidgetBalanceEnded.vue';
import WidgetUserVerified from '~/components/Widget/WidgetUserVerified.vue';
import WidgetChatWsConnect from '~/components/Widget/WidgetChatWsConnect.vue';
import WidgetAlertCompanyFill from '~/components/Widget/Alert/WidgetAlertCompanyFill.vue';
import { setLang } from '~/components/Widget/WidgetLangDropdown';
import { logout } from '~/components/Widget/WidgetUserDropdown';
import { catchDispatch } from '~/utils/form';
import MenuMain from '~/components/Dashboard/MenuMainBoxDashboardComponent.vue';
import MenuSecondary from '~/components/Dashboard/MenuSecondaryBoxDashboardComponent.vue';
import WidgetWebChat from '~/components/Widget/WidgetWebChat.vue';
import ServiceWebsocketsGateway from '~/components/WebsocketsGateway/WebsocketsGateway';
import { ConvertMemory } from '~/components/GroupContact/Contact/AddPhone/ConvertService';

export default {
  components: {
    MenuMain,
    MenuSecondary,
    WidgetSelectProject,
    WidgetBalanceEnded,
    WidgetUserVerified,
    WidgetChatWsConnect,
    WidgetAlertCompanyFill,
    WidgetWebChat,
  },
  data() {
    return {
      chatCountersInterval: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isAuth() {
      return !!this.user;
    },
    selectProject() {
      try {
        return this.$store.state.projects[this.$store.state.select_project];
      } catch (e) {
        return false;
      }
    },
    pageHeaderDarkBg() {
      const year = moment().year();
      const find = [
        { from: '09-01', to: '11-10', bg: 'autumn' },
        { from: '11-11', to: '01-10', bg: 'new-year' },
        { from: '01-11', to: '03-10', bg: 'winter' },
        { from: '03-11', to: '06-10', bg: 'spring' },
        { from: '06-11', to: '09-01', bg: 'summer' },
      ].find((item) => {
        const from = moment(year + '-' + item.from);
        const to = moment(year + '-' + item.to);
        const range = to.diff(from, 'days');
        const rangeMoment = moment().diff(from, 'days');
        if (range < 0 && rangeMoment < 0) {
          from.add(-1, 'years');
        } else if (range < 0) {
          to.add(1, 'years');
        }
        return moment().isBetween(from, to);
      });
      if (!find) {
        return '';
      }
      return 'page-header-dark-bg-' + find.bg;
    },
    isContentWrapper() {
      return !!['dispatch-id-edit', 'dispatch-create'].find(
        (name) =>
          this.$route.name &&
          (this.$route.name.startsWith(name) ||
            this.$route.name.startsWith(`project-project_id-${name}`))
      );
    },
    showWidgetSelectProject() {
      const hide = [
        'dispatch-id-edit',
        'dispatch-id-launch',
        'dispatch-id-show',
      ].find(
        (name) =>
          this.$route.name &&
          (this.$route.name.startsWith(name) ||
            this.$route.name.startsWith(`project-project_id-${name}`))
      );
      if (hide) {
        return false;
      }

      return !!['sender', 'dispatch', 'template'].find(
        (name) =>
          this.$route.name?.startsWith(name) ||
          this.$route.name?.startsWith(`project-project_id-${name}`)
      );
    },
    gradient() {
      const returnDefault =
        'radial-gradient(ellipse at top, #51566e 13%, #3b3f51 100%)';
      if (!this.showWidgetSelectProject) {
        return returnDefault;
      }
      try {
        const bg_color =
          this.selectProject.trailer.style.bg_color.value.substr(1);

        const ratio = 1.7;
        const hex = (x) => ((x = x.toString(16)), x.length === 1 ? '0' + x : x);
        const r = Math.ceil(parseInt(bg_color.substring(0, 2), 16) * ratio);
        const g = Math.ceil(parseInt(bg_color.substring(2, 4), 16) * ratio);
        const b = Math.ceil(parseInt(bg_color.substring(4, 6), 16) * ratio);
        const bg_color_start = hex(r) + hex(g) + hex(b);
        // console.info('pageHeaderStyle | bg_color_start = ', bg_color_start);

        return (
          'radial-gradient(ellipse at top, #' +
          bg_color_start +
          ' 13%, #' +
          bg_color +
          ' 100%)'
        );
      } catch (e) {
        // console.error('gradient | e = ', e);
        return returnDefault;
      }
    },
    documentsBadge() {
      const statsTenants = this.$store.state.company.document.statsTenants.data;
      return Object.keys(statsTenants).reduce(
        (ret, type) => ((ret += statsTenants[type].notViewed), ret),
        0
      );
    },
    showPageTitle() {
      if (!this.$store.state.page.title) {
        return false;
      }
      return ![
        'sender-id-',
        'dispatch-id-',
        'template-id-',
        'contact-group-id-',
      ].find(
        (name) =>
          this.$route.name &&
          (this.$route.name.startsWith(name) ||
            this.$route.name.startsWith(`project-project_id-${name}`))
      );
    },
    showWidgetUserVerified() {
      return !/^onboarding/.test(this.$route.name);
    },
  },
  watch: {
    '$store.state.page.title'() {
      onTitleUpdate.call(this);
    },
    '$store.state.page.subtitle'() {
      onTitleUpdate.call(this);
    },
    '$store.state.auth.global'(global) {
      if (global) {
        this.onUpdateAuthGlobal();
      }
    },
    isAuth(isAuthNew, isAuthOld) {
      isAuthOld && !isAuthNew && logout.call(this);
    },
    '$route.name'() {
      this.onUpdateAuthGlobal();
    },
  },
  async created() {
    let locale;
    if (process.browser) {
      locale = window.localStorage.getItem('locale') || 'en';
    } else {
      locale = this.$store.state.locale;
    }
    locale && setLang.call(this, locale);

    if (process.browser) {
      if (!this.$store.state.auth.user) {
        const token = this.$store.state.auth.token
        if (token) {
          setAuthToken(token);
          await this.$store
            .dispatch('auth/fetch')
            .catch(() => resetAuthToken());
        } else {
          resetAuthToken();
        }
      }
      (function onTitleUpdateInterval() {
        if (!this.$store.state.page.title) {
          setTimeout(onTitleUpdateInterval.bind(this), 100);
        } else {
          onTitleUpdate.call(this);
        }
      }).call(this);
      this.$store.dispatch('projects');
    }

    Promise.all([
      this.$store.dispatch('company/document/statsTenants'),
      this.$store.dispatch('directory/timezone/listPreLoad'),
      this.$store.dispatch('dispatch-document/scheduled/getCount'),
      this.$store.dispatch('chat/updateCounters'),
      this.$store.dispatch('widget/balance/one'),
      this.$store.dispatch('clientsender/countByStatus/fetch'),
    ]);
  },
  mounted() {
    try {
      ServiceChat.Init(this);
      ServiceWebsocketsGateway.addProvider('chat', ServiceChat);
      ServiceWebsocketsGateway.Init(this);
      new ConvertMemory(this).run();
    } catch (e) {
      catchDispatch(this, e);
    }
  },
  beforeDestroy() {
    ServiceWebsocketsGateway.Fin();
  },
  methods: {
    onUpdateAuthGlobal() {
      const global = this.$store.state.auth.global;
      if (
        global['global.onboarding.done'] === undefined &&
        this.$route.name !== 'onboarding'
      ) {
        this.$router.push({ name: 'onboarding' });
      }
    },
  },
};
</script>

<style>
.layout-default-bg {
  min-height: 100%;
  background: linear-gradient(180deg, #f8f8f885, #fff 100%);
}

.navbar-boxed .nav.navbar-nav.navbar-right .b-dropdown.dropdown {
  margin-top: 5px;
}

.navbar-boxed .nav.navbar-nav.navbar-right .b-dropdown.dropdown .dropdown-menu {
  margin-top: 5px;
}

.full-height {
  display: flex;
  flex-direction: column;
}

.page-content {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}
</style>

export const state = () => ({
  tasks: {},
});

export const getters = {};

export const mutations = {
  addTask(store, { id }) {
    store.tasks = {
      ...store.tasks,
      [id]: id,
    };
  },
};

export const actions = {
  addTask({ commit }, payload) {
    commit('addTask', payload);
  },
};

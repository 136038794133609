export default {
  title: 'Запланованна відправка',
  description:
    'Вкажіть дату і час, коли розсилка повинна запуститись.<br>Ви завжди можете скасувати заплановану розсилку до моменту запуска.',

  send_right_now: 'Відправити прямо зараз',
  send_tomorrow: 'Надіслати завтра в {time} {offset}',
  send_at: 'Надіслати {time} {offset}',

  no_timezone: 'Часовий пояс не встановлено',
  error_no_timezone:
    'У налаштуваннях не вибрано часовий пояс. Будь ласка, перевірте правильність часового поясу.',
};

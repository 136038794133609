import change_lang from '~/locales/uk/Company/Account/ChangeLang';

export default {
  title: 'Моя компанія',

  name: 'Назва компанії',
  name_placeholder: 'ТОВ Назва компанії',
  description: 'Опис компанії',
  description_placeholder: 'Кілька слів про ваш бізнес',
  location_country: 'Страна знахождення',

  finance_doc_title: 'Фінансові документи і сповіщення',
  finance_doc_description: 'Будь ласка, вкажіть email вашої бухгалтерії для відправки актів і рахунків та інших сповіщень фінансового і юридичного характеру.',
  email_finance_doc: 'Email для фінансовых документів',
  email_finance_doc_placeholder: 'Email для фінансовых документів',

  btn_save: 'Зберегти зміни',
  save_success: 'Готово',

  change_lang,
  accept_offer: {
    description: 'Налавштування акаунту оновлені!<br>Вам потрібно прийняти умови угоди-оферти для початку роботи.',
    btn_go: 'Перейти далі',
  },
};

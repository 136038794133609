const DOCUMENT = "document";
var ENTITY;
(function(ENTITY2) {
  ENTITY2["PROJECT"] = "project";
  ENTITY2["CHANNEL"] = "channel";
  ENTITY2["CLIENT_SENDER"] = "clientsender";
  ENTITY2["TEMPLATE"] = "template";
  ENTITY2["DISPATCH"] = "dispatch";
  ENTITY2["CONTACT"] = "contact";
})(ENTITY || (ENTITY = {}));
export {
  DOCUMENT,
  ENTITY
};

import api from '~/api';
import { ActionTree, GetterTree, MutationTree } from '~/node_modules/vuex';
import { RootState } from '~/store';

export const state = () => ({});

export type ABFormDispatchDocumentState = ReturnType<typeof state>

export const getters: GetterTree<ABFormDispatchDocumentState, RootState> = {};

export const mutations: MutationTree<ABFormDispatchDocumentState> = {};

export const actions: ActionTree<ABFormDispatchDocumentState, RootState> = {
  create (_, {
    name: dispatch_name, project_id,
    group_contact,
    contents_a, contents_b,
    contacts_split_proportion,
  }) {
    const contact_ids = [
      ...group_contact.recipient,
      ...group_contact.exception,
    ];

    contacts_split_proportion /= 100;

    return api.saga.dispatch.ab
      .post({
        dispatch_name,
        project_id,
        contact_ids,
        contents_a,
        contents_b,
        contacts_split_proportion,
      })
      .then(({ data: { event_id } }) => event_id);
  },
  progress (_, { event_id }) {
    return api.saga.dispatch.ab.progress(event_id).then(({ data }) => data);
  },
};

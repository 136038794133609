export default {
  title: 'Настройки чатов',

  group_contact: {
    modal: {
      title: 'Выберите группу контактов',
    },
  },

  contact_group_title: 'Сохранение контактов в группу',
  contact_group_description: 'Вы можете сохранить контакты абонента, обратившегося к вам через мессенджер.<br>' +
    'В дальнейшем его имя и указанные дополнительные параметры будут отображаться в диалоге чата.',
  contact_group_label: 'Сохранить все контакты в группу',

  assign_user_title: 'Распределение обращений в чатах',
  assign_user_description: 'Укажите пользователя системы, который будет получать все новые поступающие обращения в чатах.<br>' +
    'Если конкретный пользователь не указан, чаты доступны для ответа любому пользователю.',
  assign_user_label: 'Назначить новый чат на оператора',

  auto_response_title: 'Автоматические ответы системы',
  auto_response_description: 'Укажите текст, который сразу получит абонент в ответ на свое обращение.<br>' +
    'Обычно в тексте автоматического ответа содержится просьба немного подождать для ответа оператора.',
  auto_response_label: 'Автоматический ответ на новый чат',

  reassign_notify_description: 'Укажите текст, который получит абонент при назначении чата на другого оператора.<br>' +
    'Чтобы проинформировать абонента о смене собеседника задайте текст уведомления о подключении нового сотрудника в переписку.',
  reassign_notify_label: 'Автоматический ответ при смене оператора в чате',

  fast_answers_title: 'Быстрые ответы в чате',
  fast_answers_description: 'Укажите до 10 фраз, которые операторы могут использовать для быстрого ответа абоненту в переписке.<br>' +
    'Готовые ответы существенно экономят время в начале и завершении диалога.<br><br>' +
    'Перетащите строки в том порядке, в котором планируете отобразить подсказки в диалоге чата.',

  save_success: 'Saved successfully',
};
